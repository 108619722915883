import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Block } from 'src/__generated__/graphqlTypes';
import { HoroscopesSignsAllAboutSigns } from 'components/Sections/HoroscopesSignsAllAboutSigns';
import { Store } from 'app-redux/types/storeTypes';
import {
  AllAboutSignsContainerInterface,
  HoroscopesSignsAllAboutSignsContainerSlug as Slug,
  AllAboutSignsType,
} from 'components/Sections/HoroscopesSignsAllAboutSigns/declarations';

const HoroscopesSignsAllAboutSignsContainer: React.FC<AllAboutSignsContainerInterface> = ({
  blocks,
}) => {
  const slug = useSelector((store: Store) => store.server.page.slug);
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const [url, setUrl] = useState<string>('');
  const getAccumulatorWithBlockItem = (accumulator: AllAboutSignsType, item: Block) => {
    const { slug } = item as Block;

    switch (slug) {
      case Slug.TITLE: {
        accumulator.title = item.content!;

        return accumulator;
      }
      case Slug.SOCIAL_MEDIA: {
        accumulator.socialSharing = item;

        return accumulator;
      }
      case Slug.OTHER_SIGNS: {
        accumulator.otherSigns = item;

        return accumulator;
      }
      default: {
        return accumulator;
      }
    }
  };

  const parseAllAboutSignsContentArray = () => blocks
    ?.reduce((accumulator, item) => {
      if (item.__typename === 'Block') {
        return getAccumulatorWithBlockItem(accumulator, item);
      }

      if (item.__typename === 'ZodiacSignFeatures') {
        accumulator.features = item;
      }

      return accumulator;
    }, {} as AllAboutSignsType);
  const {
    title,
    socialSharing,
    features,
    otherSigns,
  }: AllAboutSignsType = parseAllAboutSignsContentArray();
  const titleId = `all-about-${slug}`;

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    window.__sharethis__?.initialize();
  }, []);

  useEffect(() => {
    setUrl(`${window.location.origin}${window.location.pathname}#${titleId}`);
  }, [titleId]);

  return (
    <HoroscopesSignsAllAboutSigns
      titleId={titleId}
      url={url}
      title={title!}
      socialSharing={socialSharing}
      features={features!}
      otherSigns={otherSigns!}
      isMobileViewWidth={isMobileViewWidth}
    />
  );
};

export default HoroscopesSignsAllAboutSignsContainer;
