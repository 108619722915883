import cookie from 'js-cookie';

export const setMultipleCookies = (
  cookies: { [key: string]: string | object },
  options: { [key: string]: any } = {},
) => {
  const defaultOptions = {
    domain: '',
    secure: process.env.NODE_ENV === 'production',
    path: '/',
  };

  Object.keys(cookies).forEach((name) => {
    let value: string;

    if (typeof cookies[name] === 'object') {
      value = JSON.stringify(cookies[name]);
    } else {
      value = cookies[name] as string;
    }

    cookie.set(name, value, { ...defaultOptions, ...options });
  });
};
