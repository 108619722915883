import {
  FC,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import type { Store } from 'app-redux/types/storeTypes';
import { useClickOutside } from 'lib/shared.hook';

import styles from './styles.module.scss';
import ECView from './ECView';
import NCView from './NCView';

import type { IAuthInteractive } from '../config/declarations';

const AuthInteraction: FC<IAuthInteractive> = ({ auth }) => {
  const [isFlyoutVisible, setFlyoutVisibilityState] = useState<boolean>(false);
  const signInRef = useRef(null);
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);
  useClickOutside(signInRef, setFlyoutVisibilityState, false);

  if (!auth) {
    return null;
  }

  if (isAuthenticated) {
    return (
      <div className={styles.auth}>
        <ECView auth={auth} />
      </div>
    );
  }

  return (
    <div className={styles.auth}>
      <NCView
        signInRef={signInRef}
        auth={auth}
        isShownForm={isFlyoutVisible}
        setShownFormState={setFlyoutVisibilityState}
      />
    </div>
  );
};

export default AuthInteraction;
