import { FC } from 'react';

import { PsychicRate } from 'components/Shared/SharedComponents';

import { PsychicRateInterface } from '../config/declarations';

const PsychicRateWrapper: FC<PsychicRateInterface> = ({
  wrapperStyle,
  commonPriceStyle,
  discountStyle,
  commonWithDiscountStyle,
  price,
  discount,
}) => {
  const priceStyles = {
    wrapper: wrapperStyle,
    common: commonPriceStyle,
    commonWithDiscount: commonWithDiscountStyle,
    discount: discountStyle,
  };

  return (
    <PsychicRate
      pricePerMinute={price}
      priceWithDiscountPerMinute={discount}
      classNames={priceStyles}
    />
  );
};

export default PsychicRateWrapper;
