import { FC } from 'react';
import cn from 'classnames';
import dynamic from 'next/dynamic';

import styles from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/HoroscopesAndPredictions.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import Divider from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/Divider';
import { capitalizeFirstLetter } from 'lib/text.service';
import {
  HoroscopesAndPredictionsInterface,
  HoroscopesAndPredictionsLeftInterface as LeftContentInterface,
  HoroscopesAndPredictionsRightInterface as RightContentInterface,
} from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/declarations';

const DailyHoroscope = dynamic(() => import('components/Sections/HoroscopesSignsHoroscopesAndPredictions/DailyHoroscope'));
const MoreHoroscopes = dynamic(() => import('components/Sections/HoroscopesSignsHoroscopesAndPredictions/MoreHoroscopes'));
const HoroscopeNewsletters = dynamic(() => import('components/Sections/HoroscopeNewsletters'));
const TopPsychics = dynamic(async () => {
  const { PsychicsWithTestimonialsContainer } = await import('features/PsychicsWithTestimonials');

  return PsychicsWithTestimonialsContainer;
}, { ssr: false });
const CompatibilityCheck = dynamic(() => import('components/Sections/HoroscopesSignsHoroscopesAndPredictions/CompatibilityCheck'));

/* Nested component */
const LeftContent: FC<LeftContentInterface> = ({
  dailyHoroscope,
  moreHoroscopes,
  freeHoroscope,
  ...commonProps
}) => {
  const isNoContent = !dailyHoroscope && !moreHoroscopes && !freeHoroscope;

  if (isNoContent) {
    return null;
  }

  return (
    <div className={styles.horoscopesAndPredictionsContentLeft}>
      {dailyHoroscope && (<DailyHoroscope block={dailyHoroscope} {...commonProps} />)}
      {moreHoroscopes && (<MoreHoroscopes block={moreHoroscopes} {...commonProps} />)}
      {freeHoroscope && (<HoroscopeNewsletters block={freeHoroscope} {...commonProps} />)}
    </div>
  );
};

/* Nested component */
const RightContent: FC<RightContentInterface> = ({
  topPsychics,
  compatibilityCheck,
  ...commonProps
}) => {
  const isNoContent = !topPsychics && !compatibilityCheck;

  if (isNoContent) {
    return null;
  }

  return (
    <div className={styles.horoscopesAndPredictionsContentRight}>
      {topPsychics && (<TopPsychics block={topPsychics} {...commonProps} />)}
      {compatibilityCheck && (<CompatibilityCheck block={compatibilityCheck} {...commonProps} />)}
    </div>
  );
};

/* Main component */
const HoroscopesAndPredictions: FC<HoroscopesAndPredictionsInterface> = ({
  topDivider,
  dailyHoroscope,
  moreHoroscopes,
  freeHoroscope,
  topPsychics,
  compatibilityCheck,
  bottomDivider,
  zodiacSign,
  bgColor,
  slug,
}) => {
  const commonProps = {
    zodiacSign,
    slug,
  };

  return (
    <section
      style={{ background: bgColor }}
      className={cn(
        styles.wrapper,
        zodiacSign && styles[`wrapper${capitalizeFirstLetter(zodiacSign)}`],
      )}
    >
      <div className={styles.horoscopesAndPredictions}>
        {topDivider && (
          <Divider
            block={topDivider}
            className={classes.displayNoneMobile}
            {...commonProps}
          />
        )}
        <div className={styles.horoscopesAndPredictionsContent}>
          <LeftContent
            dailyHoroscope={dailyHoroscope}
            moreHoroscopes={moreHoroscopes}
            freeHoroscope={freeHoroscope}
            {...commonProps}
          />
          <RightContent
            topPsychics={topPsychics}
            compatibilityCheck={compatibilityCheck}
            {...commonProps}
          />
        </div>
        {bottomDivider && (
          <Divider
            block={bottomDivider}
            {...commonProps}
            lazy
          />
        )}
      </div>
    </section>
  );
};

export default HoroscopesAndPredictions;
