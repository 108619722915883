import { FC } from 'react';
import cn from 'classnames';

import type { Text } from 'src/__generated__/graphqlTypes';
import LocalLoader from 'src/shared/ui/LocalLoader/LocalLoader';
import { TestimonialBlock } from 'entities/TestimonialBlock';

import styles from './SortedTestimonials.module.scss';
import { Select } from './SortingSelect';

import type { ISortedTestimonials } from '../config/declarations';

const SortedTestimonials: FC<ISortedTestimonials> = ({
  main,
  sorting,
  testimonials,
  isLoading,
  isCollapsed,
  refetch,
}) => {
  if (!testimonials.length || !main || !sorting) {
    return null;
  }

  return (
    <div className={cn(
      styles.sorted,
      { [styles.componentHidden]: isCollapsed },
    )}
    >
      <Select
        options={sorting.contentTypesCollection?.items as Array<Text>}
        label={sorting.title}
        isLoading={isLoading}
        onChange={(item) => refetch(item.fullText!)}
      />
      <div className={styles.items}>
        {isLoading && (
          <div className={styles.loader}>
            <LocalLoader />
          </div>
        )}
        {testimonials.map((testimonial) => (
          <TestimonialBlock
            key={testimonial.id}
            block={main}
            testimonial={testimonial}
          />
        ))}
      </div>
    </div>
  );
};

export default SortedTestimonials;
