import { FC } from 'react';
import { useSelector } from 'react-redux';

import { OrangeAlert } from 'components/Sections/OrangeAlert';
import { SectionComponentInterface } from 'types/componentTypes';
import { Store } from 'app-redux/types/storeTypes';
import { ViewerDevice } from 'constants/enums';

const OrangeAlertContainer: FC<SectionComponentInterface> = ({
  blocks,
  extraData,
  bgColor,
}) => {
  const {
    mobileViewMaxWidth,
    commonTabletMaxWidth,
  } = useSelector<Store, Record<string, string>>((store) => store.server.app.scssVariables);
  const viewerDevice = useSelector<Store, ViewerDevice>((store) => store.server.app.viewerDevice);

  return (
    <OrangeAlert
      blocks={blocks}
      extraData={extraData}
      bgColor={bgColor}
      viewerDevice={viewerDevice}
      mobileViewMaxWidth={mobileViewMaxWidth}
      commonTabletMaxWidth={commonTabletMaxWidth}
    />
  );
};

export default OrangeAlertContainer;
