import { FC } from 'react';
import cn from 'classnames';

import Media from 'components/Footer/Links/Media';
import SocialMedias from 'components/Footer/Links/Media/SocialMedias';
import MobileApps from 'components/Footer/Links/Media/MobileApps';
import styles from 'components/Footer/Links/FooterLinks.module.scss';
import Divider from 'components/Footer/Links/Media/Divider';
import { MobileNavBarWrapper, DesktopNavBarWrapper } from 'components/Footer/Links/Navigation';
import { FooterType, ViewerDevice } from 'constants/enums';
import { FooterLinksInterface, FooterLinksViewInterface } from 'components/Footer/Links/declarations';
import { capitalizeFirstLetter } from 'lib/text.service';

/* Nested component */
const MobileViewLight: FC<FooterLinksViewInterface> = ({
  mobileApps,
  socialMedias,
  footerType,
}) => (
  <div className={styles.linksMobile}>
    <SocialMedias socialMedias={socialMedias} footerType={footerType} />
    <Divider footerType={footerType} />
    <MobileApps mobileApps={mobileApps} footerType={footerType} />
  </div>
);

/* Nested component */
const MobileViewCommon: FC<FooterLinksViewInterface> = ({
  navBar,
  mobileApps,
  socialMedias,
  footerType,
}) => (
  <div className={styles.linksMobile}>
    <MobileApps mobileApps={mobileApps} footerType={footerType} />
    {navBar
      ? <MobileNavBarWrapper navBar={navBar} footerType={footerType} />
      : <Divider footerType={footerType} className={styles.divider} />}
    <SocialMedias socialMedias={socialMedias} footerType={footerType} />
  </div>
);

/* Nested component */
const MobileView: FC<FooterLinksViewInterface> = (props) => {
  if (props.footerType === FooterType.LIGHT) {
    return <MobileViewLight {...props} />;
  }

  return <MobileViewCommon {...props} />;
};

/* Nested component */
const DesktopView: FC<FooterLinksViewInterface> = ({
  navBar,
  mobileApps,
  socialMedias,
  footerType,
}) => (
  <div className={styles.links}>
    <DesktopNavBarWrapper navBar={navBar} footerType={footerType} />
    <Media
      mobileApps={mobileApps}
      socialMedias={socialMedias}
      footerType={footerType}
    />
  </div>
);

/* Main component */
const FooterLinks: FC<FooterLinksInterface> = ({
  viewerDevice,
  isMobileViewWidth,
  ...props
}) => {
  const Component = (isMobileViewWidth && (viewerDevice !== ViewerDevice.DESKTOP))
    ? MobileView
    : DesktopView;

  return (
    <div className={cn(styles.wrapper, styles[`wrapper${capitalizeFirstLetter(props.footerType)}`])}>
      <Component {...props} />
    </div>
  );
};
export default FooterLinks;
