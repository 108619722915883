import cn from 'classnames';
import { FC } from 'react';

import styles from './Components.module.scss';

import type { IArrow } from '../../config/declarations';

const Arrow: FC<IArrow> = ({
  isButton = true,
  classNames,
  props,
  onClick,
}) => {
  const baseClass = 'arrow';
  const text = <span className={styles[`${baseClass}Down`]} />;

  if (!isButton) {
    return (
      <div className={cn(styles[baseClass], classNames)}>
        {text}
      </div>
    );
  }

  return (
    <button
      type="button"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onClick={onClick}
      className={cn(styles[baseClass], classNames)}
    >
      {text}
    </button>
  );
};

export default Arrow;
