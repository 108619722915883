import { FC } from 'react';

import { Status } from 'src/constants/enums';
import { PsychicComponentInterface } from 'types/componentTypes';
import { RatePerMinuetType } from 'types/objectTypes';
import { getPsychicCtaButtons, getStatus } from 'lib/psychic.service';
import { PsychicCtaButtons } from 'entities/PsychicCtaButton';
import { HiatusBar } from 'entities/HiatusBar';

import PsychicDescription from './PsychicDescription';
import PsychicWorkDescription from './PsychicWorkDescription';
import styles from './styles.module.scss';

const Card: FC<PsychicComponentInterface> = ({
  psychicSkeleton,
  bootStatus,
  user,
  psychic,
}) => {
  const chatStatus = (psychic.chatStatus?.toLowerCase() || Status.BUSY) as Status;
  const phoneStatus = (psychic.phoneStatus?.toLowerCase() || Status.BUSY) as Status;

  const ratePerMinute: RatePerMinuetType = {
    discount: psychic.customerPrice,
    price: psychic.basePrice,
  };

  const cta = getPsychicCtaButtons(psychic, psychicSkeleton, { hashedId: user?.hashedId });

  return (
    <li className={styles.card}>
      <PsychicDescription
        psychic={psychic}
        contentfulData={psychicSkeleton}
        starRating={psychic.overallScore}
        bio={psychic.usp}
        status={getStatus({ chatStatus, phoneStatus })}
        imageUrl={psychic.images[5] || psychicSkeleton.avatar?.url || ''}
        profilePath={psychic.psychicBioURL}
        responses={psychic.responses}
        psychicName={psychic.psychicName}
        ratePerMinute={ratePerMinute}
      />
      <PsychicWorkDescription
        topics={psychic.specialities}
        abilities={psychic.skills as any}
        tools={psychic.tools}
        totalReadings={psychic.totalReadings}
        startYear={psychic.serviceStartYear}
        contentfulData={psychicSkeleton}
      />
      <PsychicCtaButtons
        shape="Oval"
        bootStatus={bootStatus}
        className={styles.interaction}
        chatButton={cta.chatButton}
        talkButton={cta.talkButton}
        inQueueButton={cta.inQueueButton}
        callbackButton={cta.callbackButton}
        messageButton={cta.messageButton}
        psychicFrame={psychicSkeleton}
        psychic={psychic}
      />
      <HiatusBar
        psychic={psychic}
        contentfulData={psychicSkeleton}
        className={styles.hiatus}
      />
    </li>
  );
};

export default Card;
