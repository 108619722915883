import { FC } from 'react';
import { useSelector } from 'react-redux';

import type { NavItem } from 'src/__generated__/graphqlTypes';
import type { Store } from 'app-redux/types/storeTypes';

import styles from './styles.module.scss';
import FirstLevelItem from './1LItem';

import type { IHeaderNav } from '../config/declarations';

const HeaderNav: FC<IHeaderNav> = ({ nav, auth, rating, mobileApp }) => {
  const user = useSelector((store: Store) => store.server.auth.user);

  if (!nav) {
    return null;
  }

  const { navItemsCollection, back } = nav;
  const navItems = navItemsCollection?.items || [];

  if (!navItems) {
    return null;
  }

  return (
    <ul
      aria-label="Header Navigation"
      className={styles.nav}
    >
      {navItems.map((item) => {
        if (!item || item.isMobile === false || item.headline?.__typename !== 'Link') {
          return null;
        }

        const links = (item.linksCollection?.items || []) as Array<NavItem>;
        const { headline } = item;

        return (
          <FirstLevelItem
            user={user}
            key={item.sys.id}
            headline={headline}
            links={links}
            back={back}
            auth={auth}
            rating={rating}
            mobileApp={mobileApp}
          />
        );
      })}
    </ul>
  );
};

export default HeaderNav;
