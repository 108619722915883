import { FC } from 'react';

import styles from 'components/Sections/HoroscopesSignsMysteryDirections/MysteryDirections.module.scss';
import { calculateSizeWithRatio } from 'lib/sharedMethods.service';
import { WebpWidthLimitType } from 'types/objectTypes';
import { getImagesWithLimitedSize } from 'lib/image.service';
import { DataLink } from 'components/Shared/SharedComponents';
import { ImageFitMode } from 'constants/enums';
import { getImageBasedDevice } from 'lib/horoscope/horoscope.service';
import { HoroscopesSignsMysteryDirectionsPictureInterface, MysteryDirectionsPictureImageSizeRatioSlug as Slug } from 'components/Sections/HoroscopesSignsMysteryDirections/declarations';

const MysteryDirectionsPicture: FC<HoroscopesSignsMysteryDirectionsPictureInterface> = ({
  link,
  viewerDevice,
  mobileViewMaxWidth,
  horoscopeTabletMaxWidth,
}) => {
  const { title, image, src } = link;
  const { height, width } = image || {};

  const Image = getImageBasedDevice(viewerDevice);
  let imagesWithLimitedSize: Array<WebpWidthLimitType> | undefined;

  if (height && width) {
    const entryHeight = calculateSizeWithRatio(Slug.ENTRY_IMAGE, height);
    const entryWidth = calculateSizeWithRatio(Slug.ENTRY_IMAGE, width);
    const mobileHeight = calculateSizeWithRatio(Slug.MOBILE, entryHeight);
    const mobileWidth = calculateSizeWithRatio(Slug.MOBILE, entryWidth);
    imagesWithLimitedSize = getImagesWithLimitedSize([
      {
        height: mobileHeight,
        width: mobileWidth,
        media: `${mobileViewMaxWidth}px`,
      },
      {
        height: entryHeight,
        width: entryWidth,
        media: `${horoscopeTabletMaxWidth}px`,
      },
      {
        height: entryHeight,
        width: entryWidth,
        isMinWidth: true,
        media: `${horoscopeTabletMaxWidth}px`,
      },
    ],
    { fit: ImageFitMode.SCALE });
  }

  return (
    <DataLink
      link={link}
      href={src!}
      className={styles.mysteryDirectionsContentPlaque}
    >
      {image && (
        <Image
          image={image}
          className={styles.mysteryDirectionsContentPlaqueImage}
          widthLimit={imagesWithLimitedSize}
        />
      )}
      {title && (
        <span className={styles.mysteryDirectionsContentPlaqueTitle}>
          {title}
        </span>
      )}
    </DataLink>
  );
};

export default MysteryDirectionsPicture;
