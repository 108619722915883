import { NC_PROMOCODE } from 'constants/constants';
import { SectionType } from 'constants/enums';
import { HardcodedPersonalizationData } from 'types/objectTypes';

const orangeBigAlertHandler = (
  personalizedCriteria: Record<string, any>,
  personalizationData: HardcodedPersonalizationData,
) => {
  if (!personalizedCriteria) {
    return false;
  }

  const { router, serverCookies } = personalizationData;
  const { crit } = personalizedCriteria;
  const discountCriteria = crit.find((criteria) => criteria.l === 'discount');

  if (!discountCriteria) {
    return false;
  }

  const cookieDiscountValue: string | undefined = serverCookies[NC_PROMOCODE];
  const queryDiscountValue = router.query[NC_PROMOCODE];
  const promocodeValue = '15FREE';
  const isDiscountRequired = cookieDiscountValue?.toUpperCase() === `"${promocodeValue}"`
      || (queryDiscountValue as string)?.toUpperCase() === promocodeValue;

  return isDiscountRequired;
};

export const personalizationHandlers = new Map()
  .set(SectionType.ORANGE_ALERT_BIG, orangeBigAlertHandler);
