import { PartialCustomerDetails } from 'types/objectTypes';

export const accountInfoObject = {
  firstName: '',
  lastName: '',
  email: '',
  dob: '',
  password: '',
  sendFreeHoroAndNewsLetter: '',
  offerDuration: '',
  cardHolderName: '',
  cardNumber: '',
  expMonth: '',
  expYear: '',
  cvv: '',
  cardType: '',
  billingCountry: '',
  billingCountryName: '',
  zipCode: '',
  state: '',
  stateRegion: '',
  city: '',
  address1: '',
  address2: '',
  phoneCountry: '',
  phoneNumber: '',
  masId: '',
  custId: '',
  callbackId: '',
  extId: '',
  offerId: '',
  priceId: '',
  transactionId: '',
  transactionAmount: '',
  statusCode: '',
  errorMessage: '',
  subscriptionId: '',
  package: { duration: '20' as PackageOfferDuration },
  questionText: '',
  emailSource: 17,
};

type Package = {
  packageBasePrice: string;
  packageOfferPrice: string;
  saveAmount: string;
  packageType: PackageOfferType;
  duration: PackageOfferDuration;
  packageName: string;
}

export type AccountInfoCookie = Omit<typeof accountInfoObject, 'package'>
& {
  customerId?: string;
  ncflowtype?: number;
  package: Partial<Package>;
};
type PackageOfferDuration = '10' | '20' | '30';
type PackageOfferType = '1' | '2' | '3';

export const accountInfoTemplate: Readonly<AccountInfoCookie> = Object.freeze(accountInfoObject);

const getOfferDetails = (
  duration: PackageOfferDuration,
  packageType: PackageOfferType,
) => {
  let base = 100;
  let offer = 20;
  let packageName = 'Affordable';

  if (packageType === '2') {
    packageName = 'Popular';
    const numeralDuration = +duration || 10;
    base = 6.5 * numeralDuration;
    offer = 2 * numeralDuration;
  }

  if (packageType === '3') {
    packageName = 'Premium';
    const numeralDuration = +duration || 10;
    base = 8.5 * numeralDuration;
    offer = 4 * numeralDuration;
  }

  return {
    packageBasePrice: `$${base}`,
    packageOfferPrice: `$${offer}`,
    saveAmount: `$${base - offer}`,
    packageType,
    duration,
    packageName,
  };
};

export const getAccountPackages = (duration: PackageOfferDuration) => ({
  1: {
    ...getOfferDetails(duration, '1'),
  },
  2: {
    ...getOfferDetails(duration, '2'),
  },
  3: {
    ...getOfferDetails(duration, '3'),
  },
});

export const getInfoPhoneDetails = (details: PartialCustomerDetails): Pick<AccountInfoCookie, 'phoneCountry' | 'phoneNumber'> => {
  if (!details.customerPhoneNumber || details.customerPhoneNumber === '9999999999') {
    return { phoneCountry: details.phoneCountryCode || '', phoneNumber: '' };
  }

  return { phoneNumber: details.customerPhoneNumber, phoneCountry: details.phoneCountryCode };
};
