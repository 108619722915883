import { createContext } from 'react';

import type { IContext, IContextDispatch } from '../config/declarations';

export const initialContext: IContext = {
  isSubMenuVisible: false,
};

export const MobileHeaderNavContext = createContext<IContext>(initialContext);

export const MobileHeaderNavDispatch = createContext<IContextDispatch>(() => {});
