import { FC } from 'react';

import { CircleNotificationIndicator } from 'entities/CircleNotificationIndicator';

import { IPsychicNotification } from '../config/declarations';
import { useNotificationsFromPsychics } from '../lib';

const PsychicNotification: FC<IPsychicNotification> = ({ className }) => {
  const { data: unreadNotificationsCount } = useNotificationsFromPsychics();

  if (!unreadNotificationsCount) {
    return null;
  }

  return (
    <CircleNotificationIndicator className={className}>
      {unreadNotificationsCount}
    </CircleNotificationIndicator>
  );
};

export default PsychicNotification;
