import { FC } from 'react';
import { useSelector } from 'react-redux';

import { TextAndSideAsset } from 'components/Sections/TextAndSideAsset';
import { SectionComponentInterface } from 'types/componentTypes';
import { Store } from 'app-redux/types/storeTypes';
import { useCommonContentfulBlocks } from 'lib/shared.hook';
import { CommonContentfulSlug } from 'constants/enums';
import { Block } from 'src/__generated__/graphqlTypes';

const TextAndSideAssetContainer: FC<SectionComponentInterface> = ({
  blocks,
  extraData,
  bgColor,
}) => {
  const {
    mobileViewMaxWidth,
    commonTabletMaxWidth,
    commonPageMaxWidth,
  } = useSelector((store: Store) => store.server.app.scssVariables);
  const {
    content,
    topDivider,
    bottomDivider,
  } = useCommonContentfulBlocks<CommonContentfulSlug, Block>(blocks);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);

  return (
    <TextAndSideAsset
      content={content}
      topDivider={topDivider}
      bottomDivider={bottomDivider}
      extraData={extraData}
      bgColor={bgColor}
      isMobileViewWidth={isMobileViewWidth}
      viewerDevice={viewerDevice}
      mobileViewMaxWidth={mobileViewMaxWidth}
      commonTabletMaxWidth={commonTabletMaxWidth}
      commonPageMaxWidth={commonPageMaxWidth}
    />
  );
};

export default TextAndSideAssetContainer;
