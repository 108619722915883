import { FC, MouseEvent } from 'react';

import { PaginationButtonInterface, TemplateStateType } from '../config/declarations';
import { useTemplateContext } from '../store';

export const PaginationButton: FC<PaginationButtonInterface> = ({
  children,
  dispatch,
  onClick,
  ...rest
}) => {
  const context = useTemplateContext();
  const onClickButton = (e: MouseEvent<HTMLButtonElement>) => {
    const { currentPage } = context || {} as any;
    const payload = currentPage + 1;
    dispatch({ type: TemplateStateType.SET_CURRENT_PAGE, payload });
    onClick?.(e);
  };

  return (
    <button
      type="button"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      onClick={onClickButton}
    >
      {children}
    </button>
  );
};

export default PaginationButton;
