import {
  FC,
  Fragment,
  memo,
} from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { useSelector } from 'react-redux';

import styles from 'src/components/Sections/PsychicsSetMediumSizeImages/PsychicsSetMediumSizeImages.module.scss';
import { Block, Psychic } from 'src/__generated__/graphqlTypes';
import { RichTextParsersConfig } from 'types/objectTypes';
import { CommonRichText, DataLink } from 'components/Shared/SharedComponents';
import { PsychicCardTile } from 'features/sections/PsychicCardTile';
import { ChildrenInterface, PsychicsSetMediumSizeImagesInterface } from 'components/Sections/PsychicsSetMediumSizeImages/declarations';
import { Store } from 'app-redux/types/storeTypes';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.psychicParagraph,
  },
  [BLOCKS.HEADING_2]: {
    classNames: styles.psychicTitle,
  },
  [MARKS.BOLD]: {
    classNames: styles.psychicParagraphBold,
  },
};

const Children: FC<ChildrenInterface> = ({
  array,
  childBlocks,
  bootStatus,
  toggleFavoritePsychicState,
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);

  if (!childBlocks) {
    return null;
  }

  const childBlock = childBlocks[0];
  const components = array?.map((psychic) => (
    <PsychicCardTile
      key={psychic.extId}
      user={user}
      bootStatus={bootStatus}
      psychicSkeleton={childBlock}
      psychic={psychic}
      toggleFavoritePsychicState={toggleFavoritePsychicState}
    />
  ));

  return <div className={styles.psychicContent}>{components}</div>;
};

const PsychicsSetMediumSizeImages: FC<PsychicsSetMediumSizeImagesInterface> = ({
  blocks,
  bgColor,
  psychics,
  sectionRef,
  bootStatus,
  toggleFavoritePsychicState,
}) => {
  const buildComponents = () => blocks
    .map((block: Block) => {
      const {
        content,
        contentTypesCollection,
        link,
        entryName,
      } = block;

      if (!content) return null;

      const children = contentTypesCollection?.items as Array<Psychic> | undefined;

      return (
        <Fragment key={entryName}>
          <CommonRichText content={content} parsersConfig={parsersConfig} />
          <Children
            childBlocks={children}
            array={psychics}
            bootStatus={bootStatus}
            toggleFavoritePsychicState={toggleFavoritePsychicState}
          />
          {link && (
            <DataLink
              link={link}
              href={link.src!}
              className={styles.psychicButton}
            >
              {link.title}
            </DataLink>
          )}
        </Fragment>
      );
    });

  return (
    <section
      ref={sectionRef}
      className={styles.psychic}
      style={{ background: bgColor }}
    >
      {buildComponents()}
    </section>
  );
};

export default memo(PsychicsSetMediumSizeImages);
