import { FC } from 'react';
import cn from 'classnames';

import { PsychicCardImage } from 'entities/PsychicCardImage';
import { WebpImage } from 'components/Shared/SharedComponents';
import { StatusIndicatorWrapper as StatusIndicator } from 'entities/JsxParserComponents';

import styles from './styles.module.scss';

import type { IPsychicPhotoModule, ISelectionIcon } from '../config/declarations';
import { getPsychicSelectionIcon } from '../lib';

/* Nested component */
const SelectionIcon: FC<ISelectionIcon> = ({ psychic, frame }) => {
  const selectionIcon = getPsychicSelectionIcon(psychic, frame);

  if (!selectionIcon) {
    return null;
  }

  const wrapperStyles = cn(
    styles.iconWrapper,
    {
      [styles.iconBlue]: psychic.isInCircle,
      [styles.iconRed]: psychic.isFavorite,
    },
  );

  return (
    <div className={wrapperStyles}>
      <WebpImage
        image="external"
        src={selectionIcon}
      />
    </div>
  );
};

/* Main component */
const PhotoWithSelection: FC<IPsychicPhotoModule> = ({
  src,
  psychic,
  imageClassName,
  psychicSkeleton,
}) => (
  <div className={styles.withSelection}>
    <div className={styles.imageWrapper}>
      <PsychicCardImage
        psychic={psychicSkeleton}
        image="external"
        src={src}
        alt={psychic.psychicName}
        className={cn(styles.image, imageClassName)}
      />
      <SelectionIcon psychic={psychic} frame={psychicSkeleton} />
    </div>
    <div className={styles.name}>
      <StatusIndicator
        chatStatus={psychic.chatStatus}
        phoneStatus={psychic.phoneStatus}
        className={styles.indicator}
      />
      <span>
        {psychic.psychicName}
      </span>
    </div>
  </div>
);

export default PhotoWithSelection;
