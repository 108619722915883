import { FC } from 'react';
import cn from 'classnames';

import { DataLink } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';
import HoroscopeText from './HoroscopeText';
import Header from './Header';

import type { IDailyHoroscope } from '../config/declarations';
import { useHoroscopeRequestProps } from '../lib/hooks';

const DailyHoroscope: FC<IDailyHoroscope> = ({
  sign,
  date,
  blocks,
  className,
  content,
  imageClassName,
  titleClassName,
  headerClassName,
  linkClassName,
  horoscopeClassName,
  showDate = false,
}) => {
  const horoscopeProps = useHoroscopeRequestProps(date, sign);

  if (!horoscopeProps.sign) {
    return null;
  }

  const block = blocks
    ?.find((block) => block.slug?.toLowerCase() === horoscopeProps.sign!.toLowerCase());

  if (!block) {
    return null;
  }

  const { link, title, image } = block;

  return (
    <div className={cn(className, styles.horo)}>
      <Header
        image={image}
        title={title}
        date={date}
        showDate={showDate}
        headerClassName={headerClassName}
        imageClassName={imageClassName}
        titleClassName={titleClassName}
      />
      <HoroscopeText
        horoscopeProps={horoscopeProps}
        className={horoscopeClassName}
        content={content}
      />
      {link && (
        <DataLink
          link={link}
          className={cn(linkClassName, styles.link)}
        >
          {link.title}
        </DataLink>
      )}
    </div>
  );
};

export default DailyHoroscope;
