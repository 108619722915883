import { Link } from 'src/__generated__/graphqlTypes';
import { SectionComponentInterface, ViewsMaxWidthInterface } from 'types/componentTypes';

export interface HoroscopesSignsMysteryDirectionsInterface
  extends SectionComponentInterface,
  ViewsMaxWidthInterface {}

export interface HoroscopesSignsMysteryDirectionsPictureInterface
  extends ViewsMaxWidthInterface {
  link: Link;
}

export enum MysteryDirectionsPictureImageSizeRatioSlug {
  MOBILE = 0.88,
  ENTRY_IMAGE = 0.41,
}
