import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { FC } from 'react';
import cn from 'classnames';

import {
  Block,
  BlockContent,
  BlockRichTitle,
  Link,
} from 'src/__generated__/graphqlTypes';
import { RichTextParsersConfig, SectionExtraDataType } from 'types/objectTypes';
import styles from 'components/Sections/NcOfferPackages/NcOfferPackages.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { CardNcOfferPackages, NcOfferPackagesSlider } from 'components/Sections/NcOfferPackages';
import { CommonRichText, DataLink } from 'components/Shared/SharedComponents';
import Divider from 'components/Sections/Divider';
import { CommonSize, ElementAlign } from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';
import { mapBlocksParagraphSchemas } from 'lib/richTextMappers';
import { NcOfferPackagesInterface, NcOfferNestedPackagesInterface as PackagesInterface } from 'components/Sections/NcOfferPackages/declarations';

const getParsersConfig = ({ titleAlign, pAlign }: SectionExtraDataType): RichTextParsersConfig => {
  const titleAlignClass = classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`];
  const pAlignClass = classes[`pAlign${capitalizeFirstLetter(pAlign || titleAlign)}`];

  const getTitleClass = (headingClass: string) => cn(
    styles.offerTitle,
    headingClass,
    titleAlignClass,
  );

  return ({
    [BLOCKS.HEADING_1]: {
      classNames: getTitleClass(classes.titleMainH1),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getTitleClass(classes.titleMainH2),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getTitleClass(classes.titleMainH3),
    },
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(styles.offerDescription, pAlignClass),
      mapper: mapBlocksParagraphSchemas({
        itemProp: 'description',
      }),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};

/* Nested component */
const Packages: FC<PackagesInterface> = ({
  childBlocks,
  nextArrow,
  prevArrow,
  isMobileViewWidth,
  onPackageClick,
}) => {
  if (!childBlocks?.length) {
    return null;
  }

  const ncCardsArray = childBlocks.map((childBlock) => childBlock
  && (
    <CardNcOfferPackages
      key={childBlock.entryName}
      block={childBlock}
      onPackageClick={onPackageClick}
    />
  ));

  if (isMobileViewWidth) {
    return (
      <NcOfferPackagesSlider
        rightArrow={nextArrow}
        leftArrow={prevArrow}
        cards={ncCardsArray}
      />
    );
  }

  return (
    <div className={styles.offerContent}>
      {ncCardsArray}
    </div>
  );
};

/* Main component */
const NcOfferPackages: FC<NcOfferPackagesInterface> = ({
  content: block,
  extraData,
  bgColor,
  nextArrow,
  prevArrow,
  topDivider,
  bottomDivider,
  isMobileViewWidth,
  commonPageMaxWidth,
  onPackageClick,
}) => {
  const {
    titleAlign = ElementAlign.LEFT,
    verticalPadding = CommonSize.MEDIUM,
    pAlign,
  } = extraData || { };
  const {
    content: description,
    richTitle: title,
    contentTypesCollection,
    title: paragraph,
    link,
    entryName,
  } = block as Block;
  const children = contentTypesCollection?.items as Array<Block> | undefined;

  const applyRichText = (content?: BlockContent | BlockRichTitle) => content && (
    <CommonRichText
      content={content}
      parsersConfig={getParsersConfig({ titleAlign, pAlign })}
    />
  );

  const applyButton = (link?: Link) => link && (
    <DataLink
      link={link}
      href={link.src!}
      className={styles.offerButton}
      itemProp="url"
    >
      {link.title}
    </DataLink>
  );

  const applyText = (paragraph?: string) => paragraph && (
    <p className={styles.offerParagraph}>
      {paragraph}
    </p>
  );

  const buildComponent = () => (
    <div key={entryName} className={styles.offer}>
      {applyRichText(title!)}
      {applyRichText(description!)}
      <Packages
        childBlocks={children}
        nextArrow={nextArrow as Link}
        prevArrow={prevArrow as Link}
        isMobileViewWidth={isMobileViewWidth}
        onPackageClick={onPackageClick}
      />
      {applyText(paragraph!)}
      {applyButton(link!)}
    </div>
  );

  return (
    <section
      className={cn(styles.wrapper, classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`])}
      itemScope
      itemType="https://schema.org/OfferCatalog"
      style={{ background: bgColor }}
    >
      <Divider
        block={topDivider as Block}
        maxWidth={`${commonPageMaxWidth}px`}
      />
      {buildComponent()}
      <Divider
        block={bottomDivider as Block}
        maxWidth={`${commonPageMaxWidth}px`}
      />
    </section>
  );
};

export default NcOfferPackages;
