import { FC } from 'react';

import { ImageWrapper } from 'src/__generated__/graphqlTypes';
import { WebpImage } from 'components/Shared/SharedComponents';
import styles from 'components/Sections/OrangeAlert/OrangeAlert.module.scss';
import { ViewerDevice } from 'constants/enums';
import { WebpWidthLimitType } from 'types/objectTypes';
import { setItemInObjectBySlug } from 'lib/sharedMethods.service';
import { OrangeAlertBackgroundInterface, BackgroundFieldsEnum as BackgroundFields } from 'components/Sections/OrangeAlert/declarations';

const backgroundSlugs = {
  mobile: BackgroundFields.MOBILE,
  desktop: BackgroundFields.DESKTOP,
};

const getIconWidthLimit = (
  width?: number,
  media?: string | number,
  rest?: Partial<WebpWidthLimitType>,
) => {
  if (!media || !width) {
    return {} as WebpWidthLimitType;
  }

  return ({
    width,
    media: `${media}px`,
    ...rest,
  });
};

const BackgroundImageOrNull: FC<OrangeAlertBackgroundInterface> = ({
  backgrounds,
  viewerDevice,
  mobileViewMaxWidth,
  commonTabletMaxWidth,
}) => {
  if (!backgrounds || backgrounds.length < 2) {
    return null;
  }

  const reducer = setItemInObjectBySlug(backgroundSlugs);
  const { mobile, desktop } = backgrounds
    .reduce(reducer, {} as Record<BackgroundFields, ImageWrapper>);
  const background = (viewerDevice !== ViewerDevice.MOBILE)
    ? desktop
    : mobile;
  const { image } = background || {};

  if (!image) {
    return null;
  }

  const desktopMedia = getIconWidthLimit(image.width!, commonTabletMaxWidth, { isMinWidth: true });
  const tabletMedia = getIconWidthLimit(+commonTabletMaxWidth!, commonTabletMaxWidth);
  const mobileMedia = getIconWidthLimit(
    image.width
      ? image.width / 2
      : +mobileViewMaxWidth!,
    mobileViewMaxWidth,
  );
  const widthLimits: Array<WebpWidthLimitType> = [mobileMedia, tabletMedia, desktopMedia];

  return (
    <WebpImage
      image={image}
      widthLimit={widthLimits}
      className={styles.alertBackground}
    />
  );
};

export default BackgroundImageOrNull;
