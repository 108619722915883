import React, { FC } from 'react';

import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';
import { PsychicCardImage } from 'entities/PsychicCardImage';

import { PsychicCardImageWrapperInterface } from '../config/declarations';

const PsychicCardImageWrapper: FC<PsychicCardImageWrapperInterface> = ({
  hrefs,
  psychic,
  className,
  psychicFrame,
  isLazy = false,
}) => {
  const href = hrefs.find(Boolean) || '';

  return (
    <PsychicCardImage
      isLazy={isLazy}
      psychic={psychicFrame}
      image={EXTERNAL_WEBP_IMAGE}
      src={href}
      alt={psychic.lineName}
      className={className}
    />
  );
};

export default PsychicCardImageWrapper;
