import { FC } from 'react';
import cn from 'classnames';

import type { ImageWrapper } from 'src/__generated__/graphqlTypes';
import { FullBackgroundImage } from 'src/shared/ui/FullBackgroundImage';
import { DataLink } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';

import type { IFlatBanner, IMainButton } from '../config/declarations';

/* Nested component */
const Button: FC<IMainButton> = ({ link, className }) => {
  if (!link?.src) {
    return null;
  }

  return (
    <DataLink
      link={link}
      className={cn(styles.button, className)}
    >
      {link.title}
    </DataLink>
  );
};

/* Main component */
const FlatBanner: FC<IFlatBanner> = ({
  block,
  bgProps,
  className,
  contentClassName,
  textClassName,
  linkClassName,
}) => {
  if (!block) {
    return null;
  }

  const { imagesCollection, link, title } = block;

  return (
    <div className={cn(styles.banner, className)}>
      <div className={cn(styles.content, contentClassName)}>
        <span className={cn(styles.text, textClassName)}>{title}</span>
        <Button
          link={link}
          className={linkClassName}
        />
      </div>
      <FullBackgroundImage
        backgrounds={imagesCollection?.items as Array<ImageWrapper>}
        skipTablet
        skipMobile
        className={styles.background}
        {...bgProps}
      />
    </div>
  );
};

export default FlatBanner;
