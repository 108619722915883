import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import React from 'react';

import styles from 'src/components/Sections/AboutNcOfferPackages/AboutNcOfferPackages.module.scss';
import { RichTextParsersConfig } from 'types/objectTypes';
import { AboutNcCardClasses, UlListClasses } from 'types/classTypes';
import { mapBlocksEmbeddedEntryPrice, mapBlocksUlListSchema } from 'lib/richTextMappers';
import {
  CommonRichText,
  CommonTitle,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { CardAboutNcOfferPackagesInterface } from 'components/Sections/AboutNcOfferPackages/declarations';

const parsersConfig = (): RichTextParsersConfig => {
  const entryClassNames: AboutNcCardClasses = {
    wrapper: styles.offerContentBlockButton,
    title: styles.offerContentBlockButtonTitle,
    contentBold: styles.offerContentBlockButtonParagraphBold,
    contentParagraph: styles.offerContentBlockButtonParagraph,
  };

  const ulListClassNames: UlListClasses = {
    ul: styles.offerContentBlockDescription,
    li: styles.offerContentBlockDescriptionLine,
    p: styles.offerContentBlockDescriptionLineText,
  };

  return ({
    [BLOCKS.EMBEDDED_ENTRY]: {
      classNames: entryClassNames,
      mapper: mapBlocksEmbeddedEntryPrice,
    },
    [BLOCKS.UL_LIST]: {
      classNames: ulListClassNames,
      mapper: mapBlocksUlListSchema({ itemProp: 'description' }),
    },
    [MARKS.BOLD]: {
      classNames: styles.offerContentBlockPrice,
    },
  });
};

const Divider: React.FC = () => <div className={styles.offerContentBlockDivider} />;

const CardAboutNcOfferPackages: React.FC<CardAboutNcOfferPackagesInterface> = ({
  block,
  onPackageClick,
}) => {
  const {
    content,
    title,
    image,
    link,
    textList,
  } = block;

  const onClick = () => onPackageClick(link!, textList as Array<string>);

  const buildComponent = () => {
    if (!content) return null;

    return (
      <>
        {image && (
          <WebpImage
            image={image}
            className={styles.offerContentBlockImage}
            itemProp="image"
          />
        )}
        <CommonTitle
          title={title as string}
          className={styles.offerContentBlockTitle}
          itemProp="name"
        />
        <Divider />
        <CommonRichText content={content} parsersConfig={parsersConfig()} />
      </>
    );
  };

  return (
    <div
      role="button"
      className={styles.offerContentBlock}
      itemScope
      itemType="https://schema.org/OfferForPurchase"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={onClick}
    >
      {buildComponent()}
    </div>
  );
};

export default CardAboutNcOfferPackages;
