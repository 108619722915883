import React from 'react';

import { DataButton } from 'components/Shared/SharedComponents';
import styles from 'components/Sections/HoroscopesSignsAllAboutSigns/OtherSignsList/OtherSignsList.module.scss';
import { OtherSignsListSelect } from 'components/Sections/HoroscopesSignsAllAboutSigns/OtherSignsList';
import { AllAboutSignsOtherSignsInterface } from 'components/Sections/HoroscopesSignsAllAboutSigns/OtherSignsList/declarations';

const OtherSignsList: React.FC<AllAboutSignsOtherSignsInterface> = ({
  title,
  zodiacSigns,
  button,
  chevron,
  selectedItem,
  signImageSize,
  redirectToSignPage,
  setSelectedItem,
}) => {
  const defaultSelectTitle = selectedItem?.title;
  const applyButton = () => {
    if (!button) {
      return null;
    }

    const { title: buttonText } = button;

    return (
      <DataButton
        link={button}
        onClick={redirectToSignPage}
        className={styles.otherSignsButton}
      >
        {buttonText}
      </DataButton>
    );
  };

  return (
    <div className={styles.otherSigns}>
      <span className={styles.otherSignsTitle}>{title}</span>
      <OtherSignsListSelect
        title={defaultSelectTitle!}
        options={zodiacSigns}
        chevron={chevron}
        signImageSize={signImageSize}
        previewItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
      {applyButton()}
    </div>
  );
};

export default OtherSignsList;
