/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useRef, useState } from 'react';
import cn from 'classnames';

import { FooterMobileNavBarItemInterface } from 'types/componentTypes';
import { Text } from 'src/__generated__/graphqlTypes';

const MobileNavBarTextItem: React.FC<FooterMobileNavBarItemInterface> = ({
  navItem,
  image,
  children,
  classes,
}) => {
  const navText = navItem as Text;
  const [isRevealed, setRevealedFlag] = useState<boolean>(false);
  const contentRef = useRef<HTMLUListElement>(null);
  const [maxHeight, setMaxHeight] = useState<number>(contentRef?.current?.scrollHeight || 0);

  const onClick = () => {
    const ul = contentRef.current;

    if (!ul) return;

    setRevealedFlag((prevState) => {
      const newState = !prevState;
      setMaxHeight(newState ? ul.scrollHeight : 0);

      return newState;
    });
  };

  const imageClasses: string = cn(
    classes?.img,
    isRevealed ? classes?.imgReveal : classes?.imgCollapse,
  );

  return (
    <React.Fragment key={navText.entryName}>
      <li className={classes?.li}>
        <h3
          onClick={onClick}
          onKeyPress={onClick}
          role="button"
          aria-label={navText.text || ''}
          tabIndex={0}
          className={classes?.span}
        >
          {navText.text}
        </h3>
        <span
          style={(image?.url)
            ? { backgroundImage: `url(${image.url})` }
            : {}}
          className={imageClasses}
        />
      </li>
      <ul
        ref={contentRef}
        style={{ maxHeight: `${maxHeight}px` }}
        className={classes?.innerUl}
      >
        {children}
      </ul>
    </React.Fragment>
  );
};

export default MobileNavBarTextItem;
