import { FC } from 'react';

import { PlainItemsWithDescription } from 'components/Sections/PlainItemsWithDescription';
import { SectionComponentInterface } from 'types/componentTypes';

const PlainItemsWithDescriptionContainer: FC<SectionComponentInterface> = ({
  blocks,
  extraData,
  bgColor,
}) => {
  const [block] = blocks;

  if (!block) {
    return null;
  }

  return (
    <PlainItemsWithDescription
      block={block}
      extraData={extraData}
      bgColor={bgColor}
    />
  );
};

export default PlainItemsWithDescriptionContainer;
