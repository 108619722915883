import React, { useState } from 'react';

import styles from 'components/Sections/HoroscopesSignsAllAboutSigns/Flowers/Flowers.module.scss';
import { SignFeatureContainer } from 'components/Sections/HoroscopesSignsAllAboutSigns/Shared';
import { FlowersPicture } from 'components/Sections/HoroscopesSignsAllAboutSigns/Flowers';
import { Link } from 'src/__generated__/graphqlTypes';
import { AllAboutSignsFlowersInterface } from 'components/Sections/HoroscopesSignsAllAboutSigns/Flowers/declarations';

const Flowers: React.FC<AllAboutSignsFlowersInterface> = ({
  flowers,
  viewerDevice,
  mobileViewMaxWidth,
  horoscopeTabletMaxWidth,
}) => {
  const [maxImageWidth, setMaxWidth] = useState<number>(0);
  const [maxImageHeight, setImageMaxHeight] = useState<number>(0);

  if (!flowers) {
    return <SignFeatureContainer containerClassName={styles.flowers} />;
  }

  const { title, contentTypesCollection } = flowers;
  const flowersArray = (contentTypesCollection?.items as Array<Link>) || [];

  if (!flowersArray.length) {
    return null;
  }

  const applyFlowersArray = () => flowersArray.map(({
    entryName,
    title,
    image,
  }) => (
    <FlowersPicture
      key={entryName || image?.url}
      viewerDevice={viewerDevice}
      maxWidth={maxImageWidth}
      maxHeight={maxImageHeight}
      entryName={entryName!}
      title={title!}
      image={image!}
      horoscopeTabletMaxWidth={horoscopeTabletMaxWidth}
      mobileViewMaxWidth={mobileViewMaxWidth}
      setMaxHeight={setImageMaxHeight}
      setMaxWidth={setMaxWidth}
    />
  ));

  return (
    <SignFeatureContainer
      title={title!}
      containerClassName={styles.flowers}
    >
      <div className={styles.flowersContent}>
        {applyFlowersArray()}
      </div>

    </SignFeatureContainer>
  );
};

export default Flowers;
