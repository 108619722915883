/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef } from 'react';

import { IconInterface } from './declarations';

const Icon = forwardRef<HTMLElement, IconInterface>(({
  image,
  className,
  ...rest
}, ref) => {
  if (!image) {
    return null;
  }

  return (
    <i
      style={{ backgroundImage: `url(${image.url!})` }}
      className={className}
      {...rest}
      ref={ref}
    />
  );
});

export default Icon;
