import { FC } from 'react';

import { DataLink } from 'components/Shared/SharedComponents';

import styles from './FlexColumns.module.scss';

import type { IChild } from '../config/declarations';

const Link: FC<IChild> = ({ element }) => {
  const { link } = element;

  if (!link) {
    return null;
  }

  return (
    <DataLink
      key={element.sys.id}
      className={styles.link}
      link={link}
    >
      {link.title}
    </DataLink>
  );
};

export default Link;
