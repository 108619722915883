import axios, { AxiosRequestConfig } from 'axios';

// eslint-disable-next-line no-undef
export const checkTrustpilotScore = async (props: JSX.Element['props'] = {}) => {
  const templateId = props['data-template-id'] || '539ad0ffdec7e10e686debd7';
  const url = `https://widget.trustpilot.com/trustbox-data/${templateId}`;
  const config: AxiosRequestConfig = {
    params: {
      businessUnitId: props['data-businessunit-id'] || '54cf96850000ff00057d258b',
      locale: props['data-locale'] || 'en-US',
      reviewLanguages: props['data-review-languages'] || 'en',
      reviewTagValue: props['data-tags'] || 'SelectedReview',
      reviewsPerPage: '6',
    },
  };
  const resp = await axios.get(url, config);

  return resp.data;
};
