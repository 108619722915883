/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, FC } from 'react';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import { DataLink } from 'components/Shared/SharedComponents';
import { GAReplacementValue } from 'constants/enums';

import styles from './styles.module.scss';

import { ExtraInfoInterface, TopicsInterface } from '../config/declarations';

/* Nested component */
const Topics: FC<TopicsInterface> = ({ topics, contentfulData }) => {
  const isEmptyTopicsArray = topics
  && (topics.length === 0 || (topics.length === 1 && !topics[0]));

  if (isEmptyTopicsArray) {
    return (
      <span className={styles.extraInfoProfileInfoTopicsParagraph}>
        {contentfulData.notExist}
      </span>
    );
  }

  return (
    <>
      {topics.map((topic: string, i: number) => (
        <span key={topic + i.toString()} className={styles.extraInfoProfileInfoTopicsParagraph}>
          {topic}
        </span>
      ))}
    </>
  );
};

/* Main component */
const ExtraInfo: FC<ExtraInfoInterface> = ({
  contentfulData,
  topics,
  totalReadings,
  startYear,
  estimatedTime,
  fullProfileLink,
  isAnimationMounted,
  positionInArray,
  isCollapsed,
  psychicName,
  collapse,
}) => {
  const finalProfileLink = (contentfulData?.profileButton?.src)
    ? contentfulData.profileButton.src + fullProfileLink
    : fullProfileLink;
  const { _meta: meta = {} } = contentfulData.profileButton?.gaProperties || {};
  const getMainClasses = useCallback(() => {
    if (isAnimationMounted) {
      return isCollapsed
        ? styles.extraInfoCollapsed
        : styles.extraInfoRevealed;
    }

    return classes.fullyCollapsed;
  }, [isAnimationMounted, isCollapsed]);

  return (
    <div className={cn(styles.extraInfo, getMainClasses())}>
      <div
        className={styles.extraInfoProfile}
        onClick={collapse}
        onKeyPress={collapse}
      >
        <div className={styles.extraInfoProfileInfo}>
          <div className={styles.extraInfoProfileInfoReadings}>
            <span className={styles.extraInfoProfileInfoReadingsTitle}>
              {contentfulData.totalReadings}
            </span>
            <span className={styles.extraInfoProfileInfoReadingsCount}>{totalReadings}</span>
          </div>
          <div className={styles.extraInfoProfileInfoSince}>
            <span className={styles.extraInfoProfileInfoSinceTitle}>
              {contentfulData.since}
            </span>
            <span className={styles.extraInfoProfileInfoSinceCount}>{startYear}</span>
          </div>
          <div className={styles.extraInfoProfileInfoTopics}>
            <span className={styles.extraInfoProfileInfoTopicsTitle}>
              {contentfulData.topics}
            </span>
            <Topics topics={topics} contentfulData={contentfulData} />
          </div>
        </div>
        {contentfulData.profileButton && (
          <DataLink
            link={contentfulData.profileButton}
            href={finalProfileLink}
            className={styles.extraInfoProfileButton}
            gaData={{
              [GAReplacementValue.PSYCHIC_NAME]: psychicName,
              [GAReplacementValue.POSITION]: meta[positionInArray],
            }}
          >
            {contentfulData.profileButton.title}
          </DataLink>
        )}
      </div>
      <div className={styles.extraInfoWait}>
        <span className={styles.extraInfoWaitTitle}>
          {contentfulData.estimatedWaiting}
        </span>
        <span className={styles.extraInfoWaitTime}>
          {estimatedTime}
          m
        </span>
      </div>
    </div>
  );
};

export default ExtraInfo;
