import { FC } from 'react';
import cn from 'classnames';

import type { IMan } from './declarations';
import styles from './Man.module.scss';

const Man: FC<IMan> = ({ type = 'filled', color = 'red' }) => (
  <i className={cn(styles.man, styles[type], styles[color])}>
    <span className={styles.head} />
    <span className={styles.body} />
  </i>
);

export default Man;
