/* eslint-disable no-loop-func */
import type { Block, Link } from 'src/__generated__/graphqlTypes';

import {
  Components,
  CustomConfigInterface,
  CustomOffersInterface,
  ImageObjectInterface,
} from '../config/declarations';
import { OFFERS_LIST_CHANNEL_ID_TO_SKIP, OFFERS_LIST_DESCRIPTIONS_TO_ADD } from '../config/constants';

export const extractComponents = (blocks: Array<Block | Link>) => blocks
  .reduce((store, item) => {
    if ((item.slug === 'content' || !item.slug) && item.__typename === 'Block') {
      return { ...store, block: item };
    }

    if (item.slug === 'left-arrow') {
      return { ...store, leftArrow: item as Link };
    }

    if (item.slug === 'right-arrow') {
      return { ...store, rightArrow: item as Link };
    }

    if (item.slug === 'left-arrow-hover') {
      return { ...store, leftArrowHover: item as Link };
    }

    if (item.slug === 'right-arrow-hover') {
      return { ...store, rightArrowHover: item as Link };
    }

    return store;
  }, {} as Components);

const getImagesFromOffer = (offer: CustomOffersInterface, offersConfig: CustomConfigInterface) => {
  const {
    desktopBannerWidth,
    desktopBannerHeight,
    mobileBannerHeight,
    mobileBannerWidth,
  } = offersConfig;

  return offer.offerImages.reduce((acc, currentImage) => {
    const { height, width, url, linkUrl, channelId, description } = currentImage;

    const isValidChannelId = channelId !== OFFERS_LIST_CHANNEL_ID_TO_SKIP;
    const isValidDescription = description === OFFERS_LIST_DESCRIPTIONS_TO_ADD;

    if (!isValidChannelId && !isValidDescription) {
      return acc;
    }

    if (height === desktopBannerHeight && width === desktopBannerWidth) {
      acc.desktopUrl = url;
    }

    if (height === mobileBannerHeight && width === mobileBannerWidth) {
      acc.mobileUrl = url;
    }

    acc.linkRedirectUrl = linkUrl;

    return acc;
  }, {
    desktopUrl: '',
    mobileUrl: '',
    linkRedirectUrl: '',
  });
};

export const extractOfferImages = (
  offersConfig: CustomConfigInterface,
  custOffers: Array<CustomOffersInterface>,
): Array<ImageObjectInterface> => {
  const { bannerDisplayCount } = offersConfig;

  const bannerCollection: Array<ImageObjectInterface> = [];
  let custOffersIndex = 0;
  let canExtractMoreImages = bannerCollection.length < bannerDisplayCount;
  let isNotAllOffersChecked = custOffersIndex < custOffers.length;

  while (isNotAllOffersChecked && canExtractMoreImages) {
    const offer = custOffers[custOffersIndex];

    const {
      desktopUrl,
      mobileUrl,
      linkRedirectUrl,
    } = getImagesFromOffer(offer, offersConfig);

    if (desktopUrl || mobileUrl) {
      bannerCollection.push({
        desktopUrl,
        mobileUrl,
        sortOrder: offer.sortOrder,
        promoCode: offer.promoCode,
        linkRedirectUrl,
        offerId: offer.offerId,
        offerLabel: offer.description,
      });
    }

    custOffersIndex += 1;
    isNotAllOffersChecked = custOffersIndex < custOffers.length;
    canExtractMoreImages = bannerCollection.length < bannerDisplayCount;
  }

  return bannerCollection;
};
