import type { ReducerAction } from 'app-redux/types/storeTypes';

import { errorHandlerContext } from './context';

import type { ErrorHandlerContextValue } from '../config/declarations';

export const errorHandlerReducer = (
  store: ErrorHandlerContextValue = errorHandlerContext,
  action: ReducerAction<keyof ErrorHandlerContextValue>,
) => {
  switch (action.type) {
    case 'shouldHideSection':
    case 'buttonToHide':
    case 'shouldRefetchPsychics': {
      return { ...store, [action.type]: action.payload };
    }
    case 'apiToRefetch': {
      const { apiToRefetch, shouldRefetchPsychics } = action.payload;

      return { ...store, apiToRefetch, shouldRefetchPsychics };
    }
    default: return store;
  }
};
