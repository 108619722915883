import { FC } from 'react';
import { useSelector } from 'react-redux';

import { DataButton, PsychicRate } from 'components/Shared/SharedComponents';
import { PriceFilterSectionListProps } from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';
import { CustomerType, GAReplacementValue } from 'constants/enums';
import type { Store } from 'app-redux/types/storeTypes';

import styles from '../PsychicFilter.module.scss';

const PriceFilterSectionList: FC<PriceFilterSectionListProps> = ({
  item, changeFilter, checkIfFilterPresent, priceTitle,
}) => {
  const filterDetail = { type: 'Price', value: `${item?.psyGroup}` };
  const isSelected = checkIfFilterPresent?.(filterDetail);
  const psychicRateClassObject = {
    wrapper: '',
    commonWithDiscount: styles.commonWithDiscount,
    discount: '',
    common: '',
  };

  const formatPrice = (price) => (price ? `$${price.toFixed(2)}${priceTitle?.alt}` : '');

  const customerPrice = formatPrice(item?.rate);
  const discountPrice = item?.newCustFlatRate ? formatPrice(item.newCustFlatRate) : customerPrice;

  const user = useSelector((store: Store) => store.server.auth.user);
  const isEc = user && !user.isLeadCustomer;

  if (isSelected) {
    return (
      <DataButton
        link={priceTitle}
        gaData={{ [GAReplacementValue.PRICE]: discountPrice.replace('/Min', ''),
          [GAReplacementValue.USER]: user
            ? CustomerType.EC.toUpperCase() : CustomerType.NC.toUpperCase() }}
        className={styles.searchCategorySectionContent}
        onClick={() => changeFilter?.(filterDetail)}
      >
        <div className={styles.searchCategorySectionContentRow}>
          <div className={styles.searchCategorySectionContentRowPannel}>
            <input type="checkbox" className={styles.searchCategorySectionContentRowPannelInput} defaultChecked readOnly />
            <span className={styles.searchCategorySectionContentRowPannelLabel}>
              <span className={styles.searchCategorySectionContentRowPannelLabelText}>
                <PsychicRate
                  pricePerMinute={customerPrice}
                  priceWithDiscountPerMinute={isEc ? customerPrice : discountPrice}
                  classNames={psychicRateClassObject}
                />
              </span>
            </span>
          </div>
        </div>
      </DataButton>
    );
  }

  return (
    <DataButton
      link={priceTitle}
      gaData={{ [GAReplacementValue.PRICE]: discountPrice.replace('/Min', ''),
        [GAReplacementValue.USER]: user
          ? CustomerType.EC.toUpperCase() : CustomerType.NC.toUpperCase() }}
      className={styles.categoryContentRow}
      onClick={() => changeFilter?.(filterDetail)}
    >
      <div className={styles.categoryContentRowInner}>
        <span className={styles.categoryContentRowLabel}>
          <span className={styles.categoryContentRowTitle}>
            <PsychicRate
              pricePerMinute={customerPrice}
              priceWithDiscountPerMinute={isEc ? customerPrice : discountPrice}
              classNames={psychicRateClassObject}
            />
          </span>
        </span>
      </div>
    </DataButton>
  );
};

export default PriceFilterSectionList;
