import { FC } from 'react';
import {
  BLOCKS,
  MARKS,
  INLINES,
} from '@contentful/rich-text-types';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import { CommonRichText } from 'components/Shared/SharedComponents';
import type { RichTextParsersConfig, SectionExtraDataType } from 'types/objectTypes';
import { capitalizeFirstLetter } from 'lib/text.service';
import { ElementAlign } from 'constants/enums';

import styles from './PriceCardsWithKarma.module.scss';

import type { IRichText } from '../config/declarations';

const getParsersConfig = (extraData: SectionExtraDataType = {}): RichTextParsersConfig => {
  const {
    titleAlign = ElementAlign.CENTER,
  } = extraData;
  const titleAlignClass = classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`];

  return ({
    [BLOCKS.HEADING_1]: {
      classNames: cn(styles.heading, styles.headingH1, titleAlignClass),
    },
    [BLOCKS.HEADING_2]: {
      classNames: cn(styles.heading, styles.headingH2, titleAlignClass),
    },
    [BLOCKS.HEADING_3]: {
      classNames: cn(styles.heading, styles.headingH3, titleAlignClass),
    },
    [BLOCKS.PARAGRAPH]: {
      classNames: styles.paragraph,
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
    [INLINES.HYPERLINK]: {
      classNames: styles.paragraphLink,
    },
  });
};

const RichText: FC<IRichText> = ({ richText, extraData }) => (
  <CommonRichText content={richText} parsersConfig={getParsersConfig(extraData)} />
);

export default RichText;
