import {
  FC,
  useEffect,
  useState,
} from 'react';

import { useStableDateGetter } from 'src/shared/lib/date/hooks';

import styles from './PlainCalendar.module.scss';
import TBodyCell from './TBodyCell';

import { useDatesArray, useOutsideStateControl as useStateControlFromOutside } from '../lib/hooks';
import { makeCalendarDateKey, splitDates } from '../lib';
import type { ITBody } from '../config/declarations';

const TBody: FC<ITBody> = ({ onChange, getState, datesHandlers, forcedSelected }) => {
  const [selected, setSelected] = useState<Date | null>(forcedSelected || null);
  const dates = useDatesArray(selected);

  const getStableDate = useStableDateGetter();
  const stableDateNow = getStableDate(new Date());
  const spitedDates = splitDates(dates);

  const onCellSelect = (date: Date) => setSelected(date);

  useEffect(() => {
    if (selected) {
      onChange?.(selected);
    }
  }, [selected]);

  useStateControlFromOutside(
    dates,
    selected,
    setSelected,
    getState,
  );

  return (
    <tbody className={styles.body}>
      {spitedDates.map((row, i) => (
        <tr key={`${row.length + i}`} className={styles.tr}>
          {row.map((cell) => {
            const dateHandler = datesHandlers?.[makeCalendarDateKey(cell)];

            return (
              <TBodyCell
                key={cell.getTime()}
                cell={cell}
                now={stableDateNow}
                selected={selected}
                dateHandler={dateHandler}
                onCellSelect={onCellSelect}
              />
            );
          })}
        </tr>
      ))}
    </tbody>
  );
};

export default TBody;
