import React from 'react';
import { useSelector } from 'react-redux';

import { Traits } from 'components/Sections/HoroscopesSignsAllAboutSigns/Traits';
import { Store } from 'app-redux/types/storeTypes';
import { ZodiacSignFeatureMultipleElementsInterface } from 'components/Sections/HoroscopesSignsAllAboutSigns/Traits/declarations';

const TraitsContainer: React.FC<ZodiacSignFeatureMultipleElementsInterface> = ({
  elements,
}) => {
  const {
    mobileViewMaxWidth,
    horoscopeTabletMaxWidth,
  } = useSelector((store: Store) => store.server.app.scssVariables);

  return (
    <Traits
      traits={elements}
      mobileViewMaxWidth={mobileViewMaxWidth}
      horoscopeTabletMaxWidth={horoscopeTabletMaxWidth}
    />
  );
};

export default TraitsContainer;
