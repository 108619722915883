import { FC, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import variables from 'src/styles/_commonVariables.module.scss';
import { CollapseIcon } from 'src/shared/ui/CollapseIcon';
import type { Store } from 'app-redux/types/storeTypes';
import { SortedTestimonials } from 'features/SortedTestimonials';
import type { SectionComponentInterface } from 'types/componentTypes';

import styles from './styles.module.scss';
import Heading from './Heading';
import FeaturedTestimonial from './FeaturedTestimonial';
import LoadMore from './LoadMore';

import { getBlocks } from '../lib';
import { useTestimonials } from '../lib/hooks';

const CustomersTestimonialsList: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const {
    itemsAmount = 3,
  } = extraData || {};
  const currentWidth = useSelector((store: Store) => store.client.app.clientWidth);
  const isLessThenCommonMaxWidth = currentWidth <= (Number(variables.commonPageMaxWidth) || 1200);

  const [isCollapsed, setCollapsedState] = useState<boolean>(false);
  const {
    feature,
    testimonials,
    total,
    isLoading,
    refetch,
  } = useTestimonials(itemsAmount);
  const { main, sorting, featured } = getBlocks(blocks);

  return (
    <section
      id={main?.blockId || 'testimonials'}
      style={{ background: bgColor }}
      className={cn(
        styles.testimonials,
        { [styles.collapsed]: isCollapsed },
      )}
    >
      {isLessThenCommonMaxWidth && (
        <button
          type="button"
          className={styles.collapseButton}
          onClick={() => setCollapsedState((prev) => !prev)}
        >
          <CollapseIcon width={25} isExpanded={!isCollapsed} />
        </button>
      )}
      <Heading
        total={total}
        block={main}
        isCollapsed={isCollapsed}
      />
      <FeaturedTestimonial
        isCollapsed={isCollapsed}
        block={main}
        testimonial={feature}
        featuredBlock={featured}
      />
      <SortedTestimonials
        isCollapsed={isCollapsed}
        main={main}
        sorting={sorting}
        isLoading={isLoading}
        testimonials={testimonials}
        refetch={(sortType) => {
          refetch({ amount: itemsAmount, sortType });
        }}
      />
      <LoadMore link={main?.link} isCollapsed={isCollapsed} />
    </section>
  );
};

export default CustomersTestimonialsList;
