import { UrlObject } from 'url';

import cookie from 'cookie';
import type { NextRouter } from 'next/router';

import { IS_CLIENT_SIDE, TEMP_REFERRER_COOKIE } from 'constants/constants';

import type { ReplaceOptionsParameters, Url } from './types';

import { BioOverlay, IFRAME_QUERY_PARAMETER } from '../bio-overlay';

export class NextRouterUrlHandler {
  private type: Url | object;

  private isAbsolute: boolean;

  private stringifiedUrl: string;

  private jsUrl: URL;

  constructor(private url: Url) {
    this.url = url;
    // @ts-ignore
    this.type = typeof url;
    this.stringifiedUrl = (typeof url === 'string') ? url : url.pathname || '';
    this.isAbsolute = this.stringifiedUrl.startsWith('http') || (url as string).startsWith('//');
    this.jsUrl = new URL(this.stringifiedUrl, window.location.origin);
  }

  getJsURL() {
    return this.jsUrl;
  }

  getStringifiedUrl() {
    return this.stringifiedUrl;
  }

  getPathname(asString: boolean = false) {
    if (!asString) {
      return this.url;
    }

    if (this.type === 'string') {
      return this.url;
    }

    return (this.url as UrlObject).pathname;
  }

  getUrlWithSearch(search: string) {
    const path = this.getPathWithSearch(search);

    if (this.type === 'string') {
      return path;
    }

    (this.url as UrlObject).pathname = path;

    return (this.url as UrlObject);
  }

  getPathWithSearch(search: string = '') {
    if (this.isAbsolute) {
      return `${this.jsUrl.origin}${this.jsUrl.pathname}${search}`;
    }

    return `${this.jsUrl.pathname}${search}`;
  }
}

const getCompletedUrl = (url: Url, isAbsolute?: boolean) => {
  if (!isAbsolute) {
    return url;
  }

  let optionalSlash = '';

  if (url.toString().charAt(0) !== '/') {
    optionalSlash = '/';
  }

  if (process.env.NODE_ENV === 'production') {
    return `${process.env.NEXT_PUBLIC_BASE_SERVER_URL}`
      .concat(optionalSlash)
      .concat(url as string);
  }

  if (IS_CLIENT_SIDE) {
    return window.location.origin
      .concat(optionalSlash)
      .concat(url as string);
  }

  return url;
};

export const getHistoryChangeMethod = (
  router: NextRouter,
  method: 'push' | 'replace' = 'replace',
) => (
  url: Url,
  as?: Url,
  options?: ReplaceOptionsParameters,
) => {
  const {
    keepQuery,
    isAbsolute,
    withReload,
    ...originalOptions
  } = options || {};

  if (withReload) {
    return router.reload() as any;
  }

  const isIframe = router.query[IFRAME_QUERY_PARAMETER];

  if (isIframe) {
    return BioOverlay.notifyParentOnNavigation(url.toString());
  }

  const completedUrl = getCompletedUrl(url, isAbsolute);
  const urlHandler = new NextRouterUrlHandler(completedUrl);
  const stringifiedUrl = urlHandler.getStringifiedUrl();
  const isNotKeepSearchParameters = !keepQuery;
  const isUrlEqualsToCurrentPageUrl = !stringifiedUrl || stringifiedUrl === router.pathname;
  const currentSearchParams = new URLSearchParams(window.location.search);
  const isNoSearchParameterToAdd = Array.from(currentSearchParams).length === 0;

  if (isNotKeepSearchParameters || isUrlEqualsToCurrentPageUrl || isNoSearchParameterToAdd) {
    return router[method](completedUrl, as, originalOptions);
  }

  const newUrl = urlHandler.getJsURL();
  currentSearchParams.forEach((value, key) => {
    newUrl.searchParams.append(key, value);
  });

  const urlSearch = newUrl.search;
  const urlPath = urlHandler.getUrlWithSearch(urlSearch);

  const isUrlForBrowserBarNotProvided = !as;

  if (isUrlForBrowserBarNotProvided) {
    const asPath = urlHandler.getPathWithSearch(currentSearchParams.toString());

    return router[method](urlPath, asPath, originalOptions);
  }

  const asUrlHandler = new NextRouterUrlHandler(as!);
  const stringifiedAsUrl = asUrlHandler.getStringifiedUrl();

  if (!stringifiedAsUrl) {
    const asPath = urlHandler.getPathWithSearch(currentSearchParams.toString());

    return router[method](urlPath, asPath, originalOptions);
  }

  const newAsUrl = asUrlHandler.getJsURL();
  currentSearchParams
    .forEach((value, key) => newAsUrl.searchParams.append(key, value));
  const asUrlSearch = newAsUrl.search;
  const asPath = urlHandler.getUrlWithSearch(asUrlSearch);

  return router[method](urlPath, asPath, originalOptions);
};

export const setTempReferrerCookie = () => {
  const referrer = cookie.serialize(
    TEMP_REFERRER_COOKIE,
    window.location.href,
    { secure: process.env.NODE_ENV === 'production', maxAge: 20 },
  );
  document.cookie = referrer;
};
