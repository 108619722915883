import { FC } from 'react';
import { MARKS } from '@contentful/rich-text-types';

import classes from 'src/styles/_commonClasses.module.scss';
import { CommonRichText } from 'components/Shared/SharedComponents';
import type { RichTextParsersConfig } from 'types/objectTypes';
import { PsychicCardImage } from 'entities/PsychicCardImage';

import styles from './styles.module.scss';

import type { IECPsychicPhoto } from '../../config/declarations';

const parsersConfig: RichTextParsersConfig = {
  [MARKS.BOLD]: {
    classNames: styles.title,
  },
};

const ECPsychicPhoto: FC<IECPsychicPhoto> = ({
  psychic,
  block,
  psychicFrame,
}) => {
  if (!block) {
    return null;
  }

  return (
    <div className={styles.photo}>
      <CommonRichText content={block.richTitle} parsersConfig={parsersConfig} />
      <PsychicCardImage
        psychic={psychicFrame!}
        className={styles.image}
        image="external"
        src={psychic.psychicImageUrl}
      />
      <p className={styles.text}>
        {block.title}
        {' '}
        <strong className={classes.textBold700}>
          {psychic.lineName}
        </strong>
      </p>
    </div>
  );
};

export default ECPsychicPhoto;
