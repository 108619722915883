import { FC } from 'react';

import { SectionComponentInterface } from 'types/componentTypes';
import { PsychicsTiersHardcoded } from 'components/Sections/PsychicsTiersHardcoded';
import { HardcodedPsychicsTiers } from 'components/Sections/PsychicsTiersHardcoded/declarations';

const hardcodedPsychics: HardcodedPsychicsTiers = {
  cheap: [
    { link: '/psychics/denisha-6038' },
    { link: '/psychics/simone-5346' },
  ],
  medium: [
    { link: '/psychics/trinity-5275' },
    { link: '/psychics/nevada-6581' },
  ],
  premium: [
    { link: '/psychics/scarlet-5314' },
    { link: '/psychics/elijah-5211' },
  ],
};

const PsychicsTiersHardcodedContainer: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const [block] = blocks;

  return (
    <PsychicsTiersHardcoded
      bgColor={bgColor}
      block={block}
      extraData={extraData}
      hardcodedPsychics={hardcodedPsychics}
    />
  );
};

export default PsychicsTiersHardcodedContainer;
