import {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

import { FixedBanner } from 'components/Sections/FixedBanner';
import { SectionComponentInterface } from 'types/componentTypes';
import { Store } from 'app-redux/types/storeTypes';
import { PsychicCategoryType } from 'constants/enums';
import { RightPsychic } from 'types/objectTypes';
import { getRightPsychicsProxy } from 'src/api/psychicApi';
import { makePayloadToGetPsychic } from 'lib/psychic.service';
import { IS_CLIENT_SIDE, IS_FIXED_BANNER_SEEN } from 'constants/constants';

const BANNER_STATE = 'true';
const FixedBannerContainer: FC<SectionComponentInterface> = ({
  blocks,
  extraData,
  bgColor,
}) => {
  const {
    itemsAmount = 1,
    psychicCategory = PsychicCategoryType.ASTROLOGY,
  } = extraData || {};
  const {
    mobileViewMaxWidth,
    commonTabletMaxWidth,
  } = useSelector((store: Store) => store.server.app.scssVariables);
  const isMobileHeaderNavActive = useSelector(
    (store: Store) => store.server.app.isMobileHeaderNavActive,
  );
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const [psychic, setPsychic] = useState<RightPsychic | null>(null);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isFixedBannerSeen, setFixedBannerSeenState] = useState<boolean | string>(false);

  const closeBanner = useCallback(() => {
    if (!isFixedBannerSeen) {
      localStorage.setItem(IS_FIXED_BANNER_SEEN, BANNER_STATE);
    }

    setIsShow(false);
  }, []);

  useEffect(() => {
    const requestBody = {
      ResultType: 1,
      PageSize: itemsAmount,
      ...makePayloadToGetPsychic(psychicCategory),
    };
    getRightPsychicsProxy(requestBody)
      .then(({ psychics }) => setPsychic(psychics[0]))
      .catch((err) => console.log(err));
  }, [itemsAmount]);

  useEffect(() => {
    const isFixedBannerSeen = localStorage.getItem(IS_FIXED_BANNER_SEEN) || '';
    setFixedBannerSeenState(isFixedBannerSeen);
    setIsShow(isFixedBannerSeen !== BANNER_STATE);
  }, []);

  if (!psychic || !IS_CLIENT_SIDE || !isShow || isFixedBannerSeen) {
    return null;
  }

  return createPortal(
    <FixedBanner
      psychic={psychic}
      blocks={blocks}
      extraData={extraData}
      bgColor={bgColor}
      viewerDevice={viewerDevice}
      mobileViewMaxWidth={mobileViewMaxWidth}
      commonTabletMaxWidth={commonTabletMaxWidth}
      isMobileHeaderNavActive={isMobileHeaderNavActive}
      closeBanner={closeBanner}
    />,
    document.body,
  );
};

export default FixedBannerContainer;
