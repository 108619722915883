/* eslint-disable no-param-reassign */

import { replaceContentfulVariables } from 'lib/text.service';
import { Logger } from 'lib/logger';

export const parseJsonProperties = (
  gaProperties?: Record<string, any>,
  replacementEntry: Record<string, string> = {},
): any => {
  if (!gaProperties) {
    return {};
  }

  const mutableGaProperties = { ...gaProperties };
  Reflect.deleteProperty(mutableGaProperties, '_meta');

  try {
    const stringifiedGaProperties = JSON.stringify(mutableGaProperties);
    const parsedStringifiedGaProperties = replaceContentfulVariables(
      stringifiedGaProperties,
      replacementEntry,
    );

    return JSON.parse(parsedStringifiedGaProperties);
  } catch (e) {
    Logger.error(e);
  }

  return {};
};

export const parseEncodedJson = (json: string) => {
  if (json.charAt(0) === '%') {
    return JSON.parse(decodeURIComponent(json));
  }

  return JSON.parse(json);
};
