import { FC } from 'react';

import styles from './PriceCardsWithKarma.module.scss';

import type { IGoLink } from '../config/declarations';

const GoLink: FC<IGoLink> = ({ title }) => (
  <div className={styles.cardLink}>
    <strong className={styles.cardLinkText}>{title || 'GO'}</strong>
  </div>
);

export default GoLink;
