import React, { FC } from 'react';
import cn from 'classnames';

import styles from 'components/Sections/PsychicsSetMediumSizeImagesClone/PsychicsSetMediumSizeImages.module.scss';
import { PaginationType } from 'constants/enums';

import PaginationButton from './PaginationButton';
import { PaginationControlInterface } from './declarations';

const PaginationControl: FC<PaginationControlInterface> = ({
  paginationInfo,
  paginationButton,
  paginationBackButton,
  paginationNextButton,
  paginationType,
  changePage,
}) => {
  const {
    isPreviousPageEnabled,
    currentPageIndex = 0,
    totalPages = 0,
    isNextPageEnabled,
  } = paginationInfo || {};

  const nextPrevPagination = (
    <div className={styles.psychicPaginationControl}>
      <PaginationButton
        paginationButton={paginationBackButton}
        onClick={() => changePage(-1)}
        disabled={!isPreviousPageEnabled}
        className={cn(
          styles.psychicPaginationControlButton,
        )}
      />
      <span>{`Page ${currentPageIndex + 1} of ${totalPages}`}</span>
      <PaginationButton
        paginationButton={paginationNextButton}
        onClick={() => changePage(1)}
        disabled={!isNextPageEnabled}
        className={cn(
          styles.psychicPaginationControlButton,
        )}
      />
    </div>
  );

  const loadMoreControl = (
    <PaginationButton
      paginationButton={paginationButton}
      onClick={() => changePage(1)}
      disabled={!isNextPageEnabled}
      className={cn(
        styles.psychicButton,
        styles.psychicButtonMargin,
        styles.psychicButtonMobileSmall,
      )}
    />
  );

  const isNumberPaginationButtons = paginationNextButton && paginationBackButton;
  const isPaginationTypeNumber = paginationType === PaginationType.NUMBERS;
  const isNumberControl = isNumberPaginationButtons && isPaginationTypeNumber && totalPages > 0;
  const isLoadMoreControl = paginationButton && paginationType === PaginationType.LOAD_MORE;

  if (isNumberControl || isLoadMoreControl) {
    return isPaginationTypeNumber ? nextPrevPagination : loadMoreControl;
  }

  return null;
};

export default PaginationControl;
