import { FC } from 'react';

import { PsychicPhotoModule } from 'constants/enums';

import PhotoWithGradient from './PhotoWithGradient';
import PhotoWithRate from './PhotoWithRate';
import PhotoWithSelection from './PhotoWithSelection';
import PhotoWithReadingDetails from './PhotoWithReadingDetails';

import type { IModule } from '../config/declarations';

const Module: FC<IModule> = ({
  view,
  ...rest
}) => {
  if (view === PsychicPhotoModule.WITH_GRADIENT) {
    return <PhotoWithGradient {...rest} />;
  }

  if (view === PsychicPhotoModule.WITH_RATE) {
    return <PhotoWithRate {...rest} />;
  }

  if (view === PsychicPhotoModule.WITH_SELECTION) {
    return <PhotoWithSelection {...rest} />;
  }

  if (view === PsychicPhotoModule.WITH_READING_DETAIL) {
    return <PhotoWithReadingDetails {...rest} />;
  }

  return null;
};

export default Module;
