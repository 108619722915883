import { FC } from 'react';
import cn from 'classnames';

import LocalLoader from 'src/shared/ui/LocalLoader/LocalLoader';

import styles from './styles.module.scss';

import { useHoroscope } from '../lib/hooks';
import type { IHoroscopeText } from '../config/declarations';

const HoroscopeText: FC<IHoroscopeText> = ({
  horoscopeProps,
  content,
  className,
}) => {
  const { horoscope, isLoading } = useHoroscope(horoscopeProps, content);

  if (isLoading) {
    return <LocalLoader className={styles.loader} />;
  }

  return (
    <div className={cn(className, styles.description)}>
      {horoscope}
    </div>
  );
};

export default HoroscopeText;
