import cookie from 'cookie';

import { PsychisReadingType, Status } from 'constants/enums';
import { getStatus } from 'lib/psychic.service';
import { USER_TIME_ZONE } from 'constants/constants';
import {
  PsychicReadingKeys,
  ReadingPsychic,
  ReadingsByExtId,
  RightPsychic,
} from 'types/objectTypes';
import { capitalizeFirstLetter as capitalize } from 'lib/text.service';

export const getReadingType = (
  type: PsychisReadingType,
  readingHistory: string,
  eventReasonRaw: string,
  prtApp: boolean,
  prtType: string,
) => {
  const eventReason = eventReasonRaw?.toLowerCase();

  if (readingHistory === 'upcoming') {
    if (prtApp) {
      return prtType === 'request' ? PsychisReadingType.PENDING_REQUEST : capitalize(prtType);
    }

    return type;
  }

  if (readingHistory === 'missed' && eventReason === 'deleted by agent') {
    return prtApp ? PsychisReadingType.CANCELLED_CALLBACK : `Cancelled ${type}`;
  }

  if (readingHistory === 'missed' && eventReason !== 'deleted by customer') {
    return prtApp ? PsychisReadingType.MISSED_CALLBACK : `Missed ${type}`;
  }

  if (readingHistory === 'cancelled' || eventReason === 'deleted by customer') {
    return prtApp ? PsychisReadingType.CANCELLED_CALLBACK : `Cancelled ${type}`;
  }

  if (prtApp) {
    return prtType === 'request' ? PsychisReadingType.PENDING_REQUEST : capitalize(prtType);
  }

  return type;
};

export const getPsychicEstimatedWaitTimeText = (estimatedWaitTime: number) => {
  if (estimatedWaitTime === 0) {
    return '0m';
  }

  if (estimatedWaitTime < 60) {
    return `${estimatedWaitTime}m`;
  }

  const hours = Math.floor(estimatedWaitTime / 60);
  const minutes = estimatedWaitTime % 60;

  return `${hours}h ${minutes}m`;
};

export const getAppointmentTimeString = (dateString: string) => {
  const cookieObject = cookie.parse(document.cookie);
  const userTimeZoneCookie = cookieObject[USER_TIME_ZONE];
  const userTimeZone = userTimeZoneCookie ? JSON.parse(userTimeZoneCookie) : {};

  if (!dateString) {
    return '';
  }

  const match = dateString.match(/\d+/);

  if (!match) {
    return '';
  }

  const timestamp = parseInt(match[0], 10);
  const date = new Date(timestamp);

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: userTimeZone.timeZone,
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const parseDate = (dateString) => {
  const timestamp = parseInt(dateString.replace(/\/Date\((\d+)-\d+\)\//, '$1'), 10);

  return new Date(timestamp);
};

export const getPsychicsReadingKeys = ({
  type,
  readingHistory: readingHistoryRaw,
  eventReason,
  date,
  prtApp,
  prtType,
  psychic,
}: PsychicReadingKeys) => {
  const readingHistory = readingHistoryRaw?.toLowerCase();
  const chatStatus = (psychic.chatStatus?.toLowerCase() || Status.BUSY) as Status;
  const phoneStatus = (psychic.lineStatus?.toLowerCase() || Status.BUSY) as Status;
  const readingType = getReadingType(type, readingHistory, eventReason, prtApp, prtType);
  const isReadingTypeChat = readingType === PsychisReadingType.CHAT;
  const isReadingTypeMessage = readingType === PsychisReadingType.MESSAGE;
  const considerCtaButtons = isReadingTypeChat || isReadingTypeMessage || readingHistory === 'past';

  return {
    ...psychic,
    extId: psychic.extId,
    type: readingType,
    psychicName: psychic.lineName,
    status: getStatus({ chatStatus, phoneStatus }),
    date: date ? getAppointmentTimeString(date) : '',
    estimatedWaitTime: psychic.estimatedWaitTime ? getPsychicEstimatedWaitTimeText(psychic.estimatedWaitTime) : '0m',
    images: psychic?.images,
    phoneStatus: psychic.lineStatus,
    considerCtaButtons,
    psychicBioURL: `/${psychic.lineName}-${psychic.extId}`,
    readingHistory,
    parsedDate: date ? parseDate(date) : '',
  };
};

export const getPsychicsReadingExtIdWise = (
  data: Array<RightPsychic>,
) => data.reduce((acc, reading) => {
  const { extId } = reading;

  if (!acc[extId]) {
    acc[extId] = [];
  }

  acc[extId].push(reading);

  return acc;
}, {});

export const getSortedDisplayReading = (readingsByExtId: ReadingsByExtId) => {
  const readings: Array<ReadingPsychic & RightPsychic> = [];
  Object.keys(readingsByExtId).forEach((extId) => {
    const eachPsychicReadings = readingsByExtId[extId];
    const upcomingEntries = eachPsychicReadings.filter((reading) => reading.readingHistory.toLowerCase() === 'upcoming');
    const notUpcomingEntries = eachPsychicReadings.filter((reading) => reading.readingHistory.toLowerCase() !== 'upcoming');

    if (upcomingEntries.length > 0) {
      upcomingEntries.sort((a, b) => a.parsedDate.getTime() - b.parsedDate.getTime());
      readings.push(upcomingEntries[0]);
    } else if (notUpcomingEntries.length > 0) {
      notUpcomingEntries.sort((a, b) => b.parsedDate.getTime() - a.parsedDate.getTime());
      readings.push(notUpcomingEntries[0]);
    }
  });
  readings.sort((a, b) => b.parsedDate.getTime() - a.parsedDate.getTime());

  return readings;
};
