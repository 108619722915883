import { FC, Fragment } from 'react';
import parse from 'html-react-parser';

import { IExternalTag, IExternalTags } from '../config/declarations';

/* Nested component */
const ExternalTag: FC<IExternalTag> = ({ tag }) => {
  if (!tag.fullText) {
    return null;
  }

  return (
    <Fragment key={tag.entryName}>
      {parse(tag.fullText)}
    </Fragment>
  );
};

/* Main component */
const ExternalTags: FC<IExternalTags> = ({ externalTags }) => {
  if (!externalTags || !externalTags.length) {
    return null;
  }

  return (
    <>
      {externalTags.map((tag) => (
        <ExternalTag key={tag.entryName} tag={tag} />
      ))}
    </>
  );
};

export default ExternalTags;
