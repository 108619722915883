/* eslint-disable jsx-a11y/img-redundant-alt */
import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import styles from 'components/Footer/ExtraInfo/ExtraInfo.module.scss';
import classes from 'styles/_commonClasses.module.scss';
import { CommonRichText, LazyWebpImage } from 'components/Shared/SharedComponents';
import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';
import { RichTextParsersConfig } from 'types/objectTypes';
import { FooterExtraDataIconInterface, FooterExtraInfoInterface } from 'components/Footer/ExtraInfo/declarations';
import { Block } from 'src/__generated__/graphqlTypes';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.infoText,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
};

/* Nested component */
const Icon: FC<FooterExtraDataIconInterface> = ({ image }) => {
  if (!image?.url) {
    return null;
  }

  return (
    <LazyWebpImage
      image={EXTERNAL_WEBP_IMAGE}
      src={image.url}
      className={styles.infoImage}
    />
  );
};

/* Main component */
const ExtraInfo: FC<FooterExtraInfoInterface> = ({ additional = [] }) => {
  const [info] = additional as Array<Block>;

  if (!info) {
    return null;
  }

  const { content, image } = info;

  return (
    <div className={styles.info}>
      <Icon image={image} />
      <CommonRichText
        parsersConfig={parsersConfig}
        content={content!}
      />
    </div>
  );
};

export default ExtraInfo;
