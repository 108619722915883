import { useDispatch, useSelector } from 'react-redux';

import { createTicketProxy } from 'src/api/externalApi';
import type { Store } from 'app-redux/types/storeTypes';
import { setLoadingState } from 'app-redux/actions/appActions';
import { Logger } from 'lib/logger';
import type { ZendeskTicketRequestData } from 'types/objectTypes';

import { FeedbackFormInterfaceStateValues } from '../config/declarations';

export const useHandlers = ({
  showModal,
  clearForm,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((store: Store) => store.server.auth.user);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const userAgent = useSelector((store: Store) => store.client.app.userAgent);

  const handleSubmitForm = async (values: FeedbackFormInterfaceStateValues) => {
    const custname = user?.firstName || values.email.value || '';
    const zendeskData: ZendeskTicketRequestData = {
      name: custname,
      customerId: user?.customerId,
      emailAddressOnAccount: user?.email,
      emailAddressProvided: values.email.value,
      message: values.message.value,
      requestType: values.requestType.value,
      platform: viewerDevice,
      browser: userAgent?.browser?.name,
      browserVersion: userAgent?.browser?.version,
    };

    try {
      dispatch(setLoadingState(true));
      await createTicketProxy(zendeskData);
      showModal();
      clearForm();
    } catch (e: any) {
      Logger.error(e.data);
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  return handleSubmitForm;
};
