import { FC } from 'react';
import cn from 'classnames';

import OffersButtons from './OffersButtons';

import styles from '../styles.module.scss';
import type { IOffers } from '../../config/declarations';

const Offers: FC<IOffers> = ({ offers }) => {
  if (!offers) {
    return null;
  }

  return (
    <div className={cn(styles.box, styles.offers)}>
      <p className={styles.title}>
        {offers.title}
      </p>
      <OffersButtons
        link={offers.link}
        content={offers.content}
      />
    </div>
  );
};

export default Offers;
