/* eslint-disable no-param-reassign */
import { SdkEvents } from 'common-chat-components/enums';
import { UserType } from 'types/objectTypes';
import { parseJwt } from 'lib/text.service';
import { getChatTokensProxy } from 'src/api/authApi';

import { ConversationOptions } from '../config/declarations';
import { CHAT_TOKEN_EXPIRATION_THRESHOLD, CHAT_BUBBLE_ID } from '../config/constants';

export const getChatBubbleButton = () => {
  const bubbleButton = document
    .querySelector<HTMLButtonElement>(`#${CHAT_BUBBLE_ID}`);

  return bubbleButton;
};

export const launchStatefulChat = (bubbleButton: HTMLButtonElement | null) => {
  window.ChatApp!(SdkEvents.SHOW_LAUNCHER);

  if (bubbleButton) {
    bubbleButton.style.display = 'none';
    bubbleButton.style.visibility = 'hidden';
  }

  window.ChatApp!(SdkEvents.SHOW, () => {
    bubbleButton!.style.display = 'none';
  });
};

export const launchStatelessChat = (
  bubbleButton: HTMLButtonElement | null,
  conversationOptions: ConversationOptions,
  turnOffLoading: () => void,
) => {
  const { token, userId, psychicId } = conversationOptions;

  if (!token || !userId) {
    return;
  }

  window.ChatApp!(SdkEvents.SET_SESSION, { userId, token });

  if (bubbleButton) {
    bubbleButton.style.display = 'none';
    bubbleButton.style.visibility = 'hidden';
  }

  window.ChatApp!(SdkEvents.SET_CONVERSATION, { psychicId });
  window.ChatApp!(SdkEvents.SHOW, () => {
    bubbleButton!.style.display = 'none';
  });
  turnOffLoading();
};

export const checkIsValidChatToken = (user: UserType | null): boolean | never => {
  if (!user) {
    throw new Error();
  }

  let expirationDate: Date;
  const now = new Date();

  if (!user.chatToken) {
    throw new Error('No chat token');
  } else if (!user.chatTokenExp) {
    const parsedChatToken = parseJwt(user.chatToken);
    expirationDate = new Date(parsedChatToken.exp * 1000);
  } else {
    expirationDate = new Date(user.chatTokenExp * 1000);
  }

  expirationDate.setDate(expirationDate.getDate() - CHAT_TOKEN_EXPIRATION_THRESHOLD);

  return expirationDate >= now;
};

export const tryToUpdateChatToken = async (
  user: UserType | null,
  setUser: (user: UserType,
  ) => void,
) => {
  if (!user) {
    return;
  }

  const { chatToken, chatTokenExp } = await getChatTokensProxy({ token: user.authToken });
  const freshUser = user;
  freshUser.chatToken = chatToken;
  freshUser.chatTokenExp = +chatTokenExp;
  setUser(freshUser);
};
