import { FC, memo } from 'react';
import {
  BLOCKS,
  MARKS,
  INLINES,
} from '@contentful/rich-text-types';
import cn from 'classnames';

import styles from 'components/Sections/ExpandableItemsList/ExpandableItemsList.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { CommonRichText, DataButton } from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';
import { ExpandableItemInterface } from 'components/Sections/ExpandableItemsList/declarations';
import { getGaAccordion } from 'src/shared/lib/analytics/ga';

const getParsersConfig = (isExpanded: boolean): RichTextParsersConfig => {
  const rootClassName = 'expandableItemListItem';
  const rootButtonClassName = `${rootClassName}Button`;
  const getHClasses = (specificClass: string) => cn(
    styles[`${rootButtonClassName}Title`],
    specificClass,
  );

  return ({
    [BLOCKS.HEADING_3]: {
      classNames: getHClasses(styles[`${rootButtonClassName}TitleH3`]),
    },
    [BLOCKS.HEADING_4]: {
      classNames: getHClasses(styles[`${rootButtonClassName}TitleH4`]),
    },
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(
        styles[`${rootClassName}ParagraphsText`],
        isExpanded && styles[`${rootClassName}ParagraphsTextMargin`],
        !isExpanded && classes.visibilityHidden,
        !isExpanded && classes.zeroHeight,
      ),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
    [INLINES.HYPERLINK]: {
      classNames: styles[`${rootClassName}ParagraphsLink`],
    },
  });
};

const ExpandableItem: FC<ExpandableItemInterface> = ({
  id,
  isExpanded = false,
  title,
  link,
  paragraphs,
  toggleExpanded,
}) => {
  const { _meta: meta = {} } = link?.gaProperties || {};
  const getIcon = () => {
    const rootClassName = 'expandableItemListItemButtonIcon';

    return (
      <>
        <span className={cn(
          styles[rootClassName],
          styles[`${rootClassName}Dash`],
        )}
        />
        <span className={cn(
          styles[`${rootClassName}`],
          styles[`${rootClassName}${isExpanded ? 'Dash' : 'Line'}`],
        )}
        />
      </>
    );
  };

  return (
    <li className={styles.expandableItemListItem}>
      <DataButton
        link={link!}
        gaData={getGaAccordion(meta, !isExpanded)}
        onClick={() => toggleExpanded(id)}
        className={styles.expandableItemListItemButton}
      >
        {title && (
          <CommonRichText
            content={title}
            parsersConfig={getParsersConfig(isExpanded)}
          />
        )}
        {getIcon()}
      </DataButton>
      <hr className={styles.expandableItemListItemUnderline} />
      {paragraphs && (
        <div className={
          isExpanded
            ? styles.expandableItemListItemParagraphs
            : classes.visibilityHidden
        }
        >
          <CommonRichText
            content={paragraphs}
            parsersConfig={getParsersConfig(isExpanded)}
          />
        </div>
      )}
    </li>
  );
};

export default memo(ExpandableItem);
