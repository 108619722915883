import { Personalize } from '@uniformdev/context-react';
import { FC } from 'react';

import { SectionType } from 'src/constants/enums';
import {
  Block,
  PersonalizeSection,
  Section,
  Text,
} from 'src/__generated__/graphqlTypes';
import { chooseVariant } from 'src/shared/lib/personalization';
import { AppropriateSectionInterface } from 'components/Sections/declarations';
import AboutHeroImage from 'components/Sections/AboutHeroImage';
import AboutLeftImage from 'components/Sections/AboutLeftImage';
import AboutNcOfferPackages from 'components/Sections/AboutNcOfferPackages';
import AboutOrangeAlert from 'components/Sections/AboutOrangeAlert';
import AboutPsychicsSetMediumSizeImages from 'components/Sections/PsychicsSetMediumSizeImages';
import CircleImageDescriptionButton from 'components/Sections/CircleImageDescriptionButton';
import AboutLeftVideo from 'components/Sections/AboutLeftVideo';
import SignIntroContainer from 'components/Sections/HoroscopesSignsSignIntro';
import HoroscopesAndPredictions from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions';
import AllAboutSigns from 'components/Sections/HoroscopesSignsAllAboutSigns';
import MysteryDirections from 'components/Sections/HoroscopesSignsMysteryDirections';
import ContactUsHelpCenter from 'components/Sections/ContactUsHelpCenter';
import ContactUsGetInTouch from 'components/Sections/ContactUsGetInTouch';
import TextAndSideAsset from 'components/Sections/TextAndSideAsset';
import PsychicsSetMediumSizeImages from 'components/Sections/PsychicsSetMediumSizeImagesClone';
import OrangeAlert from 'components/Sections/OrangeAlert';
import NcOfferPackages from 'components/Sections/NcOfferPackages';
import ExpandableItemsList from 'components/Sections/ExpandableItemsList';
import CtaFlatBanner from 'components/Sections/CtaFlatBanner';
import CircleImageDescriptionButtonClone from 'components/Sections/CircleImageDescriptionButtonClone';
import MysteryLinksSet from 'components/Sections/MysteryLinksSet';
import Divider from 'components/Sections/ContentDivider';
import FixedBanner from 'components/Sections/FixedBanner';
import InlineHtml from 'components/Sections/InlineHtml';
import ExponeaPsychicsList from 'components/Sections/ExponeaPsychicsList';
import ExponeaPsychicProfile from 'components/Sections/ExponeaPsychicProfile';
import PsychicsTiersHardcoded from 'components/Sections/PsychicsTiersHardcoded';
import GridHeroBanner from 'components/Sections/GridHeroBanner';
import PlainItemsWithDescription from 'components/Sections/PlainItemsWithDescription';
import { TemplateHtmlPsychics } from 'src/widgets/TemplateHtmlPsychics';
import { CustomerTestimonials } from 'src/widgets/CustomersTestimonials';
import { mapSectionToUniformSection } from 'lib/uniform.service';
import { OptionalTrustpilot } from 'src/widgets/OptionalTrustpilot';
import { FlexColumns } from 'src/widgets/FlexColumns';
import { HardcodedPersonalizationData } from 'types/objectTypes';
import { GreetAndPsychicNotification } from 'src/widgets/GreetAndPsychicNotification';
import { HoroSignShortInfo } from 'src/widgets/HoroSignShortInfo';
import { OffersList } from 'src/widgets/OffersListCarousel';
import { OrangeAlertBig } from 'src/widgets/OrangeAlertBig';
import { SectionsCombiner } from 'src/widgets/SectionsCombiner';
import { GreetAndOffer } from 'src/widgets/GreetAndOffers';
import { PsychicsSetSwitchApi } from 'src/widgets/PsychicsSetSwitchApi';
import { PriceCardsWithKarma } from 'src/widgets/PriceCardsWithKarma';
import { CustomersTestimonialsList } from 'src/widgets/CustomersTestimonialsList';
import { SimilarPsychics } from 'src/widgets/SimilarPsychics';
import { AppointmentCalendar } from 'src/widgets/AppointmentCalendar';
import { PsychicBioInformation } from 'src/widgets/PsychicBioInformation';
import { CustomerPendingFeedback } from 'src/widgets/CustomerPendingFeedback';

export const AppropriateSection: FC<AppropriateSectionInterface> = ({ section }) => {
  if (!section) {
    return null;
  }

  const {
    type,
    blocksCollection,
    backgroundColor,
    entryName,
    data,
  } = section;
  const blocks = blocksCollection?.items as Array<Block> | undefined;

  if (!blocks) {
    return null;
  }

  switch (type) {
    case SectionType.ABOUT_HERO_IMAGE: {
      const Component = (
        <AboutHeroImage
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );

      return Component;
    }
    case SectionType.ABOUT_LEFT_IMAGE: {
      return (
        <AboutLeftImage
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.ABOUT_LEFT_VIDEO: {
      return (
        <AboutLeftVideo
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.ABOUT_NC_OFFER_PACKAGES: {
      return (
        <AboutNcOfferPackages
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.ABOUT_ORANGE_ALERT: {
      return (
        <AboutOrangeAlert
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.ABOUT_PSYCHICS_SET_MEDIUM_SIZE_IMAGES: {
      return (
        <AboutPsychicsSetMediumSizeImages
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.CIRCLE_IMAGE_DESCRIPTION_BUTTON: {
      return (
        <CircleImageDescriptionButton
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.HOROSCOPES_SIGNS_SIGN_INTRO: {
      return (
        <SignIntroContainer
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.HOROSCOPES_SIGNS_HOROSCOPES_AND_PREDICTIONS: {
      return (
        <HoroscopesAndPredictions
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.HOROSCOPES_SIGNS_ALL_ABOUT_SIGNS: {
      return (
        <AllAboutSigns
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.HOROSCOPES_SIGNS_MYSTERY_DIRECTIONS: {
      return (
        <MysteryDirections
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.CONTACT_US_HELP_CENTER: {
      return (
        <ContactUsHelpCenter
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.CONTACT_US_GET_IN_TOUCH: {
      return (
        <ContactUsGetInTouch
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.TEXT_AND_SIDE_ASSET: {
      return (
        <TextAndSideAsset
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.PSYCHICS_SET_MEDIUM_SIZE_IMAGES_CLONE: {
      return (
        <PsychicsSetMediumSizeImages
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.CIRCLE_IMAGE_DESCRIPTION_BUTTON_CLONE: {
      return (
        <CircleImageDescriptionButtonClone
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.ORANGE_ALERT_CLONE: {
      return (
        <OrangeAlert
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.NC_OFFER_PACKAGES_CLONE: {
      return (
        <NcOfferPackages
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.MYSTERY_LINKS_SET: {
      return (
        <MysteryLinksSet
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.EXPANDABLE_ITEMS_LIST: {
      return (
        <ExpandableItemsList
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.ORANGE_ALERT_BIG: {
      return (
        <OrangeAlertBig
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.CONTENT_DIVIDER: {
      return (
        <Divider
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.CTA_FLAT_BANNER: {
      return (
        <CtaFlatBanner
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.FIXED_BANNER: {
      return (
        <FixedBanner
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.INLINE_HTML: {
      return (
        <InlineHtml
          key={entryName}
          blocks={blocks as Array<Text>}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.EXPONEA_PSYCHICS_LIST: {
      return (
        <ExponeaPsychicsList
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.EXPONEA_PSYCHIC_PROFILE: {
      return (
        <ExponeaPsychicProfile
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.PSYCHICS_TIERS_HARDCODED: {
      return (
        <PsychicsTiersHardcoded
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.GRID_HERO_BANNER: {
      return (
        <GridHeroBanner
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.PLAIN_ITEMS_WITH_DESCRIPTION: {
      return (
        <PlainItemsWithDescription
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.TEMPLATE_HTML_PSYCHICS: {
      return (
        <TemplateHtmlPsychics
          key={entryName}
          blocks={blocks as Array<Text>}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.CUSTOMERS_TESTIMONIALS: {
      return (
        <CustomerTestimonials
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.OPTIONAL_TRUSTPILOT: {
      return (
        <OptionalTrustpilot
          key={entryName}
          blocks={blocks as any}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.EC_GREET_AND_PSYCHIC_NOTIFICATIONS: {
      return (
        <GreetAndPsychicNotification
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.FLEX_COLUMNS: {
      return (
        <FlexColumns
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.SECTIONS_COMBINER: {
      return (
        <SectionsCombiner
          key={entryName}
          blocks={blocks as any}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.EC_HORO_SIGH_SHORT_INFO: {
      return (
        <HoroSignShortInfo
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.EC_GREET_AND_OFFERS: {
      return (
        <GreetAndOffer
          key={entryName}
          blocks={blocks as any}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.PSYCHICS_SET_SWITCH_API: {
      return (
        <PsychicsSetSwitchApi
          key={entryName}
          blocks={blocks as any}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.EC_OFFERS_LIST: {
      return (
        <OffersList
          key={entryName}
          blocks={blocks as any}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.CUSTOMERS_TESTIMONIALS_LIST: {
      return (
        <CustomersTestimonialsList
          key={entryName}
          blocks={blocks as any}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.SIMILAR_PSYCHICS: {
      return (
        <SimilarPsychics
          key={entryName}
          blocks={blocks as any}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.EC_PRICE_CARDS_WITH_KARMA: {
      return (
        <PriceCardsWithKarma
          key={entryName}
          blocks={blocks}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.APPOINTMENT_CALENDAR: {
      return (
        <AppointmentCalendar
          key={entryName}
          blocks={blocks as any}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.PSYCHIC_BIO_INFORMATION: {
      return (
        <PsychicBioInformation
          key={entryName}
          blocks={blocks as any}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    case SectionType.CUSTOMER_PENDING_FEEDBACK: {
      return (
        <CustomerPendingFeedback
          key={entryName}
          blocks={blocks as any}
          extraData={data}
          bgColor={backgroundColor!}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export const renderReactComponentsByType = (
  content: Array<PersonalizeSection>,
  personalizationData: HardcodedPersonalizationData,
) => content
  ?.map((sections, i) => {
    // @ts-ignore
    const mappedData: Array<any> = sections?.unfrmOptP13nListCollection?.items
      .map((section) => (section && mapSectionToUniformSection(section)));
    const variants = chooseVariant(mappedData, personalizationData);

    return (
      <Personalize
        key={sections.entryName + i.toString()}
        variations={variants}
        /* This code snippet removes extra component renders */
        wrapperComponent={({ children }) => (<>{children}</>)}
        // @ts-ignore
        component={(section: Section) => <AppropriateSection section={section} />}
      />
    );
  });
