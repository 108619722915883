import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

import styles from 'src/components/Sections/AboutOrangeAlert/AboutOrangeAlert.module.scss';
import { RichTextParsersConfig } from 'types/objectTypes';
import { Block } from 'src/__generated__/graphqlTypes';
import { SectionComponentInterface } from 'types/componentTypes';
import { CommonRichText, DataLink } from 'components/Shared/SharedComponents';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.HEADING_2]: {
    classNames: styles.alertTitle,
  },
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.alertParagraph,
  },
};

const AboutOrangeAlert: React.FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const buildComponents = () => blocks
    .map((block: Block) => {
      const { content, link, entryName } = block;

      if (!content) return null;

      return (
        <React.Fragment key={entryName}>
          <CommonRichText content={content} parsersConfig={parsersConfig} />
          {link && (
            <DataLink
              link={link}
              href={link.src!}
              className={styles.alertButton}
            >
              {link.title}
            </DataLink>
          )}
        </React.Fragment>
      );
    });

  return (
    <section className={styles.alert} style={{ background: bgColor }}>
      {buildComponents()}
    </section>
  );
};

export default AboutOrangeAlert;
