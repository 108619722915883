import { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import type { Link } from 'src/__generated__/graphqlTypes';
import styles from 'components/Sections/ExponeaPsychicProfile/ExponeaPsychicProfile.module.scss';
import {
  DataLink,
  Icon,
  PsychicRate,
} from 'components/Shared/SharedComponents';
import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';
import { getPsychicCtaButtons, getStatus } from 'lib/psychic.service';
import { RatePerMinuetType } from 'types/objectTypes';
import { PsychicStatusIndicator, PsychicStatusText } from 'entities/PsychicStatus';
import type { Store } from 'app-redux/types/storeTypes';
import { HeartImage } from 'entities/PsychicHeartImage';
import { PsychicCardVideo as Video } from 'entities/PsychicCardVideo';
import { PsychicCardImage } from 'entities/PsychicCardImage';
import { HiatusBar } from 'entities/HiatusBar';

import type {
  PriceComponentInterface,
  ProfileInfoInterface,
  RatingComponentInterface,
  StatusComponentInterface,
} from './config/declarations';
import StickyInteraction from './StickyInteraction';
import Queue from './Queue';
import EstimatedTime from './EstimatedTime';

/* Nested component */
const StatusComponent: FC<StatusComponentInterface> = ({
  psychic,
  chatStatus,
  phoneStatus,
  psychicFrame,
}) => {
  const status = getStatus({ chatStatus, phoneStatus });

  return (
    <div className={styles.status}>
      <div className={styles.statusIndicator}>
        <PsychicStatusIndicator
          status={status}
          className={styles.statusIndicator}
        />
        <PsychicStatusText
          status={status}
          psychic={psychic}
          psychicFrame={psychicFrame}
          className={styles.statusText}
          wrapperClass={styles.statusTextWrapper}
        />
      </div>
    </div>
  );
};

/* Nested component */
const RatingComponent: FC<RatingComponentInterface> = (
  { overallScore, starImage, responses, tools },
) => (
  <div className={styles.rating}>
    {overallScore}
    {starImage?.url && (
      <Icon
        image={starImage}
        className={styles.ratingIcon}
      />
    )}
    {`(${responses}  ${tools})`}
  </div>
);

/* Nested component */
const PriceComponent: FC<PriceComponentInterface> = ({ ratePerMinute, ratePerMinuteText }) => {
  const priceStyles = {
    wrapper: styles.price,
    common: styles.priceCommon,
    commonWithDiscount: styles.priceCrossed,
    discount: styles.priceDiscount,
  };

  const minText = `/${ratePerMinuteText}`;

  return (
    <div className={styles.priceWrapper}>
      <PsychicRate
        pricePerMinute={`${ratePerMinute.price} ${minText}`}
        priceWithDiscountPerMinute={`${ratePerMinute.discount} ${minText}`}
        classNames={priceStyles}
      />
    </div>
  );
};

/* Main component */
const ProfileInfo: FC<ProfileInfoInterface> = ({ psychic, psychicFrame, bootStatus }) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const isMobile = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const {
    chatStatus,
    phoneStatus,
    customerPrice,
    basePrice,
    overallScore,
    responses,
    peopleInQueue,
  } = psychic;
  const ratePerMinute: RatePerMinuetType = {
    discount: customerPrice,
    price: basePrice,
  };

  const cta = getPsychicCtaButtons(
    psychic,
    psychicFrame,
    { hashedId: user?.hashedId },
  );

  const testimonialLink = psychicFrame.extraInfoButtonsCollection?.items[0] as Link;

  return (
    <>
      <div className={styles.profileInfo}>
        <div className={styles.profileInfoBio}>
          <HeartImage
            psychic={psychic}
            contentfulData={psychicFrame}
            className={styles.heartImg}
            assetClassName={styles.heartImgAsset}
            assetFavoriteClassName={styles.heartImgAssetFavorite}
            hintClassName={styles.heartImgHint}
            hintArrowClassName={styles.heartImgHintArrow}
          />
          {psychic.psychicVideoURL && (
            <Video
              psychic={psychic}
              className={cn(styles.video, {
                [styles.videoWithHiatus]: psychic?.onHiatus,
              })}
            />
          )}
          <PsychicCardImage
            image={EXTERNAL_WEBP_IMAGE}
            className={styles.profileInfoBioImage}
            src={psychic.images[3] || psychicFrame.avatar?.url || ''}
            psychic={psychicFrame}
          />
          <HiatusBar
            psychic={psychic}
            contentfulData={psychicFrame}
          />
        </div>
        <div className={styles.profileInfoContent}>
          <h1 className={styles.profileTitle}>
            {psychic.lineName}
            <Icon
              image={psychicFrame.starImage!}
              className={styles.profileTitleIcon}
            />
          </h1>
          <div className={styles.profileInfoContentStatusRating}>
            <StatusComponent
              psychic={psychic}
              psychicFrame={psychicFrame}
              phoneStatus={phoneStatus}
              chatStatus={chatStatus}
              statusText={psychicFrame.status}
            />
            <RatingComponent
              overallScore={overallScore}
              starImage={psychicFrame.starImage}
              responses={responses}
              tools={psychicFrame.tools}
            />
          </div>
          <PriceComponent
            ratePerMinute={ratePerMinute}
            ratePerMinuteText={psychicFrame.ratePerMinute}
          />
          {testimonialLink && (
            <DataLink link={testimonialLink} className={styles.testimonial}>
              {testimonialLink.title}
            </DataLink>
          )}
          <Queue
            queue={psychicFrame.queue}
            peopleInQueue={peopleInQueue}
            isModal={false}
          />
          <EstimatedTime
            texts={psychicFrame.estimatedWaiting}
            timeUnit={psychicFrame.ratePerMinute}
            estimatedTime={psychic.estimatedWaitTime}
          />
          <StickyInteraction
            bootStatus={bootStatus}
            cta={cta}
            isMobile={isMobile}
            psychic={psychic}
            psychicFrame={psychicFrame}
          />
        </div>
      </div>
    </>
  );
};

export default ProfileInfo;
