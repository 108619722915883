import { FC } from 'react';

import { DataButton, WebpImage } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';

import type { IMagnifierSearchButton } from '../config/declarations';

const MagnifierSearchButton: FC<IMagnifierSearchButton> = ({ button, onClick }) => {
  if (!button?.image?.url) {
    return null;
  }

  return (
    <DataButton
      className={styles.button}
      link={button}
      onClick={onClick}
    >
      <WebpImage
        className={styles.buttonImage}
        image="external"
        src={button.image.url}
      />
    </DataButton>
  );
};

export default MagnifierSearchButton;
