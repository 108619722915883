import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Store } from 'app-redux/types/storeTypes';
import { SectionComponentInterface } from 'types/componentTypes';
import { CtaFlatBanner } from 'components/Sections/CtaFlatBanner';

const CtaFlatBannerContainer: FC<SectionComponentInterface> = ({
  blocks,
  extraData,
  bgColor,
}) => {
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);

  return (
    <CtaFlatBanner
      blocks={blocks}
      bgColor={bgColor}
      extraData={extraData}
      viewerDevice={viewerDevice}
    />
  );
};

export default CtaFlatBannerContainer;
