import { useEffect, useRef } from 'react';

export const useLoginFormLazyLoad = (isShown: boolean) => {
  const isComponentLoaded = useRef<boolean>(false);

  useEffect(() => {
    if (isShown && !isComponentLoaded.current) {
      isComponentLoaded.current = true;
    }
  }, [isShown, isComponentLoaded]);

  return isComponentLoaded.current;
};
