/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  FC,
  KeyboardEvent,
  MouseEvent,
} from 'react';
import Carousel from 'react-responsive-carousel/lib/js/components/Carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import cn from 'classnames';

import styles from 'components/Sections/NcOfferPackages/NcOfferPackages.module.scss';
import { SliderArrow } from 'components/Shared/SharedComponents';
import { NcOfferPackagesSliderInterface } from 'components/Sections/NcOfferPackages/declarations';

const applyCommonArrowProps = (clickHandler: () => void, label: string, className: string) => ({
  height: 27,
  width: 10,
  clickHandler,
  label,
  className: cn(styles.sliderArrow, className),
});

const NcOfferPackagesSlider: FC<NcOfferPackagesSliderInterface> = ({
  cards,
  rightArrow,
  leftArrow,
}) => {
  const renderArrowRight = (clickHandler: () => void, hasNext: boolean, label: string) => (
    <SliderArrow
      arrow={rightArrow}
      href={rightArrow.image?.url!}
      altImage=">"
      hasNextElement={hasNext}
      defaultControlClass="control-next"
      {...applyCommonArrowProps(clickHandler, label, styles.sliderArrowNext)}
    />
  );

  const renderArrowLeft = (clickHandler: () => void, hasPrev: boolean, label: string) => (
    <SliderArrow
      arrow={leftArrow}
      href={leftArrow.image?.url!}
      altImage="<"
      hasNextElement={hasPrev}
      defaultControlClass="control-prev"
      {...applyCommonArrowProps(clickHandler, label, styles.sliderArrowPrev)}
    />
  );

  const renderIndicator = (
    clickHandler: (e: MouseEvent<HTMLLIElement> | KeyboardEvent<HTMLLIElement>) => void,
    isSelected: boolean,
    index: number,
    label: string,
  ) => (
    <li
      key={index}
      onClick={clickHandler}
      onKeyPress={clickHandler}
      title={label}
      aria-label={label}
      className={cn(
        isSelected && styles.sliderIndicatorSelected,
        styles.sliderIndicator,
      )}
    />
  );

  return (
    <Carousel
      className={styles.slider}
      renderArrowNext={renderArrowRight}
      renderArrowPrev={renderArrowLeft}
      renderIndicator={renderIndicator}
      infiniteLoop
      showThumbs={false}
      showStatus={false}
    >
      {cards}
    </Carousel>
  );
};

export default NcOfferPackagesSlider;
