import { FC } from 'react';
import cn from 'classnames';

import { ImageWrapper } from 'src/__generated__/graphqlTypes';
import classes from 'src/styles/_commonClasses.module.scss';
import styles from 'components/Sections/CtaFlatBanner/CtaFlatBanner.module.scss';
import { DataLink, WebpImage } from 'components/Shared/SharedComponents';
import { capitalizeFirstLetter } from 'lib/text.service';
import { CommonSize, ViewerDevice } from 'constants/enums';
import {
  CtaFlatBannerSlugs as Slugs,
  CtaFlatBannerButtonInterface,
  CtaFlatBannerImageInterface,
  CtmFlatBannerInterface,
} from 'components/Sections/CtaFlatBanner/declarations';
import { setItemInObjectBySlug } from 'lib/sharedMethods.service';
import { IS_CLIENT_SIDE } from 'constants/constants';

const slugs = {
  'background-mobile': [Slugs.BACKGROUND_MOBILE],
  'background-desktop': [Slugs.BACKGROUND_DESKTOP],
};

/* Nested component */
const Background: FC<CtaFlatBannerImageInterface> = ({
  className,
  pictureClassName,
  mobileImage,
  desktopImage,
  viewerDevice,
  isBackground = false,
}) => {
  const imageWrapper = (viewerDevice === ViewerDevice.MOBILE)
    ? mobileImage
    : desktopImage;

  if (!imageWrapper?.image) {
    return null;
  }

  const { image } = imageWrapper;

  const getSizes = () => {
    if (isBackground && viewerDevice === ViewerDevice.MOBILE) {
      const width = (IS_CLIENT_SIDE) ? window.innerWidth : 'auto';
      const height = styles.mobileBackgroundHeight;

      return { height, width };
    }

    if (isBackground && viewerDevice !== ViewerDevice.MOBILE) {
      const width = styles.desktopBackgroundWidth;
      const height = styles.desktopBackgroundHeight;

      return { height, width };
    }

    const width = Math.round(image.width! / 2);
    const height = Math.round(image.height! / 2);

    return { height, width, additionalProps: { h: height, w: width } };
  };

  const sizes = getSizes();

  return (
    <WebpImage
      image={image}
      pictureClassName={pictureClassName}
      className={className}
      {...sizes}
    />
  );
};

/* Nested component */
const Button: FC<CtaFlatBannerButtonInterface> = ({ link }) => {
  if (!link?.src) {
    return null;
  }

  return (
    <DataLink
      link={link}
      className={styles.bannerLink}
    >
      {link.title}
    </DataLink>
  );
};

/* Main component */
const CtaFlatBanner: FC<CtmFlatBannerInterface> = ({
  blocks,
  bgColor,
  extraData,
  viewerDevice,
}) => {
  const { verticalPadding = CommonSize.X_SMALL } = extraData || {};
  const [block] = blocks;

  if (!block) {
    return null;
  }

  const { imagesCollection, link, title } = block;

  const reducer = setItemInObjectBySlug(slugs);

  const {
    backgroundDesktop,
    backgroundMobile,
  } = (imagesCollection?.items as Array<ImageWrapper>)
    ?.reduce(reducer, {} as Record<Slugs, ImageWrapper>) || {};

  return (
    <section
      style={{ background: bgColor }}
      className={cn(
        styles.wrapper,
        classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`],
      )}
    >
      <div className={styles.banner}>
        <div className={styles.bannerWrapper}>
          <span className={styles.bannerText}>{title}</span>
          <Button link={link} />
        </div>
        <Background
          desktopImage={backgroundDesktop}
          mobileImage={backgroundMobile}
          viewerDevice={viewerDevice}
          isBackground
          className={styles.bannerBackground}
        />
      </div>
    </section>
  );
};

export default CtaFlatBanner;
