import { FC } from 'react';
import cn from 'classnames';
import Head from 'next/head';

import styles from 'components/Content/Content.module.scss';
import { renderReactComponentsByType } from 'components/Sections';
import { ContentInterface, IContentStyles } from 'components/Content/declarations';
import { useSidebarComponent } from 'src/shared/lib/sidebar/hooks';
import { useCustomRouter } from 'src/shared/lib/history/hooks';

const ContentStyles: FC<IContentStyles> = ({ bgColor }) => {
  if (!bgColor) {
    return null;
  }

  return (
    <Head>
      <style>
        {`
              #__next {
                background: ${bgColor};
              }
          `}
      </style>
    </Head>
  );
};

/* Main component */
const Content: FC<ContentInterface> = ({
  bgColor,
  content,
  className,
  serverCookies,
}) => {
  const router = useCustomRouter();
  const components = renderReactComponentsByType(content, { router, serverCookies });
  const { componentsWithCollapsedSidebar, standaloneSidebar } = useSidebarComponent(components);

  return (
    <div className="wrap">
      <ContentStyles bgColor={bgColor} />
      {standaloneSidebar}
      <main
        role="main"
        className={cn(styles[className], styles.common)}
      >
        {componentsWithCollapsedSidebar}
      </main>
    </div>
  );
};

export default Content;
