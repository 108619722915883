import { FC } from 'react';

import { HeaderPhones } from 'src/shared/ui/HeaderPhones';

import styles from './styles.module.scss';

import { usePhoneNumbers } from '../lib';
import type { ITopBarMobile } from '../config/declarations';

const TopBarMobile: FC<ITopBarMobile> = ({ topBar }) => {
  const phoneNumbers = usePhoneNumbers(topBar?.phonesCollection);

  if (!topBar) {
    return null;
  }

  return (
    <section className={styles.topBar}>
      <HeaderPhones phoneNumbers={phoneNumbers} />
    </section>
  );
};

export default TopBarMobile;
