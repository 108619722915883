import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';

import {
  BootState,
  PresentationMode,
  SdkEvents,
  View,
} from 'common-chat-components/enums';
import { setUser } from 'app-redux/actions/authActions';
import {
  ASK_ACCOUNT_INFO,
  CTA_TYPE,
  NC_FUNNEL_SIGN_UP_URL,
  PSYCHIC_TALK_COOKIE_BIO,
  SESSION_COOKIE_NAME,
} from 'constants/constants';
import { Store } from 'app-redux/types/storeTypes';
import { RightPsychic, UserType } from 'types/objectTypes';
import { sessionCookieOptions } from 'lib/session.service';
import { setBootStatus as setBootStatusRemote, setLoadingState } from 'app-redux/actions/appActions';
import { accountInfoObject } from 'src/shared/lib/cookie/accountInfo';
import { setPageUrlCookie } from 'src/shared/lib/cookie';
import { useExponeaProductSelectedTracking } from 'lib/external/exponeaHooks';
import { useCustomRouter } from 'src/shared/lib/history/hooks';

import {
  checkIsValidChatToken,
  getChatBubbleButton,
  launchStatefulChat,
  launchStatelessChat,
  tryToUpdateChatToken,
} from './chat';
import { setMultipleCookies } from './ctaCookie';

import { ConversationOptions } from '../config/declarations';

let isChatSet = false;

export const useBootChatSolution = () => {
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);
  const user = useSelector((store: Store) => store.server.auth.user);
  const dispatch = useDispatch();
  const [bootStatus, setBootStatus] = useState<BootState | null>(null);
  const setBootStatusProxy = (bootStatus: BootState) => {
    setBootStatus(bootStatus);
    dispatch(setBootStatusRemote(bootStatus));
  };
  const updateSession = (user: UserType) => {
    cookies.set(SESSION_COOKIE_NAME, JSON.stringify(user), sessionCookieOptions);
    dispatch(setUser(user));
  };
  const removeSessionCookie = () => cookies
    .set(SESSION_COOKIE_NAME, '', { expires: new Date().getDate(), path: '/' });

  const bootChatApp = useCallback((command, payload, callback) => {
    const { ChatApp } = window;

    if (ChatApp) {
      ChatApp!(command, payload, callback);
    }
  }, []);

  const waitForChatApp = useCallback((
    counter: number,
    timeout: Parameters<typeof clearTimeout>[0],
  ) => {
    if (counter > 15 || !isAuthenticated || isChatSet) {
      return clearTimeout(timeout);
    }

    const { ChatApp } = window;

    if (!ChatApp || typeof ChatApp !== 'function') {
      // eslint-disable-next-line no-param-reassign
      timeout = setTimeout(() => waitForChatApp(
        counter + 1,
        timeout,
      ), 100);

      return;
    }

    isChatSet = true;

    if (ChatApp.q && ChatApp.q.length > 0) {
      ChatApp.q.forEach((event) => {
        bootChatApp(event[0], event[1], event[2]);
      });
      ChatApp.q = [];
    }

    ChatApp!(SdkEvents.ON_LOGOUT, () => {
      setBootStatusProxy(BootState.STATELESS);
    });
    ChatApp!(
      SdkEvents.ON_CHAT_EVENT,
      { name: 'onSwitchToPhone' },
      ({ phoneNumber }) => window.open(`tel:${phoneNumber}`, '_parent'),
    );
    ChatApp!(
      SdkEvents.BOOT,
      {
        appId: '1002',
        chatHost: process.env.NEXT_PUBLIC_CHAT_SDK_URL,
        presentationMode: {
          type: PresentationMode.BUBBLE,
          view: View.CUSTOMER,
        },
      },
      async ({ bootStatus }) => {
        setBootStatusProxy(bootStatus);

        try {
          const isValid = checkIsValidChatToken(user);

          if (!isValid) {
            await tryToUpdateChatToken(user, updateSession);
          }

          if (bootStatus === BootState.STATEFUL) {
            const bubbleButton = getChatBubbleButton();
            launchStatefulChat(bubbleButton);
          }
        } catch (e) {
          removeSessionCookie();
        }
      },
    );
  }, [isAuthenticated]);

  useEffect(() => {
    if (isChatSet) {
      return;
    }

    const counter = 0;
    const timeout: any = null;
    waitForChatApp(counter, timeout);

    return () => clearTimeout(timeout);
  }, [waitForChatApp]);

  useEffect(() => () => { isChatSet = false; }, []);

  return bootStatus;
};

export const useChatClick = (
  bootStatus: BootState | null,
  psychic: RightPsychic,
  pageUrl: string,
) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const slug = useSelector((store: Store) => store.server.page.slug);
  const trackExponea = useExponeaProductSelectedTracking(psychic);
  const router = useCustomRouter();
  const dispatch = useDispatch();

  const [wasClicked, setClickedState] = useState<boolean>(false);

  useEffect(() => {
    if (!wasClicked || bootStatus === null) {
      return;
    }

    const isChatCannotStart = !user
      || !window.ChatApp
      || typeof window.ChatApp !== 'function';

    if (isChatCannotStart) {
      return;
    }

    if (slug === 'psychic-bio') {
      setPageUrlCookie(pageUrl);
    }

    if (user?.isLeadCustomer) {
      const number = user?.customerPhoneNumber === '9999999999' ? '' : user?.customerPhoneNumber;
      setMultipleCookies({
        [ASK_ACCOUNT_INFO]: {
          ...accountInfoObject,
          extId: psychic.extId,
          custId: user?.custId,
          chat: true,
          dob: user?.userDateOfBirth,
          ncflowtype: 2,
          email: user?.userEmail,
          phoneNumber: number,
        },
      });
      router.push(`${NC_FUNNEL_SIGN_UP_URL}?extid=${psychic.extId}&ischat=1`);

      return;
    }

    trackExponea('Chat');
    setMultipleCookies(
      {
        [CTA_TYPE]: 'Chat'.toLowerCase(),
        [PSYCHIC_TALK_COOKIE_BIO]: { ExtId: psychic.extId, Name: psychic.lineName },
        [ASK_ACCOUNT_INFO]: { ...accountInfoObject, extId: psychic.extId, chat: true },
      },
    );
    dispatch(setLoadingState(true));
    const bubbleButton = getChatBubbleButton();

    if (bootStatus === BootState.STATEFUL) {
      launchStatefulChat(bubbleButton);
      setClickedState(false);
      dispatch(setLoadingState(false));
    } else if (bootStatus === BootState.STATELESS) {
      const conversationOptions: ConversationOptions = {
        psychicId: psychic.extId,
        userId: user!.custId,
        token: user!.chatToken,
      };
      launchStatelessChat(
        bubbleButton,
        conversationOptions,
        () => {
          dispatch(setLoadingState(false));
          setClickedState(false);
        },
      );
    }
  }, [wasClicked, bootStatus]);

  return () => {
    setClickedState(true);
  };
};
