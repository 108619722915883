import type { Block, Text } from 'src/__generated__/graphqlTypes';

import { ParsedBlocks } from './blocksTypes';

export const getBlockAndStyles = (blocks: Array<Text | Block>): ParsedBlocks => blocks
  .reduce((store, item) => {
    if (item.__typename === 'Block' && (!item.slug || item.slug === 'block' || item.slug === 'content')) {
      return { ...store, block: item };
    }

    if (item.__typename === 'Text' && item.slug === 'styles') {
      return { ...store, styles: item };
    }

    return store;
  }, {});
