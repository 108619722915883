import { FC } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import { getCustomersFeedback } from 'src/api/customerApi';
import type { Store } from 'app-redux/types/storeTypes';
import { CommonRichText, DataLink } from 'components/Shared/SharedComponents';
import classes from 'styles/_commonClasses.module.scss';
import { mapMarksBoldWithReplacement } from 'lib/richTextMappers';
import { parseDotNetDate } from 'lib/date.service';
import type { RichTextParsersConfig, RightPsychic } from 'types/objectTypes';
import type { SectionComponentInterface } from 'types/componentTypes';

import styles from './CustomerPendingFeedback.module.scss';

const getParsersConfig = (date: string, name: string): RichTextParsersConfig => ({
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.paragraph,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
    mapper: mapMarksBoldWithReplacement({
      name,
      date: (parseDotNetDate(date) as Date).toLocaleDateString('en-US'),
    }),
  },
});

const selectPsychic = (store: Store) => store.server.page.data.psychic;

const CustomerPendingFeedback: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const [block] = blocks;
  const psychic: RightPsychic | undefined = useSelector(selectPsychic);
  const user = useSelector((store: Store) => store.server.auth.user);

  const { data } = useSWR('feedback', () => {
    if (!user || !psychic) {
      return null;
    }

    return getCustomersFeedback(user, psychic?.extId);
  }, { revalidateOnFocus: false });

  if (!block || !user || !psychic || !data || data.testimonialId !== 0) {
    return null;
  }

  const { link, content } = block;

  return (
    <section
      style={{ background: bgColor }}
      className={cn(
        styles.wrapper,
      )}
    >
      <h2 className={styles.title}>{block.title}</h2>
      <CommonRichText
        content={content}
        parsersConfig={getParsersConfig(data.datetime, psychic.lineName)}
      />
      {link && (
        <DataLink
          link={link}
          className={styles.button}
        >
          {link?.title}
        </DataLink>
      )}
    </section>
  );
};

export default CustomerPendingFeedback;
