import { FC } from 'react';
import { useSelector } from 'react-redux';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import type { Store } from 'app-redux/types/storeTypes';
import { CommonRichText } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';

import type { IHeaderGreeting } from '../config/declarations';

const parsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.description,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
};

const HeaderGreetingMobile: FC<IHeaderGreeting> = ({ greeting, className }) => {
  const user = useSelector((store: Store) => store.server.auth.user);

  if (!greeting) {
    return null;
  }

  return (
    <div className={cn(styles.greet, className)}>
      <strong className={styles.title}>
        {greeting.title}
        ,
        {user && ` ${user.displayNameHeader}`}
      </strong>
      <CommonRichText
        content={greeting.content}
        parsersConfig={parsersConfig}
      />
    </div>
  );
};

export default HeaderGreetingMobile;
