import { FC } from 'react';

import styles from './styles.module.scss';

import { FieldInterface, SimpleAdditionalInformationInterface } from '../config/declarations';

/* Nested component */
const Field: FC<FieldInterface> = ({ name, value }) => (
  <>
    <strong>{name}</strong>
    :
    {' '}
    {value}
  </>
);

/* Main component */
const AdditionalInformation: FC<SimpleAdditionalInformationInterface> = ({ psychic }) => (
  <>
    <p className={styles.cardInfoDescriptionExtId}>
      <Field name="ExtId" value={psychic.extId} />
    </p>
    <p className={styles.cardInfoDescription}>
      <Field name="Style" value={psychic.style || '-'} />
      <br />
      <Field name="Topics" value={psychic.specialities[0] || '-'} />
      <br />
      <Field name="Tools" value={psychic.tools[0] || '-'} />
      <br />
      {/* @ts-ignore */}
      <Field name="Abilities" value={(psychic.skills as Array<string>)[0] || '-'} />
    </p>
  </>
);

export default AdditionalInformation;
