import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import styles from 'components/Sections/PlainItemsWithDescription/PlainItemsWithDescription.module.scss';
import { RichTextParsersConfig } from 'types/objectTypes';
import {
  Content,
  Title,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { PlainItemIconInterface as IconInterface, PlainItemInterface } from 'components/Sections/PlainItemsWithDescription/declarations';

const getHClasses = (heading: 'H2' | 'H3' | 'H4') => cn(
  styles.contentLiTitle,
  styles[`contentLiTitle${heading}`],
);

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.HEADING_2]: {
    classNames: getHClasses('H2'),
  },
  [BLOCKS.HEADING_3]: {
    classNames: getHClasses('H3'),
  },
  [BLOCKS.HEADING_4]: {
    classNames: getHClasses('H4'),
  },
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.contentLiParagraph,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
};

/* Nested component */
const Icon: FC<IconInterface> = ({ image }) => {
  if (!image) {
    return null;
  }

  return (
    <WebpImage
      image={image}
      className={styles.contentLiImage}
    />
  );
};

/* Main component */
const PlainItem: FC<PlainItemInterface> = ({
  block,
  siblingsAmount,
}) => {
  if (!block) {
    return null;
  }

  const { image, entryName, richTitle, content } = block;

  return (
    <li
      key={entryName}
      className={cn(styles.contentLi, styles[`content${siblingsAmount}`])}
    >
      <Title
        richText={richTitle}
        config={parsersConfig}
      />
      <Icon image={image} />
      <Content
        richText={content}
        config={parsersConfig}
      />
    </li>
  );
};

export default PlainItem;
