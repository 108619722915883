import { FC } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import classes from 'src/styles/_commonClasses.module.scss';
import type {
  Block,
  ImageWrapper,
  Text,
} from 'src/__generated__/graphqlTypes';
import { FullBackgroundImage } from 'src/shared/ui/FullBackgroundImage';
import type { Store } from 'app-redux/types/storeTypes';
import { DataLink } from 'components/Shared/SharedComponents';
import { ColorTheme, ElementAlign } from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';
import { useFetchSegmentData } from 'lib/external/segment';
import type { SectionComponentGenericInterface } from 'types/componentTypes';
import { ContentfulStyles } from 'entities/ContentfulStyles';
import { getBlockAndStyles } from 'src/shared/lib/blocks';

import styles from './OrangeAlertBig.module.scss';
import ContentWrapper from './ContentWrapper';
import { Content, Title } from './RichTextComponents';
import ExtraComponentProcessor from './ExtraComponentProcessor';

import type {
  IButton,
  INote,
  RichTextParam,
} from '../config/declarations';
import { constructId, getExtraComponents } from '../lib';
import { BlockPosition } from '../config/constants';
import { ExtraComponents } from '../lib/context';

/* Nested component */
const Button: FC<IButton> = ({ button, id }) => {
  const segmentObj = useFetchSegmentData(button);

  if (!button) {
    return null;
  }

  return (
    <DataLink
      id={id!}
      link={button}
      className={cn(styles.alertButton, styles.alertButtonCommon)}
      segmentData={segmentObj}
    >
      {button.title}
    </DataLink>
  );
};

/* Nested component */
const Note: FC<INote> = ({ note, id }) => {
  if (!note) {
    return null;
  }

  return (
    <span id={constructId(id, '-note')} className={styles.alertNote}>
      {note}
    </span>
  );
};

/* Main Component */
const OrangeAlertBig: FC<SectionComponentGenericInterface<Block | Text>> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const isMobile = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const {
    titleAlign = ElementAlign.CENTER,
    pAlign = ElementAlign.CENTER,
    blockAlign = ElementAlign.CENTER,
    verticalPadding,
    mobileBlockJustify,
    h1Size,
    colorTheme = ColorTheme.BLACK,
    considerOnlyPresented = false,
  } = extraData || {};
  const richTextConfig: RichTextParam = {
    titleAlign,
    h1Size,
    pAlign,
    isOffset: isMobile && !mobileBlockJustify,
  };

  const { block, styles: extraStyles } = getBlockAndStyles(blocks);

  if (!block) {
    return null;
  }

  const {
    content,
    richTitle,
    link,
    imagesCollection,
    title: note,
    blockId,
    contentTypesCollection,
  } = block;

  const extraComponents = getExtraComponents(contentTypesCollection?.items as Array<Text>);

  return (
    <ExtraComponents.Provider value={extraComponents}>
      <ContentfulStyles styles={extraStyles} />
      <section
        id={constructId(blockId)}
        className={cn(
          styles.wrapper,
          { [classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`]]: Boolean(verticalPadding) },
        )}
        style={{ background: bgColor }}
      >
        <ContentWrapper
          id={blockId}
          isMobile={isMobile}
          blockAlign={blockAlign}
          colorTheme={colorTheme}
          mobileBlockJustify={mobileBlockJustify}
        >
          <ExtraComponentProcessor
            location={BlockPosition.BEFORE_TITLE}
          />
          <Title
            richText={richTitle}
            config={{ ...richTextConfig }}
            id={blockId}
          />
          <ExtraComponentProcessor
            location={BlockPosition.BEFORE_PARAGRAPH}
          />
          <Content
            richText={content}
            config={{
              ...richTextConfig,
              considerOnlyPresented,
              isContentAbove: Boolean(richTitle),
              isContentBelow: Boolean(link) || Boolean(note),
            }}
            id={blockId}
          />
          <ExtraComponentProcessor
            location={BlockPosition.BEFORE_BUTTON}
          />
          <Button
            button={link}
            id={constructId(blockId, '-button')}
          />
          <ExtraComponentProcessor
            location={BlockPosition.BEFORE_NOTE}
          />
          <Note id={blockId} note={note} />
          <ExtraComponentProcessor
            location={BlockPosition.AFTER_NOTE}
          />
        </ContentWrapper>
        <FullBackgroundImage
          backgrounds={imagesCollection?.items as Array<ImageWrapper>}
          disableDefaultSource
          skipTablet
          className={styles.alertBackground}
          id={constructId(blockId, '-bg')}
        />
      </section>
    </ExtraComponents.Provider>
  );
};

export default OrangeAlertBig;
