import { AxiosError } from 'axios';

import { remoteServerAxios } from './axios';

export const getCustomerOffers = (
  custId: string,
  extId: number,
  duration: number,
) => remoteServerAxios
  .get(`offers/${custId}/${extId}/${duration}`)
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });
