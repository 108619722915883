import { FC } from 'react';

import { HeaderRating } from 'src/shared/ui/HeaderRating';
import { HeaderPhones } from 'src/shared/ui/HeaderPhones';

import styles from './styles.module.scss';
import TopBarInteraction from './Interaction';

import { usePhoneNumbers } from '../lib';
import type { ITopBarDesktop } from '../config/declarations';

const TopBarDesktop: FC<ITopBarDesktop> = ({
  topBar,
  rating,
  auth,
  search,
}) => {
  const phoneNumbers = usePhoneNumbers(topBar?.phonesCollection);

  if (!topBar || (!phoneNumbers?.length && !rating && !auth && !search)) {
    return null;
  }

  return (
    <section className={styles.wrapper}>
      <div className={styles.topBar}>
        <HeaderRating rating={rating} />
        <div>
          <HeaderPhones phoneNumbers={phoneNumbers} />
        </div>
        <TopBarInteraction auth={auth} />
      </div>
    </section>
  );
};

export default TopBarDesktop;
