/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC } from 'react';
import cn from 'classnames';

import styles from 'components/Footer/Footer.module.scss';
import FooterCopyright from 'components/Footer/Copyright';
import FooterLinksContainer from 'components/Footer/Links';
import { Link } from 'src/__generated__/graphqlTypes';
import { FooterInterface } from 'components/Footer/declarations';
import { FooterType } from 'constants/enums';
import ExtraInfo from 'components/Footer/ExtraInfo/ExtraInfo';
import { capitalizeFirstLetter } from 'lib/text.service';

const Footer: FC<FooterInterface> = ({
  footer,
  isVisibleScrollTopButton,
  scrollToTop,
}) => {
  const footerType = (footer.type || FooterType.ORANGE) as FooterType;

  return (
    <footer className={cn(
      styles.footer,
      styles[`footer${capitalizeFirstLetter(footerType)}`],
    )}
    >
      {(footer.type === FooterType.LIGHT)
          && <ExtraInfo additional={footer.additionalCollection?.items} />}
      <FooterLinksContainer
        navBar={footer.nav!}
        mobileApps={footer.mobile!}
        footerType={footerType}
        socialMedias={footer.socialMediasCollection?.items as Array<Link>}
      />
      <FooterCopyright
        copyrightDescription={footer.copyrightDescription!}
        desktopSiteRequest={footer.desktopSiteRequest!}
        footerType={footerType}
        mobileSiteRequest={footer.mobileSiteRequest!}
        copyrightLinks={footer.copyrightLinksCollection?.items as Array<Link>}
      />
      {isVisibleScrollTopButton && (
        <div
          role="button"
          tabIndex={0}
          onClick={scrollToTop}
          onKeyPress={scrollToTop}
          style={{ backgroundImage: `url(${footer.scrollTop?.url})` }}
          className={styles.footerScrollTop}
        />
      )}
    </footer>
  );
};

export default Footer;
