import { AxiosError } from 'axios';

import { remoteServerAxios, proxyServerAxios } from 'src/api/axios';

export const getCountriesList = () => remoteServerAxios
  .get('site/signupcountrylist')
  .then((resp) => resp.data)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const getCommonCountriesList = () => remoteServerAxios
  .get('site/countrylist')
  .then((resp) => resp.data)
  .catch((e: AxiosError) => {
    throw e.response;
  });

export const getCountriesListProxy = () => proxyServerAxios
  .get('countries')
  .then((resp) => resp.data)
  .catch((e: AxiosError) => {
    throw e.response;
  });
