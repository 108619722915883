import { FC } from 'react';
import cn from 'classnames';

import { WebpImage } from 'components/Shared/SharedComponents';
import { TestimonialBlock } from 'entities/TestimonialBlock';

import styles from './styles.module.scss';

import type { IFeaturedTestimonial } from '../config/declarations';

const FeaturedTestimonial: FC<IFeaturedTestimonial> = ({
  block,
  isCollapsed,
  testimonial,
  featuredBlock,
}) => {
  if (!block || !testimonial || !Object.keys(testimonial).length) {
    return null;
  }

  return (
    <div className={cn(
      styles.feature,
      { [styles.hidden]: isCollapsed },
    )}
    >
      <div className={styles.featureHeading}>
        {featuredBlock?.image?.url && (
          <span className={styles.featureHeadingCircle}>
            <WebpImage
              className={styles.featureHeadingCircleImage}
              image="external"
              src={featuredBlock.image.url}
            />
          </span>
        )}
        {featuredBlock?.title}
      </div>
      <TestimonialBlock
        isFeatured
        block={block}
        testimonial={testimonial}
      />
    </div>
  );
};

export default FeaturedTestimonial;
