import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import { CommonRichText } from 'components/Shared/SharedComponents';
import { SignFeatureContainer } from 'components/Sections/HoroscopesSignsAllAboutSigns/Shared';
import styles from 'components/Sections/HoroscopesSignsAllAboutSigns/BestDayAndLuckyNumbers/BestDayAndLuckyNumbers.module.scss';
import { capitalizeFirstLetter } from 'lib/text.service';
import { BestDayAndLuckyNumbersInterface } from 'components/Sections/HoroscopesSignsAllAboutSigns/BestDayAndLuckyNumbers/declarations';

const createParsersConfigBestDay = (slug: string) => ({
  [BLOCKS.PARAGRAPH]: {
    classNames: cn(styles.bestDayContent, styles[`bestDayContent${capitalizeFirstLetter(slug)}`]),
  },
});

const createParsersConfigLuckyNumbers = (slug: string) => ({
  [BLOCKS.PARAGRAPH]: {
    classNames: cn(
      styles.luckyNumbersListItem,
      styles[`luckyNumbersListItem${capitalizeFirstLetter(slug)}`],
      styles.luckyNumbersListItemSeparator,
    ),
  },
});

const BestDayAndLuckyNumbers: React.FC<BestDayAndLuckyNumbersInterface> = ({
  bestDay,
  luckyNumbers,
  slug,
}) => {
  const { content: bestDayContent } = bestDay || {};
  const {
    content: luckyNumbersContent,
    textList: luckyNumbersList,
  } = luckyNumbers || {};

  const isLuckyNumbersPresented = !luckyNumbersList?.length || luckyNumbersContent;

  const applyLuckyNumbersList = () => {
    const { length } = luckyNumbersList!;

    return luckyNumbersList!.map((luckyNumber, i) => {
      const isLastElement = i + 1 === length;

      if (isLastElement) {
        return (
          <li key={luckyNumber}>
            <CommonRichText
              content={luckyNumbersContent!}
              parsersConfig={createParsersConfigLuckyNumbers(slug)}
            />
            <span className={cn(styles.luckyNumbersListItem, styles[`luckyNumbersListItem${capitalizeFirstLetter(slug)}`])}>
              {luckyNumber}
            </span>
          </li>
        );
      }

      return (
        <li key={luckyNumber}>
          <span className={cn(styles.luckyNumbersListItem, styles[`luckyNumbersListItem${capitalizeFirstLetter(slug)}`])}>
            {luckyNumber}
            ,
          </span>
        </li>
      );
    });
  };

  return (
    <div className={styles.wrapper}>
      {bestDay
        ? (
          <SignFeatureContainer
            containerClassName={styles.bestDay}
            title={bestDay.title!}
            titleClassName={styles.bestDayTitle}
          >
            {bestDayContent && (
              <CommonRichText
                content={bestDayContent}
                parsersConfig={createParsersConfigBestDay(slug)}
              />
            )}
          </SignFeatureContainer>
        )
        : <SignFeatureContainer containerClassName={styles.bestDay} />}
      {luckyNumbers
        ? (
          <SignFeatureContainer
            containerClassName={styles.luckyNumbers}
            title={luckyNumbers.title!}
            titleClassName={styles.luckyNumbersListTitle}
          >
            {isLuckyNumbersPresented && (
              <ul className={styles.luckyNumbersList}>
                {applyLuckyNumbersList()}
              </ul>
            )}
          </SignFeatureContainer>
        )
        : <SignFeatureContainer containerClassName={styles.luckyNumbers} />}
    </div>
  );
};

export default BestDayAndLuckyNumbers;
