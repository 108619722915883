import { FC } from 'react';
import cn from 'classnames';

import styles from './AppointmentCalendar.module.scss';
import NavigationButton from './NavigationButton';

import { useNavigationDate } from '../lib/hooks';
import { INavigation } from '../config/declarations';

const Navigation: FC<INavigation> = ({ dispatch, className, dateFormat = 'long', date }) => {
  const dateText = useNavigationDate(date, dateFormat);

  return (
    <div className={cn(styles.nav, className)}>
      <NavigationButton dispatch={dispatch} date={date} isLeft />
      <span className={cn(
        styles.navText,
        (dateFormat === 'long') ? styles.navTextSmall : styles.navTextMed,
      )}
      >
        {dateText}
      </span>
      <NavigationButton date={date} dispatch={dispatch} />
    </div>
  );
};

export default Navigation;
