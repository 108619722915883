/* eslint-disable no-param-reassign */
import {
  Dispatch,
  RefObject,
  useContext,
  useEffect,
} from 'react';

import type { ReducerAction } from 'app-redux/types/storeTypes';

import { MobileHeaderNavContext, MobileHeaderNavDispatch } from './context';

import { IContext } from '../config/declarations';

export const useLocalContext = () => {
  const store = useContext(MobileHeaderNavContext);

  return store;
};

export const useLocalDispatch = () => {
  const dispatch = useContext(MobileHeaderNavDispatch);

  return dispatch;
};

export const useToggleTransition = (ref: RefObject<HTMLUListElement>, isVisible: boolean) => {
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (isVisible) {
      ref.current.style.maxHeight = `${ref.current.scrollHeight}px`;
    } else {
      ref.current.style.maxHeight = '';
    }
  }, [isVisible]);
};

export const useClosingSubMenuOnNavigation = (
  dispatch: Dispatch<ReducerAction<keyof IContext>>,
  isMobileHeaderNavActive: boolean,
) => {
  useEffect(() => {
    if (!isMobileHeaderNavActive) {
      dispatch({ type: 'isSubMenuVisible', payload: false });
    }
  }, [isMobileHeaderNavActive]);
};
