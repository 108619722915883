import React from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import cookie from 'cookie';

import { SectionComponentInterface } from 'types/componentTypes';
import AboutNcOfferPackages from 'src/components/Sections/AboutNcOfferPackages/AboutNcOfferPackages';
import { Store } from 'app-redux/types/storeTypes';
import { SortedBlocksType } from 'types/objectTypes';
import { sortBlocksAndLinks } from 'lib/sharedMethods.service';
import { Link } from 'src/__generated__/graphqlTypes';
import { PsychicPackageName } from 'constants/enums';
import { ASK_ACCOUNT_INFO } from 'constants/constants';
import { Logger } from 'lib/logger';

let accountInfo = {
  firstName: '',
  lastName: '',
  email: '',
  dob: '',
  password: '',
  sendFreeHoroAndNewsLetter: '',
  offerDuration: '',
  cardHolderName: '',
  cardNumber: '',
  expMonth: '',
  expYear: '',
  cvv: '',
  cardType: '',
  billingCountry: '',
  billingCountryName: '',
  zipCode: '',
  state: '',
  stateRegion: '',
  city: '',
  address1: '',
  address2: '',
  phoneCountry: '',
  phoneNumber: '',
  masId: '',
  custId: '',
  callbackId: '',
  extId: '',
  offerId: '',
  priceId: '',
  transactionId: '',
  transactionAmount: '',
  statusCode: '',
  errorMessage: '',
  subscriptionId: '',
  package: { duration: '20' },
  questionText: '',
  emailSource: 17,
};

const AboutNcOfferPackagesContainer: React.FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const sortedBlocks: SortedBlocksType = sortBlocksAndLinks(blocks);
  const router = useRouter();

  const onPackageClick = (link?: Link, textList: Array<string> = []) => {
    if (!link) return;

    try {
      const [psychicType] = textList;
      const currentAccountInfoCookie = cookie.parse(document.cookie)[ASK_ACCOUNT_INFO];

      if (currentAccountInfoCookie) {
        const currentAccountInfo = JSON.parse((currentAccountInfoCookie));
        accountInfo = currentAccountInfo;
      }

      switch (psychicType) {
        case PsychicPackageName.POPULAR: {
          const popularPackage = {
            packageName: 'Popular',
            packageType: '1',
            packageBasePrice: '$100',
            packageOfferPrice: '$20',
            saveAmount: '$80',
          };
          accountInfo.package = { ...accountInfo.package, ...popularPackage };

          break;
        }
        case PsychicPackageName.PREFERRED: {
          const preferredPackage = {
            packageBasePrice: '$130',
            packageName: 'Preferred',
            packageOfferPrice: '$40',
            packageType: '2',
            saveAmount: '$90',
          };
          accountInfo.package = { ...accountInfo.package, ...preferredPackage };

          break;
        }
        case PsychicPackageName.PREMIER: {
          const premiumPackage = {
            packageBasePrice: '$170',
            packageName: 'Premium',
            packageOfferPrice: '$80',
            packageType: '3',
            saveAmount: '$90',
          };
          accountInfo.package = { ...accountInfo.package, ...premiumPackage };

          break;
        }
        default: {
          break;
        }
      }

      document.cookie = cookie.serialize(
        ASK_ACCOUNT_INFO,
        JSON.stringify(accountInfo),
        { secure: true, path: '/' },
      );
      router.replace(link.src || '');
    } catch (e) {
      Logger.error(e);
    }
  };

  return (
    <AboutNcOfferPackages
      bgColor={bgColor}
      blocks={sortedBlocks.blocks}
      images={sortedBlocks.images}
      isMobileViewWidth={isMobileViewWidth}
      onPackageClick={onPackageClick}
    />
  );
};

export default AboutNcOfferPackagesContainer;
