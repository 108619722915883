import { FC } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import type { Block } from 'src/__generated__/graphqlTypes';
import classes from 'src/styles/_commonClasses.module.scss';
import type { Store } from 'app-redux/types/storeTypes';
import { CommonSize } from 'constants/enums';
import type { SectionComponentGenericInterface } from 'types/componentTypes';
import { capitalizeFirstLetter } from 'lib/text.service';

import Title from './Title';
import Content from './Content';
import Image from './Image';
import HoroscopeText from './HoroscopeText';
import styles from './HoroSignShortInfo.module.scss';

const HoroSignShortInfo: FC<SectionComponentGenericInterface<Block>> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const shouldBeShown = user && Object.keys(user).length > 0;
  const { verticalPadding = CommonSize.X_SMALL } = extraData || {};
  const verticalPaddingClass = classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`];
  const [block] = blocks;

  if (!shouldBeShown) {
    return null;
  }

  const signBlock = (block.contentTypesCollection?.items as Array<Block> || [])
    .find((block) => block.slug === user!.userSign?.toLowerCase());

  if (!signBlock) {
    return null;
  }

  return (
    <section
      className={cn(styles.sign, verticalPaddingClass)}
      style={{ background: bgColor }}
    >
      <Image image={signBlock.image} />
      <div className={styles.content}>
        <Title title={signBlock.richTitle} />
        <Content content={signBlock.content} text={block.title} />
        <HoroscopeText user={user} link={signBlock.link} />
      </div>
    </section>
  );
};

export default HoroSignShortInfo;
