import { FC } from 'react';

import { FooterTypeInterface } from 'components/Footer/declarations';
import styles from 'components/Footer/Links/Media/Media.module.scss';
import { FooterType } from 'constants/enums';

const Divider: FC<FooterTypeInterface> = ({ footerType, className }) => {
  if (footerType !== FooterType.LIGHT && !className) {
    return null;
  }

  return <hr className={className || styles.divider} />;
};

export default Divider;
