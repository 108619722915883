import {
  Block,
  Link,
  Text,
} from 'src/__generated__/graphqlTypes';

import {
  ButtonSlug,
  HeartImageProps,
  HintProps,
} from '../config/declarations';

const constructHint = (hint: Text | Block): HintProps => {
  if (hint.__typename === 'Text') {
    return {
      text: hint.text,
      __typename: hint.__typename,
    };
  }

  const block = hint as Block;

  return {
    text: block.title,
    richText: block.content,
    __typename: block.__typename || 'Block',
  };
};

export const getHeartImageProps = (
  collection: Array<Link | Text | Block>,
  isRemove: boolean,
) => collection
  .reduce((accumulator, item) => {
    const mutableAccumulator = accumulator;

    if (item.__typename === 'Link' && (item as Link).slug === ButtonSlug.BUTTON) {
      mutableAccumulator.button = item;
    }

    if (item.__typename === 'Text' && mutableAccumulator.hint?.__typename !== 'Block') {
      const text = item as Text;

      if (isRemove && text.slug === ButtonSlug.HINT_REMOVE) {
        mutableAccumulator.hint = constructHint(text);
      }

      if (!isRemove && text.slug === ButtonSlug.HINT_ADD) {
        mutableAccumulator.hint = constructHint(text);
      }
    }

    if (item.__typename === 'Block') {
      const block = item as Block;

      if (isRemove && block.slug === ButtonSlug.HINT_REMOVE) {
        mutableAccumulator.hint = constructHint(block);
      }

      if (!isRemove && block.slug === ButtonSlug.HINT_ADD) {
        mutableAccumulator.hint = constructHint(block);
      }
    }

    return mutableAccumulator;
  }, {} as HeartImageProps);
