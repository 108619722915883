import { FC } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { CollapseIcon } from 'src/shared/ui/CollapseIcon';
import type { Store } from 'app-redux/types/storeTypes';
import type { RightPsychic } from 'types/objectTypes';

import styles from './AppointmentCalendar.module.scss';

import type { INoSidebarContent } from '../config/declarations';

const NoSidebarContent: FC<INoSidebarContent> = ({ isSidebar, title, toggle, isCollapsed }) => {
  const psychic: RightPsychic = useSelector((store: Store) => store.server.page.data?.psychic);

  if (isSidebar) {
    return null;
  }

  return (
    <div className={cn(
      styles.noSidebarWrap,
      { [styles.noSidebarWrapMargin]: !isCollapsed },
    )}
    >
      <h2 className={styles.mobileTitle}>
        {title}
        {' '}
        {psychic?.lineName}
      </h2>
      <button
        type="button"
        className={styles.collapseButton}
        onClick={toggle}
      >
        <CollapseIcon width={25} isExpanded={!isCollapsed} />
      </button>
    </div>
  );
};

export default NoSidebarContent;
