/* eslint-disable max-classes-per-file */
class Node {
  private _next: Node | null = null;

  private _previous: Node | null = null;

  public get next() {
    return this._next;
  }

  public set next(value) {
    this._next = value;
  }

  public get previous() {
    return this._previous;
  }

  public set previous(value) {
    this._previous = value;
  }

  constructor(public link: string) {
    this.link = link;
  }
}

export class BrowserHistory {
  private current: Node | null;

  private length: number = 0;

  constructor(startPage: string) {
    this.current = new Node(startPage);
    this.length = 1;
  }

  visit(url: string) {
    const urlNode = new Node(url);
    urlNode.previous = this.current;

    if (this.current) {
      this.current.next = urlNode;
    }

    this.current = urlNode;
    this.length++;
  }

  recordPop(path: string) {
    if (!this.current?.link || this.current?.link === path) {
      return;
    }

    const prevNode = this.current.previous;

    if (path === prevNode?.link) {
      this.back();

      return 'back';
    }

    const nextNode = this.current.next;

    if (path === nextNode?.link) {
      this.forward();

      return 'forward';
    }
  }

  log() {
    const arr = new Array<string>(0);

    let first = this.current;

    while (first?.previous) {
      first = first.previous;
    }

    while (first?.next) {
      arr.push(first?.link);
      first = first.next;
    }

    if (first?.link) {
      arr.push(first?.link);
    }
  }

  getCurrent() {
    return this.current;
  }

  destroy() {
    let first = this.current;

    while (first?.previous) {
      first = first.previous;
    }

    while (first?.next) {
      const current = first;
      first = first.next;
      current.next = null;
    }

    this.length = 0;
  }

  back() {
    this.current = this.current?.previous || null;

    return this.current?.link;
  }

  forward() {
    this.current = this.current?.next || null;

    return this.current?.link || null;
  }
}
