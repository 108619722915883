import cookie from 'cookie';

import { PsychicPackageName } from 'constants/enums';
import { ASK_ACCOUNT_INFO } from 'constants/constants';

import { defaultAccountInfoObject } from './declarations';

const updateAccountInfoObject = (
  packageDynamicName: string,
  psychicType: string = '',
  cookieAccountInfo?: Record<string, any>,
) => {
  const accountInfo = cookieAccountInfo || defaultAccountInfoObject;

  switch (psychicType) {
    case PsychicPackageName.POPULAR: {
      const popularPackage = {
        packageName: packageDynamicName || 'Affordable',
        packageType: '1',
        packageBasePrice: '$100',
        packageOfferPrice: '$20',
        saveAmount: '$80',
      };
      accountInfo.package = { ...accountInfo.package, ...popularPackage };

      break;
    }
    case PsychicPackageName.PREFERRED: {
      const preferredPackage = {
        packageBasePrice: '$130',
        packageName: packageDynamicName || 'Popular',
        packageOfferPrice: '$40',
        packageType: '2',
        saveAmount: '$90',
      };
      accountInfo.package = {
        ...accountInfo.package,
        ...preferredPackage,
      };

      break;
    }
    case PsychicPackageName.PREMIER: {
      const premiumPackage = {
        packageBasePrice: '$170',
        packageName: packageDynamicName || 'Premium',
        packageOfferPrice: '$80',
        packageType: '3',
        saveAmount: '$90',
      };
      accountInfo.package = { ...accountInfo.package, ...premiumPackage };

      break;
    }
    default: {
      break;
    }
  }

  return accountInfo;
};

export const getCurrentAccountInfoObject = (
  packageDynamicName: string,
  psychicType: string = '',
): Record<string, any> | never => {
  const currentAccountInfoCookie = cookie.parse(document.cookie)[ASK_ACCOUNT_INFO];

  if (currentAccountInfoCookie) {
    const currentAccountInfo = JSON.parse((currentAccountInfoCookie));

    return updateAccountInfoObject(packageDynamicName, psychicType, currentAccountInfo);
  }

  return updateAccountInfoObject(packageDynamicName, psychicType);
};
