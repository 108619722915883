import React, { useState } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import styles from 'src/components/Sections/AboutLeftVideo/AboutLeftVideo.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { SectionComponentInterface } from 'types/componentTypes';
import {
  Block,
  Link,
  Asset,
} from 'src/__generated__/graphqlTypes';
import { RichTextParsersConfig } from 'types/objectTypes';
import {
  CommonRichText,
  DataLink,
  DataButton,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { CustomAssetInterface } from 'components/Sections/AboutLeftVideo/declarations';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.leftVideoContentParagraph,
  },
  [BLOCKS.HEADING_2]: {
    classNames: styles.leftVideoContentTitle,
  },
};

const CustomAsset: React.FC<CustomAssetInterface> = ({
  video,
  isPreviewImageClicked,
  onClickPreviewImage,
}) => {
  if (video) {
    const videoPreview = video.image as Asset;
    const visibleElemStyles = styles.leftVideoMedia;
    const visibleElemStylesWithPointer = cn(styles.leftVideoAsset, classes.cursorPointer);

    return (
      <>
        {videoPreview && !isPreviewImageClicked && (
          <DataButton
            link={video}
            className={styles.leftVideoPreviewButton}
            onClick={onClickPreviewImage}
            onKeyPress={onClickPreviewImage}
          >
            <WebpImage
              image={videoPreview}
              width="auto"
              height="100%"
              aria-label={videoPreview.title as string}
              className={visibleElemStylesWithPointer}
            />
          </DataButton>

        )}
        {isPreviewImageClicked && (
          <iframe
            className={visibleElemStyles}
            src={video.src as string}
            title={video.title as string}
            allow="autoplay"
            frameBorder="0"
            allowFullScreen
          />
        )}
      </>
    );
  }

  return null;
};

const AboutLeftVideo: React.FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const [isPreviewImageClicked, setPreviewImageClicked] = useState(false);

  const onClickPreviewImage = () => {
    setPreviewImageClicked(true);
  };

  const buildComponents = () => blocks
    .map((block: Block) => {
      const {
        content,
        video,
        link,
        entryName,
      } = block;

      if (!content) return null;

      return (
        <div className={styles.leftVideo} key={entryName}>
          <CustomAsset
            video={video as Link}
            onClickPreviewImage={onClickPreviewImage}
            isPreviewImageClicked={isPreviewImageClicked}
          />
          <div className={styles.leftVideoContent}>
            <CommonRichText content={content} parsersConfig={parsersConfig} />
            {link && (
              <DataLink
                link={link}
                href={link.src!}
                className={cn(styles.leftVideoContentButton, classes.cursorPointer)}
              >
                {link.title}
              </DataLink>
            )}
          </div>
        </div>
      );
    });

  return (
    <section className={styles.wrapper} style={{ background: bgColor }}>
      {buildComponents()}
    </section>
  );
};

export default AboutLeftVideo;
