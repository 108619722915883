import cookie from 'js-cookie';

import { MAS_PHONE_NUMBER } from '../config/constants';

export const getMasPhoneNumber = (phoneNumber?: string, phoneNumberSplit?: string[]): string => {
  let masPhoneNumber: string = '';

  if (phoneNumberSplit?.length) {
    masPhoneNumber = '1.'.concat(phoneNumberSplit.join('.'));
  }

  if (phoneNumber) {
    const masPhoneNumberArray: Array<string> = new Array(4);

    masPhoneNumberArray[0] = '1';
    masPhoneNumberArray[1] = phoneNumber.slice(0, 3);
    masPhoneNumberArray[2] = phoneNumber.slice(3, 6);
    masPhoneNumberArray[3] = phoneNumber.slice(6);

    masPhoneNumber = masPhoneNumberArray.join('.');
  }

  return masPhoneNumber;
};

export const getNumber = (defaultNumber: string, ctmNumber?: string) => {
  let defaultFormattedNumber = defaultNumber;

  if (defaultNumber.startsWith('tel:')) {
    defaultFormattedNumber = defaultNumber.substring(4);
  }

  const masNumber = ctmNumber || cookie.get(MAS_PHONE_NUMBER);

  if (!masNumber) {
    return defaultFormattedNumber;
  }

  const formattedMasNumber = getMasPhoneNumber(masNumber);

  return formattedMasNumber;
};
