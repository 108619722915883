import { FC } from 'react';

import { AuthInteractions } from 'features/AuthInteractions';

import styles from './styles.module.scss';

import type { ITopBarInteraction } from '../config/declarations';

const TopBarInteraction: FC<ITopBarInteraction> = ({ auth }) => {
  if (!auth) {
    return null;
  }

  return (
    <div className={styles.interaction}>
      <AuthInteractions auth={auth} />
    </div>
  );
};

export default TopBarInteraction;
