import { FC } from 'react';

import type { IGreet } from '../../config/declarations';
import styles from '../styles.module.scss';
import { useGreetingText } from '../../lib/hooks';

const Greet: FC<IGreet> = ({ text }) => {
  const greetingText = useGreetingText(text);

  if (!greetingText) {
    return null;
  }

  return (
    <p className={styles.greet}>
      {greetingText}
    </p>
  );
};

export default Greet;
