import {
  ElementAlign,
  MobileItemsLayout,
  ViewerDevice,
} from 'constants/enums';
import { Block, ImageWrapper } from 'src/__generated__/graphqlTypes';
import { MediaWidthInterface, SectionComponentInterface } from 'types/componentTypes';
import { SectionExtraDataType } from 'types/objectTypes';

interface IsMobileMaxWidth {
  isMobileWidth: boolean;
}

export interface HeroBannerRichTextParam {
  titleAlign: ElementAlign;
  pAlign: ElementAlign;
}

interface RichTextParam {
  titleAlign: ElementAlign;
  pAlign: ElementAlign;
  h1Size: SectionExtraDataType['h1Size'] | '';
}

export interface GridHeroBannerInterface
  extends SectionComponentInterface,
  MediaWidthInterface {
  viewerDevice: ViewerDevice;
}

export interface GridHeroBannerListItemInterface
  extends MediaWidthInterface {
  mobileItemsLayout: MobileItemsLayout;
  link: Block['link'];
}

export interface GridHeroBannerBackgroundInterface extends MediaWidthInterface, IsMobileMaxWidth {
  viewerDevice: ViewerDevice;
  isMobileWidth: boolean;
  backgrounds?: Array<ImageWrapper>;
}

export enum BackgroundFieldsEnum {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export interface HeroBannerRichText {
  richText: Block['richTitle'] | Block['content'];
  config: RichTextParam;
}

export interface GridHeroBannerComponent
  extends Omit<SectionComponentInterface, 'blocks'>,
  IsMobileMaxWidth {
  block: Block;
  viewerDevice: ViewerDevice;
  mobileViewMaxWidth: string;
}

export interface Love1LogoInterface extends IsMobileMaxWidth {
  block: Block;
  viewerDevice: ViewerDevice;
}
