import { FC } from 'react';
import {
  BLOCKS,
  INLINES,
  MARKS,
} from '@contentful/rich-text-types';
import cn from 'classnames';

import { CommonRichText } from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';
import classes from 'styles/_commonClasses.module.scss';
import { mapBlocksDiv } from 'lib/richTextMappers';

import styles from './styles.module.scss';

import { HintInterface } from '../config/declarations';

const getParsersConfig = (className?: string): RichTextParsersConfig => ({
  [BLOCKS.PARAGRAPH]: {
    classNames: cn(className, styles.heartImgHint),
    mapper: mapBlocksDiv,
  },
  [INLINES.HYPERLINK]: {
    classNames: styles.heartImgHintLink,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
});

export const Hint: FC<HintInterface> = ({
  isAddToFavoriteHintActive,
  hint,
  className,
  arrowClassName,
}) => {
  if (!isAddToFavoriteHintActive || !hint || (!hint.text && !hint.richText)) {
    return null;
  }

  const hintComponent = hint.richText
    ? (
      <CommonRichText
        content={hint.richText!}
        parsersConfig={getParsersConfig(className)}
      />
    )
    : (
      <div className={cn(className, styles.heartImgHint)}>
        {hint.text}
      </div>
    );

  return (
    <>
      {hintComponent}
      <span className={cn(arrowClassName, styles.heartImgHintArrow)} />
    </>
  );
};
