import type { FC } from 'react';
import { useSelector } from 'react-redux';

import type { ImageWrapper } from 'src/__generated__/graphqlTypes';
import type { Store } from 'app-redux/types/storeTypes';
import { WebpImage } from 'components/Shared/SharedComponents';
import { ViewerDevice } from 'constants/enums';
import type { WebpWidthLimitType } from 'types/objectTypes';
import type { IFullBackground } from 'types/componentTypes';
import { setItemInObjectBySlug } from 'lib/sharedMethods.service';

const backgroundSlugs = {
  mobile: ViewerDevice.MOBILE,
  desktop: ViewerDevice.DESKTOP,
  tablet: ViewerDevice.TABLET,
};

const getIconWidthLimit = (
  width?: number,
  media?: string | number,
  rest?: Partial<WebpWidthLimitType>,
) => {
  if (!width) {
    return {} as WebpWidthLimitType;
  }

  return ({
    width,
    media: media ? `${media}px` : undefined,
    ...rest,
  });
};

const FullBackgroundImage: FC<IFullBackground> = ({
  backgrounds,
  widths,
  skipTablet,
  skipMobile,
  ...rest
}) => {
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const {
    mobileViewMaxWidth,
    commonTabletMaxWidth,
  } = useSelector((store: Store) => store.server.app.scssVariables);

  if (!backgrounds || !backgrounds.length) {
    return null;
  }

  const reducer = setItemInObjectBySlug(backgroundSlugs);
  const sortedBackgrounds = backgrounds
    .reduce(reducer, {} as Record<ViewerDevice, ImageWrapper>);
  const background = sortedBackgrounds[viewerDevice] || sortedBackgrounds.desktop;
  const { image } = background || {};

  if (!image) {
    return null;
  }

  const widthLimits: Array<WebpWidthLimitType> = [];
  const desktopImageWidth = widths?.desktop || image.width! || +commonTabletMaxWidth;
  const desktopMedia = getIconWidthLimit(
    desktopImageWidth,
    commonTabletMaxWidth,
    { isMinWidth: true },
  );
  widthLimits.push(desktopMedia);

  if (!skipTablet) {
    const tabletImageWidth = widths?.tablet || image.width! || +commonTabletMaxWidth;
    const tabletMedia = getIconWidthLimit(
      tabletImageWidth,
      commonTabletMaxWidth,
    );
    widthLimits.push(tabletMedia);
  }

  if (!skipMobile) {
    const mobileImageWidth = widths?.mobile || (image.width)
      ? Math.round(image.width! / 2)
      : +mobileViewMaxWidth!;
    const mobileMedia = getIconWidthLimit(
      mobileImageWidth,
      mobileViewMaxWidth,
    );
    widthLimits.push(mobileMedia);
  }

  return (
    <WebpImage
      image={image}
      widthLimit={widthLimits}
      {...rest}
    />
  );
};

export default FullBackgroundImage;
