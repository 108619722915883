import {
  RefObject,
  MouseEvent,
  DetailedHTMLProps,
  InputHTMLAttributes,
  SetStateAction,
  Dispatch,
  FormEvent,
} from 'react';

import { AlertTypes } from 'constants/enums';
import {
  Alert,
  ImageWrapper,
  Link,
  LoginForm,
} from 'src/__generated__/graphqlTypes';
import { CommonLoginFormInterface } from 'types/componentTypes';
import { checkSignInAttempt } from 'src/api/authApi';
import { Logger } from 'lib/logger';

export const SIGN_IN_ATTEMPTS_COOKIE_NAME = 'SignInAttempt';

export class SignInAttemptsChecker {
  private lockAttempt: number = 0;

  private signInAttempt: number = 0;

  async checkAttempts(email: string) {
    try {
      const attempts = (await checkSignInAttempt(email)).data || {};
      this.lockAttempt = attempts.lockAttempt || 0;
      this.signInAttempt = attempts.signInAttempt || 0;
    } catch (e: any) {
      Logger.error(e);
    }

    return {
      lockAttempt: this.lockAttempt,
      signInAttempt: this.signInAttempt,
    };
  }

  isCaptchaRequired(additionalCondition?: boolean) {
    const originalCondition = this.lockAttempt === 2 && this.signInAttempt === 2;

    return SignInAttemptsChecker.concatBoolean(originalCondition, additionalCondition);
  }

  isAccountLocked(additionalCondition?: boolean) {
    const originalCondition = this.lockAttempt >= 5 && this.signInAttempt >= 2;

    return SignInAttemptsChecker.concatBoolean(originalCondition, additionalCondition);
  }

  getLockAttempt() {
    return this.lockAttempt;
  }

  getSignInAttempt() {
    return this.signInAttempt;
  }

  private static concatBoolean(originalCondition: boolean, additionalCondition?: boolean) {
    if (additionalCondition === undefined) {
      return originalCondition;
    }

    return originalCondition && additionalCondition;
  }
}

export interface LoginFormContainerInterface extends CommonLoginFormInterface {
  isShown: boolean;
  setShownFormState: (isShown: boolean) => void;
}

export interface LoginFormInterface extends CommonLoginFormInterface {
  isShown: boolean;
  alert: Alert | null;
  isAlertShown: boolean;
  emailRef: RefObject<HTMLInputElement>;
  passwordRef: RefObject<HTMLInputElement>;
  formInputs: LoginFormInputs;
  isRecaptchaVisible: boolean;
  isRecaptchaAfterLoad: boolean;
  onRecaptchaChange: (key: string | null) => void;
  setExternalSignInAlert: (alertType: AlertTypes) => void;
  setFormInputs: Dispatch<SetStateAction<LoginFormInputs>>;
  handleSubmit: (event: LoginFormSubmit) => void;
}

export interface ButtonWithStylesInterface {
  button: Link;
  className: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}

export type AlertStyles = {
  title?: string;
  description?: string;
  background?: string;
};

export interface PasswordInputInterface
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  passwordRef: RefObject<HTMLInputElement>;
  password: LoginForm['password'];
  image: ImageWrapper['image'];
}

export type LoginFormInputs = {
  email: string;
  password: string;
}

export type LoginFormSubmit = MouseEvent<HTMLButtonElement | HTMLAnchorElement>
| FormEvent<HTMLFormElement>;
