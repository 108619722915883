import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import cn from 'classnames';

import styles from 'components/CookiePolicy/CookiePolicy.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { CommonRichText } from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';
import { CookiePolicyInterface } from 'components/CookiePolicy/declarations';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.cookiePolicyInfoText,
  },
  [INLINES.HYPERLINK]: {
    classNames: cn(styles.cookiePolicyInfoText, styles.cookiePolicyInfoTextLink),
  },
};

const CookiePolicy: React.FC<CookiePolicyInterface> = ({
  isStickyOfferExists,
  cookiePolicy,
  acceptCookies,
}) => (
  <section className={cn(styles.cookiePolicy, isStickyOfferExists
    ? styles.cookiePolicyWithSticky
    : styles.cookiePolicyNoSticky)}
  >
    <div className={styles.cookiePolicyInfo}>
      <strong className={styles.cookiePolicyInfoTitle}>
        {cookiePolicy?.title}
      </strong>
      <CommonRichText content={cookiePolicy.content!} parsersConfig={parsersConfig} />
    </div>

    <button
      type="button"
      className={cn(styles.cookiePolicyButton, classes.cursorPointer)}
      onClick={acceptCookies}
    >
      {cookiePolicy?.link?.title}
    </button>
  </section>
);

export default CookiePolicy;
