import { FC } from 'react';
import cn from 'classnames';
import {
  BLOCKS,
  MARKS,
  INLINES,
} from '@contentful/rich-text-types';

import styles from 'components/Sections/MysteryLinksSet/MysteryLinksSet.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import {
  CommonRichText,
  DataLink,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { GAReplacementValue } from 'constants/enums';
import { MysteryLinkInterface } from 'components/Sections/MysteryLinksSet/declarations';

const parsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.mysteryLinksSetGeneralInfoCardDescription,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
  [INLINES.HYPERLINK]: {
    classNames: styles.mysteryLinksSetGeneralInfoCardDescriptionLink,
  },
};

/* Main component */
const MysteryLink: FC<MysteryLinkInterface> = ({
  link,
  entryName,
  content,
  positionInArray,
}) => {
  const cardClasses = cn(
    styles.mysteryLinksSetGeneralInfoCard,
    styles.mysteryLinksSetGeneralInfoCardBorder,
  );

  if (!link?.image) {
    return null;
  }

  const { image, gaProperties } = link;
  const { _meta: meta = {} } = gaProperties || {};

  return (
    <div key={entryName} className={cardClasses}>
      <DataLink
        link={link}
        gaData={{ [GAReplacementValue.POSITION]: meta[positionInArray] }}
        className={styles.mysteryLinksSetGeneralInfoCardLink}
      >
        {image.url && (
          <WebpImage
            image="external"
            src={image.url!}
            className={styles.mysteryLinksSetGeneralInfoCardImage}
          />
        )}
        <span className={styles.mysteryLinksSetGeneralInfoCardLinkTitle}>
          {link.title}
        </span>
      </DataLink>
      {content && (
        <CommonRichText
          content={content}
          parsersConfig={parsersConfig}
        />
      )}
    </div>
  );
};

export default MysteryLink;
