import { FC } from 'react';

import { ITrustpilotWrapper } from '../config/declarations';

const TrustpilotWrapper: FC<ITrustpilotWrapper> = ({ sectionRef, className, style, children }) => (
  <section ref={sectionRef} className={className} style={style}>
    {children}
  </section>
);

export default TrustpilotWrapper;
