import { useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';

import { subscribeToHoroscopesProxy } from 'src/api/horoscopeApi';
import { setLoadingState } from 'app-redux/actions/appActions';
import { Store } from 'app-redux/types/storeTypes';
import {
  ErrorMessageNewsletter,
  FreeHoroscopeInputsReducerType,
  WebpWidthLimitType,
} from 'types/objectTypes';
import {
  ErrorCodeSignUpNewsletters,
  CommonSize,
  ImageFitMode,
  ModalWindowStepNumber,
} from 'constants/enums';
import { setItemInObjectBySlug } from 'lib/sharedMethods.service';
import { Asset, ImageWrapper } from 'src/__generated__/graphqlTypes';
import { IMAGE_SIZE_RATIO } from 'constants/constants';
import { HoroscopeNewslettersContainerInterface } from 'components/Sections/HoroscopeNewsletters/declarations';
import { Logger } from 'lib/logger';

const HoroscopeNewsletters = dynamic(() => import('components/Sections/HoroscopeNewsletters/HoroscopeNewsletters'), {
  ssr: false,
});

const helpCenterSlugs = {
  [CommonSize.SMALL]: CommonSize.SMALL,
  [CommonSize.MEDIUM]: CommonSize.MEDIUM,
  [CommonSize.LARGE]: CommonSize.LARGE,
};
const CODE_FIELD_NAME = 'messageCode';
const HoroscopeNewslettersContainer: FC<HoroscopeNewslettersContainerInterface> = ({
  block: freeHoroscope,
  mobileMediaMaxWidth,
  tabletMediaMaxWidth,
}) => {
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const [modalStep, setModalStep] = useState<ModalWindowStepNumber>(ModalWindowStepNumber.FIRST);
  const [
    modalErrors,
    setModalErrors,
  ] = useState<Array<ErrorMessageNewsletter> | undefined>(undefined);
  const [isModalVisible, setModalVisibilityState] = useState(false);
  const openModal = () => setModalVisibilityState(true);
  const closeModal = () => setModalVisibilityState(false);
  const dispatch = useDispatch();

  const {
    mobileViewMaxWidth,
    horoscopeDesktopLeftSection,
    horoscopeMobileImageBackgroundWidth,
  } = useSelector((store: Store) => store.server.app.scssVariables);

  const multiplyOnImageRatio = (size?: number) => (size ? Math.floor(size * IMAGE_SIZE_RATIO) : 0);
  const goNextStepOrShowErrors = (errors: Array<any>) => {
    if (errors?.length > 0) {
      const errorList = errors
        .filter((error: any) => Object.values(ErrorCodeSignUpNewsletters)
          .find((value) => error[CODE_FIELD_NAME] === value))
        .map((error) => ({ code: error[CODE_FIELD_NAME], message: error.messageText }));

      if (errorList.length) {
        setModalErrors(errorList);
      } else {
        setModalStep(ModalWindowStepNumber.SECOND);
      }
    } else {
      setModalStep(ModalWindowStepNumber.SECOND);
    }
  };

  const subscribeToNewsletters = async (userData: FreeHoroscopeInputsReducerType) => {
    try {
      dispatch(setLoadingState(true));
      const { errors } = await subscribeToHoroscopesProxy(userData);

      return errors;
    } catch (e) {
      Logger.error(e);

      return [];
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  const reducer = setItemInObjectBySlug(helpCenterSlugs);
  const imagesCollection = freeHoroscope.imagesCollection?.items || [];
  const {
    small: smallImage,
    medium: mediumImage,
    large: largeImage,
  } = imagesCollection.reduce(reducer, {} as Record<CommonSize, ImageWrapper>);

  const constructImageWidthLimit = (
    width: number,
    height: number,
    numberMedia: number = mobileViewMaxWidth,
    isMinWidth: boolean = false,
  ) => {
    const media = `${numberMedia}px`;

    return ({
      width,
      height,
      isMinWidth,
      media,
      fit: ImageFitMode.SCALE,
    });
  };

  const getImageWidthOrDefault = (
    defaultValue: number,
    imageWrapper?: ImageWrapper,
  ) => ((imageWrapper?.image?.width)
    ? multiplyOnImageRatio(imageWrapper.image.width)
    : defaultValue);

  const addToArrayImagesLimitations = (imageWidthLimit: Array<WebpWidthLimitType>) => {
    const mediumImageWidth = getImageWidthOrDefault(horoscopeDesktopLeftSection, mediumImage);

    if (largeImage?.image) {
      const largeImageWidth = getImageWidthOrDefault(horoscopeDesktopLeftSection * 2, largeImage);
      imageWidthLimit.push(constructImageWidthLimit(
        mediumImageWidth,
        multiplyOnImageRatio(mediumImage?.image?.height!),
        tabletMediaMaxWidth,
      ));
      imageWidthLimit.push(constructImageWidthLimit(
        largeImageWidth,
        multiplyOnImageRatio(largeImage?.image?.height!),
        tabletMediaMaxWidth,
        true,
      ));
    } else {
      imageWidthLimit.push(constructImageWidthLimit(
        mediumImageWidth,
        multiplyOnImageRatio(mediumImage?.image?.height!),
        mobileMediaMaxWidth,
        true,
      ));
    }
  };

  const getImageWidthLimit = () => {
    const smallImageWidth = getImageWidthOrDefault(horoscopeMobileImageBackgroundWidth, smallImage);
    const mobileWidthLimit: WebpWidthLimitType = constructImageWidthLimit(
      smallImageWidth,
      multiplyOnImageRatio(smallImage.image?.height!),
      mobileMediaMaxWidth,
    );
    const imageWidthLimit: Array<WebpWidthLimitType> = [mobileWidthLimit];
    addToArrayImagesLimitations(imageWidthLimit);

    return imageWidthLimit;
  };
  const getImageIfLargeImagePresented = (
    currentWidth: number,
    mobileWidth: number,
  ): Asset | null => {
    if (tabletMediaMaxWidth! <= currentWidth) {
      return largeImage.image!;
    }

    const ifTabletWidthAndMediumImage = mediumImage?.image
      && currentWidth >= mobileWidth
      && tabletMediaMaxWidth! > currentWidth;

    if (ifTabletWidthAndMediumImage) {
      return mediumImage.image!;
    }

    if (smallImage?.image && currentWidth < mobileWidth) {
      return smallImage.image;
    }

    return null;
  };

  const imageWidthLimit = getImageWidthLimit();
  const getCurrentImage = (): Asset | null => {
    if (!process.browser) {
      if (isMobileViewWidth) {
        return smallImage?.image || mediumImage?.image!;
      }

      return largeImage?.image || mediumImage?.image || smallImage?.image!;
    }

    const currentWidth = +window.innerWidth;
    const mobileWidth = mobileMediaMaxWidth || +mobileViewMaxWidth;

    if (largeImage?.image) {
      return getImageIfLargeImagePresented(currentWidth, mobileWidth);
    }

    if (mediumImage?.image && currentWidth >= mobileWidth) {
      return mediumImage.image!;
    }

    if (smallImage?.image && currentWidth < mobileWidth) {
      return smallImage.image!;
    }

    return null;
  };

  return (
    <HoroscopeNewsletters
      isModalVisible={isModalVisible}
      imageWidthLimit={imageWidthLimit}
      currentBackground={getCurrentImage()}
      freeHoroscope={freeHoroscope}
      horoscopeDesktopLeftSection={+horoscopeDesktopLeftSection}
      modalStep={modalStep}
      modalErrors={modalErrors}
      multiplyOnImageRatio={multiplyOnImageRatio}
      openModal={openModal}
      closeModal={closeModal}
      subscribeToNewsletters={subscribeToNewsletters}
      goNextStepOrShowErrors={goNextStepOrShowErrors}
      setModalErrors={setModalErrors}
    />
  );
};

export default HoroscopeNewslettersContainer;
