/* eslint-disable indent */
import { RefObject } from 'react';

import {
  PsychicCategoryType,
  PsychisReadingType,
  Status,
} from 'constants/enums';
import { Psychic } from 'src/__generated__/graphqlTypes';
import {
  CtaButtons,
  CtaButtonsExtraData,
  PsychicCategoryCriteriaObject,
  PsychicReadingButtons,
  PsychicStatusObject,
  RemoteSearchOptionsType,
  RightPsychic,
} from 'types/objectTypes';
import { IMG_NODE_NAME, SOURCE_NODE_NAME } from 'constants/constants';

import { capitalizeFirstLetter } from './text.service';

const sortingParameter = { SortBy: 'BestAvailable' };
const defaultSearchOptions: RemoteSearchOptionsType = {
  Subject: '',
  Ability: '',
  Tool: '',
  Style: '',
  Price: '',
};

const getWithSearchOpt = (option: RemoteSearchOptionsType) => ({
  ...sortingParameter,
  SearchOptions: { ...defaultSearchOptions, ...option },
});

export const updateWithDefaultSearchOptions = (apiData: any) => {
  if (!apiData.SearchOptions) {
    return apiData;
  }

  return {
    ...apiData,
    SearchOptions: {
      ...defaultSearchOptions,
      ...apiData.SearchOptions,
    },
  };
};

export const makePayloadToGetPsychic = (
  criteria: PsychicCategoryType | PsychicCategoryCriteriaObject = PsychicCategoryType.ASTROLOGY,
) => {
  if (typeof criteria !== 'string') {
    const { parameter, order, value } = criteria;

    if (order === 'high' && !parameter) {
      return { ...sortingParameter, CategoryFilter: value };
    }

    return { ...sortingParameter, ...getWithSearchOpt({ [parameter]: value }) };
  }

  switch (criteria) {
    case PsychicCategoryType.ALL: {
      return sortingParameter;
    }
    case PsychicCategoryType.RISING_STARS: {
      return { ...sortingParameter, CategoryFilter: 'RisingStars' };
    }
    case PsychicCategoryType.STAFF_PICKS: {
      return { ...sortingParameter, CategoryFilter: 'StaffPick' };
    }
    case PsychicCategoryType.PREMIER: {
      return { ...sortingParameter, CategoryFilter: 'ElitePsychics' };
    }
    case PsychicCategoryType.NEW: {
      return { ...sortingParameter, ...getWithSearchOpt({ IsNewPsychics: 'yes' }) };
    }
    case PsychicCategoryType.LOVE: {
      return { ...sortingParameter, ...getWithSearchOpt({ Subject: 'Love/relationships' }) };
    }
    case PsychicCategoryType.CAREER: {
      return { ...sortingParameter, ...getWithSearchOpt({ Subject: 'Career/work' }) };
    }
    case PsychicCategoryType.DESTINY_OR_LIFE_PATH: {
      return { ...sortingParameter, ...getWithSearchOpt({ Subject: 'Destiny/life path' }) };
    }
    case PsychicCategoryType.MONEY: {
      return { ...sortingParameter, ...getWithSearchOpt({ Subject: 'Money/finance' }) };
    }
    case PsychicCategoryType.PET: {
      return { ...sortingParameter, ...getWithSearchOpt({ Subject: 'Pets/animals' }) };
    }
    case PsychicCategoryType.PAST_LIVES: {
      return { ...sortingParameter, ...getWithSearchOpt({ Subject: 'Past lives' }) };
    }
    case PsychicCategoryType.DECEASED_LOVED: {
      return { ...sortingParameter, ...getWithSearchOpt({ Subject: 'Deceased loved ones' }) };
    }
    case PsychicCategoryType.LOST_OBJECTS: {
      return { ...sortingParameter, ...getWithSearchOpt({ Subject: 'Lost objects' }) };
    }
    case PsychicCategoryType.MISSING_PERSONS: {
      return { ...sortingParameter, ...getWithSearchOpt({ Subject: 'Lost people/pets' }) };
    }
    case PsychicCategoryType.EMPATH: {
      return { ...sortingParameter, ...getWithSearchOpt({ Ability: 'Empath' }) };
    }
    case PsychicCategoryType.MEDIUM: {
      return { ...sortingParameter, ...getWithSearchOpt({ Ability: 'Medium' }) };
    }
    case PsychicCategoryType.CLAIRVOYANT: {
      return { ...sortingParameter, ...getWithSearchOpt({ Ability: 'Clairvoyant' }) };
    }
    case PsychicCategoryType.CLAIRAUDIENT: {
      return { ...sortingParameter, ...getWithSearchOpt({ Ability: 'Clairaudient' }) };
    }
    case PsychicCategoryType.CLAIRSENTIENT: {
      return { ...sortingParameter, ...getWithSearchOpt({ Ability: 'Clairsentient' }) };
    }
    case PsychicCategoryType.DREAM_ANALYSIS: {
      return { ...sortingParameter, ...getWithSearchOpt({ Ability: 'Dream analyst' }) };
    }
    case PsychicCategoryType.REMOTE_VIEWING: {
      return { ...sortingParameter, ...getWithSearchOpt({ Ability: 'Remote viewing' }) };
    }
    case PsychicCategoryType.CHANNELING: {
      return { ...sortingParameter, ...getWithSearchOpt({ Ability: 'Channeling' }) };
    }
    case PsychicCategoryType.AUTOMATIC_WRITING: {
      return { ...sortingParameter, ...getWithSearchOpt({ Ability: 'Automatic writing' }) };
    }
    case PsychicCategoryType.NO_TOOLS: {
      return { ...sortingParameter, ...getWithSearchOpt({ Tool: 'No Tools' }) };
    }
    case PsychicCategoryType.TAROT: {
      return { ...sortingParameter, ...getWithSearchOpt({ Tool: 'Tarot' }) };
    }
    case PsychicCategoryType.ASTROLOGY: {
      return { ...sortingParameter, ...getWithSearchOpt({ Tool: 'Astrology' }) };
    }
    case PsychicCategoryType.NUMEROLOGY: {
      return { ...sortingParameter, ...getWithSearchOpt({ Tool: 'Numerology' }) };
    }
    case PsychicCategoryType.CRYSTALS: {
      return { ...sortingParameter, ...getWithSearchOpt({ Tool: 'Crystals' }) };
    }
    case PsychicCategoryType.ORACLE_CARDS: {
      return { ...sortingParameter, ...getWithSearchOpt({ Tool: 'Oracle cards' }) };
    }
    case PsychicCategoryType.RUNES: {
      return { ...sortingParameter, ...getWithSearchOpt({ Tool: 'Runes' }) };
    }
    case PsychicCategoryType.I_CHING: {
      return { ...sortingParameter, ...getWithSearchOpt({ Tool: 'I-Ching' }) };
    }
    case PsychicCategoryType.PENDULUM: {
      return { ...sortingParameter, ...getWithSearchOpt({ Tool: 'Pendulum' }) };
    }
    case PsychicCategoryType.INSPIRATIONAL: {
      return { ...sortingParameter, ...getWithSearchOpt({ Style: 'Inspirational' }) };
    }
    case PsychicCategoryType.COMPASSIONATE: {
      return { ...sortingParameter, ...getWithSearchOpt({ Style: 'Compassionate' }) };
    }
    case PsychicCategoryType.STRAIGHTFORWARD: {
      return { ...sortingParameter, ...getWithSearchOpt({ Style: 'Straightforward' }) };
    }
    default: {
      return { ...sortingParameter, ...getWithSearchOpt({ Tool: 'Astrology' }) };
    }
  }
};

export const setDefaultImgOnError = (
  contentfulData: Psychic,
  psychicImageRef: RefObject<HTMLImageElement>,
): void => {
  const { avatar } = contentfulData;
  const isNoImportantData = !psychicImageRef
      || !psychicImageRef.current
      || !avatar?.url;
  const isUrlsEqual = avatar?.url === psychicImageRef.current!.src;

  if (isNoImportantData || isUrlsEqual) {
    return;
  }

  const parent = psychicImageRef.current!.parentNode;

  if (!parent) {
    return;
  }

  parent.childNodes.forEach((node) => {
    const newNode = node;

    if (newNode.nodeName === SOURCE_NODE_NAME) {
      (newNode as HTMLSourceElement).srcset = avatar?.url || '';
    }

    if (node.nodeName === IMG_NODE_NAME) {
      (newNode as HTMLImageElement).src = avatar?.url || '';
    }
  });
};

export const getStatus = (statuses: PsychicStatusObject, lineStatus?: string) => {
  if (lineStatus) {
    return lineStatus as Status;
  }

  const chatStatus = statuses.chatStatus?.toLowerCase();
  const phoneStatus = statuses.phoneStatus?.toLowerCase();

  const isOnBreak = phoneStatus === Status.ON_BREAK
      || chatStatus === Status.ON_BREAK;

  if (isOnBreak) {
    return Status.ON_BREAK;
  }

  const isBusyOrPending = phoneStatus === Status.BUSY
    || chatStatus === Status.BUSY
    || phoneStatus === Status.PENDING
    || chatStatus === Status.PENDING
    || phoneStatus === Status.ON_CALL
    || chatStatus === Status.ON_CALL;

  if (isBusyOrPending) {
    return Status.BUSY;
  }

  const isAvailable = chatStatus === Status.AVAILABLE || phoneStatus === Status.AVAILABLE;

  if (isAvailable) {
    return Status.AVAILABLE;
  }

  return Status.OFFLINE;
};

export const getPsychicCtaButtons = (
  psychic: RightPsychic,
  psychicSkeleton: Psychic,
  data: CtaButtonsExtraData = {},
): CtaButtons => {
  const chatStatus = psychic.chatStatus?.toLowerCase() || Status.BUSY;
  const phoneStatus = psychic.phoneStatus?.toLowerCase() || Status.BUSY;
  const messageStatus = psychic.messageStatus?.toLowerCase() || Status.OFFLINE;
  const isPhoneAvailable = phoneStatus === Status.AVAILABLE;
  const isChatAvailable = chatStatus === Status.AVAILABLE;
  const isChatBusyOrPending = chatStatus === Status.BUSY || chatStatus === Status.PENDING;
  const isPhoneBusyOrPending = phoneStatus === Status.BUSY || phoneStatus === Status.PENDING;
  const { chatButton, talkButton, callbackButton, messageButton, inQueueButton } = psychicSkeleton;
  let buttons = {} as CtaButtons;
  const isChatButtonWillBeShown = !isPhoneBusyOrPending && isChatAvailable && psychic.isChatEnabled;
  const isOfflineMessageButtonWillBeShown = messageStatus !== Status.OFFLINE
    && psychic.isDirectMessageEnabled
    && messageButton?.link;

  if (psychic?.onHiatus) {
    return {} as CtaButtons;
  }

  if (isChatButtonWillBeShown) {
    buttons = { ...buttons, chatButton };
  } else if (isOfflineMessageButtonWillBeShown) {
    buttons = { ...buttons, messageButton };
  }

  if (psychic.isPhoneEnabled || !psychic.isChatOnly) {
    const isPhoneButtonWillBeShown = !isChatBusyOrPending && isPhoneAvailable;

    if (isPhoneButtonWillBeShown) {
      buttons = { ...buttons, talkButton };
    } else if ((psychic?.customerPlaceInQueue || 0) > 0
      || (Array.isArray(psychic.phoneQueue)
        && psychic.phoneQueue.find((id) => id === data.hashedId))) {
      buttons = { ...buttons, inQueueButton };
    } else {
      buttons = { ...buttons, callbackButton };
    }
  }

  return buttons;
};

export const getCtaButtonConsideration = (psychic) => psychic.considerCtaButtons;

export const getPsychicReadingButtons = (
  psychic,
  psychicSkeleton: Psychic,
): PsychicReadingButtons => {
  const {
    detailsButton,
  } = psychicSkeleton;

  let buttons = {} as PsychicReadingButtons;

  if (psychic.considerCtaButtons) {
    return buttons;
  }

  switch (psychic.type) {
    case PsychisReadingType.CALLBACK:
    case PsychisReadingType.APPOINTMENT:
    case PsychisReadingType.PENDING_REQUEST:
    case PsychisReadingType.CANCELLED_CHAT:
    case PsychisReadingType.CANCELLED_MESSAGE:
    case PsychisReadingType.MISSED_CHAT:
    case PsychisReadingType.UPCOMING:
    case PsychisReadingType.MISSED_CALLBACK:
    case PsychisReadingType.CANCELLED_APPOINTMENT:
    case PsychisReadingType.CANCELLED_CALLBACK:
    case PsychisReadingType.MISSED_APPOINTMENT:
    case PsychisReadingType.MISSED_MESSAGE: {
      buttons = { ...buttons, detailsButton };

      break;
    }

    default: {
      break;
    }
  }

  return buttons;
};

export const parsePsychicsUrl = (url: string) => {
  const pathFragments = url.split('/') || [];
  const realSlug = pathFragments[pathFragments.length - 1];
  const slugFragments = realSlug.split('-') || [];
  const extIdFragment = slugFragments[slugFragments.length - 1];
  const extId = (extIdFragment?.match(/^\d+$/g)) ? extIdFragment : undefined;

  if (!extId) {
    return {};
  }

  const psychicName = capitalizeFirstLetter(slugFragments
    .slice(0, slugFragments.length - 1)
    .join('-'));

  return {
    psychicName,
    extId,
  };
};
