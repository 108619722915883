import { Position, Status } from 'constants/enums';
import { Asset, Psychic } from 'src/__generated__/graphqlTypes';
import { RatePerMinuetType, RightPsychic } from 'types/objectTypes';

export interface BarInterface {
  psychic: RightPsychic;
  contentfulData: Psychic;
  status: Status;
  ratePerMinute: RatePerMinuetType;
  isCollapsedExtraInfo: boolean;
  onClickArrow: () => void;
}

export interface FloatInfoInterface {
  starImage: Asset;
  extension: string;
  tagLine: string;
  responses: number;
  overallScore: number;
  extId: number;
}

export interface ExtraInfoInterface {
  positionInArray: Position | '';
  psychicName?: string;
  contentfulData: Psychic;
  topics: Array<string>;
  totalReadings: number;
  startYear?: number;
  estimatedTime: number;
  fullProfileLink: string;
  isAnimationMounted: boolean;
  isCollapsed: boolean;
  collapse: () => void;
}

export enum ButtonState {
  OPEN = 'open',
  CLOSE = 'close',
}

export interface TopicsInterface {
  topics: Array<string>;
  contentfulData: Psychic;
}
