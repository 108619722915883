import { FC } from 'react';
import JsxParser from 'react-jsx-parser';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import { Loader } from 'components/Loader';
import { capitalizeFirstLetter } from 'lib/text.service';
import {
  CtaButtonsWrapper,
  DataLinkWrapper,
  PsychicCardImageWrapper,
  PsychicPaginationButton,
  PsychicRateWrapper,
  StatusIndicatorWrapper,
  StatusTextWrapper,
  StringWithComma,
} from 'entities/JsxParserComponents';
import Icon from 'src/shared/ui/Icon';
import { HeartImage } from 'entities/PsychicHeartImage';

import styles from './TemplateHtmlPsychics.module.scss';

import type { TemplateHtmlPsychicsContent, TemplateHtmlPsychicsInterface } from '../config/declarations';

/* Nested component */
const Content: FC<TemplateHtmlPsychicsContent> = ({
  loader,
  isLoading,
  texts,
  psychics,
  psychicFrame,
  dispatch,
}) => {
  const loaderComponent = (
    <div className={styles.loaderWrapper}>
      <div className={styles.loaderBackground} />
      <Loader
        image={loader}
        isVisible={isLoading}
        withBackground={false}
      />
    </div>
  );
  const isOnlyLoader = !psychics.length && loader && isLoading;

  if (isOnlyLoader) {
    return loaderComponent;
  }

  return (
    <>
      {texts.map(({ fullText, entryName }) => fullText && (
      // @ts-ignore
        <JsxParser
          key={entryName}
          blacklistedTags={[]}
          // @ts-ignore
          components={{
            PaginationButton: PsychicPaginationButton,
            ArrayToString: StringWithComma,
            CtaButtons: CtaButtonsWrapper,
            PsychicCardImage: PsychicCardImageWrapper,
            StatusIndicator: StatusIndicatorWrapper,
            StatusText: StatusTextWrapper,
            DataLink: DataLinkWrapper,
            HeartImage,
            Icon,
            PsychicRate: PsychicRateWrapper,
          }}
          renderInWrapper={false}
          bindings={{ psychics, dispatch, psychicFrame }}
          onError={(error) => console.log(error)}
          jsx={fullText}
        />
      ))}
      {loader && isLoading && loaderComponent}
    </>
  );
};

/* Main component */
const TemplateHtmlPsychics: FC<TemplateHtmlPsychicsInterface> = ({
  psychics,
  isLoading,
  loader,
  extraData,
  texts,
  bgColor,
  psychicFrame,
  dispatch,
}) => {
  const { horizontalPadding, verticalPadding } = extraData || {};
  const extraDataClasses = {
    [styles[`paddingHorizontal${capitalizeFirstLetter(horizontalPadding)}`]]: Boolean(horizontalPadding),
    [classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`]]: Boolean(verticalPadding),
  };
  const wrapperStyles = cn(styles.wrapper, extraDataClasses);

  return (
    <section className={wrapperStyles} style={{ background: bgColor }}>
      <Content
        loader={loader}
        isLoading={isLoading}
        psychics={psychics}
        texts={texts}
        psychicFrame={psychicFrame}
        dispatch={dispatch}
      />
    </section>
  );
};

export default TemplateHtmlPsychics;
