import { FC } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import classes from 'src/styles/_commonClasses.module.scss';
import type { Block } from 'src/__generated__/graphqlTypes';
import type { Store } from 'app-redux/types/storeTypes';
import { CommonSize } from 'constants/enums';
import type { SectionComponentGenericInterface } from 'types/componentTypes';
import { capitalizeFirstLetter } from 'lib/text.service';

import styles from './PriceCardsWithKarma.module.scss';
import RichText from './RichText';
import Cards from './Cards';
import StabRichText from './StubRichText';

import { useOffer } from '../lib/hooks';
import { parseContentfulBlocks } from '../lib';

const PriceCardsWithKarma: FC<SectionComponentGenericInterface<Block>> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const {
    verticalPadding = CommonSize.SMALL,
    isMobileVisible = true,
    forSidebar = false,
  } = extraData || {};
  const verticalPaddingClass = classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`];
  const { content, stub } = parseContentfulBlocks(blocks);
  const { packages, offerId, isReserveContent } = useOffer(user);
  const block = (isReserveContent && !forSidebar) ? stub : content;
  const [helperBlock] = (stub?.contentTypesCollection?.items || []) as Array<Block>;

  if (!user || !block || !packages) {
    return null;
  }

  return (
    <section
      className={cn(
        styles.wrapper,
        verticalPaddingClass,
        {
          [styles.sidebarWrapper]: forSidebar,
          [styles.hiddenMobileAndTablet]: !isMobileVisible,
        },
      )}
      style={{ background: bgColor }}
    >
      <div className={cn({
        [styles.block]: !isReserveContent,
        [styles.stub]: isReserveContent,
        [styles.sidebar]: forSidebar,
      })}
      >
        <RichText richText={block.richTitle} extraData={extraData} />
        {isReserveContent && <RichText richText={block.content} />}
        <Cards
          packages={packages}
          offerId={offerId}
          isReserveContent={isReserveContent}
          user={user}
          block={block}
        />
        {!isReserveContent && <RichText richText={block.content} />}
        {isReserveContent && <StabRichText richText={helperBlock?.content} />}
      </div>
    </section>
  );
};

export default PriceCardsWithKarma;
