import { FC } from 'react';
import { useSelector } from 'react-redux';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import { GET_NOTIFICATIONS_FROM_PSYCHICS } from 'src/shared/api/apiNames';
import type { Store } from 'app-redux/types/storeTypes';
import {
  CommonRichText,
  DataLink,
  WebpImage,
} from 'components/Shared/SharedComponents';
import type { SectionComponentInterface } from 'types/componentTypes';
import type { RichTextParsersConfig } from 'types/objectTypes';
import classes from 'styles/_commonClasses.module.scss';
import { mapBlocksParagraphWithReplacement, mapMarksBoldWithReplacement } from 'lib/richTextMappers';
import { ViewerDevice } from 'constants/enums';
import { InboxEnvelope as Envelope } from 'entities/MyInboxEnvelope';

import styles from './GreetAndPsychicNotification.module.scss';

const getParsersConfig = (
  replacementObject: Record<string, string | undefined>,
): RichTextParsersConfig => ({
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.text,
    mapper: mapBlocksParagraphWithReplacement(replacementObject),
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
    mapper: mapMarksBoldWithReplacement(replacementObject),
  },
});

const API_NAME = GET_NOTIFICATIONS_FROM_PSYCHICS;

const GreetAndPsychicNotification: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const apiData = useSelector((store: Store) => store.client.api[API_NAME]);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);

  const [block] = blocks;
  const shouldComponentBeSkipped = !block
    || !user
    || viewerDevice !== ViewerDevice.MOBILE
    || !apiData?.response;

  if (shouldComponentBeSkipped) {
    return null;
  }

  const background = block.imagesCollection?.items
    ?.find((image) => image?.slug === ViewerDevice.MOBILE);

  return (
    <section
      style={{ background: bgColor }}
      className={styles.wrapper}
    >
      <DataLink
        link={block.link!}
        className={styles.content}
      >
        <Envelope image={block.image} />
        <CommonRichText
          content={block.content}
          parsersConfig={getParsersConfig({ name: user?.firstName })}
        />
        {background?.image && (
          <WebpImage
            image={background.image}
            className={styles.background}
          />
        )}
      </DataLink>
    </section>
  );
};

export default GreetAndPsychicNotification;
