import { FC } from 'react';

import { PsychicStatusIndicator } from 'entities/PsychicStatus';
import { getStatus } from 'lib/psychic.service';

import { StatusIndicatorInterface } from '../config/declarations';

/* Nested component */
const StatusIndicator: FC<StatusIndicatorInterface> = ({
  chatStatus,
  phoneStatus,
  className,
}) => {
  const status = getStatus({ chatStatus, phoneStatus });

  return (
    <PsychicStatusIndicator
      status={status}
      className={className}
    />
  );
};

export default StatusIndicator;
