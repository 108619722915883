import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';

import styles from 'components/Sections/CircleImageDescriptionButton/CircleImageDescriptionButton.module.scss';
import { Block } from 'src/__generated__/graphqlTypes';
import { SectionComponentInterface } from 'types/componentTypes';
import { RichTextParsersConfig } from 'types/objectTypes';
import {
  CommonRichText,
  DataLink,
  WebpImage,
} from 'components/Shared/SharedComponents';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.HEADING_2]: {
    classNames: styles.cardsBlockTitle,
  },
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.cardsBlockParagraph,
  },
};

const CircleImageDescriptionButton: React.FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const buildComponents = () => blocks
    .map((block: Block) => {
      const {
        content,
        link,
        image,
        entryName,
      } = block;

      if (!content) return null;

      return (
        <div key={entryName} className={styles.cardsBlock}>
          {image && (
            <WebpImage
              image={image}
              className={styles.cardsBlockImage}
            />
          )}
          <CommonRichText content={content} parsersConfig={parsersConfig} />
          {link && (
            <DataLink
              link={link}
              href={link.src!}
              className={styles.cardsBlockButton}
            >
              {link.title}
            </DataLink>
          )}
        </div>
      );
    });

  return (
    <section className={styles.wrapper} style={{ background: bgColor }}>
      <div className={styles.cards}>
        {buildComponents()}
      </div>
    </section>
  );
};

export default CircleImageDescriptionButton;
