import { Block } from 'src/__generated__/graphqlTypes';
import { ViewsMaxWidthInterface } from 'types/componentTypes';

export interface AllAboutSignsGemstonesInterface
  extends ViewsMaxWidthInterface {
  gemstones: Block;
}

export enum GemstonesImageSizeRatioSlug {
  TABLET = 0.94,
  MOBILE = 0.72,
  ENTRY_IMAGE = 0.5,
}
