import Script from 'next/script';
import React, { useEffect } from 'react';

const TrustpilotCode = ({ element, sectionRef }) => {
  useEffect(() => {
    if (!window.Trustpilot || !sectionRef.current) {
      return;
    }

    const element = sectionRef.current.querySelector('div.trustpilot-widget[data-businessunit-id]');

    if (!element) {
      return;
    }

    window.Trustpilot.loadFromElement(element, true);
  });

  return (
    <>
      <Script
        type="text/javascript"
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      />
      {element}
    </>
  );
};

export default TrustpilotCode;
