import { FC } from 'react';

import { ImageWrapper } from 'src/__generated__/graphqlTypes';
import { WebpImage } from 'components/Shared/SharedComponents';
import { GridHeroBannerBackgroundInterface, BackgroundFieldsEnum as BackgroundFields } from 'components/Sections/GridHeroBanner/declarations';
import styles from 'components/Sections/GridHeroBanner/Love1/Love1.module.scss';
import { ViewerDevice } from 'constants/enums';
import { WebpWidthLimitType } from 'types/objectTypes';
import { setItemInObjectBySlug } from 'lib/sharedMethods.service';

const backgroundSlugs = {
  'mobile-bg': BackgroundFields.MOBILE,
  'desktop-bg': BackgroundFields.DESKTOP,
};

const getIconWidthLimit = (
  width?: number,
  media?: string | number,
  rest?: Partial<WebpWidthLimitType>,
) => {
  if (!media || !width) {
    return {} as WebpWidthLimitType;
  }

  return ({
    width,
    media: `${media}px`,
    ...rest,
  });
};

const Love1Background: FC<GridHeroBannerBackgroundInterface> = ({
  backgrounds,
  viewerDevice,
  isMobileWidth,
  mobileViewMaxWidth,
}) => {
  if (!backgrounds || backgrounds.length < 2) {
    return null;
  }

  const reducer = setItemInObjectBySlug(backgroundSlugs);
  const { desktop, mobile } = backgrounds
    .reduce(reducer, {} as Record<BackgroundFields, ImageWrapper>);
  const background = (viewerDevice !== ViewerDevice.MOBILE || isMobileWidth)
    ? desktop
    : mobile;
  const { image } = background || {};

  if (!image) {
    return null;
  }

  const desktopMedia = getIconWidthLimit(image.width!, mobileViewMaxWidth, { isMinWidth: true });
  const mobileMedia = getIconWidthLimit(image.width!, mobileViewMaxWidth);

  const widthLimits: Array<WebpWidthLimitType> = [mobileMedia, desktopMedia];

  return (
    <WebpImage
      image={image}
      widthLimit={widthLimits}
      className={styles.loveBackground}
    />
  );
};

export default Love1Background;
