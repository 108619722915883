import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import { Block } from 'src/__generated__/graphqlTypes';
import classes from 'src/styles/_commonClasses.module.scss';
import styles from 'components/Sections/PlainItemsWithDescription/PlainItemsWithDescription.module.scss';
import { PlainItem } from 'components/Sections/PlainItemsWithDescription';
import {
  PlainItemWithDescriptionButton as ButtonInterface,
  PlainItemWithDescriptionOptionalList as OptionalListInterface,
  PlainItemWithDescriptionInterface,
  PlainItemWithDescriptionRichTextParam,
} from 'components/Sections/PlainItemsWithDescription/declarations';
import { RichTextParsersConfig } from 'types/objectTypes';
import {
  Content,
  DataLink,
  Title,
} from 'components/Shared/SharedComponents';
import { CommonSize, ElementAlign } from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';

const getParsersConfig = ({
  titleAlign,
  pAlign,
}: PlainItemWithDescriptionRichTextParam): RichTextParsersConfig => {
  const getHClasses = (heading: 'H1' | 'H2' | 'H3', specificClass?: string) => cn(
    styles.title,
    classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`],
    {
      [specificClass || classes[`titleMain${heading}`]]: !styles[`plainItemsTitle${heading}`],
    },
  );
  const paragraphAlign = classes[`pAlign${capitalizeFirstLetter(pAlign)}`];

  return ({
    [BLOCKS.HEADING_1]: {
      classNames: getHClasses('H1'),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHClasses('H2'),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getHClasses('H3'),
    },
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(styles.paragraph, paragraphAlign),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};

/* Nested component */
const PlainItems: FC<OptionalListInterface> = ({
  blocks,
}) => {
  if (!blocks?.length) {
    return null;
  }

  const siblingsAmount = (blocks.length < 5) ? blocks.length : 4;

  return (
    <ul className={styles.content}>
      {blocks.map((link) => (
        <PlainItem
          key={link.entryName}
          block={link}
          siblingsAmount={siblingsAmount}
        />
      ))}
    </ul>
  );
};

/* Nested component */
const Button: FC<ButtonInterface> = ({ button }) => {
  if (!button) {
    return null;
  }

  return (
    <DataLink
      link={button}
      className={styles.button}
    >
      {button.title}
    </DataLink>
  );
};

/* Main Component */
const PlainItemsWithDescription: FC<PlainItemWithDescriptionInterface> = ({
  block,
  bgColor,
  extraData,
}) => {
  const {
    titleAlign = ElementAlign.CENTER,
    pAlign = ElementAlign.CENTER,
    verticalPadding = CommonSize.MEDIUM,
  } = extraData || {};
  const {
    content,
    richTitle,
    link,
    contentTypesCollection,
  } = block;
  const links = contentTypesCollection?.items as Array<Block>;
  const verticalPaddingClass = classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`];
  const richTextConfig = getParsersConfig({ titleAlign, pAlign });

  return (
    <section
      className={cn(styles.wrapper, verticalPaddingClass)}
      style={{ background: bgColor }}
    >
      <div className={cn(styles.plainItems)}>
        <Title richText={richTitle} config={richTextConfig} />
        <Content richText={content} config={richTextConfig} />
        <PlainItems blocks={links} />
        <Button button={link} />
      </div>
    </section>
  );
};

export default PlainItemsWithDescription;
