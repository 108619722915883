/* eslint-disable jsx-a11y/img-redundant-alt */
import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import { Link } from 'src/__generated__/graphqlTypes';
import {
  CommonRichText,
  DataLink,
  LazyWebpImage,
} from 'components/Shared/SharedComponents';
import classes from 'styles/_commonClasses.module.scss';
import styles from 'components/Footer/Links/Media/Media.module.scss';
import { RichTextParsersConfig } from 'types/objectTypes';
import {
  MobileAppsInterface,
  ChildrenInterface,
  FooterMediaIconInterface,
} from 'components/Footer/Links/Media/declarations';
import { FooterType } from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.mobileTitle,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
};

/* Nested component */
const Icon: FC<FooterMediaIconInterface> = ({ image, footerType }) => {
  if (!image?.url) {
    return null;
  }

  const width = (footerType === FooterType.LIGHT) ? 190 : 140;
  const height = (footerType === FooterType.LIGHT) ? 55 : 40;

  return (
    <LazyWebpImage
      image={image}
      additionalProps={{ w: width }}
      width="auto"
      height={`${height}px`}
      className={styles.mobileImagesButtonIcon}
    />
  );
};

/* Nested component */
const Children: FC<ChildrenInterface> = ({ links, footerType }) => {
  if (!links) {
    return null;
  }

  return (
    <div className={styles.mobileImages}>
      {links.map((link) => (
        <DataLink
          key={link.entryName}
          link={link}
          className={styles.mobileImagesButton}
          href={link.src as string}
        >
          <Icon
            image={link.image}
            footerType={footerType}
          />
        </DataLink>
      ))}
    </div>
  );
};

/* Main component */
const MobileApps: FC<MobileAppsInterface> = ({ mobileApps, footerType }) => {
  const { content, contentTypesCollection } = mobileApps;
  const links = contentTypesCollection?.items as Array<Link> | undefined;

  if (!content) {
    return null;
  }

  return (
    <div className={cn(styles.mobile, styles[`mobile${capitalizeFirstLetter(footerType)}`])}>
      <CommonRichText
        content={content}
        parsersConfig={parsersConfig}
      />
      <Children links={links} footerType={footerType} />
    </div>
  );
};

export default MobileApps;
