import { CommonContentfulSlug } from 'constants/enums';

export const contentfulSlugs = {
  content: CommonContentfulSlug.CONTENT,
  'top-divider': CommonContentfulSlug.TOP_DIVIDER,
  'bottom-divider': CommonContentfulSlug.BOTTOM_DIVIDER,
  next: CommonContentfulSlug.NEXT_ARROW,
  prev: CommonContentfulSlug.PREV_ARROW,
  banner: 'banner',
};
