import React from 'react';

import styles from 'components/Sections/HoroscopesSignsAllAboutSigns/SelfCareArticles/SelfCareArticles.module.scss';
import { WebpWidthLimitType } from 'types/objectTypes';
import { getImagesWithLimitedSize } from 'lib/image.service';
import { ImageFitMode, RelationalOperator } from 'constants/enums';
import { calculateSizeWithRatio, isAppropriatViewportWidth } from 'lib/sharedMethods.service';
import { getImageBasedDevice } from 'lib/horoscope/horoscope.service';
import { AllAboutSignsSelfCareArticlePictureInterface, SelfCareArticlePictureImageSizeRatioSlug as Slug } from 'components/Sections/HoroscopesSignsAllAboutSigns/SelfCareArticles/declarations';

const SelfCareArticlePicture: React.FC<AllAboutSignsSelfCareArticlePictureInterface> = ({
  maxWidth,
  maxHeight,
  entryName,
  title,
  image,
  viewerDevice,
  mobileViewMaxWidth,
  horoscopeTabletMaxWidth,
  setMaxWidth,
  setMaxHeight,
}) => {
  if (!image || !title) {
    return null;
  }

  const setMaxWidthIfNewOneGraterThanCurrent = (newWidth?: number) => {
    if (newWidth && (newWidth > maxWidth)) {
      setMaxWidth(newWidth);
    }
  };

  const setMaxHeightIfNewOneGraterThanCurrent = (newHeight?: number) => {
    if (newHeight && (newHeight > maxHeight)) {
      setMaxHeight(newHeight);
    }
  };

  const Image = getImageBasedDevice(viewerDevice);
  const { height, width } = image;
  let imagesWithLimitedSize: Array<WebpWidthLimitType> | undefined;
  let style: React.CSSProperties | undefined;

  if (height && width) {
    const entryHeight = calculateSizeWithRatio(Slug.ENTRY_IMAGE, height);
    const entryWidth = calculateSizeWithRatio(Slug.ENTRY_IMAGE, width);
    const desktopHeight = calculateSizeWithRatio(Slug.DESKTOP, entryHeight);
    const desktopWidth = calculateSizeWithRatio(Slug.DESKTOP, entryWidth);
    const tabletHeight = calculateSizeWithRatio(Slug.TABLET, entryHeight);
    const tabletWidth = calculateSizeWithRatio(Slug.TABLET, entryWidth);
    imagesWithLimitedSize = getImagesWithLimitedSize([
      {
        height: entryHeight,
        width: entryWidth,
        media: `${mobileViewMaxWidth}px`,
      },
      {
        height: tabletHeight,
        width: tabletWidth,
        media: `${horoscopeTabletMaxWidth}px`,
      },
      {
        height: desktopHeight,
        width: desktopWidth,
        isMinWidth: true,
        media: `${horoscopeTabletMaxWidth}px`,
      },
    ],
    { fit: ImageFitMode.SCALE });
    let newHeight: number | undefined;
    let newWidth: number | undefined;

    if (isAppropriatViewportWidth(horoscopeTabletMaxWidth, RelationalOperator.GREATER_THEN)) {
      newHeight = desktopHeight;
      newWidth = desktopWidth;
    } else if (isAppropriatViewportWidth(mobileViewMaxWidth, RelationalOperator.LESS_OR_EQUAL)) {
      newHeight = entryHeight;
      newWidth = entryWidth;
    } else if (process.browser) {
      const expandedTabletWidth = tabletWidth + 10;
      newHeight = tabletHeight;
      newWidth = expandedTabletWidth;
    }

    setMaxHeightIfNewOneGraterThanCurrent(newHeight);
    setMaxWidthIfNewOneGraterThanCurrent(newWidth);
    style = { ...style, maxWidth, gridTemplateRows: `${maxHeight}px auto` };
  }

  return (
    <div
      key={entryName || image.url}
      className={styles.selfCareContentArticle}
      style={style}
    >
      <Image
        image={image}
        height={style?.height}
        className={styles.selfCareContentArticleImage}
        pictureClassName={styles.selfCareContentArticlePicture}
        widthLimit={imagesWithLimitedSize}
      />
      <span
        title={title}
        className={styles.selfCareContentArticleDescription}
      >
        {title}
      </span>
    </div>
  );
};

export default SelfCareArticlePicture;
