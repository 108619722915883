import React, {
  CSSProperties,
  FC,
  MouseEvent,
  useCallback,
  useState,
} from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';
import qs from 'query-string';

import styles from 'components/Sections/FixedBanner/FixedBanner.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { FixedBannerBackground } from 'components/Sections/FixedBanner';
import { RichTextParsersConfig } from 'types/objectTypes';
import {
  CommonRichText,
  DataButton,
  DataLink,
  PsychicRate,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { ImageWrapper, Psychic } from 'src/__generated__/graphqlTypes';
import {
  ElementAlign,
  GAReplacementValue,
  ViewerDevice,
} from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';
import {
  FixedBannerContentDescriptionInterface,
  FixedBannerContentInterface,
  FixedBannerInterface,
  FixedBannerButtonInterface,
  FixedBannerRichText as RichTextInterface,
  FixedBannerCtaLinkInterface,
} from 'components/Sections/FixedBanner/declarations';

const getParsersConfig = (titleAlign: ElementAlign): RichTextParsersConfig => {
  const getHClasses = (specificClass: string) => cn(
    styles.bannerTitle,
    specificClass,
    classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`],
  );

  return ({
    [BLOCKS.HEADING_1]: {
      classNames: getHClasses(classes.titleMainH1),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHClasses(classes.titleMainH2),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getHClasses(classes.titleMainH3),
    },
    [BLOCKS.PARAGRAPH]: {
      classNames: styles.bannerParagraph,
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};

/* Nested Component */
const CloseOrToggleButton: FC<FixedBannerButtonInterface> = ({
  isExpanded,
  viewerDevice,
  toggleExpanded,
  closeBanner,
}) => {
  const isDesktop = viewerDevice !== ViewerDevice.MOBILE;
  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (isDesktop || isExpanded) {
      closeBanner();
    } else {
      toggleExpanded();
    }
  };

  const getDesktopIcon = () => (
    <span className={styles.bannerButtonClose} />
  );

  const getMobileIcon = () => {
    const rootClassName = 'bannerButtonIcon';

    return (
      <i className={cn({
        [styles[`${rootClassName}`]]: !isExpanded,
        [styles[`${rootClassName}Up`]]: !isExpanded,
        [styles[`${rootClassName}Close`]]: isExpanded,
      })}
      />
    );
  };

  return (
    <DataButton
      onClick={onClick}
      className={styles.bannerButton}
    >
      {isDesktop ? getDesktopIcon() : getMobileIcon()}
    </DataButton>
  );
};

/* Nested Component */
const Description: FC<FixedBannerContentDescriptionInterface> = ({ psychic, min }) => (
  <div className={styles.bannerContentDescription}>
    <span className={styles.bannerContentDescriptionStatus}>
      {`${psychic.chatStatus}: `}
      <span className={cn(styles.bannerContentDescriptionStatus, classes.textBoldImp)}>
        {psychic.psychicName}
      </span>
    </span>
    <div className={styles.bannerContentDescriptionPriceWrapper}>
      <PsychicRate
        pricePerMinute={psychic.basePrice}
        priceWithDiscountPerMinute={psychic.customerPrice}
        classNames={{
          wrapper: styles.bannerContentDescriptionPrice,
          common: cn(styles.bannerContentDescriptionPriceText, classes.textBoldImp),
          commonWithDiscount: styles.bannerContentDescriptionPriceText,
          discount: cn(
            styles.bannerContentDescriptionPriceText,
            styles.bannerContentDescriptionPriceDiscount,
            classes.textBoldImp,
          ),
        }}
      />
      <span className={cn(
        styles.bannerContentDescriptionPriceText,
        styles.bannerContentDescriptionPriceMin,
      )}
      >
        /
        {' '}
        {min}
      </span>
    </div>
  </div>
);

/* Nested component */
const CtaLink: FC<FixedBannerCtaLinkInterface> = ({ link, psychicName, psychicId }) => {
  if (!link) {
    return null;
  }

  const { title, slug, image, entryName } = link;
  const url = qs.stringifyUrl({ url: link.src || '', query: { extid: psychicId } });

  return (
    <DataLink
      key={entryName}
      href={url}
      gaData={{ [GAReplacementValue.PSYCHIC_NAME]: psychicName }}
      link={link}
      className={styles.bannerContentLinksButton}
    >
      {image && (
        <div
          title={image.title!}
          style={{ backgroundImage: `url(${image.url})` }}
          className={cn(
            styles.bannerContentLinksButtonImage,
            styles[`bannerContentLinksButtonImage${capitalizeFirstLetter(slug || '')}`],
          )}
        />
      )}
      <span title={title!} className={styles.bannerContentLinksButtonText}>
        {title}
      </span>
    </DataLink>
  );
};

/* Nested Component */
const Content: FC<FixedBannerContentInterface> = ({ src, psychicFrame, psychic }) => {
  const { chatButton, talkButton } = psychicFrame;

  return (
    <div className={styles.bannerContent}>
      <WebpImage
        src={src}
        image="external"
        className={styles.bannerContentImage}
      />
      <Description psychic={psychic} min={psychicFrame.ratePerMinute} />
      <div className={styles.bannerContentLinks}>
        <CtaLink
          link={talkButton}
          psychicName={psychic.psychicName}
          psychicId={psychic.extId}
        />
        <CtaLink
          link={chatButton}
          psychicName={psychic.psychicName}
          psychicId={psychic.extId}
        />
      </div>
    </div>
  );
};

/* Nested component */
const Title: FC<RichTextInterface> = ({ richText, config }) => {
  if (!richText) {
    return null;
  }

  return (
    <CommonRichText
      content={richText}
      parsersConfig={config}
    />
  );
};

/* Main Component */
const FixedBanner: FC<FixedBannerInterface> = ({
  psychic,
  blocks,
  extraData,
  bgColor,
  viewerDevice,
  mobileViewMaxWidth,
  commonTabletMaxWidth,
  isMobileHeaderNavActive,
  closeBanner,
}) => {
  const {
    titleAlign = ElementAlign.CENTER,
  } = extraData || {};

  const [isExpanded, setIsExpanded] = useState<boolean>(viewerDevice !== ViewerDevice.MOBILE);
  const toggleExpanded = useCallback(() => setIsExpanded((prev) => !prev), []);
  const { images, psychicImageUrl, psychicBioURL } = psychic;
  const psychicImage = images[5] || psychicImageUrl;
  const [{
    richTitle,
    contentTypesCollection,
    imagesCollection,
  }] = blocks;

  const [psychicFrame] = contentTypesCollection?.items as Array<Psychic> || [];
  const { profileButton } = psychicFrame;
  const bioUrl = psychicBioURL.startsWith('/') ? psychicBioURL : `/${psychicBioURL}`;
  const profileUrl = `${profileButton?.src || 'psychics'}${bioUrl}`;
  const display: CSSProperties['display'] = isMobileHeaderNavActive ? 'none' : 'block';

  return (
    <DataLink
      href={profileUrl}
      gaData={{ [GAReplacementValue.PSYCHIC_NAME]: psychic.psychicName }}
      link={psychicFrame.profileButton!}
      className={cn(styles.wrapper)}
      role="dialog"
      aria-modal
      style={{ background: bgColor, display }}
    >
      <div className={styles.banner}>
        <CloseOrToggleButton
          toggleExpanded={toggleExpanded}
          closeBanner={closeBanner}
          isExpanded={isExpanded}
          viewerDevice={viewerDevice}
        />
        <Title
          richText={richTitle}
          config={getParsersConfig(titleAlign)}
        />
        {isExpanded && (
          <Content
            src={psychicImage}
            psychic={psychic}
            psychicFrame={psychicFrame}
          />
        )}
      </div>
      <FixedBannerBackground
        backgrounds={imagesCollection?.items as Array<ImageWrapper>}
        viewerDevice={viewerDevice}
        mobileViewMaxWidth={mobileViewMaxWidth}
        commonTabletMaxWidth={commonTabletMaxWidth}
      />
    </DataLink>
  );
};

export default FixedBanner;
