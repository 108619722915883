import { Link } from 'src/__generated__/graphqlTypes';
import { FooterTypeInterface } from 'components/Footer/declarations';

export interface FooterCopyrightContainerInterface extends FooterTypeInterface {
  copyrightDescription: string;
  copyrightLinks: Array<Link>;
  desktopSiteRequest: string;
  mobileSiteRequest: string;
}

export interface FooterCopyrightInterface
  extends FooterCopyrightContainerInterface {
  isMobileView: boolean;
  isDesktopRequested: boolean;
  requestAnotherSiteView: () => void;
}

export enum CopyrightSlug {
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_OF_USE = 'terms-of-use',
  SITE_MAP = 'site-map',
  PERSONAL_INFO = 'personal-info',
}

export interface CopyrightLinksInterface {
  copyrightLinks: Array<Link>;
}

export interface CopyrightLinkInterface {
  link: Link;
}
