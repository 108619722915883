import type { ReducerAction } from 'app-redux/types/storeTypes';

import { messageButtonContext } from './messageContext';

import type { IMessageContext } from '../config/declarations';

export const messageButtonModalReducer = (
  store: IMessageContext = messageButtonContext,
  action: ReducerAction<keyof IMessageContext>,
) => {
  switch (action.type) {
    case 'isButtonDisabled':
    case 'customerDetails':
    case 'countryList':
    case 'telephones': {
      return { ...store, [action.type]: action.payload };
    }
    default: return store;
  }
};
