import { FC, PropsWithoutRef } from 'react';

import { WebpImage } from 'components/Shared/SharedComponents';
import { WebpImageInterface } from 'components/Shared/declarations';

import styles from './styles.module.scss';

import { ITestimonialStars } from '../config/declarations';

const MAX_STARS = 5;
const mockArray = new Array(MAX_STARS).fill(1);

/* Nested Component */
const Stars: FC<ITestimonialStars> = ({
  score,
  fallback,
  filledStar,
  halfStar,
  outlinedStar,
}) => {
  if (score <= 0) {
    return null;
  }

  if (!filledStar.image || !outlinedStar.image) {
    return (
      <span className={styles.stars}>
        {fallback}
        {' '}
        {score}
      </span>
    );
  }

  const sharedProps: PropsWithoutRef<WebpImageInterface> = {
    className: styles.star,
    image: 'external',
    loading: 'lazy',
  };

  return (
    <span className={styles.stars}>
      {mockArray.map((_, i) => {
        const currentIndex = i + 1;

        if (currentIndex - 0.5 <= score) {
          return (
            <WebpImage
              src={filledStar.image?.url!}
              {...sharedProps}
            />
          );
        }

        if (currentIndex - 0.5 > score && currentIndex - 1 < score) {
          if (!halfStar.image?.url) {
            return (
              <WebpImage
                src={outlinedStar.image?.url!}
                {...sharedProps}
              />
            );
          }

          return (
            <WebpImage
              src={halfStar.image.url}
              {...sharedProps}
            />
          );
        }

        if (currentIndex >= score) {
          return (
            <WebpImage
              src={outlinedStar.image?.url!}
              {...sharedProps}
            />
          );
        }
      })}
    </span>
  );
};

export default Stars;
