import { FC } from 'react';
import cn from 'classnames';

import styles from './AppointmentCalendar.module.scss';

import type { IColorInfo } from '../config/declarations';

const ColorInfo: FC<IColorInfo> = ({ list }) => {
  if (!list?.length || list.length < 2) {
    return null;
  }

  return (
    <ul className={styles.info}>
      <li className={styles.li}>
        <i className={cn(styles.icon, styles.cellBgAv)} />
        {list[0]}
      </li>
      <li className={styles.li}>
        <i className={cn(styles.icon, styles.cellBgAvCallbacks)} />
        {list[1]}
      </li>
      {list[2] && (
        <li className={styles.li}>
          <i className={cn(styles.icon, styles.cellBgNotAv)} />
          {list[2]}
        </li>
      )}
    </ul>
  );
};

export default ColorInfo;
