import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import styles from 'components/Sections/OrangeAlert/OrangeAlert.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { OrangeAlertBackground } from 'components/Sections/OrangeAlert';
import { RichTextParsersConfig } from 'types/objectTypes';
import { CommonRichText, DataLink } from 'components/Shared/SharedComponents';
import { ImageWrapper, Link } from 'src/__generated__/graphqlTypes';
import {
  ColorTheme,
  CommonSize,
  ElementAlign,
  MobileItemsLayout,
} from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';
import {
  OrangeAlertButton as ButtonInterface,
  OrangeAlertInterface,
  OrangeAlertOptionalList as OptionalListInterface,
  OrangeAlertRichText as RichTextInterface,
  OrangeAlertRichTextParam,
} from 'components/Sections/OrangeAlert/declarations';
import OrangeAlertListItem from 'components/Sections/OrangeAlert/OrangeAlertListItem';
import { useFetchSegmentData } from 'lib/external/segment';

const getParsersConfig = ({
  titleAlign,
  h1Size = '',
  pAlign,
}: OrangeAlertRichTextParam): RichTextParsersConfig => {
  const getHClasses = (specificClass: string) => cn(
    styles.alertTitle,
    specificClass,
    classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`],
  );
  const paragraphAlign = classes[`pAlign${capitalizeFirstLetter(pAlign)}`];

  return ({
    [BLOCKS.HEADING_1]: {
      classNames: getHClasses(classes[`titleMainH1${capitalizeFirstLetter(h1Size)}`]),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHClasses(classes.titleMainH2),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getHClasses(classes.titleMainH3),
    },
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(styles.alertParagraph, paragraphAlign),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};

/* Nested component */
const OptionalList: FC<OptionalListInterface> = ({
  links,
  mobileItemsLayout,
  mobileViewMaxWidth,
  commonTabletMaxWidth,
}) => {
  if (!links?.length) {
    return null;
  }

  return (
    <ul className={cn(styles.alertContent, styles[`${mobileItemsLayout}`])}>
      {links.map((link) => (
        <OrangeAlertListItem
          key={link.sys.id}
          link={link}
          mobileItemsLayout={mobileItemsLayout}
          mobileViewMaxWidth={mobileViewMaxWidth}
          commonTabletMaxWidth={commonTabletMaxWidth}
        />
      ))}
    </ul>
  );
};

/* Nested component */
const Button: FC<ButtonInterface> = ({ button }) => {
  const segmentObj = useFetchSegmentData(button);

  if (!button) {
    return null;
  }

  return (
    <DataLink
      link={button}
      className={styles.alertButton}
      segmentData={segmentObj}
    >
      {button.title}
    </DataLink>
  );
};

/* Nested component */
const Content: FC<RichTextInterface> = ({ richText, config }) => {
  if (!richText) {
    return null;
  }

  return (
    <CommonRichText
      content={richText}
      parsersConfig={getParsersConfig(config)}
    />
  );
};

/* Nested component */
const Title: FC<RichTextInterface> = Content;

/* Main Component */
const OrangeAlert: FC<OrangeAlertInterface> = ({
  blocks,
  bgColor,
  extraData,
  viewerDevice,
  mobileViewMaxWidth,
  commonTabletMaxWidth,
}) => {
  const {
    titleAlign = ElementAlign.CENTER,
    pAlign = ElementAlign.CENTER,
    verticalPadding = CommonSize.MEDIUM,
    h1Size,
    colorTheme = ColorTheme.WHITE,
    mobileItemsLayout = MobileItemsLayout.FLEX_COLUMN,
  } = extraData || {};
  const richTextConfig = { titleAlign, h1Size, pAlign };
  const [{
    content,
    richTitle,
    link,
    contentTypesCollection,
    imagesCollection,
  }] = blocks;
  const links = contentTypesCollection?.items as Array<Link>;
  const verticalPaddingClass = classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`];

  return (
    <section
      className={cn(styles.wrapper, verticalPaddingClass)}
      style={{ background: bgColor }}
    >
      <div className={cn(styles.alert, classes[`themeColor${capitalizeFirstLetter(colorTheme)}`])}>
        <Title richText={richTitle} config={richTextConfig} />
        <Content richText={content} config={richTextConfig} />
        <OptionalList
          links={links}
          mobileItemsLayout={mobileItemsLayout}
          mobileViewMaxWidth={mobileViewMaxWidth}
          commonTabletMaxWidth={commonTabletMaxWidth}
        />
        <Button button={link} />
      </div>
      <OrangeAlertBackground
        backgrounds={imagesCollection?.items as Array<ImageWrapper>}
        viewerDevice={viewerDevice}
        mobileViewMaxWidth={mobileViewMaxWidth}
        commonTabletMaxWidth={commonTabletMaxWidth}
      />
    </section>
  );
};

export default OrangeAlert;
