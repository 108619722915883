import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SectionComponentInterface } from 'types/componentTypes';
import PsychicsSetMediumSizeImages from 'components/Sections/PsychicsSetMediumSizeImages/PsychicsSetMediumSizeImages';
import { getRightPsychicsProxy } from 'src/api/psychicApi';
import { RightPsychic } from 'types/objectTypes';
import { IntersectionObserverInit, IntersectionObserverWrapper } from 'lib/intersectionObserver';
import { setFirebaseRequiredState } from 'app-redux/actions/appActions';
import { usePsychicStatuses } from 'src/firebase/firebase.hook';
import { Store } from 'app-redux/types/storeTypes';
import { Logger } from 'lib/logger';
import { useBootChatSolution } from 'entities/PsychicCtaButton';

const PsychicsSetMediumSizeImagesContainer: React.FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const [psychics, setPsychics] = useState<Array<RightPsychic>>([]);
  const dispatch = useDispatch();
  const sectionRef = useRef<HTMLElement>(null);
  const idList: Array<number> = psychics?.flatMap((psychic) => psychic.extId);
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);
  const prevIsAuthenticated = useRef<boolean | null>(null);

  usePsychicStatuses(idList, setPsychics);
  const bootStatus = useBootChatSolution();

  useEffect(() => {
    if (psychics?.length > 0 && isAuthenticated !== prevIsAuthenticated.current) {
      const requestBody = {
        AppId: 1002,
        CustFavorite: false,
        ResultType: 1,
        SearchOptions: null,
      };
      getRightPsychicsProxy(requestBody).then((resp) => setPsychics(resp.psychics));
    }

    prevIsAuthenticated.current = isAuthenticated;
  }, [isAuthenticated, psychics]);

  useEffect(() => {
    if (!sectionRef.current) {
      (async () => {
        try {
          const resp = await getRightPsychicsProxy();
          setPsychics(resp.psychics);
          dispatch(setFirebaseRequiredState(true));
        } catch (e) {
          Logger.error(e);
        }
      })();

      return;
    }

    const intersectionHandler = (
      entries: Array<IntersectionObserverEntry>,
      observer: IntersectionObserver,
    ) => entries
      .forEach(async (entry) => {
        if (entry.isIntersecting) {
          try {
            const resp = await getRightPsychicsProxy();
            setPsychics(resp.psychics);
            dispatch(setFirebaseRequiredState(true));
            observer.unobserve(sectionRef.current!);
          } catch (e) {
            Logger.error(e);
          }
        }
      });

    const options: IntersectionObserverInit = {
      rootMargin: '300px',
    };

    const observer = IntersectionObserverWrapper.getInstance(intersectionHandler, options);

    observer?.observe(sectionRef.current);
  }, []);

  const toggleFavoritePsychicState = (extId: number, isFavorite: boolean) => {
    const newPsychics = psychics.map((psychic) => {
      const newPsychic = psychic;

      if (newPsychic.extId === extId) {
        newPsychic.isFavorite = isFavorite;
      }

      return psychic;
    });

    setPsychics(newPsychics);
  };

  return (
    <PsychicsSetMediumSizeImages
      bootStatus={bootStatus}
      bgColor={bgColor}
      blocks={blocks}
      psychics={psychics}
      sectionRef={sectionRef}
      toggleFavoritePsychicState={toggleFavoritePsychicState}
    />
  );
};

export default PsychicsSetMediumSizeImagesContainer;
