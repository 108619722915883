import { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';

import type { Store } from 'src/redux/types/storeTypes';
import { useFirebaseLazyLoading } from 'src/firebase/firebase.hook';
import { MainLayout } from 'components/MainLayout';
import { MainLayoutContainerInterface } from 'components/declarations';
import { GAReplacementValue, MetaReplacementValue } from 'constants/enums';
import {
  CLOSING_SCRIPTS,
  HEAD_SCRIPTS,
  HOROSCOPES_TYPES,
  ZODIAC_SIGNS,
} from 'constants/constants';
import { dynamicValue } from 'contentfulScripts/migration/regex.service';
import { useSegmentAnalytics, useWatchMobileViewWidth } from 'lib/shared.hook';
import { capitalizeFirstLetter, replaceContentfulVariables } from 'lib/text.service';
import { getDateWithNewTimezone, getOracleFormattedDate } from 'lib/date.service';
import type { MetaData } from 'types/objectTypes';
import { mapNoScriptTag } from 'lib/tag.service';
import { useCustomRouter, useRoutingConsistencyCheck } from 'src/shared/lib/history/hooks';
import { parsePsychicsUrl } from 'lib/psychic.service';

const MainLayoutContainer: FC<MainLayoutContainerInterface> = ({
  children,
  header,
  footer,
  seo,
  externalNoScripts,
  externalScripts,
  externalStyles,
  externalTags,
  ...rest
}) => {
  if (!typeof window) {
    smoothscroll.polyfill();
  }

  const { mobileViewMaxWidth } = useSelector((store: Store) => store.server.app.scssVariables);
  const isMobileHeaderNavActive = useSelector((store: Store) => store.server.app
    .isMobileHeaderNavActive);
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const isDesktopRequested = useSelector((store: Store) => store.client.app.isDesktopRequested);
  const slug = useSelector((store: Store) => store.server.page.slug);
  const router = useCustomRouter();

  const desktopViewportWidth = +mobileViewMaxWidth + 100;
  const isSlugMatchPath = useRoutingConsistencyCheck();

  useWatchMobileViewWidth(+mobileViewMaxWidth, isMobileViewWidth);

  useFirebaseLazyLoading();

  useSegmentAnalytics();

  const calculateRobotMetaTagValue = (noFollow: boolean, noIndex: boolean) => {
    const isNone = noFollow && noIndex;
    const isNoIndex = !noFollow && noIndex;
    const isNoFollow = noFollow && !noIndex;

    if (isNone) return 'none';

    if (isNoIndex) return 'noindex';

    if (isNoFollow) return 'nofollow';

    return 'all';
  };

  const getMetaData = (): MetaData => {
    if (!seo) {
      return {} as MetaData;
    }

    const findCallback = (item: string) => slug.includes(item);
    const horoscopeTypeSlug = HOROSCOPES_TYPES.find(findCallback);
    const horoscopeType = (horoscopeTypeSlug || '')
      .split('-')
      .map((word) => capitalizeFirstLetter(word))
      .join(' ');
    const replacementKeywords = seo.keywords?.reduce((accumulator, keyword, i) => {
      accumulator[`${MetaReplacementValue.KEYWORD}${i + 1}`] = keyword;

      return accumulator;
    }, {});
    const zodiacSign = ZODIAC_SIGNS.find(findCallback);
    const utcDateString = getDateWithNewTimezone('America/Los_Angeles');
    const replacementVariables = {
      [MetaReplacementValue.SITE_NAME]: seo.siteName!,
      [MetaReplacementValue.ZODIAC_SIGN]: capitalizeFirstLetter(zodiacSign || ''),
      [MetaReplacementValue.HOROSCOPE_TYPE]: horoscopeType || '',
      [MetaReplacementValue.CURRENT_DATE]: getOracleFormattedDate(utcDateString, 4),
      ...replacementKeywords,
    };

    const { psychicName } = parsePsychicsUrl(router.asPath);

    const title = replaceContentfulVariables(
      seo.title!,
      { ...replacementVariables, [GAReplacementValue.PSYCHIC_NAME]: psychicName },
    )
      .replace(dynamicValue, '');
    const description = replaceContentfulVariables(seo.description!, {
      ...replacementVariables,
      [MetaReplacementValue.TITLE]: title || '',
      [GAReplacementValue.PSYCHIC_NAME]: psychicName,
    })
      .replace(dynamicValue, '');
    const image = seo.image
      ? {
        url: seo.image.url! || '',
        height: seo.image.height! || 630,
        width: seo.image.width! || 1200,
        contentType: seo.image.contentType! || '',
      }
      : {};

    return {
      robotMetaValue: calculateRobotMetaTagValue(seo.noFollow!, seo.noIndex!),
      description,
      title,
      image,
      favicon: rest.favicon?.url,
      type: seo.type,
      locale: seo.locale,
      siteName: seo.siteName,
      redirect: rest.redirect,
      keywords: seo.keywords?.join(','),
      schema: seo.schema,
      url: seo.url,
    } as MetaData;
  };

  return (
    <>
      {isSlugMatchPath && externalNoScripts.map((tag) => mapNoScriptTag(tag))}
      <MainLayout
        isSlugMatchPath={isSlugMatchPath}
        isDesktopRequested={isDesktopRequested}
        header={header}
        footer={footer}
        metaData={getMetaData()}
        isMobileHeaderNavActive={isMobileHeaderNavActive}
        isMobile={isMobileViewWidth}
        desktopViewportWidth={desktopViewportWidth}
        externalScripts={externalScripts[HEAD_SCRIPTS]}
        externalStyles={externalStyles}
        externalTags={externalTags}
      >
        {children}
      </MainLayout>
      {isSlugMatchPath && externalScripts[CLOSING_SCRIPTS].map((script) => (
        <Fragment key={script.key}>
          {script}
        </Fragment>
      ))}
    </>
  );
};

export default MainLayoutContainer;
