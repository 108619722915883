/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/indent */
import {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { Store } from 'app-redux/types/storeTypes';
import { ExponeaPsychicsList } from 'components/Sections/ExponeaPsychicsList';
import { SectionComponentInterface } from 'types/componentTypes';
import { Cortege, RightPsychic } from 'types/objectTypes';
import {
  CommonContentfulSlug,
  CustomerType,
  ExponeaPsychicsResults,
  ExponeaSortOrder,
} from 'constants/enums';
import { Block, ExponeaExtraData } from 'src/__generated__/graphqlTypes';
import { useCommonContentfulBlocks } from 'lib/shared.hook';
import { getExponeaOnPsychicsLoaded, updateExponeaExtraData } from 'lib/external/exponea';
import { setFirebaseRequiredState } from 'app-redux/actions/appActions';
import { EXPONEA_RECOMMENDATION_SIZE_MULTIPLIER } from 'constants/constants';
import { ExponeaBlocks } from 'components/Sections/ExponeaPsychicsList/declarations';
import ExponeaFirestoreProvider from 'components/Sections/ExponeaPsychicsList/ExponeaFirestoreProvider';
import { Logger } from 'lib/logger';
import { useBootChatSolution } from 'entities/PsychicCtaButton';

/* Nested function */
const reducer = <T extends Record<string, any>>(store: T, item: Block | ExponeaExtraData) => {
  if (item.slug === 'content' || item.__typename === 'Block') {
    // @ts-ignore
    store.block = item;
  }

  if (item.slug === 'config' || item.__typename === 'ExponeaExtraData') {
    // @ts-ignore
    store.config = item;
  }

  return store;
};
/* Nested function */
const getSortComparator = <T, >(a: T, b: T, order: ExponeaSortOrder) => {
  if (a === b) {
    return 0;
  }

  if (order === ExponeaSortOrder.ASC) {
    if (a > b) {
      return 1;
    }

    return -1;
  }

  if (order === ExponeaSortOrder.DESC) {
    if (a < b) {
      return 1;
    }

    return -1;
  }

  return 0;
};

/* Main component */
const ExponeaPsychicsListContainer: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const { config } = blocks.reduce(reducer, {} as ExponeaBlocks);
  const updatedExtraData = updateExponeaExtraData(extraData, config);
  const {
    exponeaOptions = {},
    resultPsychics = ExponeaPsychicsResults.AVAILABLE,
  } = updatedExtraData;
  const isFirebaseRequired = useSelector((store: Store) => store.client.app.isFirebaseRequired);
  // @ts-ignore
  const [psychics, setPsychics] = useState<Array<RightPsychic> | null>(null);
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [canHookBeInvoked, setFirestoreHookInvocationState] = useState<boolean>(false);
  const sectionRef = useRef<HTMLElement>(null);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const pages = useSelector((store: Store) => store.server.page.pages);
  const slug = useSelector((store: Store) => store.server.page.slug);
  const { loader, customerType } = pages[slug];
  const {
    content,
    topDivider,
    bottomDivider,
    commonPageMaxWidth,
  } = useCommonContentfulBlocks<CommonContentfulSlug, Block>(blocks);
  const dispatch = useDispatch();
  const psychicCortege: Cortege<Array<RightPsychic> | null> = [psychics, setPsychics];
  const loadingCortege: Cortege<boolean> = [isLoading, setLoadingState];
  const router = useRouter();
  const bootStatus = useBootChatSolution();

  const modifyPsychics = (psychics: Array<RightPsychic>) => {
    const { priceSort, rateSort } = updatedExtraData;

    if (priceSort && rateSort) {
      psychics.sort((a, b) => {
        if (a.basePrice === b.basePrice) {
          return getSortComparator(a.overallScore, b.overallScore, rateSort);
        }

        const aPrice = +a.basePrice.slice(1);
        const bPrice = +b.basePrice.slice(1);

        return getSortComparator(aPrice, bPrice, priceSort);
      });

      return psychics;
    }

    if (priceSort) {
      psychics.sort((first, second) => {
        const aPrice = +first.basePrice.slice(1);
        const bPrice = +second.basePrice.slice(1);

        return getSortComparator(aPrice, bPrice, priceSort);
      });
    }

    if (rateSort) {
      psychics.sort((first, second) => getSortComparator(
        first.overallScore,
        second.overallScore,
        rateSort,
      ));
    }

    return psychics;
  };

  const setPsychicsAndFirebase = (psychics: Array<RightPsychic> | null) => {
    if (psychics?.length) {
      const modifiedPsychics = modifyPsychics(psychics);
      setFirestoreHookInvocationState(true);
      setPsychics(modifiedPsychics);

      if (!isFirebaseRequired) {
        dispatch(setFirebaseRequiredState(true));
      }
    }
  };
  const onRecommendationsLoaded = getExponeaOnPsychicsLoaded(
    updatedExtraData,
    customerType as CustomerType,
    setPsychicsAndFirebase,
    () => setLoadingState(false),
  );

  const waitForExponeaAndGetRecommendations = (
    counter: number,
    timeout: Parameters<typeof clearTimeout>[0],
    options: any,
  ) => {
    if (!exponeaOptions?.recommendationId) {
      return;
    }

    const { exponea } = window;

    if (counter > 10) {
      clearTimeout(timeout);
      setLoadingState(false);

      return;
    }

    if (counter === 0) {
      setLoadingState(true);
    }

    if (!exponea) {
      timeout = setTimeout(() => waitForExponeaAndGetRecommendations(
        counter + 1,
        timeout,
        options,
      ), 100);
      const warn = `Exponea  was late with loading ${counter + 1} time`;
      Logger.warn(warn);

      return;
    }

    const size = (resultPsychics === ExponeaPsychicsResults.ALL)
      ? options.size
      : options.size * EXPONEA_RECOMMENDATION_SIZE_MULTIPLIER;

    const { extId, extid } = router.query;
    const id = (extId || extid) as string;

    if (id) {
      options = { ...options, items: { [id]: 1 } };
    }

    exponea.getRecommendation({
      callback: onRecommendationsLoaded,
      ...options,
      size,
    });
  };

  useEffect(() => {
    const counter = 0;
    const timeout: any = null;
    waitForExponeaAndGetRecommendations(counter, timeout, exponeaOptions);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <ExponeaFirestoreProvider
        canHookBeInvoked={canHookBeInvoked}
        resultPsychics={resultPsychics}
        extraData={updatedExtraData}
        loadingData={loadingCortege}
        psychicData={psychicCortege}
        setHookInvocationState={setFirestoreHookInvocationState}
      />
      <ExponeaPsychicsList
        bootStatus={bootStatus}
        bgColor={bgColor}
        isLoading={isLoading}
        loader={loader}
        extraData={updatedExtraData}
        psychics={psychics || []}
        sectionRef={sectionRef}
        viewerDevice={viewerDevice}
        content={content as any}
        topDivider={topDivider}
        bottomDivider={bottomDivider}
        commonPageMaxWidth={commonPageMaxWidth}
      />
    </>
  );
};

export default ExponeaPsychicsListContainer;
