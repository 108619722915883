import React from 'react';
import { useSelector } from 'react-redux';

import { Store } from 'app-redux/types/storeTypes';
import { SectionComponentInterface } from 'types/componentTypes';
import SignIntro from 'components/Sections/HoroscopesSignsSignIntro/SignIntro';

const SignIntroContainer: React.FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const slug = useSelector((store: Store) => store.server.page.slug);

  return (
    <SignIntro
      blocks={blocks}
      bgColor={bgColor}
      slug={slug}
    />
  );
};

export default SignIntroContainer;
