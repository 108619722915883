import { FC, useContext } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import { capitalizeFirstLetter } from 'lib/text.service';
import type { RichTextParsersConfig, SectionExtraDataType } from 'types/objectTypes';
import { CommonRichText } from 'components/Shared/SharedComponents';
import { Heading } from 'constants/enums';
import { mapBlocksHeadingWithId, mapBlocksParagraphWithExtraData } from 'lib/richTextMappers';

import styles from './styles.module.scss';

import type { IRichText } from '../config/declarations';
import { constructId } from '../service';
import { IdProvider } from '../service/context';

const getParsersConfig = ({
  titleAlign,
  pAlign,
  id,
}: SectionExtraDataType): RichTextParsersConfig => {
  const titleAlignClass = classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`];
  const pAlignClass = classes[`pAlign${capitalizeFirstLetter(pAlign || titleAlign)}`];
  const getHeadingClasses = (headingClass: string) => cn(
    styles.title,
    headingClass,
    titleAlignClass,
  );

  return ({
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(styles.description, pAlignClass),
      mapper: mapBlocksParagraphWithExtraData({ id: constructId(id, '-paragraph'), title: undefined }),
    },
    [BLOCKS.HEADING_1]: {
      classNames: getHeadingClasses(styles.titleH1),
      mapper: mapBlocksHeadingWithId(constructId(id, '-heading'), Heading.FIRST),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHeadingClasses(styles.titleH2),
      mapper: mapBlocksHeadingWithId(constructId(id, '-heading')),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getHeadingClasses(styles.titleH3),
      mapper: mapBlocksHeadingWithId(constructId(id, '-heading'), Heading.THIRD),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold700,
    },
  });
};

const Title: FC<IRichText> = ({ config, richText }) => {
  const id = useContext(IdProvider);

  if (!richText) {
    return null;
  }

  return (
    <CommonRichText
      content={richText}
      parsersConfig={getParsersConfig({ ...config, id })}
    />
  );
};

const Content = Title;

export { Content, Title };
