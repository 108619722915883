import { MouseEvent } from 'react';
import { Dispatch } from 'redux';
import qs from 'query-string';

import { setLoadingState } from 'app-redux/actions/appActions';

import styles from './bioOverlay.module.scss';

import type { CustomNextRouter } from '../history/types';

const IFRAME_ID = 'bio-overlay';
export const IFRAME_QUERY_PARAMETER = 'isIframe';
export const IFRAME_NAVIGATION_EVENT = 'iframe-navigation';

export class BioOverlay {
  private static iframe: HTMLIFrameElement | null = null;

  private static dispatch: any = () => {};

  private static router: CustomNextRouter | null = null;

  static setDispatch(dispatch: Dispatch<any>) {
    this.dispatch = dispatch;
  }

  static setRouter(router: CustomNextRouter) {
    this.router = router;
  }

  static isOverlayActive() {
    return this.iframe !== null;
  }

  static create(path: string) {
    const prevIframe = document.getElementById(IFRAME_ID) as HTMLIFrameElement;

    if (prevIframe && !prevIframe.src.includes(path)) {
      this.remove();
    }

    const src = `${window.location.origin}${path}`;
    const url = new URL(src);
    const iframe = document.createElement('iframe');
    url.searchParams.append(IFRAME_QUERY_PARAMETER, 'true');
    iframe.src = url.toString();
    iframe.classList.add(styles.overlay);
    iframe.style.border = 'none';
    iframe.id = IFRAME_ID;

    this.dispatch(setLoadingState(true));

    iframe.onload = () => {
      this.router?.prefetch(path);

      if (this.iframe?.style.display !== 'none') {
        document.body.style.overflowY = 'hidden';
      }

      this.dispatch(setLoadingState(false));
    };

    iframe.onerror = () => {
      this.dispatch(setLoadingState(false));

      this.remove();
      window.history.back();
    };

    this.iframe = iframe;
    document.body.append(iframe);

    iframe.contentWindow?.addEventListener('DOMContentLoaded', () => {
      if (!window.location.pathname.includes(path)) {
        window.history.pushState({ as: path, url: path }, '', path);
      }
    });
  }

  static remove() {
    document.body.style.overflowY = 'unset';

    if (!BioOverlay.iframe) {
      return;
    }

    BioOverlay.iframe.remove();
  }

  static hide() {
    document.body.style.overflowY = 'unset';

    if (!BioOverlay.iframe) {
      return false;
    }

    BioOverlay.iframe.style.display = 'none';
  }

  static show() {
    document.body.style.overflowY = 'hidden';

    if (!BioOverlay.iframe) {
      return false;
    }

    BioOverlay.iframe.style.display = 'block';
  }

  static notifyParentOnNavigation(path: string) {
    const iframeNavigation = new CustomEvent(IFRAME_NAVIGATION_EVENT, { detail: path });
    window.parent.document.dispatchEvent(iframeNavigation);
  }

  static handleClickInIframe(
    e: MouseEvent<HTMLAnchorElement>,
    calculatedPath: string,
  ) {
    if (window.location.hash && window.location.pathname.includes(calculatedPath)) {
      return;
    }

    const query = qs.parse(window.location.search);

    if (!query[IFRAME_QUERY_PARAMETER]) {
      return;
    }

    const isFragmentNavigationOnSamePage = calculatedPath.startsWith('#');

    e.preventDefault?.();
    e.stopPropagation?.();

    if (isFragmentNavigationOnSamePage) {
      const id = document.getElementById(calculatedPath.substring(1));

      if (id) {
        id.scrollIntoView({ behavior: 'smooth' });
      }

      return;
    }

    this.notifyParentOnNavigation(calculatedPath);
  }
}
