import type { FC } from 'react';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import type { Block, ImageWrapper } from 'src/__generated__/graphqlTypes';
import { FullBackgroundImage } from 'src/shared/ui/FullBackgroundImage';
import { CommonSize } from 'constants/enums';
import type { SectionComponentGenericInterface } from 'types/componentTypes';
import { capitalizeFirstLetter } from 'lib/text.service';

import styles from './FlexColumns.module.scss';
import FlexElement from './FlexElement';

import { parseBlocks } from '../lib/parser';

const FlexColumns: FC<SectionComponentGenericInterface<Block>> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const {
    verticalPadding = CommonSize.X_SMALL,
  } = extraData || {};
  const verticalPaddingClass = classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`];
  const { left, center, right, bg } = parseBlocks(blocks);
  const images = (bg?.imagesCollection?.items || []) as Array<ImageWrapper>;

  return (
    <section
      className={cn(
        styles.wrapper,
        styles.wrapperGrid,
        verticalPaddingClass,
        {
          [styles.wrapperGridAll]: Boolean(left && center && right),
          [styles.wrapperGridLR]: Boolean(left && !center && right),
          [styles.wrapperGridLC]: Boolean(left && center && !right),
          [styles.wrapperGridCR]: Boolean(!left && center && right),
        },
      )}
      style={{ background: bgColor }}
    >
      <FlexElement element={left} className={styles.left} />
      <FlexElement element={center} className={styles.center} />
      <FlexElement element={right} className={styles.right} />
      <FullBackgroundImage
        backgrounds={images}
        disableDefaultSource
        className={styles.bg}
        skipTablet
        widths={{ desktop: 2000 }}
      />
    </section>
  );
};

export default FlexColumns;
