import { FC } from 'react';

import { PsychicCardAppearance } from 'constants/enums';
import { PsychicDefaultCardSimpleVertical } from 'features/sections/PsychicCardSimpleVertical';

import type { IDefaultCard } from '../config/declarations';

const PsychicDefaultCard: FC<IDefaultCard> = ({
  defaultCard,
  extraData,
}) => {
  const {
    psychicCardAppearance = PsychicCardAppearance.SIMPLE_VERTICAL,
  } = extraData;

  if (psychicCardAppearance === PsychicCardAppearance.SIMPLE_VERTICAL) {
    return (
      <PsychicDefaultCardSimpleVertical card={defaultCard} />
    );
  }

  return null;
};

export default PsychicDefaultCard;
