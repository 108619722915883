import { ReactElement } from 'react';

import { Block, Link } from 'src/__generated__/graphqlTypes';
import { CommonContentfulSlug } from 'constants/enums';
import { BlockComponentInterface, SectionComponentInterface } from 'types/componentTypes';

interface PackageClickFunction {
  onPackageClick: (
    packageDynamicName: string,
    link?: Link,
    psychicType?: string,
  ) => void;
}

export interface CardNcOfferPackagesInterface
  extends BlockComponentInterface, PackageClickFunction {
}

export interface NcOfferPackagesInterface extends
  Omit<SectionComponentInterface, 'blocks'>,
  Record<CommonContentfulSlug, Block | Link>,
  PackageClickFunction {
  commonPageMaxWidth?: string;
  isMobileViewWidth: boolean;
}

export interface NcOfferPackagesSliderInterface {
  rightArrow: Link;
  leftArrow: Link;
  cards: Array<ReactElement>;
}

export interface NcOfferNestedPackagesInterface extends PackageClickFunction {
  childBlocks: Array<Block> | undefined;
  nextArrow: Link;
  prevArrow: Link;
  isMobileViewWidth: boolean;
}

export const defaultAccountInfoObject = {
  firstName: '',
  lastName: '',
  email: '',
  dob: '',
  password: '',
  sendFreeHoroAndNewsLetter: '',
  offerDuration: '',
  cardHolderName: '',
  cardNumber: '',
  expMonth: '',
  expYear: '',
  cvv: '',
  cardType: '',
  billingCountry: '',
  billingCountryName: '',
  zipCode: '',
  state: '',
  stateRegion: '',
  city: '',
  address1: '',
  address2: '',
  phoneCountry: '',
  phoneNumber: '',
  masId: '',
  custId: '',
  callbackId: '',
  extId: '',
  offerId: '',
  priceId: '',
  transactionId: '',
  transactionAmount: '',
  statusCode: '',
  errorMessage: '',
  subscriptionId: '',
  package: { duration: '20', packageOfferPrice: '' },
  questionText: '',
  emailSource: 17,
};
