import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

import styles from 'src/components/Sections/AboutLeftImage/AboutLeftImage.module.scss';
import { SectionComponentInterface } from 'types/componentTypes';
import { Block, Asset } from 'src/__generated__/graphqlTypes';
import { RichTextParsersConfig, WebpWidthLimitType } from 'types/objectTypes';
import {
  CommonRichText,
  DataLink,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { CustomAssetInterface } from 'components/Sections/AboutLeftImage/declarations';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.leftImageContentParagraph,
  },
  [BLOCKS.HEADING_2]: {
    classNames: styles.leftImageContentTitle,
  },
};

const ASSET_WIDTH_IN_PX = 380;
const MAX_WIDTH = '400px';
const CustomAsset: React.FC<CustomAssetInterface> = ({ image }) => {
  if (image) {
    const widthLimit: Array<WebpWidthLimitType> = [{
      width: ASSET_WIDTH_IN_PX,
      media: MAX_WIDTH,
    }];

    return (
      <WebpImage
        widthLimit={widthLimit}
        image={image}
        className={styles.leftImageAsset}
      />
    );
  }

  return null;
};

const AboutLeftImage: React.FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const buildComponents = () => blocks
    .map((block: Block) => {
      const {
        content,
        image,
        link,
        entryName,
      } = block;

      if (!content) return null;

      return (
        <div className={styles.leftImage} key={entryName}>
          <CustomAsset image={image as Asset} />
          <div className={styles.leftImageContent}>
            <CommonRichText content={content} parsersConfig={parsersConfig} />
            {link && (
              <DataLink link={link} href={link.src!}>
                {link.title}
              </DataLink>
            )}
          </div>
        </div>
      );
    });

  return (
    <section className={styles.wrapper} style={{ background: bgColor }}>
      {buildComponents()}
    </section>
  );
};

export default AboutLeftImage;
