/* eslint-disable no-param-reassign */
import { FC } from 'react';
import withoutHydration from 'react-without-hydration';
import parse from 'html-react-parser';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import styles from 'src/components/Sections/InlineHtml/InlineHtml.module.scss';
import { SectionComponentTextInterface } from 'types/componentTypes';
import { capitalizeFirstLetter } from 'lib/text.service';
import { useHtmlParserReplace } from 'lib/tag.service';

const InlineHtml: FC<SectionComponentTextInterface> = ({
  blocks: texts,
  extraData,
  bgColor,
}) => {
  const { horizontalPadding, verticalPadding } = extraData || {};
  const extraDataClasses = {
    [styles[`paddingHorizontal${capitalizeFirstLetter(horizontalPadding)}`]]: Boolean(horizontalPadding),
    [classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`]]: Boolean(verticalPadding),
  };
  const wrapperStyles = cn(styles.wrapper, extraDataClasses);
  const replace = useHtmlParserReplace();

  return (
    <section className={wrapperStyles} style={{ background: bgColor }}>
      {texts.map(({ fullText }) => fullText && parse(fullText, { replace }))}
    </section>
  );
};

export default withoutHydration()(InlineHtml);
