import { FC } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';

import { WebpImage } from 'components/Shared/SharedComponents';
import { getLocalizedFullMonthDYDate } from 'lib/date.service';

import styles from './styles.module.scss';

import {
  IHeader,
  IHeaderImage,
  IHeaderOptionalDate,
} from '../config/declarations';

/* Nested component */
const Image: FC<IHeaderImage> = ({ className, image }) => {
  if (!image?.url) {
    return null;
  }

  return (
    <WebpImage
      src={image.url}
      image="external"
      className={cn(className, styles.image)}
    />
  );
};

/* Nested component */
const OptionalDate: FC<IHeaderOptionalDate> = ({ showDate, date }) => {
  const router = useRouter();

  if (!showDate) {
    return null;
  }

  return (
    <strong className={styles.date}>
      {getLocalizedFullMonthDYDate(router.locale, date)}
    </strong>
  );
};

/* Main component */
const Header: FC<IHeader> = ({
  image,
  title,
  date,
  showDate,
  headerClassName,
  imageClassName,
  titleClassName,
}) => (
  <div className={cn(headerClassName, styles.header)}>
    <Image className={imageClassName} image={image} />
    <div className={cn(titleClassName, styles.titleWrapper)}>
      <span className={styles.title}>{title}</span>
      <OptionalDate date={date} showDate={showDate} />
    </div>
  </div>
);

export default Header;
