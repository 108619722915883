import { FC } from 'react';

import { WebpImage } from 'components/Shared/SharedComponents';

import styles from './FlexColumns.module.scss';

import type { IChild } from '../config/declarations';

const Image: FC<IChild> = ({ element }) => {
  const { image } = element;

  if (!image) {
    return null;
  }

  return (
    <WebpImage
      key={element.sys.id}
      image={image}
      className={styles.image}
    />
  );
};

export default Image;
