import { forwardRef } from 'react';

import { DataLink } from 'components/Shared/SharedComponents';
import { GAReplacementValue } from 'constants/enums';
import { replaceContentfulVariables } from 'lib/text.service';

import { DataLinkWrapperInterface } from '../config/declarations';

const DataLinkWrapper = forwardRef<HTMLAnchorElement, DataLinkWrapperInterface>(({
  children,
  href,
  complexHref,
  psychic,
  ...rest
}, ref) => {
  const finalRef = href || complexHref?.reduce((accumulator, item) => {
    const parsedItem = replaceContentfulVariables(
      item,
      { [GAReplacementValue.FULL_URL]: process.env.NEXT_PUBLIC_BASE_SERVER_URL },
    );

    if (parsedItem.charAt(0) === '/' || accumulator.charAt(accumulator.length - 1) === '/') {
      return accumulator.concat(parsedItem);
    }

    return accumulator.concat('/').concat(parsedItem);
  }, '') || '';
  const gaData = psychic
    ? {
      [GAReplacementValue.PSYCHIC_NAME]: psychic.lineName,
      [GAReplacementValue.EXT_ID]: psychic.extId.toString(),
    }
    : {};

  return (
    <DataLink
      {...rest}
      ref={ref}
      href={finalRef}
      gaData={gaData}
    >
      {children}
    </DataLink>
  );
});

export default DataLinkWrapper;
