import { BLOCKS } from '@contentful/rich-text-types';
import Modal from 'react-modal';
import { FC } from 'react';

import classes from 'src/styles/_commonClasses.module.scss';
import { CommonRichText, DataButton } from 'components/Shared/SharedComponents';
import type { RichTextParsersConfig } from 'types/objectTypes';

import styles from './Form.module.scss';

import type { ZendeskModalInterface } from '../config/declarations';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.modalParagraph,
  },
};
const ZendeskModal: FC<ZendeskModalInterface> = ({
  modal,
  isModalVisible = false,
  closeModal,
}) => {
  if (!modal) {
    return null;
  }

  const { link, title, content } = modal;

  return (
    <Modal
      isOpen={isModalVisible}
      className={styles.modal}
      overlayClassName={styles.modalBackground}
      shouldCloseOnOverlayClick
      bodyOpenClassName={classes.overflowHidden}
      onRequestClose={closeModal}
    >
      <span className={styles.modalTitle}>{title}</span>
      {content && (
        <CommonRichText
          content={content}
          parsersConfig={parsersConfig}
        />
      )}
      {link && (
        <DataButton
          link={link}
          className={styles.modalButton}
          onClick={closeModal}
        >
          {link.title}
        </DataButton>
      )}
    </Modal>
  );
};

export default ZendeskModal;
