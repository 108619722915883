import { FC } from 'react';
import cn from 'classnames';

import desktopNavStyles from 'components/Footer/Links/Navigation/DesktopNavBar.module.scss';
import { MobileNavBarClasses } from 'types/classTypes';
import CommonNavBar from 'components/Navigation';
import { NavigationLocation } from 'constants/enums';
import { DesktopNavBarWrapperInterface } from 'components/Footer/Links/Navigation/declarations';
import { capitalizeFirstLetter } from 'lib/text.service';

const DesktopNavBarWrapper: FC<DesktopNavBarWrapperInterface> = ({ navBar, footerType }) => {
  const classes: MobileNavBarClasses = {
    highOrder: {
      ul: cn(desktopNavStyles.navBarUl, desktopNavStyles[`navBarUl${capitalizeFirstLetter(footerType)}`]),
      li: desktopNavStyles.navBarUlLi,
      span: desktopNavStyles.navBarUlLiHeading,
      innerUl: desktopNavStyles.navBarUlLiChildrenUl,
    },
    middleOrder: {
      li: desktopNavStyles.navBarUlLiChildrenUlLi,
      link: desktopNavStyles.navBarUlLiChildrenUlLiA,
    },
  };

  return (
    <CommonNavBar
      navBar={navBar}
      classes={classes}
      isMobile={false}
      navigationLocation={NavigationLocation.FOOTER}
    />
  );
};

export default DesktopNavBarWrapper;
