import { useSelector } from 'react-redux';

import type { Store } from 'app-redux/types/storeTypes';
import { USER_TIME_ZONE } from 'constants/constants';
import { getEqualDateForTimezones } from 'lib/date.service';

import { useCustomRouter } from '../history/hooks';

export const useStableDateGetter = () => {
  const router = useCustomRouter();
  const serverCookies = useSelector((store: Store) => store.server.app.serverCookies);
  const userTimeZone = serverCookies[USER_TIME_ZONE];

  return (date: Date = new Date()) => {
    const stableDate = getEqualDateForTimezones(date, router.locale, userTimeZone?.timezone);

    return stableDate;
  };
};
