import {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import type { Link } from 'src/__generated__/graphqlTypes';
import classes from 'src/styles/_commonClasses.module.scss';
import { getRightPsychicsProxy } from 'src/api/psychicApi';
import type { Store } from 'app-redux/types/storeTypes';
import type { SectionComponentInterface } from 'types/componentTypes';
import { RightPsychic } from 'types/objectTypes';
import {
  CommonSize,
  ElementAlign,
  LoadingMode,
  PsychicCategoryType,
} from 'constants/enums';
import { IntersectionObserverInit, IntersectionObserverWrapper } from 'lib/intersectionObserver';
import { Logger } from 'lib/logger';
import { PsychicsWithTestimonials } from 'features/PsychicsWithTestimonials';
import { getPsychicsWithTestimonials } from 'src/shared/lib/psychics';
import { makePayloadToGetPsychic } from 'lib/psychic.service';
import { capitalizeFirstLetter } from 'lib/text.service';

import styles from './CustomersTestimonials.module.scss';

import { extractComponents } from '../lib';
import { ServerPsychicsBlock } from '../config/declarations';

const CustomersTestimonialsContainer: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const {
    block,
    leftArrow,
    rightArrow,
  } = extractComponents(blocks as Array<ServerPsychicsBlock | Link>);
  const [
    psychics,
    setPsychics,
  ] = useState<Array<RightPsychic>>((block as ServerPsychicsBlock).psychics);
  const sectionRef = useRef<HTMLElement>(null);
  const user = useSelector((store: Store) => store.server.auth.user);
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const slug = useSelector((store: Store) => store.server.page.slug);
  const pages = useSelector((store: Store) => store.server.page.pages);
  const { loader } = pages[slug];
  const prevIsAuthenticated = useRef<boolean | null>(null);

  const {
    itemsAmount,
    titleAlign = ElementAlign.LEFT,
    psychicCategory = PsychicCategoryType.ASTROLOGY,
    loadingMode = LoadingMode.LAZY,
    verticalPadding = CommonSize.MEDIUM,
    isSimpleView = false,
  } = extraData || {};

  const setPsychicsFromRemote = useCallback(async () => {
    const requestBody: Record<string, any> = {
      format: 'json',
      custID: user?.custId,
      ...makePayloadToGetPsychic(psychicCategory),
    };

    if (itemsAmount) {
      requestBody.PageSize = itemsAmount;
    }

    try {
      setLoadingState(true);
      const { psychics } = await getRightPsychicsProxy(requestBody);
      const psychicsWithTestimonials = await getPsychicsWithTestimonials(psychics, user?.custId);
      setPsychics(psychicsWithTestimonials);
    } finally {
      setLoadingState(false);
    }
  }, [extraData]);

  useEffect(() => {
    (async () => {
      if (psychics?.length === 0 && isAuthenticated !== prevIsAuthenticated.current) {
        await setPsychicsFromRemote();
      }

      prevIsAuthenticated.current = isAuthenticated;
    })();
  }, [isAuthenticated, psychics, setPsychicsFromRemote]);

  useEffect(() => {
    if (loadingMode === LoadingMode.EAGER) {
      if (itemsAmount !== psychics?.length) {
        (async () => {
          try {
            await setPsychicsFromRemote();
          } catch (e) {
            Logger.error(e);
          }
        })();
      }

      return;
    }

    if (!sectionRef.current) {
      (async () => {
        try {
          await setPsychicsFromRemote();
        } catch (e) {
          Logger.error(e);
        }
      })();

      return;
    }

    const intersectionHandler = async (
      entries: Array<IntersectionObserverEntry>,
      observer: IntersectionObserver,
    ) => {
      const handleSuccess = async () => {
        await setPsychicsFromRemote();
        observer.unobserve(sectionRef.current!);
      };
      const observed: Array<any> = [];
      entries
        .forEach((entry) => {
          if (entry.isIntersecting) {
            try {
              observed.push(handleSuccess());
            } catch (e) {
              Logger.error(e);
            }
          }
        });

      await Promise.all(observed);
    };

    const options: IntersectionObserverInit = {
      rootMargin: '300px',
    };

    const observer = IntersectionObserverWrapper.getInstance(intersectionHandler, options);

    observer?.observe(sectionRef.current);
  }, [setPsychicsFromRemote]);

  return (
    <section
      style={{ background: bgColor }}
      className={cn(
        styles.testimonials,
        classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`],
      )}
    >
      <PsychicsWithTestimonials
        topPsychicsBlock={block as any}
        psychics={psychics as any}
        loaderImage={loader!}
        isLoading={isLoading}
        leftArrow={leftArrow!}
        rightArrow={rightArrow!}
        titleAlign={titleAlign}
        isSimpleView={isSimpleView}
      />
    </section>
  );
};

export default CustomersTestimonialsContainer;
