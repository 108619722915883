import cookie from 'cookie';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ClientOnly } from 'lib/decorators';
import { Store } from 'app-redux/types/storeTypes';
import { UserType } from 'types/objectTypes';
import { getChatTokens } from 'src/api/authApi';
import { SESSION_COOKIE_NAME } from 'constants/constants';
import { Logger } from 'lib/logger';

import { getAuthProviderSessionCookie, invalidateSessionRedirect } from '.';

const TOKEN_EXPIRATION_COOKIE = 'token-expiration';

export class TokenRefresher {
  // eslint-disable-next-line no-undef
  private static timeout: NodeJS.Timeout | null;

  private static expirationDate: Date | null;

  private static isCookieSet: boolean = false;

  private static authData: UserType | null;

  private static isRequestInProcess: boolean = false;

  @ClientOnly()
  static init(user: UserType | null) {
    this.authData = user;
  }

  @ClientOnly()
  static setTokensExpirationCookie() {
    const timeInMinutes = Number(process.env.NEXT_PUBLIC_MINUTES_TO_REFRESH_TOKEN) || 4;
    const expirationDate = new Date();
    expirationDate.setMinutes(expirationDate.getMinutes() + timeInMinutes);
    this.expirationDate = expirationDate;
    const expiresOption = new Date(expirationDate);
    expiresOption.setMinutes(expiresOption.getMinutes() + 3);
    document.cookie = cookie
      .serialize(TOKEN_EXPIRATION_COOKIE, expirationDate.toString(), { expires: expiresOption });
    this.isCookieSet = true;
  }

  @ClientOnly()
  static async setRefreshTimeout() {
    if (!this.expirationDate) {
      const expirationDate = cookie.parse(document.cookie)?.[TOKEN_EXPIRATION_COOKIE];

      if (!expirationDate) {
        if (this.isCookieSet) {
          return this.redirect();
        }

        await this.resetTokens();

        return;
      }

      this.expirationDate = new Date(expirationDate);
    }

    const currentDate = new Date();
    const delay = this.expirationDate.getTime() - currentDate.getTime();
    this.timeout = setTimeout(() => this.resetTokens(), delay);
  }

  @ClientOnly()
  static addRefreshHandler() {
    if (!this.authData) {
      return;
    }

    this.setTokensExpirationCookie();
    this.setRefreshTimeout();
  }

  @ClientOnly()
  static removeTokenExpirationCookie() {
    document.cookie = cookie
      .serialize(TOKEN_EXPIRATION_COOKIE, '', { expires: new Date() });
  }

  private static async resetTokens() {
    if (!this.authData) {
      return;
    }

    if (this.isRequestInProcess) {
      return;
    }

    this.isRequestInProcess = true;
    try {
      const tokens = await getChatTokens(this.authData.authToken);
      const authToken = `Token ${tokens.data.sessionId}`;
      const { chatToken, chatTokenExp } = tokens.data.meta;
      this.authData.authToken = authToken;
      this.authData.chatToken = chatToken;
      this.authData.chatTokenExp = chatTokenExp;
      const currentAuthCookie = cookie.parse(document.cookie)?.[SESSION_COOKIE_NAME];

      if (!currentAuthCookie) {
        return this.redirect();
      }

      const newAuthCookie = JSON.stringify({
        ...JSON.parse(currentAuthCookie),
        authToken,
        chatToken,
        chatTokenExp,
      });

      document.cookie = cookie
        .serialize(
          SESSION_COOKIE_NAME,
          newAuthCookie,
          getAuthProviderSessionCookie(),
        );
      this.isCookieSet = false;
      this.setTokensExpirationCookie();
      this.setRefreshTimeout();
    } catch (e) {
      Logger.warn(e);
      this.redirect();
    } finally {
      this.isRequestInProcess = false;
    }
  }

  private static async redirect() {
    invalidateSessionRedirect();
  }

  @ClientOnly()
  static destroy() {
    if (!this.timeout) {
      return;
    }

    clearTimeout(this.timeout);
  }
}

export const useAuthTokenRefresher = () => {
  const user = useSelector((store: Store) => store.server.auth.user);

  useEffect(() => {
    if (!user) {
      TokenRefresher.destroy();
      TokenRefresher.removeTokenExpirationCookie();

      return;
    }

    TokenRefresher.init(user);
    TokenRefresher.addRefreshHandler();
  }, [user]);

  useEffect(() => () => TokenRefresher.destroy(), []);
};
