import { FC, MouseEvent } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { DataLink, DataButton } from 'components/Shared/SharedComponents';
import { capitalizeFirstLetter } from 'lib/text.service';
import { GAReplacementValue } from 'constants/enums';
import { useExponeaProductSelectedTracking } from 'lib/external/exponeaHooks';
import { Store } from 'app-redux/types/storeTypes';
import { setPageUrlCookie } from 'src/shared/lib/cookie';
import {
  ASK_ACCOUNT_INFO,
  CTA_TYPE,
  NC_FUNNEL_SIGN_UP_URL,
  PSYCHIC_TALK_COOKIE_BIO,
} from 'constants/constants';
import { accountInfoObject } from 'src/shared/lib/cookie/accountInfo';
import { useCustomRouter } from 'src/shared/lib/history/hooks';
import { updateCustomerDetails } from 'src/shared/lib/auth';

import { ButtonIcon } from './Icon';
import styles from './styles.module.scss';

import { setMultipleCookies } from '../lib/ctaCookie';
import { ButtonInterface } from '../config/declarations';
import { getUrl } from '../lib';
import { useCallbackReserveFunction } from '../lib/talkHooks';

const Button: FC<ButtonInterface> = ({
  button: link,
  psychic,
  className,
  buttonName,
  shape = 'Rectangle',
  href = '',
  onClick,
  ...rest
}) => {
  const trackExponea = useExponeaProductSelectedTracking(psychic);
  const slug = useSelector((store: Store) => store.server.page.slug);
  const { extId, psychicName } = psychic;
  const url = getUrl(href, link?.src!, { extid: extId.toString() });
  const router = useCustomRouter();
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);
  const user = useSelector((store: Store) => store.server.auth.user);
  const { talkReserve } = useCallbackReserveFunction();

  if (!link) {
    return null;
  }

  const localOnClick = async (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    setMultipleCookies(
      {
        [CTA_TYPE]: buttonName.toLowerCase(),
        [PSYCHIC_TALK_COOKIE_BIO]: { ExtId: extId, Name: psychicName },
        [ASK_ACCOUNT_INFO]: { ...accountInfoObject, extId, chat: false },
      },
    );

    if (user && !user?.isLeadCustomer && buttonName !== 'InQueue') {
      e.preventDefault();
      const customerDetails = await updateCustomerDetails();

      if (customerDetails?.enableAutoRecharge) {
        await talkReserve(user?.custId, extId);
      }

      router.push(url);
    }

    if (slug === 'psychic-bio') {
      setPageUrlCookie(url);
    }

    if (user?.isLeadCustomer) {
      const number = user?.customerPhoneNumber === '9999999999' ? '' : user?.customerPhoneNumber;
      setMultipleCookies({
        [ASK_ACCOUNT_INFO]: {
          ...accountInfoObject,
          extId: psychic.extId,
          custId: user?.custId,
          chat: false,
          dob: user?.userDateOfBirth,
          ncflowtype: 2,
          email: user?.userEmail,
          phoneNumber: number,
        },
      });
      router.push(`${NC_FUNNEL_SIGN_UP_URL}?extid=${psychic.extId}`);

      return;
    }

    trackExponea(buttonName);
    onClick?.(e);
  };

  const ButtonComponent = isAuthenticated ? DataButton : DataLink;

  return (
    <ButtonComponent
      {...rest}
      link={link}
      href={url}
      className={cn(
        styles.button,
        styles[`shape${capitalizeFirstLetter(shape)}`],
        styles[`button${buttonName}`],
        className,
      )}
      gaData={{
        [GAReplacementValue.PSYCHIC_NAME]: psychicName,
        [GAReplacementValue.EXT_ID]: extId.toString(),
      }}
      onMouseDown={localOnClick}
    >
      <ButtonIcon
        isSpriteImage={buttonName !== 'InQueue'}
        image={link.image}
        className={styles.buttonAsset}
        buttonName={buttonName}
      />
      <span className={styles.buttonTitle}>
        {link.title}
      </span>
    </ButtonComponent>
  );
};

export default Button;
