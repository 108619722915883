import {
  Block,
  BlockContent,
  ZodiacSignFeatures,
} from 'src/__generated__/graphqlTypes';
import { SectionComponentInterface } from 'types/componentTypes';

export interface AllAboutSignsContainerInterface
  extends Omit<SectionComponentInterface, 'blocks'> {
  blocks: Array<Block | ZodiacSignFeatures>;
}

export interface AllAboutSignsInterface {
  titleId: string;
  url: string;
  title: BlockContent;
  socialSharing: Block;
  features: ZodiacSignFeatures;
  otherSigns: Block;
  isMobileViewWidth: boolean;
}

export enum HoroscopesSignsAllAboutSignsContainerSlug {
  TITLE = 'title',
  SOCIAL_MEDIA = 'social-media',
  OTHER_SIGNS = 'other-signs',
}

export type AllAboutSignsType = {
  title?: BlockContent;
  socialSharing: Block;
  features?: ZodiacSignFeatures;
  otherSigns?: Block;
};
