import { FC } from 'react';
import cn from 'classnames';
import cookieParser from 'cookie';

import classes from 'src/styles/_commonClasses.module.scss';
import { DataButton } from 'components/Shared/SharedComponents';
import { replaceContentfulVariables } from 'lib/text.service';
import { OFFER_CHECKOUT } from 'constants/constants';
import { NodeEnv } from 'constants/enums';
import { Logger } from 'lib/logger';

import styles from './PriceCardsWithKarma.module.scss';
import GoLink from './GoLink';

import type {
  IBottomRow,
  ICard,
  IMain,
  IMiddleRow,
  ITopRow,
} from '../config/declarations';

/* Nested Component */
const Main: FC<IMain> = ({ text, buttonTitle, amountToCharge, karmaPoints }) => (
  <>
    <div className={styles.mainTop}>
      <span className={styles.mainTopPrice}>
        {text.text}
        {amountToCharge}
      </span>
      <span className={styles.mainTopButton}>{buttonTitle}</span>
    </div>
    {text.fullText && Boolean(karmaPoints) && (
      <p className={styles.mainText}>
        {replaceContentfulVariables(text.fullText, { price: `${karmaPoints}` })}
      </p>
    )}
  </>
);

/* Nested Component */
const TopRow: FC<ITopRow> = ({
  currency,
  textBlock,
  isDiscount,
  amountToCredit,
  karmaPoints,
}) => {
  if (!textBlock) {
    return null;
  }

  const crossedPrice = `${currency || textBlock.text}${amountToCredit.toFixed(2)}`;
  const karmaPointsText = isDiscount ? `+${karmaPoints}` : karmaPoints;

  return (
    <div className={styles.cardTop}>
      {isDiscount && (
        <s className={classes.textBold700}>
          {crossedPrice}
        </s>
      )}
      <span className={cn({ [styles.cardTopDiscount]: isDiscount })}>
        <strong className={classes.textBold700}>{karmaPointsText}</strong>
        {' '}
        {karmaPointsText
          ? textBlock.fullText
          : ''}
      </span>
    </div>
  );
};

/* Nested component */
const MiddleRow: FC<IMiddleRow> = ({
  currency,
  textBlock,
  amountToCharge,
}) => {
  if (!textBlock) {
    return null;
  }

  const chargeAmountWithFixedDigits = Number.isInteger(amountToCharge)
    ? amountToCharge
    : amountToCharge.toFixed(2);
  const prise = `${currency || textBlock.text}${chargeAmountWithFixedDigits}`;

  return (
    <div className={styles.cardMiddle}>
      <strong className={cn(classes.textBold800, styles.cardMiddleText)}>
        {textBlock.fullText}
      </strong>
      <strong className={cn(classes.textBold700, styles.cardMiddlePrice)}>
        {prise}
      </strong>
    </div>
  );
};

/* Nested component */
const BottomRow: FC<IBottomRow> = ({
  isDiscount,
  discount,
  textBlock,
  currency,
  link,
}) => {
  if (!textBlock) {
    return null;
  }

  const savings = `${currency || textBlock.text}${discount}`;

  return (
    <div className={styles.cardBottom}>
      {isDiscount && (
        <span className={styles.cardBottomSave}>
          <strong className={classes.textBold700}>
            {savings}
          </strong>
          {' '}
          {textBlock.fullText}
        </span>
      )}
      <GoLink title={link?.title} />
    </div>
  );
};

/* Main component */
const Card: FC<ICard> = ({
  link,
  router,
  offerId,
  currency,
  cardParts,
  offerPackage,
}) => {
  const {
    amountToCredit,
    amountToCharge,
    priceId,
    karmaPoints,
  } = offerPackage;
  const discount = amountToCredit - amountToCharge;
  const isDiscount = discount > 0;
  const { top, middle, bottom, main } = cardParts;
  const onClick = () => {
    if (!link) {
      return;
    }

    // @ts-ignore
    const parameters = new URLSearchParams({
      price_id: priceId,
      offer_id: offerId || '0',
      kr: karmaPoints,
      rate: amountToCharge,
    });
    parameters.forEach((value, key) => {
      if (!value) {
        parameters.delete(key);
      }
    });

    const checkoutInfo = {
      offerId,
      priceId,
      amountToCharge,
      amountToCredit,
      karmaRewardPoints: karmaPoints,
    };

    try {
      document.cookie = cookieParser.serialize(
        OFFER_CHECKOUT,
        JSON.stringify(checkoutInfo),
        { secure: process.env.NODE_ENV === NodeEnv.PROD, path: '/' },
      );
    } catch (e) {
      Logger.error(e);
    }

    const url = `${link.src}?${parameters.toString()}`;
    router.push(url);
  };

  if (main) {
    return (
      <DataButton
        link={link!}
        className={cn(styles.card, styles.main)}
        onClick={onClick}
      >
        <Main
          text={main}
          buttonTitle={link?.title}
          karmaPoints={karmaPoints}
          amountToCharge={amountToCharge}
        />
      </DataButton>
    );
  }

  return (
    <DataButton
      link={link!}
      className={styles.card}
      onClick={onClick}
    >
      <TopRow
        textBlock={top}
        isDiscount={isDiscount}
        amountToCredit={amountToCredit}
        karmaPoints={karmaPoints}
        currency={currency}
      />
      <MiddleRow
        textBlock={middle}
        amountToCharge={amountToCharge}
        currency={currency}
      />
      <BottomRow
        textBlock={bottom}
        isDiscount={isDiscount}
        discount={discount}
        currency={currency}
        link={link}
      />
    </DataButton>
  );
};

export default Card;
