export const enum PsychicApiToUse {
  FAVORITE_AND_CIRCLE = 'Favorite and Circle',
  VISITED = 'Visited',
  READ_BEFORE = 'Read Before',
  NEW_HIGH_PRICE = 'New High Price',
  COMMON = 'Common',
  READINGS = 'Readings',
}

export const enum ErrorMessage {
  NO_USER = 'no-user',
  NO_PSYCHICS = 'no-psychics'
}

export const apiNames = {
  [PsychicApiToUse.FAVORITE_AND_CIRCLE]: 'favoriteAndCircle',
  [PsychicApiToUse.NEW_HIGH_PRICE]: 'psychics',
  [PsychicApiToUse.READ_BEFORE]: 'customerReading',
  [PsychicApiToUse.VISITED]: 'visitPsychicBio',
};
