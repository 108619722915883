import {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { Psychic, Text } from 'src/__generated__/graphqlTypes';
import { getRightPsychicsProxy } from 'src/api/psychicApi';
import { usePsychicStatuses } from 'src/firebase/firebase.hook';
import { Store } from 'app-redux/types/storeTypes';
import { setFirebaseRequiredState } from 'app-redux/actions/appActions';
import type { SectionComponentGenericInterface } from 'types/componentTypes';
import { RightPsychic } from 'types/objectTypes';
import { updateWithDefaultSearchOptions } from 'lib/psychic.service';
import { TemplateContext, useTemplateReducer } from 'entities/JsxParserComponents';

import TemplateHtmlPsychics from './TemplateHtml';

import { divideBlocks, getSetFunction } from '../lib';

const TemplateHtmlPsychicsContainer: FC<SectionComponentGenericInterface<Text | Psychic>> = ({
  blocks,
  extraData,
  bgColor,
}) => {
  const pages = useSelector((store: Store) => store.server.page.pages);
  const slug = useSelector((store: Store) => store.server.page.slug);
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);

  const [psychics, setPsychics] = useState<Array<RightPsychic>>([]);
  const idList: Array<number> = psychics?.flatMap((psychic) => psychic.extId);
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const { loader } = pages[slug];
  const wasRequestSend = useRef<boolean>(false);
  const prevIsAuthenticated = useRef<boolean | null>(null);
  const [templateState, dispatchReducer] = useTemplateReducer();
  const { texts, psychicFrame } = divideBlocks(blocks);

  const dispatch = useDispatch();
  const {
    itemsAmount,
    psychicCategory,
    apiData,
  } = extraData || {};

  usePsychicStatuses(idList, setPsychics);

  const setPsychicsFromRemote = useCallback(
    getSetFunction({
      setLoadingState,
      setPsychics,
      extraData: { itemsAmount, psychicCategory },
      requestFirebase: () => dispatch(setFirebaseRequiredState(true)),
    }),
    [extraData],
  );

  useEffect(() => {
    if (psychics.length === 0 && wasRequestSend.current) {
      return;
    }

    wasRequestSend.current = true;

    if (psychics.length === 0) {
      setPsychicsFromRemote({ ...apiData, PageIndex: 0 });
    }

    const shouldPsychicsBeUpdated = psychics?.length > 0
      && isAuthenticated !== prevIsAuthenticated.current
      && prevIsAuthenticated.current !== null;

    if (shouldPsychicsBeUpdated) {
      const optionalApiData = apiData
        ? { ...apiData, PageIndex: templateState.currentPage }
        : undefined;
      setPsychicsFromRemote(optionalApiData);
    }

    prevIsAuthenticated.current = isAuthenticated;
  }, [isAuthenticated, psychics]);

  /**
* For now this working only with apiData
*/
  useEffect(() => {
    if (!apiData || templateState.currentPage === 0) {
      return;
    }

    (async () => {
      try {
        setLoadingState(true);
        const requestBody = {
          ...updateWithDefaultSearchOptions(apiData),
          PageIndex: templateState.currentPage,
          isFullData: true,
        };
        const { psychics } = await getRightPsychicsProxy(requestBody);

        setPsychics((prevPsychics) => [...prevPsychics, ...psychics]);
      } finally {
        setLoadingState(false);
      }
    })();
  }, [templateState.currentPage, apiData]);

  return (
    <TemplateContext.Provider value={templateState}>
      <TemplateHtmlPsychics
        psychics={psychics}
        isLoading={isLoading}
        texts={texts}
        loader={loader}
        extraData={extraData}
        psychicFrame={psychicFrame}
        dispatch={dispatchReducer}
        bgColor={bgColor}
      />
    </TemplateContext.Provider>

  );
};

export default TemplateHtmlPsychicsContainer;
