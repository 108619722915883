/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC } from 'react';
import ReactModal from 'react-modal';
import ReactPlayer from 'react-player';

import { Cross } from 'src/shared/ui/Cross';

import styles from './styles.module.scss';

import { VideoModalProps } from '../config/declarations';

const VideoModal: FC<VideoModalProps> = ({ isOpen, onClose, videoUrl }) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div onClick={(e) => e.stopPropagation()}>
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <button onClick={onClose} type="button" className={styles.buttonClose}>
        <Cross color="white" />
      </button>
      <ReactPlayer
        url={videoUrl}
        controls
        width="100%"
        height="100%"
        playing={isOpen}
      />
    </ReactModal>
  </div>
);

export default VideoModal;
