import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import type { Store } from 'app-redux/types/storeTypes';
import type { RightPsychic } from 'types/objectTypes';
import { usePsychicStatuses } from 'src/firebase/firebase.hook';
import { getSimilarPsychics } from 'src/api/psychicApi';
import { mapPsychicFromRemoteServer } from 'lib/serverMapper.service';

const psychicSelector = (store: Store) => store.server.page.data?.psychic;
/**
 * It use an array for psychic because {@link usePsychicStatuses}
 * does not support different way
 * */
export const usePsychic = () => {
  const serverPsychic: RightPsychic | undefined = useSelector(psychicSelector);
  const [psychics, setPsychics] = useState<Array<RightPsychic>>([serverPsychic || {} as any]);
  const idList = psychics
    .filter((psychic) => Boolean(psychic?.extId))
    .map((psychic) => Number(psychic!.extId));
  usePsychicStatuses(idList, setPsychics);

  return psychics[0] as RightPsychic | undefined;
};

export const useSimilarPsychics = (isPsychicNotOffline: boolean, psychic?: RightPsychic) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const key = (psychic?.extId && !isPsychicNotOffline) ? `similarpsychics/${psychic.extId}` : null;
  const fetcher = () => getSimilarPsychics(psychic!, user?.custId);
  const { data, isLoading } = useSWR(key, fetcher, {
    revalidateOnFocus: false,
    refreshInterval: 2 * 60 * 1000,
  });
  const [psychics, setPsychics] = useState<Array<RightPsychic>>([]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const psychics = data.results
      .map((psychic: RightPsychic) => mapPsychicFromRemoteServer(psychic, user));
    setPsychics(psychics as Array<RightPsychic>);
  }, [data, user]);

  const idList = psychics.map((psychic) => psychic.extId);
  usePsychicStatuses(idList, setPsychics);

  return { psychics, isLoading };
};
