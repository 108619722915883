/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import parse from 'html-react-parser';

import { parseJsonProperties } from 'lib/json.service';
import { SocialMediaSharingInterface } from 'components/SocialMediaSharing/declarations';

const SocialMediaSharing: React.FC<SocialMediaSharingInterface> = ({
  tag,
  dataProps,
  replacementValues,
}) => {
  const dataVariables = parseJsonProperties(dataProps, replacementValues);
  const element = tag ? parse(tag) : null;

  // @ts-ignore
  const elementProps = element?.props;

  if (elementProps) {
    const allProps = { ...elementProps, ...dataVariables };

    return <div {...allProps} />;
  }

  return (
    <div
      className="sharethis-inline-share-buttons"
      {...dataVariables}
    />
  );
};

export default SocialMediaSharing;
