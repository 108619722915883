import { FC, useState } from 'react';
import cn from 'classnames';

import type { Link, NavItem } from 'src/__generated__/graphqlTypes';
import { DataButton, DataLink } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';
import ForthLevelMenu from './4LMenu';

import type { IThirdLevelItem, IThirdLevelNavItem } from '../config/declarations';

/* Nested Component */
const ThirdLevelNavItem: FC<IThirdLevelNavItem> = ({ item }) => {
  const [isSubMenuVisible, setSubMenuVisibilityState] = useState<boolean>(false);
  const { headline, linksCollection } = item;
  const links = linksCollection?.items || [];
  const link = headline as Link;
  const toggleSubMenu = () => setSubMenuVisibilityState((prev) => !prev);

  return (
    <li className={styles.li}>
      <DataButton
        link={link}
        className={styles.liButton}
        onClick={toggleSubMenu}
      >
        {link.title}
        <i className={cn(
          styles.reveal,
          { [styles.revealClosed]: isSubMenuVisible },
        )}
        />
      </DataButton>
      <ForthLevelMenu
        isVisible={isSubMenuVisible}
        links={links as Array<Link>}
      />
    </li>
  );
};

/* Main component */
const ThirdLevelItem: FC<IThirdLevelItem> = ({ item }) => {
  if (!item) {
    return null;
  }

  if (item.__typename === 'NavItem') {
    return (
      <ThirdLevelNavItem item={item as NavItem} />
    );
  }

  if (item.__typename === 'Link') {
    const link = item as Link;

    return (
      <li className={styles.li}>
        <DataLink link={link} className={styles.liButton}>
          {link.title}
        </DataLink>
      </li>
    );
  }

  return null;
};

export default ThirdLevelItem;
