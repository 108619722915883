import { FC } from 'react';

import Arrow from './Arrow';
import styles from './Components.module.scss';

import type { IButtonSelect } from '../../config/declarations';

const ButtonSelect: FC<IButtonSelect> = ({
  isValuePresented,
  props,
  className,
  onClick,
  selectedItem,
  title,
}) => (
  <button
    type="button"
    className={className}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    onClick={onClick}
  >
    {(isValuePresented)
      ? (
        <span
          title={selectedItem!.text!}
          className={styles.formDropdownButtonText}
        >
          {selectedItem!.text}
        </span>
      )
      : (
        <span
          title={title}
          className={styles.formDropdownButtonPlaceholder}
        >
          {title}
        </span>
      )}
    <Arrow isButton={false} />
  </button>
);

export default ButtonSelect;
