/* eslint-disable eqeqeq */
/* eslint-disable no-underscore-dangle */
import { CSSProperties } from 'react';
import { UseComboboxGetInputPropsOptions, UseSelectGetToggleButtonPropsOptions } from 'downshift';
import cookie from 'js-cookie';

import { Position, RelationalOperator } from 'constants/enums';
import { Block, Link } from 'src/__generated__/graphqlTypes';
import { SortedBlocksType } from 'types/objectTypes';
import { contentfulSlugs } from 'constants/objects';
import { ZODIAC_SIGNS } from 'constants/constants';

export const getStyles = (link?: Link): CSSProperties => {
  if (!link) return {};

  const { backgroundColor: background, color, borderColor } = link;

  // @ts-ignore
  return ({ background, color, borderColor });
};

export const sortBlocksAndLinks = (blocks: Array<Block | Link>): SortedBlocksType => {
  const startObject: SortedBlocksType = { blocks: [], images: [] };

  if (!blocks) return startObject;

  return blocks
    .reduce((accumulator, item) => {
      if (item.__typename === 'Block') {
        accumulator.blocks.push(item as Block);
      }

      if (item.__typename === 'Link') {
        accumulator.images.push(item as Link);
      }

      return accumulator;
    }, startObject);
};

export const compareStatements = <T extends number | string>(
  leftStatement: T,
  rightStatement: T,
  relationOperator: RelationalOperator,
) => {
  switch (relationOperator) {
    case RelationalOperator.EQUAL: {
      return leftStatement === rightStatement;
    }
    case RelationalOperator.NOT_EQUAL: {
      return leftStatement !== rightStatement;
    }
    case RelationalOperator.GREATER_THEN: {
      return leftStatement > rightStatement;
    }
    case RelationalOperator.LESS_THEN: {
      return leftStatement < rightStatement;
    }
    case RelationalOperator.GREATER_OR_EQUAL: {
      return leftStatement >= rightStatement;
    }
    case RelationalOperator.LESS_OR_EQUAL: {
      return leftStatement <= rightStatement;
    }
    default: {
      return false;
    }
  }
};

export const compareStatementsUnsafe = <T extends number | string, K extends number | string>(
  leftStatement: T,
  rightStatement: K,
  relationOperator: RelationalOperator,
) => {
  switch (relationOperator) {
    case RelationalOperator.EQUAL_WITHOUT_TYPE: {
      // @ts-ignore
      return leftStatement == rightStatement;
    }
    case RelationalOperator.NOT_EQUAL_WITHOUT_TYPE: {
      // @ts-ignore
      return leftStatement != rightStatement;
    }
    default: {
      return false;
    }
  }
};

export const calculateSizeWithRatio = (
  ratio: number,
  sideSize: number,
) => Math.floor(sideSize * ratio);

export const isAppropriatViewportWidth = (width: string, relationOperator: RelationalOperator) => {
  if (!process.browser) {
    return false;
  }

  const numericWidth = +width;

  return numericWidth
    ? compareStatements(window.innerWidth, numericWidth, relationOperator)
    : false;
};

export const setItemInObjectBySlug = (
  slugMap: object = contentfulSlugs,
) => <T, K>(accumulator: T, item: K) => {
  // @ts-ignore
  const fieldName = slugMap[item.slug || ''];

  if (fieldName) {
    accumulator[fieldName] = item;
  }

  return accumulator;
};

export const getItemPositionForGa = <T, >(array: Array<T>, index: number) => {
  if (!array?.length) {
    return '';
  }

  const limit = array.length / 3;
  const humanReadableIndex = index + 1;

  if (limit >= humanReadableIndex) {
    return Position.LEFT;
  }

  if (limit * 2 >= humanReadableIndex) {
    return Position.MIDDLE;
  }

  return Position.RIGHT;
};

export const getZodiacSign = (slug: string) => ZODIAC_SIGNS
  .find((sign) => slug.includes(sign)) || ZODIAC_SIGNS[0];

export const getSelectItemId = (
  sysId: string,
  name: 'button' | 'ul' | 'li' | 'input',
): any => {
  const commonId = `${sysId}-${name}`;
  const response: UseSelectGetToggleButtonPropsOptions | UseComboboxGetInputPropsOptions = {
    id: commonId,
    'aria-labelledby': commonId,
  };

  return response;
};

export const getCookieOptions = () => {
  const expires = new Date();
  expires.setFullYear(expires.getFullYear() + 1);

  const isCPEnvironment = process.env.NODE_ENV === 'production'
    && window.location.hostname.includes(process.env.NEXT_PUBLIC_CP_DOMAIN!);
  const cookieOptions = {
    expires,
    path: '/',
    secure: process.env.NODE_ENV === 'production',
    domain: (isCPEnvironment)
      ? process.env.NEXT_PUBLIC_CP_DOMAIN
      : window.location.hostname,
  };

  return cookieOptions;
};

export const updateOrAddCookie = (key: string, value: object, cookieName: string) => {
  const existingDataStr = cookie.get(cookieName);
  const existingData = existingDataStr ? JSON.parse(decodeURI(cookie.get(cookieName) || '')) : {};
  existingData[key] = value;
  cookie.set(cookieName, JSON.stringify(existingData), getCookieOptions());
};

export const removeCookie = (cookieName: string) => {
  cookie.remove(cookieName);
};
