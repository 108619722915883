import { FC } from 'react';
import cn from 'classnames';

import style from 'components/Loader/Loader.module.scss';
import { WebpImage } from 'components/Shared/SharedComponents';
import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';
import { LoaderInterface } from 'components/Loader/declarations';

const Loader: FC<LoaderInterface> = ({
  image,
  withBackground,
  isVisible,
  isLocal = true,
}) => {
  const styleLoaderBackground = isVisible && withBackground
    ? style.loaderBackground
    : style.hidden;
  const mainLoaderClass = cn(
    style.loader,
    { [style.loaderGlobal]: !isLocal },
  );

  const styleLoader = isVisible ? mainLoaderClass : style.hidden;

  return (
    <>
      <div className={styleLoaderBackground} />
      <div className={styleLoader}>
        {image && (
          <WebpImage
            image={EXTERNAL_WEBP_IMAGE}
            alt={image.title!}
            src={image.url!}
            className={style.loaderRotate}
          />
        )}
      </div>
    </>
  );
};

export default Loader;
