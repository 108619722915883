import type { FC } from 'react';
import { useSelector } from 'react-redux';

import { PsychicCtaButtons, useBootChatSolution } from 'entities/PsychicCtaButton';
import { getPsychicCtaButtons } from 'lib/psychic.service';
import { Store } from 'app-redux/types/storeTypes';

import { CtaButtonsWrapperInterface } from '../config/declarations';

const CtaButtons: FC<CtaButtonsWrapperInterface> = ({
  psychicFrame,
  psychic,
  shape,
  className,
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const bootStatus = useBootChatSolution();

  const cta = getPsychicCtaButtons(psychic, psychicFrame, { hashedId: user?.hashedId });

  return (
    <PsychicCtaButtons
      shape={shape}
      bootStatus={bootStatus}
      className={className}
      chatButton={cta.chatButton}
      talkButton={cta.talkButton}
      inQueueButton={cta.inQueueButton}
      callbackButton={cta.callbackButton}
      messageButton={cta.messageButton}
      psychicFrame={psychicFrame}
      psychic={psychic}
    />
  );
};

export default CtaButtons;
