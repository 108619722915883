import type { Block } from 'src/__generated__/graphqlTypes';

import type { Blocks } from '../config/declarations';

export const getBlocks = (blocks: Array<Block>) => blocks.reduce((store, item) => {
  if (item.__typename !== 'Block') {
    return store;
  }

  return { ...store, [item.slug || '']: item };
}, {} as Blocks);
