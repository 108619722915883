/* eslint-disable no-param-reassign */
import { Asset } from 'src/__generated__/graphqlTypes';
import {
  HeightAndWidth,
  WebpLimitedImagesType,
  WebpWidthLimitType,
} from 'types/objectTypes';

export const getImagesWithLimitedSize = (
  imagesProps: Array<WebpLimitedImagesType>,
  sharedProps: WebpLimitedImagesType,
): Array<WebpWidthLimitType> => imagesProps.map((imageProps) => {
  const sharedPropsEntries = Object.entries(sharedProps);
  const { squareSide } = imageProps;

  if (squareSide) {
    imageProps.width = squareSide;
    imageProps.height = squareSide;
  }

  const imageWithSharedProps = sharedPropsEntries.reduce((
    currentImageProps,
    [sharedPropsKey, sharedPropsValue],
  ) => {
    const existingValue = imageProps[sharedPropsKey];

    if (!existingValue) {
      currentImageProps[sharedPropsKey] = sharedPropsValue;
    }

    return currentImageProps;
  }, imageProps as WebpWidthLimitType);

  return imageWithSharedProps;
});

export class SidesCalculator {
  constructor(
    private image: Asset,
    private multiplier: (val?: number, multiplier?: number) => number,
  ) {
    this.multiplier = multiplier;
    this.image = image;
  }

  calculate(multiplier?: number): HeightAndWidth<number> {
    const { height, width } = this.image;
    const iconHeight = this.multiplier(height!, multiplier);
    const iconWidth = this.multiplier(width!, multiplier);

    return { height: iconHeight, width: iconWidth };
  }
}
