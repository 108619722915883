/* eslint-disable no-param-reassign */
import type { Block, Link } from 'src/__generated__/graphqlTypes';

import type { Blocks, GreetingsLinks } from '../config/declarations';

export const getBlocks = (blocks: Array<Block>) => blocks.reduce((store, item) => {
  if (item.__typename === 'Block' && (item.slug === 'greeting' || item.slug === 'offers')) {
    store[item.slug] = item;
  }

  return store;
}, {} as Blocks);

export const getGreetingLinks = (links?: Array<Link>) => (links?.reduce((store, item) => {
  if (item.__typename !== 'Link') {
    return store;
  }

  if (item.slug === 'balance' || item.slug === 'collapse') {
    store[item.slug] = item;
  }

  if (item.slug === 'rewards') {
    store.karmaHub = item;
  }

  if (item.slug === 'karma-rewards') {
    store.joinKarmaRewords = item;
  }

  return store;
}, {} as any) || {}) as GreetingsLinks;
