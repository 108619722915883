import { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Store } from 'app-redux/types/storeTypes';
import { useSidebarScreenSpace } from 'src/shared/lib/sidebar/hooks';
import { useCustomRouter } from 'src/shared/lib/history/hooks';
import { AppropriateSection } from 'components/Sections';

import styles from './Sidebar.module.scss';

const Sidebar: FC = () => {
  const router = useCustomRouter();
  const slug = useSelector((store: Store) => store.server.page.slug);
  const page = useSelector((store: Store) => store.server.page.pages[slug]);
  const { sidebar } = page;
  const space = useSidebarScreenSpace(sidebar);

  if (!sidebar || !sidebar.contentCollection?.items?.length) {
    return null;
  }

  if (!space) {
    return (
      <>
        {
          sidebar.contentCollection.items
            .map((section) => (
              <AppropriateSection
                key={section?.entryName}
                section={section!}
              />
            ))
        }
      </>
    );
  }

  const pageStyle = router.pathname
    .replace(/\/\[/g, '-')
    .replace(/]/g, '')
    .substring(1, router.pathname.length);

  return (
    <>
      <aside className={cn(styles.sidebar, styles[pageStyle])}>
        {
          sidebar.contentCollection.items
            .map((section) => (
              <AppropriateSection
                key={section?.entryName}
                section={section!}
              />
            ))
        }
      </aside>
    </>
  );
};

export default Sidebar;
