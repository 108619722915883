import React, { FC } from 'react';

import { PsychicCardAppearance, PsychicPhotoModule } from 'constants/enums';
import { PsychicCardSimple } from 'features/sections/PsychicCardSimple';
import { PsychicCardSimpleVertical } from 'features/sections/PsychicCardSimpleVertical';
import { PsychicCardSimpleHorizontal } from 'features/sections/PsychicCardSimpleHorizontal';
import { PsychicCardTile } from 'features/sections/PsychicCardTile';
import { PsychicCardList } from 'features/sections/PsychicCardList';

import type { IPsychicCard } from '../config/declarations';

const PsychicCard: FC<IPsychicCard> = ({
  user,
  psychic,
  psychicFrame,
  positionInArray,
  bootStatus,
  extraData,
}) => {
  const {
    psychicCardAppearance = PsychicCardAppearance.SIMPLE_VERTICAL,
    psychicPhotoModule = PsychicPhotoModule.WITH_GRADIENT,
  } = extraData;
  const commonParameters = {
    user,
    psychic,
    psychicSkeleton: psychicFrame,
    positionInArray,
  };

  if (psychicCardAppearance === PsychicCardAppearance.SIMPLE_WITH_DESCRIPTION
    || psychicCardAppearance === PsychicCardAppearance.SIMPLE_ADDITIONAL
    || psychicCardAppearance === PsychicCardAppearance.SIMPLE) {
    return (
      <PsychicCardSimple
        {...commonParameters}
        view={psychicCardAppearance}
      />
    );
  }

  if (psychicCardAppearance === PsychicCardAppearance.SIMPLE_VERTICAL) {
    // eslint-disable-next-line prefer-destructuring, no-param-reassign
    psychic.psychicImageUrl = psychic.images?.[2];

    return (
      <PsychicCardSimpleVertical
        view={psychicPhotoModule}
        bootStatus={bootStatus}
        {...commonParameters}
      />
    );
  }

  if (psychicCardAppearance === PsychicCardAppearance.SIMPLE_HORIZONTAL) {
    // eslint-disable-next-line prefer-destructuring, no-param-reassign
    psychic.psychicImageUrl = psychic.images?.[2];

    return (
      <PsychicCardSimpleHorizontal
        bootStatus={bootStatus}
        {...commonParameters}
      />
    );
  }

  if (psychicCardAppearance === PsychicCardAppearance.TILE) {
    return (
      <PsychicCardTile
        bootStatus={bootStatus}
        {...commonParameters}
      />
    );
  }

  if (psychicCardAppearance === PsychicCardAppearance.LIST) {
    return (
      <PsychicCardList
        bootStatus={bootStatus}
        {...commonParameters}
      />
    );
  }

  return null;
};

export default PsychicCard;
