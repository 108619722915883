import { useEffect, useState } from 'react';

import { getHoroscopeProxy } from 'src/api/horoscopeApi';
import { HoroscopeType } from 'constants/enums';
import type { UserType } from 'types/objectTypes';

export const useUserHoroscope = (user: UserType | null) => {
  const [horoscope, setHoroscope] = useState<string | null>(null);

  useEffect(() => {
    const shouldBeShown = user && Object.keys(user).length > 0;

    if (!shouldBeShown) {
      return;
    }

    if (user && !user.userSign) {
      return setHoroscope('-');
    }

    getHoroscopeProxy({
      sign: user!.userSign.toLowerCase(),
      date: new Date().toDateString(),
      type: HoroscopeType.DAILY_TODAY,
      customerId: user!.customerId,
    })
      .then((data) => setHoroscope(data))
      .catch(() => setHoroscope('-'));
  }, [user]);

  return horoscope;
};
