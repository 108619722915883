import { FC, useContext } from 'react';

import { DataLink } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';

import type { ICtaButtons } from '../config/declarations';
import { IdProvider } from '../service/context';
import { constructId } from '../service';
import { useErrorHandler } from '../service/hooks';

const PsychicCtaButtons: FC<ICtaButtons> = ({ primary, secondary }) => {
  const id = useContext(IdProvider);
  const state = useErrorHandler();

  if (!primary && !secondary) {
    return null;
  }

  return (
    <div
      id={constructId(id, '-buttons')}
      className={styles.buttons}
    >
      {state.buttonToHide !== 'primary' && primary && (
        <DataLink
          link={primary}
          className={styles.button}
        >
          {primary.title}
        </DataLink>
      )}
      {state.buttonToHide !== 'secondary' && secondary && (
        <DataLink
          link={secondary}
          className={styles.button}
        >
          {secondary.title}
        </DataLink>
      )}
    </div>
  );
};

export default PsychicCtaButtons;
