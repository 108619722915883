import React from 'react';

import styles from 'components/Sections/HoroscopesSignsAllAboutSigns/CareersAndCelebrity/CareersAndCelebrity.module.scss';
import { SignFeatureContainer } from 'components/Sections/HoroscopesSignsAllAboutSigns/Shared';
import { Block } from 'src/__generated__/graphqlTypes';
import { CareersAndCelebrityInterface, CareersAndCelebritySlug as Slug } from 'components/Sections/HoroscopesSignsAllAboutSigns/CareersAndCelebrity/declarations';

const CareersAndCelebrity: React.FC<CareersAndCelebrityInterface> = ({
  celebrity,
  careers,
}) => {
  const applyCelebritiesOrCareers = (
    classPrefix: keyof CareersAndCelebrityInterface,
    element?: Block,
  ) => {
    if (!element) {
      <SignFeatureContainer containerClassName={styles[classPrefix]} />;
    }

    const { title, textList } = element!;

    return (
      <SignFeatureContainer
        containerClassName={styles[classPrefix]}
        title={title || ''}
        titleClassName={styles[`${classPrefix}Title`]}
        underlineClassName={styles[`${classPrefix}Underline`]}
      >
        {textList && (
          <ul className={styles[`${classPrefix}Content`]}>
            {textList.map((item) => (
              <li
                key={item}
                className={styles[`${classPrefix}ContentRecord`]}
              >
                {item}
              </li>
            ))}
          </ul>
        )}
      </SignFeatureContainer>
    );
  };

  const applyCelebrity = () => applyCelebritiesOrCareers(
    Slug.CELEBRITY,
    celebrity,
  );
  const applyCarres = () => applyCelebritiesOrCareers(
    Slug.CARRERS,
    careers,
  );

  return (
    <div className={styles.wrapper}>
      {applyCarres()}
      {applyCelebrity()}
    </div>
  );
};

export default CareersAndCelebrity;
