import { FC } from 'react';
import { useSelector } from 'react-redux';

import { RulingPlanetAndSignElement } from 'components/Sections/HoroscopesSignsAllAboutSigns/RulingPlanetAndSignElement';
import { Store } from 'app-redux/types/storeTypes';
import { RulingPlanetAndSignElementContainerInterface } from 'components/Sections/HoroscopesSignsAllAboutSigns/RulingPlanetAndSignElement/declarations';

const RulingPlanetAndSignElementContainer: FC<RulingPlanetAndSignElementContainerInterface> = ({
  rulingPlanet,
  signElement,
}) => {
  const {
    mobileViewMaxWidth,
    horoscopeTabletMaxWidth,
  } = useSelector<Store, any>((store) => store.server.app.scssVariables);

  return (
    <RulingPlanetAndSignElement
      signElement={signElement}
      rulingPlanet={rulingPlanet}
      mobileViewMaxWidth={mobileViewMaxWidth}
      horoscopeTabletMaxWidth={horoscopeTabletMaxWidth}
    />
  );
};

export default RulingPlanetAndSignElementContainer;
