import { createContext } from 'react';

import type { Block } from 'src/__generated__/graphqlTypes';

import type { ErrorHandlerContextDispatch, ErrorHandlerContextValue } from '../config/declarations';

export const IdProvider = createContext<Block['blockId']>(null);

export const errorHandlerContext: ErrorHandlerContextValue = {
  shouldHideSection: false,
};

export const ErrorHandler = createContext<ErrorHandlerContextValue>(errorHandlerContext);

export const ErrorHandlerDispatch = createContext<ErrorHandlerContextDispatch>(() => {});
