import { FC } from 'react';
import cn from 'classnames';

import { PsychicCardImage } from 'entities/PsychicCardImage';
import { PsychisReadingType } from 'constants/enums';
import { StatusIndicatorWrapper as StatusIndicator } from 'entities/JsxParserComponents';

import styles from './styles.module.scss';

import type { IPsychicReadingsPhotoModule } from '../config/declarations';

/* Nested Component */
const CallbackReadingDetail = ({ psychic, psychicSkeleton }) => {
  const { detailsButton } = psychicSkeleton;

  return (
    <div className={styles.details}>
      <p>
        {detailsButton.slug}
        {psychic.estimatedWaitTime}
      </p>
    </div>
  );
};

/* Nested Component */
const ReadingDetails = ({ psychic, psychicSkeleton }) => {
  const { type } = psychic;
  const isCallBack = type === PsychisReadingType.CALLBACK;
  const isAppointment = type === PsychisReadingType.APPOINTMENT;
  const isUpcoming = type === PsychisReadingType.UPCOMING;
  const showColon = isCallBack || isAppointment || isUpcoming;

  if (psychic.considerCtaButtons) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.textPlaceHolder1}>
        <p className={styles.type}>{showColon ? `${type}:` : type}</p>
      </div>
      {isCallBack && (
        <CallbackReadingDetail
          psychic={psychic}
          psychicSkeleton={psychicSkeleton}
        />
      )}
      {(isAppointment || isUpcoming) && (
        <p>{psychic.date}</p>
      )}
    </div>
  );
};

/* Main component */
const PhotoWithReadingDetails: FC<IPsychicReadingsPhotoModule> = ({
  src,
  psychic,
  imageClassName,
  psychicSkeleton,
}) => (
  <div className={styles.photoWithReadings}>
    <PsychicCardImage
      psychic={psychicSkeleton}
      image="external"
      src={src}
      alt={psychic.psychicName}
      className={cn(styles.image, imageClassName)}
    />
    <div className={styles.psychicStatusWrapper}>
      <StatusIndicator
        chatStatus={psychic.chatStatus}
        phoneStatus={psychic.phoneStatus}
        className={styles.statusIndicator}
      />
      <p className={styles.name}>{psychic.psychicName}</p>
    </div>
    <ReadingDetails
      psychic={psychic}
      psychicSkeleton={psychicSkeleton}
    />
  </div>
);

export default PhotoWithReadingDetails;
