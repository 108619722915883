import React from 'react';
import { useRouter } from 'next/router';

import styles from 'components/Header/MiddleBar/MainLogo/MiddleBarMainLogo.module.scss';
import {
  DataButton,
  DataLink,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { MiddleBarMainLogoInterface } from 'components/Header/MiddleBar/MainLogo/declarations';
import { SVG_TYPE } from 'constants/constants';

const checkIsHomepage = (path: string) => {
  const pathParts = path.split('/');
  const firstPart = pathParts[1]?.toLowerCase();
  const isHomepage = !firstPart || firstPart.includes('homepage') || firstPart === '#';

  return isHomepage;
};

const MiddleBarMainLogo: React.FC<MiddleBarMainLogoInterface> = ({ mainLogo, isMobileView }) => {
  const { src, image } = mainLogo;
  const router = useRouter();

  if (!image) {
    return null;
  }

  const isSvg = image.contentType === SVG_TYPE;
  const additionalProps = isMobileView
    ? { w: 170, h: 26 }
    : undefined;
  const altText = 'Main logo';
  const imageTitle = image.title || altText;
  const mobileClassPrefix = isMobileView ? 'Mobile' : '';
  const imageComponent = (
    <WebpImage
      image={isSvg ? 'external' : image}
      src={isSvg ? image.url! : undefined}
      width="auto"
      height="70px"
      additionalProps={additionalProps}
      title={imageTitle}
      className={styles[`mainLogo${mobileClassPrefix}Img`]}
    />
  );

  if (checkIsHomepage(router.asPath)) {
    return (
      <DataButton
        link={mainLogo}
        className={styles[`mainLogo${mobileClassPrefix}`]}
      >
        {imageComponent}
      </DataButton>
    );
  }

  return (
    <DataLink
      link={mainLogo}
      href={src!}
      className={styles[`mainLogo${mobileClassPrefix}`]}
    >
      {imageComponent}
    </DataLink>
  );
};

export default MiddleBarMainLogo;
