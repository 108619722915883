import { FC } from 'react';
import {
  BLOCKS,
  MARKS,
  INLINES,
} from '@contentful/rich-text-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import type { Store } from 'app-redux/types/storeTypes';
import { CommonRichText } from 'components/Shared/SharedComponents';
import classes from 'styles/_commonClasses.module.scss';
import { CTMNumber } from 'entities/CTMPhone';
import type { RichTextParsersConfig } from 'types/objectTypes';

import styles from './CalendarModal.module.scss';

import type { IAppointmentModalContent } from '../../config/declarations';

const getRichTextConfig = (): RichTextParsersConfig => ({
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.paragraph,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
  [INLINES.HYPERLINK]: {
    classNames: cn(styles.alertText, styles.alertLink),
  },
});

const AppointmentModalContent: FC<IAppointmentModalContent> = ({ block, onClick }) => {
  const ctmNumber = useSelector((store: Store) => store.client.app.ctmNumber);

  if (!block) {
    return null;
  }

  return (
    <>
      <h3 className={styles.title}>{block.title}</h3>
      <CommonRichText content={block.content} parsersConfig={getRichTextConfig()} />
      <CTMNumber
        number={ctmNumber}
        className={styles.number}
      />
      <button
        type="button"
        onClick={onClick}
        className={styles.button}
      >
        OK
      </button>
    </>
  );
};

export default AppointmentModalContent;
