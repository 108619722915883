import React, { FC } from 'react';

import type { Link } from 'src/__generated__/graphqlTypes';
import { DataLink, WebpImage } from 'components/Shared/SharedComponents';

import styles from './FlexColumns.module.scss';

import type { IChild } from '../config/declarations';

const Links: FC<IChild> = ({ element }) => {
  const links = element.contentTypesCollection!.items.filter((link) => link?.__typename === 'Link') as Array<Link>;

  if (!links?.length) {
    return null;
  }

  return (
    <div key={element.sys.id} className={styles.links}>
      {links.map((link) => (
        <DataLink
          key={link.entryName}
          link={link}
          className={styles.linksItem}
        >
          <WebpImage
            widthLimit={[{ width: 130, height: 40 }]}
            disableDefaultSource
            image={link.image!}
          />
        </DataLink>
      ))}
    </div>
  );
};

export default Links;
