import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Head from 'next/head';
import cookie from 'js-cookie';

import { Store } from 'app-redux/types/storeTypes';
import { setCtmNumber } from 'app-redux/actions/appActions';
import { AgentChat } from 'components/AgentChat';
import { AgentChatIframe, checkShouldPageHaveAgentChat } from 'components/AgentChat/service';
import { CHAT_AGENT_PERSISTANCE_COOKIE, MAS_PHONE_NUMBER } from 'constants/constants';

const accountId = process.env.NEXT_PUBLIC_CTM_CHAT_ACCOUNT_ID;
const chatId = process.env.NEXT_PUBLIC_CTM_CHAT_ID;

const AgentChatContainer = () => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const slug = useSelector((store: Store) => store.server.page.slug);
  const [isCtmChatLoadingEnabled, setCtmChatLoadingState] = useState<boolean>(false);
  const [formreactorUrl, setFormreactorUrl] = useState<string>('');
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (iframeRef) {
      AgentChatIframe.setIframe(iframeRef.current);
    }
  }, [iframeRef, iframeRef.current]);

  useEffect(() => {
    if (!isCtmChatLoadingEnabled && accountId) {
      setFormreactorUrl(`https://${accountId}.tctm.co/formreactor.js`);
    }
  }, [accountId, isCtmChatLoadingEnabled]);

  const buildUrl = useCallback(() => {
    const baseUrl = `https://${accountId}.tctm.co/form/${chatId}.html`;

    if (!user) {
      return baseUrl;
    }

    const {
      customerPhoneNumber,
      countryCallingCode,
      userEmail,
      displayNameHeader,
    } = user;

    const isSomeParameterMissing = !customerPhoneNumber
      && !countryCallingCode
      && !userEmail
      && !displayNameHeader;

    if (isSomeParameterMissing) {
      return baseUrl;
    }

    const query = new URLSearchParams();

    if (displayNameHeader) {
      query.append('caller_name', displayNameHeader);
    }

    if (customerPhoneNumber && countryCallingCode) {
      query.append('phone_number', customerPhoneNumber);
      query.append('country_code', `${countryCallingCode}`);
    }

    if (userEmail) {
      query.append('email', userEmail);
    }

    query.append('hide_when_set', 'caller_name,email,phone_number');

    return `${baseUrl}?${query.toString()}`;
  }, [user]);

  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    const lastBeforeUnload = window.onbeforeunload;
    window.CTMSourceEvent = (event) => {
      const phoneNumber = cookie.get(MAS_PHONE_NUMBER);

      if (event.state !== 'after' || !event.new_number) {
        return;
      }

      if (phoneNumber) {
        dispatch(setCtmNumber(phoneNumber));

        return;
      }

      const expires = new Date();
      expires.setFullYear(expires.getFullYear() + 1);

      const isCPEnvironment = process.env.NODE_ENV === 'production'
        && window.location.hostname.includes(process.env.NEXT_PUBLIC_CP_DOMAIN!);
      dispatch(setCtmNumber(event.new_number));
      cookie.set(MAS_PHONE_NUMBER, event.new_number, {
        expires,
        path: '/',
        secure: true,
        domain: (isCPEnvironment)
          ? process.env.NEXT_PUBLIC_CP_DOMAIN
          : window.location.hostname,
      });
    };

    (window as Window).onbeforeunload = () => {
      const persistenceCookie = cookie.get(CHAT_AGENT_PERSISTANCE_COOKIE);

      if (persistenceCookie && !AgentChatIframe.isOpened()) {
        const currentDate = new Date();
        cookie.set(CHAT_AGENT_PERSISTANCE_COOKIE, 'false', { expires: currentDate, path: '/' });
      }
    };

    return () => { window.onbeforeunload = lastBeforeUnload; };
  }, []);

  useEffect(() => {
    const persistenceCookie = cookie.get(CHAT_AGENT_PERSISTANCE_COOKIE);
    const isAllowedPageOrNotMobile = !isCtmChatLoadingEnabled
      && (checkShouldPageHaveAgentChat(slug)
        || AgentChatIframe.isOpened()
        || persistenceCookie);

    if (isAllowedPageOrNotMobile) {
      const MINUTES_AMOUNT = 10;
      const currentDate = new Date();
      const expirationDate = new Date(currentDate
        .setMinutes(currentDate.getMinutes() + MINUTES_AMOUNT));
      cookie.set(CHAT_AGENT_PERSISTANCE_COOKIE, 'true', { expires: expirationDate, path: '/' });
      setUrl(buildUrl());
      setCtmChatLoadingState(true);
    }
  }, [isCtmChatLoadingEnabled, slug, buildUrl]);

  useEffect(() => {
    const persistenceCookie = cookie.get(CHAT_AGENT_PERSISTANCE_COOKIE);
    const isRemoveChatRequired = !checkShouldPageHaveAgentChat(slug)
      && ((!AgentChatIframe.isOpened() && AgentChatIframe.isSet()) || persistenceCookie);

    if (isRemoveChatRequired) {
      setUrl('');
      setCtmChatLoadingState(false);
      const currentDate = new Date();
      cookie.set(CHAT_AGENT_PERSISTANCE_COOKIE, 'false', { expires: currentDate, path: '/' });
    }
  }, [slug]);

  const isNoEssentialDataPresented = !accountId || !chatId;
  const isEarlyToLoadIframe = !isCtmChatLoadingEnabled || !url;

  if (isNoEssentialDataPresented || isEarlyToLoadIframe) {
    return null;
  }

  return (
    <>
      <Head>
        {formreactorUrl && <script defer src={formreactorUrl} />}
      </Head>
      <AgentChat ref={iframeRef} url={url!} />
    </>
  );
};

export default AgentChatContainer;
