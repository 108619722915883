import { FC } from 'react';

import THead from './THead';
import styles from './PlainCalendar.module.scss';
import TBody from './TBody';

import type { IPlainCalendar } from '../config/declarations';

const PlainCalendar: FC<IPlainCalendar> = ({
  weekdays,
  selected,
  datesHandlers,
  getState,
  onChange,
}) => (
  <table className={styles.cal}>
    <THead weekdays={weekdays} />
    <TBody
      forcedSelected={selected}
      getState={getState}
      onChange={onChange}
      datesHandlers={datesHandlers}
    />
  </table>
);

export default PlainCalendar;
