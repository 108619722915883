import { FC } from 'react';
import cn from 'classnames';

import { FullBackgroundImage } from 'src/shared/ui/FullBackgroundImage';
import { DataLink } from 'components/Shared/SharedComponents';
import { ImageWrapper } from 'src/__generated__/graphqlTypes';

import styles from './styles.module.scss';

import type { IBanner, IButton } from '../config/declarations';

/* Nested component */
const Button: FC<IButton> = ({ link }) => {
  if (!link?.src) {
    return null;
  }

  return (
    <DataLink
      link={link}
      className={styles.bannerLink}
    >
      {link.title}
    </DataLink>
  );
};

/* Main Component */
const Banner: FC<IBanner> = ({ link, title, className, images }) => (
  <div className={cn(styles.banner, className)}>
    <div className={styles.bannerWrapper}>
      <span className={styles.bannerText}>{title}</span>
      <Button link={link} />
    </div>
    <FullBackgroundImage
      skipTablet
      backgrounds={images?.items as Array<ImageWrapper>}
      className={styles.bannerBackground}
    />
  </div>
);

export default Banner;
