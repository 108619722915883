export enum Status {
  CONNECTED = 'connected',
  CONNECTING = 'connecting',
  AVAILABLE = 'available',
  ENABLED = 'enabled',
  BUSY = 'busy',
  ON_BREAK = 'OnBreak',
  RESERVED = 'Reserved',
  OFFLINE = 'offline',
  EXPIRED = 'expired',
  COMPLETED = 'completed',
  STARTED = 'started',
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  ASSIGNED = 'assigned',
  INSUFFICIENT_FUNDS = 'insufficientFunds',
  PREPARING = 'preparing',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export enum ChatRequest {
  CHAT_REQUEST_CREATED = 'chatRequestCreated',
  CHAT_REQUEST_ACCEPTED = 'chatRequestAccepted',
  CHAT_REQUEST_REJECTED = 'chatRequestRejected',
  CHAT_REQUEST_EXPIRED = 'chatRequestExpired',
}

export enum ButtonColor {
  DANGER = 'danger',
  WARNING = 'warning',
  LIGHT_OUTLINE = 'lightOutline',
  WARNING_OUTLINE = 'warningOutline',
  WHITE_OUTLINE = 'whiteOutline',
  DISABLE = 'disable',
  SYSTEM = 'system',
  ORANGE = '#F78633',
}

export enum ButtonSize {
  E = 'extreme',
  M = 'medium',
  L = 'large',
  S = 'small',
  XL = 'extraLarge',
}

export enum ButtonShape {
  ROUNDED = 'rounded',
  RECTANGULAR = 'rectangular',
}

export enum ConversationType {
  DIRECT_MESSAGE = 'DirectMessage',
  LIVE_CHAT = 'RealTime',
}

export enum Message {
  CLIENT = 'client',
  PSYCHIC = 'psychic',
  TIMESTAMP = 'timestamp',
  SYSTEM = 'system',
}

export enum NotificationType {
  PSYCHIC_JOINED = 'psychic-joined',
  CUSTOMER_JOINED = 'customer-joined',
  BILLING_STARTED = 'billing-started',
  PSYCHIC_FINISHED_CHAT = 'finished-by-psychic',
  CUSTOMER_FINISHED_CHAT = 'finished-by-customer',
  INACTIVE_CHAT_ENDED = 'inactivity',
  LOW_FUNDS_CHAT_ENDED = 'low-funds',
  CHAT_STARTED = 'chat-started',
  CHAT_ENDED = 'chat-ended',
  CHAT_ENDED_MESSAGE = 'chatEndedMessage',
  CHAT_COMPLETED = 'chat-completed',
  CHAT_ENDED_LOW_FUNDS = 'low-funds',
  CHAT_PAYMENT_REQUIRED = 'chatPaymentRequired',
  CHAT_ENDED_INSUFFICIENT_FUNDS = 'insufficient-funds',
  CHAT_FUNDS_ADDED = 'chatFundsAdded',
  ADD_FUNDS_REQUEST = 'add-funds-request',
  CUSTOMER_CANCEL_OM = 'customer-canceled-dm',
  ANSWERED_TO_DM = 'psychic-answered-to-dm',
}

export enum ChatCompletedReason {
  INACTIVITY = 'inactivity',
  CUSTOMER_FINISHED_CHAT = 'finished-by-customer',
  PSYCHIC_FINISHED_CHAT = 'finished-by-psychic',
  SWITCH_TO_PHONE = 'switch-to-phone',
  LOW_FUNDS = 'low-funds',
  DM_EXPIRED = 'dm-expired',
  UPCOMING_APPOINTMENT = 'upcoming-appointment',
  CHAT_TASK_CANCELLED_LOW_FUNDS = 'chatTaskCancelledLowFunds'
}

export enum PopUpNotificationType {
  INACTIVE_CHAT_ENDED = 'inactivity',
  LOW_FUNDS_CHAT_ENDED = 'low-funds',
  PSYCHIC_FINISHED_CHAT = 'finished-by-psychic',
  CUSTOMER_FINISHED_CHAT = 'finished-by-customer',
  END_CHAT_REQUEST = 'end-chat-request',
  FUNDS_RUN_OUT = 'run-out-of-funds',
  CALL_DESKTOP = 'call-desktop',
  CALL_MOBILE = 'call-mobile',
  SWITCH_TO_PHONE = 'switch-to-phone',
  DUE_TO_INACTIVITY = 'due-to-inactivity',
  LACK_OF_CHARACTERS = 'lack-of-characters',
  SEND_MESSAGE_CONFIRMATION = 'send-message-confirm',
  NOT_AVAILABLE_PSYCHIC = 'not-available-psychic',
  NOT_HAVE_ENOUGH_FUNDS = 'not-have-enough-funds',
  INSUFFICIENT_FUNDS = 'insufficient-funds',
  PAYMENT_FAILED = 'payment-failed',
  PSYCHIC_MISS_CHAT = 'psychic-miss-chat',
  CUSTOMER_CANCEL_OM = 'customer-cancel-om',
  EXPIRED_TOKEN = 'expired-token',
  REQUEST_ERROR = 'request-error',
  BEFORE_APPOINTMENT = 'beforeAppointment',
  DUE_TO_APPOINTMENT = 'dueToAppointment',
  NOT_ENABLED_FOR_CHAT = 'notEnabledForChat',
  TAKE_A_BREAK = 'takeBreak',
  LOGOUT = 'logout',
  PSYCHIC_EARLY_LOGOUT = 'psychicEarlyLogout',
  CHAT_TASK_CANCELLED_LOW_FUNDS = 'chatTaskCancelledLowFunds'
}

export enum ChannelEvents {
  CHANNEL_JOINED = 'channelJoined',
  CHANNEL_LEFT = 'channelLeft',
  MEMBER_LEFT = 'memberLeft',
  MESSAGE_ADDED = 'messageAdded',
  MESSAGE_UPDATED = 'messageUpdated',
  DISCONNECT = 'disconnect',
  TYPING_STARTED = 'typingStarted',
  TYPING_ENDED = 'typingEnded',
}

export enum View {
  CUSTOMER = 'Customer',
  PSYCHIC = 'Psychic',
}

export enum SdkEvents {
  BOOT = 'boot',
  ON_SEND_MESSAGE = 'onSendMessage',
  GET_MESSAGES = 'getMessages',
  SHOW = 'show',
  HIDE = 'hide',
  ON_SHOW = 'onShow',
  ON_HIDE = 'onHide',
  SEND_MESSAGE = 'sendMessage',
  ON_CHAT_EVENT = 'onChatEvent',
  SET_PHANTOM = 'setPhantom',
  CLEAR_SEND_MESSAGE = 'clearSendMessage',
  ON_PSYCHICS_PRESENCE = 'onPsychicPresenceChange',
  GET_PSYCHICS_PRESENCE = 'getPsychicPresence',
  SET_SESSION = 'setSession',
  SET_CONVERSATION = 'setConversation',
  GET_CONVERSATION = 'getConversation',
  ON_UNREAD_COUNT_CHANGE = 'onUnreadCountChange',
  ON_NOTIFICATION_RECEIVED = 'onNotificationReceived',
  ON_MESSAGE_RECEIVED = 'onMessageReceived',
  ON_OFFLINE_MESSAGE_RECEIVED = 'onOfflineMessageReceived',
  ON_OFFLINE_MESSAGE_UPDATED = 'onOfflineMessageUpdated',
  ON_CONVERSATION_CHANGE = 'onConversationChange',
  ON_CONNECTIONS_STATUS_CHANGE = 'onConnectionsStatusChange',
  HIDE_LAUNCHER = 'hideLauncher',
  SHOW_LAUNCHER = 'showLauncher',
  RESET = 'reset',
  ON_TOKEN_EXPIRED = 'onTokenExpired',
  LOGOUT = 'logout',
  ON_LOGOUT = 'onLogout',
  SHUT_DOWN = 'shutDown',
  ACCEPT_CHAT = 'acceptChat',
  IGNORE_CHAT_CONFIRM = 'ignoreChatConfirm',
  ON_RECEIVE_CHAT = 'onReceiveChat',
  ON_END_CHAT = 'onEndChat',
  ON_IGNORE_CHAT = 'onIgnoreChat',
  ON_ACCEPT_CHAT = 'onAcceptChat',
  RUN_DIAGNOSTIC = 'runDiagnostic',
  ON_ERROR = 'onError',
}

export enum SdkNotificationMode {
  MODAL = 'modal',
  TOAST = 'toast',
  CUSTOM = 'custom',
}

export enum FirebaseEvents {
  ADDED = 'added',
  MODIFIED = 'modified',
  REMOVED = 'removed',
}

export enum CollectionNames {
  ADVISORS = 'advisors',
  PRESENCE = 'presence',
}

export enum ClientEvents {
  TOKEN_ABOUT_TO_EXPIRE = 'tokenAboutToExpire',
  TOKEN_EXPIRED = 'tokenExpired',
  CONNECTION_STATE_CHANGED = 'connectionStateChanged',
  CONNECTION_ERROR = 'connectionError',
}

export enum FirebaseConfigNames {
  PSYCHIC_PRESENCE = 'psychicPresence',
  CALIFORNIA_PSYCHICS = 'californiaPsychics',
}

export enum InternetConnectionEvents {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export enum MixpanelEvents {
  SCREEN_VIEWED = 'Screen_Viewed',
  BUTTON_TAPPED = 'Button_Tapped',
  BUTTON_VISIBLE = 'Button_Visible',
  PSYCHIC_ACCEPTED_CHAT = 'Psychic_Accepted_Chat',
  PROMPT_DELIVERED = 'Prompt_Delivered',
  CTA_TAPPED = 'CTA_Tapped',
  CHAT_CUSTOMER_INACTIVITY_PERIOD = 'Chat_Customer_Inactivity_Period',
  CHAT_PSYCHIC_INACTIVITY_PERIOD = 'Chat_Psychic_Inactivity_Period',
  ALERT_VIEWED = 'Alert_Viewed',
  ALERT_TAPPED = 'Alert_Tapped',
  FUNDS_DEPOSITED_FE = 'Funds_Deposited_FE',
}

export enum BootState {
  STATEFUL = 'stateful',
  STATELESS = 'stateless',
  INVALID = 'invalid',
}

export enum PresentationMode {
  FULLSCREEN = 'fullScreen',
  EMBEDDED = 'embedded',
  BUBBLE = 'bubble',
}

export enum ErrorRequestType {
  ALERT_ABOVE_INPUT = 'alert-above-input',
  POP_UP_NOTIFICATION = 'pop-up-notification',
}

export enum SourcePlatforms {
  WebDesktop = 'WebDesktop',
  MobileWebAndroid = 'MobileWebAndroid',
  MobileWebIOS = 'MobileWebIOS',
  AppIOS = 'AppIOS',
  AppAndroid = 'AppAndroid',
  SMS = 'SMS',
  ELECTRON = 'Electron',
}

export enum TwilioStatus {
  CONNECTED = 'connected',
  CONNECTING = 'connecting',
}

export enum DiagnosticListType {
  TOKEN = 'token',
  STATUS = 'status',
}

export enum PsychicStatusCommand {
  SET_ONLINE = 'loginany_8|',
  SET_OFFLINE = 'break00',
  SET_BREAK_5 = 'break05',
  SET_BREAK_10 = 'break10',
  SET_BREAK_15 = 'break15',
  SET_BREAK_30 = 'break30',
  SET_BREAK_45 = 'break45',
  SET_BREAK_60 = 'break60',
}

export enum PsychicStatusChannel {
  BREAK = 5,
  OFFLINE_MESSAGES = 4,
  OFFLINE_MESSAGES_AVAILABILITY = 6,
  CHAT_AVAILABILITY = 7,
  PHONE_CHAT = 3,
  PHONE = 1,
  CHAT = 2,
}
