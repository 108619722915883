import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import styles from 'components/Header/MiddleBar/Interaction/LoginForm/LoginForm.module.scss';
import { AlertTypes } from 'src/constants/enums';
import { AlertStyles } from 'components/Header/MiddleBar/Interaction/LoginForm/declarations';

const LoginFormAlert = ({ alert }) => {
  const [alertStyles, setAlertStyles] = useState<AlertStyles>({
    title: styles.alertTitle,
    description: styles.alertDescription,
    background: styles.alertBackground,
  });

  useEffect(() => {
    const commonTextStyles = {
      title: styles.alertTitle,
      description: styles.alertDescription,
    };

    switch (alert.type) {
      case AlertTypes.LOGIN_FAILED:
      case AlertTypes.BAD_CREDENTIALS:
      case AlertTypes.UNKNOWN_USERNAME:
        setAlertStyles({
          ...commonTextStyles,
          background: styles.alertBackgroundWarning,
        });

        break;
      case AlertTypes.SERVER_ERROR:
        setAlertStyles({
          ...commonTextStyles,
          background: styles.alertBackgroundError,
        });

        break;
      default: {
        setAlertStyles({
          ...commonTextStyles,
          background: styles.alertBackgroundWarning,
        });

        break;
      }
    }
  }, [alert]);

  return (
    <div className={cn(styles.alert, alertStyles.background)}>
      <p className={alertStyles.title}>{alert.title}</p>
      <p className={alertStyles.description}>{alert.description}</p>
    </div>
  );
};

export default LoginFormAlert;
