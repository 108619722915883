import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getRightPsychicsProxy } from 'src/api/psychicApi';
import type { Store } from 'app-redux/types/storeTypes';
import { setFirebaseRequiredState } from 'app-redux/actions/appActions';
import { PsychicCardAppearance } from 'constants/enums';
import { Logger } from 'lib/logger';
import { IntersectionObserverInit, IntersectionObserverWrapper } from 'lib/intersectionObserver';
import type { RightPsychic, SectionExtraDataType } from 'types/objectTypes';

export const useRefetchPsychicsOnSignInOrSignOut = (
  fetchMethod: () => Promise<void>,
  psychics: Array<RightPsychic>,
) => {
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);
  const prevAuthenticationState = useRef<boolean | null>(null);

  useEffect(() => {
    (async () => {
      if (psychics?.length === 0 && isAuthenticated !== prevAuthenticationState.current) {
        try {
          await fetchMethod();
        } catch (e) {
          const extraMessage = `Failed while refetching psychics after ${prevAuthenticationState.current ? 'sign out' : 'sign in'}`;
          Logger.error(extraMessage, e);
        }
      }

      prevAuthenticationState.current = isAuthenticated;
    })();
  }, [isAuthenticated, psychics, fetchMethod]);
};

export const useFetchPsychicsOnIntersection = (
  ref: RefObject<HTMLElement>,
  fetchMethod: () => Promise<void>,
) => {
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const intersectionHandler = async (
      entries: Array<IntersectionObserverEntry>,
      observer: IntersectionObserver,
    ) => {
      const handleSuccess = async () => {
        await fetchMethod();

        if (!ref.current) {
          return;
        }

        observer.unobserve(ref.current);
      };
      const observed: Array<any> = [];
      entries
        .forEach((entry) => {
          if (entry.isIntersecting) {
            try {
              observed.push(handleSuccess());
            } catch (e) {
              Logger.error(e);
            }
          }
        });

      await Promise.all(observed);
    };

    const options: IntersectionObserverInit = {
      rootMargin: '300px',
    };

    const observer = IntersectionObserverWrapper.getInstance(intersectionHandler, options);

    observer?.observe(ref.current);
  }, [ref, fetchMethod]);
};

export const useGetFetchPsychicsFunction = (
  extraData: SectionExtraDataType,
  setPsychics: Dispatch<SetStateAction<RightPsychic[]>>,
) => {
  const dispatch = useDispatch();

  const setPsychicsFromRemote = useCallback(async () => {
    const { itemsAmount, psychicCardAppearance } = extraData;
    const requestBody: any = {
      AppId: 1002,
      CustFavorite: false,
      ResultType: 1,
      SearchOptions: null,
    };

    if (itemsAmount) {
      requestBody.PageSize = itemsAmount;
    }

    const { psychics } = await getRightPsychicsProxy(requestBody);
    setPsychics(psychics);

    const shouldUseFirebase = psychicCardAppearance !== PsychicCardAppearance.SIMPLE
      && psychicCardAppearance !== PsychicCardAppearance.SIMPLE_ADDITIONAL;

    if (shouldUseFirebase) {
      dispatch(setFirebaseRequiredState(true));
    }
  }, [extraData]);

  return setPsychicsFromRemote;
};
