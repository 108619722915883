import { FC } from 'react';
import { useSelector } from 'react-redux';

import type { Link } from 'src/__generated__/graphqlTypes';
import type { Store } from 'app-redux/types/storeTypes';
import { ViewerDevice } from 'constants/enums';
import MiddleBarMainLogo from 'components/Header/MiddleBar/MainLogo/MiddleBarMainLogo';
import { SearchBar } from 'entities/SearchBar';
import { MobileHamburgerMenu } from 'features/MobileHamburgerMenu';
import { HamburgerMenuButton } from 'entities/HamburgerMenuButton';
import { HeaderTopBarDesktop, HeaderTopBarMobile } from 'features/HeaderTopBar';

import styles from './styles.module.scss';
import NavigationContainerDesktop from './NavigationDesktop';
import QuickLinks from './QuickLinks';

import type { IHeader } from '../config/declarations';
import { calculateMobileHeaderHeight } from '../lib';

const Header: FC<IHeader> = ({ header }) => {
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);

  if (!header) {
    return null;
  }

  const isMobile = isMobileViewWidth || (viewerDevice === ViewerDevice.TABLET);

  if (isMobile) {
    const height = calculateMobileHeaderHeight(header);

    return (
      <>
        <MobileHamburgerMenu
          auth={header.authorization}
          rating={header.rating}
          nav={header.nav}
          mobileApp={header.mobileApplication}
        />
        <header style={{ height }} className={styles.header}>
          <div className={styles.headerContainer}>
            <HeaderTopBarMobile topBar={header.top} />
            <section className={styles.head}>
              <HamburgerMenuButton
                button={header.hamburgerButton}
                className={styles.headHamburger}
              />
              <MiddleBarMainLogo isMobileView mainLogo={header.mainLogoMobile!} />
              <SearchBar
                search={header.searchBox}
                classNameIcon={styles.headSearch}
                isTooltip
              />
            </section>
            <QuickLinks links={header.quickLinksCollection?.items as Array<Link>} />
          </div>
        </header>
      </>
    );
  }

  return (
    <header className={styles.header}>
      <HeaderTopBarDesktop
        topBar={header.top!}
        rating={header.rating}
        auth={header.authorization}
        search={header.searchBox}
      />
      <NavigationContainerDesktop
        search={header.searchBox}
        nav={header.nav}
        logo={header.mainLogo}
      />
    </header>
  );
};

export default Header;
