import {
  FC,
  Fragment,
  useContext,
} from 'react';
import parse, {
  HTMLReactParserOptions,
  DOMNode,
  Element,
} from 'html-react-parser';

import styles from './OrangeAlertBig.module.scss';

import type { IExtraComponentProcessor } from '../config/declarations';
import { ExtraComponents } from '../lib/context';

const options: HTMLReactParserOptions = {
  replace(domNode: DOMNode) {
    const element = domNode as Element;

    if (element.type !== 'tag' || element.parent) {
      return element;
    }

    element.attribs.class = `${element.attribs.class || ''} ${styles.commonZIndex}`;

    return element;
  },
};

const ExtraComponentProcessor: FC<IExtraComponentProcessor> = ({
  location,
}) => {
  const extraComponents = useContext(ExtraComponents);

  if (!extraComponents || !Object.keys(extraComponents).length) {
    return null;
  }

  const components = extraComponents[location];

  if (!components || !components.length) {
    return null;
  }

  return (
    <>
      {components.map(({ sys, fullText }) => fullText && (
        <Fragment key={sys.id}>
          {parse(fullText, options)}
        </Fragment>
      ))}
    </>
  );
};

export default ExtraComponentProcessor;
