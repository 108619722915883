import axios, { AxiosError } from 'axios';
import btoa from 'btoa';

import { ZendeskTicketRequestData } from 'types/objectTypes';
import { proxyServerAxios } from 'src/api/axios';

export const createTicketProxy = (data: ZendeskTicketRequestData) => proxyServerAxios
  .post('zendesk/create-ticket', data)
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response; });

export const createTicket = (data: object, credentials: string) => axios
  .post(
    `https://${process.env.ZENDESK_SUBDOMAIN_NAME}.zendesk.com/api/v2/requests.json`,
    data,
    { headers: { Authorization: `Basic ${btoa(credentials)}` } },
  )
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response || e.message; });

export const fillTicketFields = (
  data: object,
  credentials: string,
  ticketId: number,
) => axios
  .put(
    `https://${process.env.ZENDESK_SUBDOMAIN_NAME}.zendesk.com/api/v2/tickets/${ticketId}.json`,
    data,
    { headers: { Authorization: `Basic ${btoa(credentials)}` } },
  )
  .then((resp) => resp.data)
  .catch((e: AxiosError) => { throw e.response || e.message; });
