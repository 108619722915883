import { FC } from 'react';
import cn from 'classnames';

import { WebpImage } from 'components/Shared/SharedComponents';

import type { IHeaderRating } from './declarations';
import styles from './styles.module.scss';

const Rating: FC<IHeaderRating> = ({ rating, className }) => {
  if (!rating) {
    return null;
  }

  return (
    <div className={cn(styles.rating, className)}>
      <span>{rating.title}</span>
      {rating.image && (
        <WebpImage
          image={rating.image}
          className={styles.ratingImage}
        />
      )}
    </div>
  );
};

export default Rating;
