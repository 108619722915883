import { useSelector } from 'react-redux';

import { Store } from 'app-redux/types/storeTypes';
import { ViewerDevice } from 'constants/enums';
import { DeviceHoroscopeInfoType } from 'types/objectTypes';

export const useDeviceHoroscopeInfo = (): DeviceHoroscopeInfoType => {
  const {
    mobileViewMaxWidth,
    horoscopeTabletMaxWidth,
  } = useSelector<Store, any>((store) => store.server.app.scssVariables);
  const viewerDevice = useSelector<Store, ViewerDevice>((store) => store.server.app.viewerDevice);

  return {
    mobileViewMaxWidth,
    horoscopeTabletMaxWidth,
    viewerDevice,
  };
};
