/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
  DetailedHTMLProps,
  InputHTMLAttributes,
  FocusEvent,
  FC,
  TextareaHTMLAttributes,
  HTMLAttributes,
} from 'react';
import cn from 'classnames';

import { ValidationMark } from 'components/Shared/SharedComponents';
import type { IdNameType, ValidationAndValue } from 'types/objectTypes';

import styles from './Form.module.scss';
import ErrorMessage from './Components/ErrorMessage';

import {
  FeedbackFormInputInterface,
  FeedbackFormTextareaInterface,
  CommonInputInterface,
} from '../config/declarations';

const CommonInput: FC<CommonInputInterface> = ({
  inputData,
  className,
  children,
}) => {
  const { isValid, error } = inputData;

  return (
    <div className={cn(styles.formFieldsetDiv, className)}>
      {children}
      {(isValid !== null) && (
        <div className={styles.formFieldsetDivMark}>
          <ValidationMark isValid={isValid} />
        </div>
      )}
      <ErrorMessage error={error} isInvalid={isValid === false} />
    </div>
  );
};

const useFeedbackFormInput = <T extends DetailedHTMLProps<HTMLAttributes<any>, HTMLElement>>(
  inputData: ValidationAndValue<IdNameType<string> | string>,
  ...rest: Array<T>
) => {
  const [props] = rest;
  const [isFocused, setFocusedState] = useState<boolean>(false);
  const { isValid, value, placeholder } = inputData;
  const onFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
    setFocusedState(true);
    props.onFocus?.(e);
  };

  const onBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    setFocusedState(false);
    props.onBlur?.(e);
  };

  const inputStyles = cn(
    styles.formFieldsetDivInput,
    isFocused && styles.formFieldsetInputFocused,
    isValid && styles.formFieldsetDivInputSuccess,
    (isValid === false) && styles.formFieldsetDivInputError,
  );

  const sameProps: T = {
    required: true,
    value: value as string,
    placeholder,
    ...props,
    onFocus,
    onBlur,
  };

  return { inputStyles, sameProps };
};

export const FeedbackFormTextarea: FC<FeedbackFormTextareaInterface> = ({
  inputData,
  ...rest
}) => {
  const { inputStyles, sameProps } = useFeedbackFormInput<
  DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
  >>(inputData, rest);

  return (
    <CommonInput inputData={inputData} className={sameProps.className}>
      <textarea {...sameProps} className={cn(inputStyles, styles.formFieldsetDivInputFullHeight)} />
    </CommonInput>
  );
};

export const FeedbackFormInput: FC<FeedbackFormInputInterface> = ({
  inputData,
  ...rest
}) => {
  const { inputStyles, sameProps } = useFeedbackFormInput<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
  >>(inputData, rest);

  return (
    <CommonInput inputData={inputData} className={sameProps.className}>
      <input {...sameProps} className={inputStyles} />
    </CommonInput>
  );
};
