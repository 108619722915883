import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  Dispatch,
  MutableRefObject,
  useEffect,
  useRef,
} from 'react';
import { useRouter } from 'next/router';

import { getPsychicNotifications } from 'src/api/customerApi';
import { GET_NOTIFICATIONS_FROM_PSYCHICS } from 'src/shared/api/apiNames';
import type { Store } from 'app-redux/types/storeTypes';
import { Logger } from 'lib/logger';
import {
  setApiError,
  setApiResponse,
  setApiStatus,
} from 'app-redux/actions/apiActions';
import { ApiConfigType, UserType } from 'types/objectTypes';

const API_NAME = GET_NOTIFICATIONS_FROM_PSYCHICS;

let requestId = '';
const ids = new Set<string>();

const makeRequest = async (user: UserType, dispatch: Dispatch<any>) => {
  try {
    const resp = await getPsychicNotifications(user);
    const { unreadNotificationsCount } = resp;

    if (!unreadNotificationsCount) {
      return dispatch(setApiResponse({ key: API_NAME, value: '' }));
    }

    const stringUnreadCount = (unreadNotificationsCount > 9)
      ? '+9'
      : unreadNotificationsCount?.toString();
    dispatch(setApiResponse({ key: API_NAME, value: stringUnreadCount }));
  } catch (e) {
    Logger.error(e);
    dispatch(setApiError({ key: API_NAME, value: e }));
  }
};

const useRoutingRefresh = (
  config: ApiConfigType,
  user: UserType | null,
  dispatch: Dispatch<any>,
) => {
  const router = useRouter();

  useEffect(() => {
    if (!config?.refresh || !user) {
      return;
    }

    const onRouteChangeRefreshRequest = config.refresh.find((item) => item.on === 'routing');

    if (!onRouteChangeRefreshRequest) {
      return;
    }

    const handler = async () => {
      await makeRequest(user, dispatch);
    };
    router.events.on('routeChangeComplete', handler);

    return () => router.events.off('routeChangeComplete', handler);
  }, [config?.refresh, router]);
};

const useUpdateRequestsIds = (localRequestId: MutableRefObject<string>) => {
  useEffect(() => {
    const localId = localRequestId.current;

    if (!localId) {
      return;
    }

    ids.add(localId);

    return () => {
      ids.delete(localId);

      if (ids.size === 0) {
        requestId = '';
      }
    };
  }, [localRequestId]);
};

export const useNotificationsFromPsychics = (config: ApiConfigType = { refresh: [{ on: 'load' }] }) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const apiData = useSelector((store: Store) => store.client.api[API_NAME]);

  const dispatch = useDispatch();
  const localRequestId = useRef<string>(uuidv4());

  const isLoading = !apiData || apiData.status === 'pending';
  const refetch = async () => {
    if (!user) {
      return;
    }

    await makeRequest(user, dispatch);
  };

  useUpdateRequestsIds(localRequestId);

  useEffect(() => {
    if (!user) {
      return;
    }

    const localId = localRequestId.current;
    const onLoadRefresh = config.refresh?.find((item) => item.on === 'load');

    const isExistsCurrentApiThatMakeCalls = Boolean(requestId);

    if (!isExistsCurrentApiThatMakeCalls) {
      if (apiData?.status && onLoadRefresh) {
        requestId = localId;

        if (apiData?.status === 'success') {
          dispatch(setApiResponse({ key: API_NAME, value: undefined }));
        }

        if (apiData?.status === 'error') {
          dispatch(setApiError({ key: API_NAME, value: undefined }));
        }

        dispatch(setApiStatus({ key: API_NAME, value: 'pending' }));

        return;
      }

      if (!apiData?.status) {
        requestId = localId;
        dispatch(setApiStatus({ key: API_NAME, value: 'pending' }));

        return;
      }
    }

    const isCurrentApiShouldMakeCall = requestId === localId;

    if (!isCurrentApiShouldMakeCall) {
      return;
    }

    const isApiCallHasResult = apiData?.status && apiData.status !== 'pending';

    if (isApiCallHasResult) {
      return;
    }

    (async () => { await makeRequest(user, dispatch); })();
  }, [user, apiData, localRequestId, config.refresh]);

  useRoutingRefresh(config, user, dispatch);

  return {
    data: apiData?.response,
    isLoading,
    refetch,
    status: apiData?.status,
  };
};
