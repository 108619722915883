import { FC } from 'react';

import { WebpImage } from 'components/Shared/SharedComponents';
import styles from 'components/Sections/ExponeaPsychicProfile/ExponeaPsychicProfile.module.scss';
import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';
import { getPsychicLabel } from 'src/shared/lib/psychics';
import { PsychicLabel } from 'constants/enums';

import { ProfileInteractionInterface } from './config/declarations';

const indexes = new Map()
  .set(PsychicLabel.RISING_STAR, 0)
  .set(PsychicLabel.PREMIER, 1)
  .set(PsychicLabel.NEW, 2)
  .set(PsychicLabel.STAFF_PICK, 3)
  .set(PsychicLabel.CUSTOMER_PICK, 4)
  .set(PsychicLabel.ELITE, 5)
  .set(PsychicLabel.SELECT, 6);

/* Main component */
export const ProfileInteraction: FC<ProfileInteractionInterface> = ({
  psychic,
  psychicFrame,
}) => {
  const psychicLabel = getPsychicLabel(psychic);
  const labelText = psychicFrame.abilities?.split(',')?.[indexes.get(psychicLabel)];
  const labelIcon = psychicFrame.labelsCollection?.items
    ?.find((item) => item?.slug === psychicLabel);

  return (
    <div className={styles.profileInteraction}>
      {labelText && (
        <div className={styles.profileInteractionPick}>
          {labelIcon?.image?.url && (
            <WebpImage
              image={EXTERNAL_WEBP_IMAGE}
              src={labelIcon.image.url}
              className={styles.profileInteractionPickImage}
            />
          )}
          <span className={styles.profileInteractionPickTitle}>
            {labelText}
          </span>
        </div>
      )}
    </div>
  );
};

export default ProfileInteraction;
