import { FC } from 'react';
import dynamic from 'next/dynamic';

import { useSidebarScreenSpace } from 'src/shared/lib/sidebar/hooks';

import type { IGlobalStyles } from '../config/declarations';

const CommonStyles = dynamic(() => import('./CommonStyles'), { ssr: false });
const SidebarStyles = dynamic(() => import('./SidebarStyles'), { ssr: false });

const GlobalStyles: FC<IGlobalStyles> = ({ sidebar }) => {
  const asideScreenSpace = useSidebarScreenSpace(sidebar);

  if (!sidebar || !sidebar.contentCollection?.items?.length || !asideScreenSpace) {
    return <CommonStyles />;
  }

  return <SidebarStyles sidebar={sidebar} />;
};

export default GlobalStyles;
