import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import Modal from 'react-modal';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import { DataButton, ValidationMark } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';

import { useAddNumberFunction, useNumberCheckerFunction } from '../../lib/messageHooks';
import type { IEcTelephoneActions } from '../../config/declarations';
import { useMessageButtonContext, useMessageDispatch } from '../../lib/message';

const ECAddPhoneNumber: FC<IEcTelephoneActions> = ({
  link,
  countryList,
  placeholder,
  additionalComponents,
  setNewNumberSetupState,
}) => {
  const country = useRef<HTMLSelectElement>(null);
  const [telephone, setTelephone] = useState<string>('');
  const [isValid, setValidityState] = useState<boolean | null>(null);
  const [isModalOpened, setModalState] = useState<boolean>(false);
  const [isPhoneVerified, setPhoneVerificationState] = useState<boolean>(false);
  const isBlurEventRequired = useRef<boolean>(true);
  const dispatch = useMessageDispatch();
  const store = useMessageButtonContext();

  useEffect(() => {
    dispatch({ type: 'isButtonDisabled', payload: true });

    return () => dispatch({ type: 'isButtonDisabled', payload: false });
  }, []);

  const addNewNumber = useAddNumberFunction();
  const {
    isNumberValid,
    isPhoneValidatingNow,
  } = useNumberCheckerFunction(telephone, setPhoneVerificationState);

  if (!countryList) {
    return null;
  }

  const errorMessage = additionalComponents.find((item) => item.slug === 'error');
  const getSelectedCountry = () => countryList[country.current?.selectedIndex ?? 1000];

  /**
   * @function
   * Here is used onMouseDown not onClick because in some cases {@link onBlur} ran
   * before this method and user had to click on the button twice
   */
  const onMouseDown = async () => {
    isBlurEventRequired.current = false;
    const newTelephone = telephone.replace(/-/g, '');
    const currentCountry = getSelectedCountry();

    if (store.telephones) {
      if (isValid === null) {
        const isNumberExists = store.telephones
          .find((telephone) => telephone.phoneNumber === newTelephone);

        if (isNumberExists) {
          return setNewNumberSetupState(false);
        }

        try {
          const isValid = await isNumberValid(currentCountry, newTelephone);

          if (!isValid) {
            return setNewNumberSetupState(false);
          }

          await addNewNumber(currentCountry, newTelephone, isPhoneVerified);

          return setNewNumberSetupState(false);
        } catch (e: any) {
          if (e.message === 'open-modal') {
            setValidityState(false);

            return setModalState(true);
          }

          return setNewNumberSetupState(false);
        }
      }
    }

    if (isValid) {
      try {
        await addNewNumber(currentCountry, newTelephone, isPhoneVerified);
      } finally {
        // eslint-disable-next-line no-unsafe-finally
        return setNewNumberSetupState(false);
      }
    }

    setNewNumberSetupState(false);
  };

  const onBlur = async () => {
    if (!isBlurEventRequired.current) {
      return;
    }

    const selectedCountry = getSelectedCountry();
    const newTelephone = telephone.replace(/-/g, '');

    const isNumberExists = store.telephones
      ?.find((telephone) => telephone.phoneNumber === newTelephone);

    if (isNumberExists) {
      return setNewNumberSetupState(false);
    }

    try {
      const isValid = await isNumberValid(selectedCountry, newTelephone);
      setValidityState(isValid);
    } catch (e: any) {
      setValidityState(false);

      if (e.message === 'open-modal') {
        return setModalState(true);
      }
    }
  };

  const handleBackspace = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key.toLowerCase() !== 'backspace') {
      return;
    }

    if (e.metaKey || e.ctrlKey) {
      setTelephone('');
    }

    const lastIndex = telephone.length - 1;

    /* It will remove extra - sign. Number can be removed without manual remove */
    if (telephone.charAt(lastIndex) === '-') {
      setTelephone(telephone.substring(0, lastIndex));
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhoneVerificationState(false);

    const { value } = e.target;
    const selectedCountry = getSelectedCountry();

    if (!selectedCountry || selectedCountry.countryCode !== 'USA') {
      return setTelephone(value);
    }

    const plainNumber = value.replace(/[^\d]/g, '');

    if (plainNumber.length > 10) {
      return;
    }

    let i = 0;

    const newNumber = plainNumber.replace(/\d{1}/g, (value: string) => {
      ++i;

      if (i === 3 || i === 6) {
        return `${value}-`;
      }

      return value;
    });

    return setTelephone(newNumber);
  };

  return (
    <>
      {isValid === false && isModalOpened && (
        <Modal
          isOpen={isModalOpened}
          className={cn(styles.telephoneModal, classes.modal)}
          overlayClassName={classes.modalBackground}
        >
          {errorMessage?.fullText}
          <button
            type="button"
            className={styles.close}
            onClick={() => setModalState(false)}
          >
            <span
              className={styles.icon}
            />
          </button>
        </Modal>
      )}
      <div className={cn(styles.actions, styles.actionsGap)}>
        <div className={styles.selectWrapper}>
          <select
            defaultValue={countryList[0].countryCode}
            className={styles.selectCountries}
            ref={country}
          >
            {countryList.map((country) => (
              <option
                key={country.countryCode}
                value={country.countryCode}
              >
                {`${country.countryCode}+${country.countryCallingCode}`}
              </option>
            ))}
          </select>
          <i className={styles.selectCountriesIcon} />
        </div>
        <div className={styles.inputWrapper}>
          <input
            type="tel"
            value={telephone}
            onBlur={onBlur}
            onKeyDown={handleBackspace}
            onChange={onChange}
            className={styles.input}
            placeholder={placeholder!}
            pattern={'\\d+-*\\d+-*\\d+'}
            minLength={0}
            maxLength={20}
          />
          {isPhoneVerified && (
            <div className={styles.verifiedMark}>
              <ValidationMark isValid />
            </div>
          )}
        </div>
        {link && (
          <DataButton
            link={link}
            className={cn(styles.button, classes.textBold)}
            onMouseDown={onMouseDown}
            disabled={isPhoneValidatingNow}
          >
            {link.alt}
          </DataButton>
        )}
      </div>
    </>
  );
};

export { ECAddPhoneNumber };
