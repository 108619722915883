import React, {
  FC,
  KeyboardEvent,
  MouseEvent,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import cookie from 'js-cookie';

import { Logger } from 'lib/logger';
import { Link } from 'src/__generated__/graphqlTypes';
import { FooterMobileNavBarItemInterface } from 'types/componentTypes';
import {
  DataButton,
  DataLink,
  LazyWebpImage,
} from 'components/Shared/SharedComponents';
import {
  DATA_NAVIGATION_ATTRIBUTE_NAME,
  LABEL_COOKIE_NAME,
  LINK_TAG_NAME,
  MY_INBOX,
} from 'constants/constants';
import { LinkImagesInterface } from 'components/Navigation/Mobile/declarations';
import styles from 'components/Navigation/Mobile/MobileNavBar.module.scss';
import { updateOrAddCookie } from 'lib/sharedMethods.service';

const checkIfNewButtonVisible = () => {
  try {
    const labelCookieStr = cookie.get(LABEL_COOKIE_NAME);

    if (!labelCookieStr) {
      return true;
    }

    const labelCookieValue = JSON.parse(labelCookieStr ? decodeURI(labelCookieStr) : '');
    const isLableShouldBeShown = labelCookieValue?.newMyInboxLabel?.show;

    return isLableShouldBeShown !== undefined ? isLableShouldBeShown : true;
  } catch (e) {
    Logger.error(e);

    return false;
  }
};

/* Nested component */
const TitleWithImages: FC<LinkImagesInterface> = ({
  link,
  image,
  classes,
  isChildFree,
  isRevealed,
  isLableShouldBeShown,
}) => {
  const linkImage = link.image;

  const calculateImageClasses = () => {
    if (isChildFree) {
      return cn(classes?.img, classes?.imgChildFree);
    }

    return cn(
      classes?.img,
      isRevealed
        ? classes?.imgReveal
        : classes?.imgCollapse,
    );
  };

  return (
    <>
      {linkImage?.url && (
        <LazyWebpImage
          src={linkImage.url}
          image="external"
          loadingThreshold={1}
          className={classes?.icon}
        />
      )}
      <span className={classes?.span}>
        {link.title}
      </span>
      {isLableShouldBeShown && (
        <span className={styles.newButton}>{link?.alt}</span>
      )}
      <span
        style={(image?.url)
          ? { backgroundImage: `url(${image.url})` }
          : {}}
        className={calculateImageClasses()}
      />
    </>
  );
};

const updateLabelnewMyInboxLabelKey = () => {
  const newAutoReloadLabelCookie = {
    show: false,
    updatedDate: new Date(),
  };
  updateOrAddCookie('newMyInboxLabel', newAutoReloadLabelCookie, LABEL_COOKIE_NAME);
};

/* Main component */
const MobileNavBarLinkItem: FC<FooterMobileNavBarItemInterface> = ({
  navItem,
  image,
  classes,
  children,
  isHighlight,
  navigationLocation,
}) => {
  const link = navItem as Link;
  const contentRef = useRef<HTMLUListElement>(null);
  const [maxHeight, setMaxHeight] = useState<number>(contentRef?.current?.scrollHeight || 0);
  const [isRevealed, setRevealedFlag] = useState<boolean>(false);
  const isChildFree = !children;
  const onClick = (
    e: MouseEvent<HTMLLIElement | HTMLUListElement>
    | KeyboardEvent<HTMLLIElement | HTMLUListElement>,
  ) => {
    const ul = contentRef.current;

    if (!ul || isChildFree) {
      return e;
    }

    // @ts-ignore
    if (e.target.tagName?.toLowerCase() !== LINK_TAG_NAME) {
      e.stopPropagation();
      e.preventDefault();
    }

    const parent = ul.parentElement?.parentElement;
    const styles = parent && window.getComputedStyle(parent);

    if (styles?.maxHeight !== 'none') {
      const intMaxHeight = +styles!.maxHeight.replace('px', '');

      parent!.style.maxHeight = `${intMaxHeight + ul.scrollHeight}px`;
    }

    setRevealedFlag((prevState) => {
      const newState = !prevState;

      setMaxHeight(newState ? ul.scrollHeight : 0);

      return newState;
    });

    return e;
  };

  const isLableShouldBeShown = link?.slug === MY_INBOX && checkIfNewButtonVisible();

  const string = image
    ? (
      <TitleWithImages
        link={link}
        image={image}
        classes={classes}
        isChildFree={isChildFree}
        isRevealed={isRevealed}
        isLableShouldBeShown={isLableShouldBeShown}
      />
    )
    : link.title;

  const applyAClasses = () => {
    const aClasses = [classes?.link];

    if (isChildFree && !isHighlight) {
      aClasses.push(classes?.linkChildFree);
    }

    if (isHighlight) {
      aClasses.push(classes?.linkBold);
    }

    return cn(...aClasses);
  };

  const applyLink = () => (isChildFree
    ? (
      <DataLink
        link={link}
        href={link.src!}
        className={applyAClasses()}
        {...{ [DATA_NAVIGATION_ATTRIBUTE_NAME]: navigationLocation }}
        {...isLableShouldBeShown && { onClick: updateLabelnewMyInboxLabelKey }}
      >
        {string}
      </DataLink>
    )
    : (
      <DataButton
        link={link}
        className={applyAClasses()}
        {...{ [DATA_NAVIGATION_ATTRIBUTE_NAME]: navigationLocation }}
      >
        {string}
      </DataButton>
    ));

  return (
    <li
      key={link.entryName}
      className={classes?.li}
      onClick={onClick}
      onKeyPress={onClick}
      role="presentation"
    >
      {applyLink()}
      {!isChildFree && (
        <ul
          ref={contentRef}
          style={{ maxHeight: `${maxHeight}px` }}
          className={classes?.innerUl}
          onClick={onClick}
          onKeyPress={onClick}
          role="presentation"
        >
          {children}
        </ul>
      )}
    </li>
  );
};

export default MobileNavBarLinkItem;
