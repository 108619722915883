/* eslint-disable react/jsx-props-no-spreading */
import { useSelect } from 'downshift';
import { FC } from 'react';
import cn from 'classnames';

import Tick from 'src/shared/ui/Tick/Tick';

import styles from './SortedTestimonials.module.scss';

import type { ISortedTestimonialsSelect } from '../config/declarations';

export const Select: FC<ISortedTestimonialsSelect> = ({
  options = [],
  label,
  isLoading,
  onChange,
}) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items: options,
    defaultSelectedItem: options?.[0],
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        onChange(selectedItem);
      }
    },
  });

  if (!options.length) {
    return null;
  }

  return (
    <div className={styles.select}>
      {label && (
        <span className={styles.label} {...getLabelProps()}>
          {label}
        </span>
      )}
      <div className={styles.selectWrapper}>
        <button
          className={styles.button}
          type="button"
          disabled={isLoading}
          {...getToggleButtonProps()}
        >
          <strong>{selectedItem?.text}</strong>
          <Tick
            sideSize={8}
            direction="bottom"
            className={styles.buttonTick}
          />
        </button>
        <ul
          className={cn(styles.list, { [styles.hidden]: !isOpen })}
          {...getMenuProps()}
        >
          {options.map((item, index) => {
            const isSelected = item.sys.id === selectedItem?.sys.id;
            const classNamePart = isSelected ? 'Selected' : 'Common';

            return (
              <li
                key={item.sys.id}
                className={cn(
                  styles.item,
                  styles[`item${classNamePart}`],
                )}
                {...getItemProps({ item, index })}
              >
                <i className={cn(
                  styles.circle,
                  styles[`circle${classNamePart}`],
                )}
                />
                <span>{item.text}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
