import { FC } from 'react';
import cn from 'classnames';

import { PsychicCardImage } from 'entities/PsychicCardImage';
import { StatusIndicatorWrapper as StatusIndicator } from 'entities/JsxParserComponents';

import styles from './styles.module.scss';

import type { IPsychicPhotoModule } from '../config/declarations';

const PhotoWithGradient: FC<IPsychicPhotoModule> = ({
  src,
  psychic,
  imageClassName,
  psychicSkeleton,
}) => (
  <div className={styles.photoWithGradient}>
    <PsychicCardImage
      psychic={psychicSkeleton}
      image="external"
      src={src}
      alt={psychic.psychicName}
      className={cn(styles.image, imageClassName)}
    />
    <div>
      <StatusIndicator
        chatStatus={psychic.chatStatus}
        phoneStatus={psychic.phoneStatus}
        className=""
      />
      <span className={styles.name}>
        {psychic.psychicName}
      </span>
    </div>

    <div className={styles.gradient} />
  </div>
);

export default PhotoWithGradient;
