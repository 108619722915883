import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import classes from 'src/styles/_commonClasses.module.scss';
import { CommonRichText } from 'components/Shared/SharedComponents';
import type { RichTextParsersConfig } from 'types/objectTypes';

import styles from './HoroSignShortInfo.module.scss';

import type { ITitle } from '../config/declarations';

const getParsersConfig = (): RichTextParsersConfig => ({
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.title,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
});

const Title: FC<ITitle> = ({ title }) => (
  <CommonRichText content={title} parsersConfig={getParsersConfig()} />
);

export default Title;
