import {
  FC,
  useReducer,
  useState,
} from 'react';
import Modal from 'react-modal';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import classes from 'styles/_commonClasses.module.scss';
import type { Store } from 'app-redux/types/storeTypes';
import { requestOfflineMessage } from 'src/api/customerApi';
import type { PhoneNumberType } from 'types/objectTypes';
import { OAPlatform } from 'constants/enums';
import { SourcePlatforms } from 'common-chat-components/enums';
import { setLoadingState } from 'app-redux/actions/appActions';
import type { Block } from 'src/__generated__/graphqlTypes';
import { formatPhoneInputFromString } from 'lib/text.service';

import ECPsychicPhoto from './ECPsychicPhoto';
import ECTelephone from './ECTelephone';
import OfflineMassageButton from './OfflineMassageButton';
import ECAdditionalInformation from './ECAdditionalInformation';
import { ECResponseError, ECResponseOk } from './ECResponseComponents';
import styles from './styles.module.scss';

import type { IEcModal } from '../../config/declarations';
import { messageButtonModalReducer } from '../../lib/reducers';
import { useMessageButtonApis } from '../../lib/message';
import {
  MessageContext,
  MessageContextDispatch,
  messageButtonContext,
} from '../../lib/messageContext';

const getPlatform = (oaPlatform: OAPlatform) => {
  if (oaPlatform === OAPlatform.ANDROID) {
    return SourcePlatforms.MobileWebAndroid;
  }

  if (oaPlatform === OAPlatform.IOS) {
    return SourcePlatforms.MobileWebIOS;
  }

  return SourcePlatforms.WebDesktop;
};

const ECModal: FC<IEcModal> = ({
  block,
  psychic,
  isOpened,
  psychicFrame,
  messageIcon,
  closeModal,
}) => {
  const [store, localDispatch] = useReducer(messageButtonModalReducer, messageButtonContext);
  const [isResponseModalOpened, setResponseOpenedModalState] = useState<boolean>(false);
  const user = useSelector((store: Store) => store.server.auth.user);
  const oaPlatform = useSelector((store: Store) => store.server.app.oaPlatform);
  const dispatch = useDispatch();
  const {
    photo,
    phone,
    button,
    faq,
    response,
  } = (block.contentTypesCollection?.items as Array<Block>)
    ?.reduce((store, item) => {
      if (item.__typename !== 'Block') {
        return store;
      }

      if (item.slug) {
        return { ...store, [item.slug]: item };
      }

      return store;
    }, {} as Record<string, Block>) || {} as Record<string, Block>;

  const [telephone, setTelephone] = useState<PhoneNumberType>();
  const [responseModalComponent, setResponseModalComponent] = useState<any | null>(null);
  const { shouldBeShown } = useMessageButtonApis(localDispatch, isOpened);

  if (!shouldBeShown) {
    return null;
  }

  const requestOfflineMessageOnClick = async () => {
    const profileButtonPrePath = psychicFrame?.profileButton?.src || '/psychics';
    const bioUrl = `${profileButtonPrePath}/${psychic.lineName.toLowerCase()}-${psychic.extId}`;
    const closeResponseModal = () => {
      closeModal();
      setResponseOpenedModalState(false);
      localDispatch({ type: 'isButtonDisabled', payload: false });
    };

    if (!telephone || !user?.custId) {
      return setResponseModalComponent(
        <ECResponseError
          message={response.title || 'Something went wrong'}
          close={closeResponseModal}
        />,
      );
    }

    try {
      const platform = getPlatform(oaPlatform);
      dispatch(setLoadingState(true));
      localDispatch({ type: 'isButtonDisabled', payload: true });
      const {
        isSuccess,
        responseMessage,
        psychicPhoneNumber,
        responseCode,
      } = await requestOfflineMessage(
        user,
        psychic.extId,
        `+${telephone.countryCallingCode}${telephone.phoneNumber}`,
        platform,
      );
      setResponseOpenedModalState(true);

      if (isSuccess) {
        const formattedTelephone = formatPhoneInputFromString(psychicPhoneNumber);

        return setResponseModalComponent(
          <ECResponseOk
            redirectUrl={bioUrl}
            number={formattedTelephone}
            psychicFrame={psychicFrame}
            psychic={psychic}
            text={response.content}
            close={closeResponseModal}
          />,
        );
      }

      return setResponseModalComponent(
        <ECResponseError
          redirectUrl={bioUrl}
          shouldRedirect={responseCode === 1003}
          message={responseMessage || response.title}
          close={closeResponseModal}
        />,
      );
    } catch (e: any) {
      setResponseOpenedModalState(true);
      setResponseModalComponent(
        <ECResponseError
          message={e.message || response.title}
          close={closeResponseModal}
        />,
      );
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  return (
    <MessageContext.Provider value={store}>
      <MessageContextDispatch.Provider value={localDispatch}>
        { responseModalComponent && (
          <Modal
            isOpen={isResponseModalOpened}
            className={cn(styles.messageModalResponse, classes.modal)}
            overlayClassName={classes.modalBackground}
            shouldFocusAfterRender={false}
          >
            {responseModalComponent}
          </Modal>
        )}
        <Modal
          isOpen={isOpened}
          className={cn(styles.messageModal, classes.modal)}
          overlayClassName={classes.modalBackground}
          shouldFocusAfterRender={false}
        >
          <ECPsychicPhoto
            psychicFrame={psychicFrame}
            psychic={psychic}
            block={photo}
          />
          <hr className={styles.divider} />
          <ECTelephone
            setTelephone={setTelephone}
            telephone={telephone}
            block={phone}
          />
          <OfflineMassageButton
            psychic={psychic}
            onClick={requestOfflineMessageOnClick}
            messageIcon={messageIcon}
            block={button}
            telephone={telephone}
          />
          <ECAdditionalInformation block={faq} />
          <button
            type="button"
            className={styles.close}
            onClick={() => {
              closeModal();
              localDispatch({ type: 'isButtonDisabled', payload: false });
            }}
          >
            <span
              className={styles.icon}
            />
          </button>
        </Modal>
      </MessageContextDispatch.Provider>
    </MessageContext.Provider>
  );
};

export default ECModal;
