import { FC } from 'react';
import {
  BLOCKS,
  MARKS,
  INLINES,
} from '@contentful/rich-text-types';

import classes from 'src/styles/_commonClasses.module.scss';
import { CommonRichText } from 'components/Shared/SharedComponents';
import type { RichTextParsersConfig } from 'types/objectTypes';
import { mapSmallText } from 'lib/richTextMappers';

import styles from './PriceCardsWithKarma.module.scss';

import type { IRichText } from '../config/declarations';

const getParsersConfig = (): RichTextParsersConfig => ({
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.small,
    mapper: mapSmallText,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
  [INLINES.HYPERLINK]: {
    classNames: styles.paragraphLink,
  },
});

const StabRichText: FC<IRichText> = ({ richText }) => (
  <CommonRichText content={richText} parsersConfig={getParsersConfig()} />
);

export default StabRichText;
