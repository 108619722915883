import { FC } from 'react';

import styles from 'components/Sections/HoroscopesSignsAllAboutSigns/LuckyColor/LuckyColor.module.scss';
import { SignFeatureContainer } from 'components/Sections/HoroscopesSignsAllAboutSigns/Shared';
import { Link } from 'src/__generated__/graphqlTypes';
import { getImagesWithLimitedSize } from 'lib/image.service';
import { ImageFitMode } from 'constants/enums';
import { getImageBasedDevice } from 'lib/horoscope/horoscope.service';
import { AllAboutSignsLuckColorInterface, LuckyColorImageSizeSlug as Slug } from 'components/Sections/HoroscopesSignsAllAboutSigns/LuckyColor/declarations';

const LuckyColor: FC<AllAboutSignsLuckColorInterface> = ({
  luckyColor,
  viewerDevice,
  mobileViewMaxWidth,
}) => {
  if (!luckyColor) {
    return <SignFeatureContainer containerClassName={styles.luckyColor} />;
  }

  const Image = getImageBasedDevice(viewerDevice);
  const { title, link = {} } = luckyColor;
  const { image, title: color } = link as Link;

  const imagesWithLimitedSize = getImagesWithLimitedSize([
    {
      height: Slug.MOBILE_HEIGHT,
      width: Slug.MOBILE_WIDTH,
    },
    {
      height: Slug.DESKTOP_HEIGHT,
      width: Slug.DESKTOP_WIDTH,
      isMinWidth: true,
    },
  ],
  {
    media: `${mobileViewMaxWidth}px`,
    fit: ImageFitMode.SCALE,
  });

  return (
    <SignFeatureContainer
      title={title!}
      containerClassName={styles.luckyColor}
    >
      {image && (
        <div className={styles.luckyColorAsset}>
          <Image
            image={image}
            className={styles.luckyColorAssetImage}
            widthLimit={imagesWithLimitedSize}
          />
          <span className={styles.luckyColorAssetTitle}>{color}</span>
        </div>
      )}
    </SignFeatureContainer>
  );
};

export default LuckyColor;
