import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import cookie from 'cookie';

import { Block, Link } from 'src/__generated__/graphqlTypes';
import { Store } from 'app-redux/types/storeTypes';
import { NcOfferPackages } from 'components/Sections/NcOfferPackages';
import { getCurrentAccountInfoObject } from 'components/Sections/NcOfferPackages/service';
import { SectionComponentInterface } from 'types/componentTypes';
import { CommonContentfulSlug } from 'constants/enums';
import { ASK_ACCOUNT_INFO } from 'constants/constants';
import { useCommonContentfulBlocks } from 'lib/shared.hook';
import { Logger } from 'lib/logger';

const NcOfferPackagesContainer: FC<SectionComponentInterface> = ({
  blocks,
  extraData,
  bgColor,
}) => {
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const {
    content,
    nextArrow,
    prevArrow,
    topDivider,
    bottomDivider,
    commonPageMaxWidth,
  } = useCommonContentfulBlocks<CommonContentfulSlug, Block | Link>(blocks);
  const router = useRouter();

  const onPackageClick = (
    packageDynamicName: string,
    link?: Link,
    psychicType: string = '',
  ) => {
    if (!link) {
      return;
    }

    try {
      const accountInfo = getCurrentAccountInfoObject(packageDynamicName, psychicType);
      document.cookie = cookie.serialize(
        ASK_ACCOUNT_INFO,
        JSON.stringify(accountInfo),
        { secure: true, path: '/' },
      );
      router.replace(link.src || '');
    } catch (e) {
      Logger.error(e);
    }
  };

  return (
    <NcOfferPackages
      bgColor={bgColor}
      extraData={extraData}
      content={content}
      topDivider={topDivider}
      bottomDivider={bottomDivider}
      commonPageMaxWidth={commonPageMaxWidth}
      nextArrow={nextArrow}
      prevArrow={prevArrow}
      isMobileViewWidth={isMobileViewWidth}
      onPackageClick={onPackageClick}
    />
  );
};

export default NcOfferPackagesContainer;
