import { Asset, Block } from 'src/__generated__/graphqlTypes';
import { ViewsMaxWidthInterface } from 'types/componentTypes';

export interface AllAboutSignsFlowersInterface extends ViewsMaxWidthInterface {
  flowers: Block;
}

export interface AllAboutSignsFlowersPictureInterface
  extends ViewsMaxWidthInterface {
  maxWidth: number;
  maxHeight: number;
  entryName?: string;
  title?: string;
  image?: Asset;
  mobileViewMaxWidth: string;
  horoscopeTabletMaxWidth: string;
  setMaxWidth: (value: number) => void;
  setMaxHeight: (value: number) => void;
}

export enum FlowersPictureImageSizeRatioSlug {
  MOBILE = 0.57,
  TABLET = 0.71,
  ENTRY_IMAGE = 0.5,
}
