import {
  useEffect,
  useState,
  FC,
} from 'react';

import FooterComponent from 'components/Footer/Footer';
import { Footer } from 'src/__generated__/graphqlTypes';
import { SCROLL_EVENT } from 'constants/constants';
import { CommonFooterInterface } from 'components/Footer/declarations';

const FooterContainer: FC<CommonFooterInterface> = ({ footer }) => {
  const [isVisibleScrollTopButton, setScrollTopButtonVisibility] = useState<boolean>(false);

  useEffect(() => {
    const handler = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const appearationLimit = window.innerHeight * 1.5;
      setScrollTopButtonVisibility(scrollTop > appearationLimit);
    };
    window.addEventListener(SCROLL_EVENT, handler);

    return () => window.removeEventListener(SCROLL_EVENT, handler);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <FooterComponent
      footer={footer as Footer}
      scrollToTop={scrollToTop}
      isVisibleScrollTopButton={isVisibleScrollTopButton}
    />
  );
};

export default FooterContainer;
