import {
  ReactChild,
  ReactFragment,
  ReactPortal,
} from 'react';

import type { SliderArrowInterface } from 'components/Shared/declarations';
import type {
  Block,
  ImageWrapper,
  Link,
} from 'src/__generated__/graphqlTypes';

export interface ImageObjectInterface {
  desktopUrl?: string;
  mobileUrl?: string;
  sortOrder?: number;
  promoCode?: string;
  linkRedirectUrl?: string | null;
  isOfferImage?: boolean;
  offerId?: number | string;
  offerLabel?: string;
}

export enum ImageSlug {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export interface CustomConfigInterface {
  bannerDisplayCount: number;
  desktopBannerWidth: number;
  desktopBannerHeight: number;
  mobileBannerHeight: number;
  mobileBannerWidth: number;
}

export interface Offer {
  width: number;
  height: number;
  url: string;
  linkUrl: string;
  channelId: number;
  description?: number;
}

export interface CustomOffersInterface {
  offerImages: Array<Offer>;
  sortOrder: number;
  promoCode: string;
  offerId?: number | string;
  description?: string;
}

export interface ExtendedImageInterface extends ImageObjectInterface {
  src?: string;
  image: ImageWrapper['image'];
  bannerUrl?: string;
}
export interface CarouselImageInterface {
  imageObject: Omit<ExtendedImageInterface, 'image'>;
  isMobileViewWidth: boolean;
  image: ImageWrapper['image'];
  link: Block['link'];
  redirectToUrl: (src: Link['src']) => void;
}

export type Components = Partial<{
  leftArrow: Link;
  leftArrowHover: Link;
  rightArrow: Link;
  rightArrowHover: Link;
  block: Block;
}>;

export interface IHoveredSliderArrow {
  hasNext: boolean;
  className: string;
  label: string;
  commonArrow?: Link;
  hoveredArrow?: Link;
  onClick: SliderArrowInterface['clickHandler'];
  isCarouselHovered?: boolean;
  defaultControlClass: 'control-prev' | 'control-next';
  altImage: '>' | '<';
  height: number;
  width: number;
}

export type ArrowRendererProps = {
  commonArrow?: Link;
  hoveredArrow?: Link;
  sideSize: number;
  isCarouselHovered?: boolean;
}

export interface IOffersListCarouselWrapper {
  isMobileViewWidth: boolean;
  pauseDurationMobile: number;
  pauseDurationDesktop: number;
  blocks: Components;
  children: (ReactChild[]
  & (boolean | ReactFragment | ReactPortal | ReactChild | null)) | undefined;
}
