import {
  FC,
  memo,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from 'app-redux/types/storeTypes';
import Copyright from 'src/components/Footer/Copyright/Copyright';
import { setDesktopRequestedState, setMobileViewWidthState } from 'app-redux/actions/appActions';
import { ViewerDevice } from 'constants/enums';
import { FooterCopyrightContainerInterface } from 'components/Footer/Copyright/declarations';

const CopyrightContainer: FC<FooterCopyrightContainerInterface> = ({
  copyrightDescription,
  copyrightLinks,
  desktopSiteRequest,
  mobileSiteRequest,
  footerType,
}) => {
  const isMobileView = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const isDesktopRequested = useSelector((store: Store) => store.client.app.isDesktopRequested);
  const dispatch = useDispatch();

  const requestAnotherSiteView = useCallback(() => {
    const isNotDesktop = viewerDevice !== ViewerDevice.DESKTOP;

    if (isNotDesktop) {
      dispatch(setDesktopRequestedState(!isDesktopRequested));
      dispatch(setMobileViewWidthState(!isMobileView));
    }
  }, [dispatch, isDesktopRequested, viewerDevice, isMobileView]);

  return (
    <Copyright
      footerType={footerType}
      isMobileView={isMobileView}
      copyrightDescription={copyrightDescription}
      copyrightLinks={copyrightLinks}
      desktopSiteRequest={desktopSiteRequest}
      mobileSiteRequest={mobileSiteRequest}
      isDesktopRequested={isDesktopRequested}
      requestAnotherSiteView={requestAnotherSiteView}
    />
  );
};

export default memo(CopyrightContainer);
