import { FC } from 'react';

import { PsychicCtaButtons as Buttons } from 'entities/PsychicCtaButton';
import { getPsychicCtaButtons } from 'lib/psychic.service';

import styles from './CardSimpleSquare.module.scss';
import Profile from './Profile';

import type { ICard } from '../config/declarations';

const PsychicCardSimpleSquare: FC<ICard> = ({
  psychicFrame,
  positionInArray,
  psychic,
  user,
  bootStatus,
}) => {
  if (!psychicFrame || !psychic) {
    return null;
  }

  const cta = getPsychicCtaButtons(psychic, psychicFrame, { hashedId: user?.hashedId });

  return (
    <li className={styles.card}>
      <Profile
        psychic={psychic}
        psychicFrame={psychicFrame}
        positionInArray={positionInArray}
      />
      <em className={styles.description}>
        {psychic.usp}
      </em>
      <Buttons
        className={styles.buttons}
        shape="Oval"
        bootStatus={bootStatus}
        chatButton={cta.chatButton}
        talkButton={cta.talkButton}
        callbackButton={cta.callbackButton}
        inQueueButton={cta.inQueueButton}
        messageButton={cta.messageButton}
        psychicFrame={psychicFrame}
        psychic={psychic}
      />
    </li>
  );
};

export default PsychicCardSimpleSquare;
