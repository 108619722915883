import { useSelector } from 'react-redux';

import type { Store } from 'app-redux/types/storeTypes';

export const useLoaderImage = () => {
  const pages = useSelector((store: Store) => store.server.page.pages);
  const slug = useSelector((store: Store) => store.server.page.slug);
  const { loader } = pages[slug];

  return loader;
};
