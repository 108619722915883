import { FC, Fragment } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import { CommonRichText } from 'components/Shared/SharedComponents';
import type { RichTextParsersConfig } from 'types/objectTypes';
import { mapMarksBoldWithBr } from 'lib/richTextMappers';

import styles from './FlexColumns.module.scss';

import type { IChild } from '../config/declarations';

const getParsersConfig = (): RichTextParsersConfig => ({
  [BLOCKS.HEADING_1]: {
    classNames: cn(styles.heading, styles.headingH1),
  },
  [BLOCKS.HEADING_2]: {
    classNames: cn(styles.heading, styles.headingH2),
  },
  [BLOCKS.HEADING_3]: {
    classNames: cn(styles.heading, styles.headingH3),
  },
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.paragraph,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold700,
    mapper: mapMarksBoldWithBr,
  },
});

const Title: FC<IChild> = ({ element }) => (
  <Fragment key={element.sys.id}>
    <CommonRichText content={element.richTitle} parsersConfig={getParsersConfig()} />
    <CommonRichText content={element.content} parsersConfig={getParsersConfig()} />
  </Fragment>
);

export default Title;
