import { FC, useRef } from 'react';

import { DataLink } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';

import type { IForthLevelMenu } from '../config/declarations';
import { useToggleTransition } from '../lib/hooks';

const ForthLevelMenu: FC<IForthLevelMenu> = ({ links, isVisible }) => {
  const ulRef = useRef<HTMLUListElement>(null);
  useToggleTransition(ulRef, isVisible);

  if (!links.length) {
    return null;
  }

  return (
    <ul
      ref={ulRef}
      className={styles.l3navUl}
    >
      {links.map((link) => (
        <li className={styles.li} key={link.sys.id}>
          <DataLink link={link} className={styles.liButton}>
            {link.title}
          </DataLink>
        </li>
      ))}
    </ul>
  );
};

export default ForthLevelMenu;
