import { FC } from 'react';
import cn from 'classnames';

import { ItemsLayout } from 'constants/enums';
import { DataButton } from 'components/Shared/SharedComponents';
import { ProfileViewInterface } from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';
import { useCommonDataButtonsProps } from 'lib/shared.hook';

import styles from '../PsychicFilter.module.scss';

const ProfileView: FC<ProfileViewInterface> = ({
  gridViewLink,
  listViewLink,
  selectedLayout,
  displayGridView,
  displayListView,
  gaData,
}) => {
  const {
    parsedGaProperties: parsedGaPropertiesGrid,
  } = useCommonDataButtonsProps(gaData, gridViewLink);
  const {
    parsedGaProperties: parsedGaPropertiesList,
  } = useCommonDataButtonsProps(gaData, listViewLink);

  return (
    <div className={styles.profileView}>
      {gridViewLink && (
        <DataButton link={gridViewLink} onClick={displayGridView} {...parsedGaPropertiesGrid}>
          <div className={styles.iconGrid}>
            <div className={cn(
              styles.iconGridItem,
              { [styles.iconGridItemActive]: selectedLayout === ItemsLayout.GRID },
            )}
            />
            <div className={cn(
              styles.iconGridItem,
              { [styles.iconGridItemActive]: selectedLayout === ItemsLayout.GRID },
            )}
            />
            <div className={cn(
              styles.iconGridItem,
              { [styles.iconGridItemActive]: selectedLayout === ItemsLayout.GRID },
            )}
            />
            <div className={cn(
              styles.iconGridItem,
              { [styles.iconGridItemActive]: selectedLayout === ItemsLayout.GRID },
            )}
            />
          </div>
        </DataButton>
      )}
      {listViewLink && (
        <DataButton link={listViewLink} onClick={displayListView} {...parsedGaPropertiesList}>
          <div className={styles.iconList}>
            <div className={cn(
              styles.iconListItem,
              styles.iconListItemRectangle,
              { [styles.iconListItemActive]: selectedLayout === ItemsLayout.COLUMN },
            )}
            />
            <div className={cn(
              styles.iconListItem,
              styles.iconListItemRectangle,
              { [styles.iconListItemActive]: selectedLayout === ItemsLayout.COLUMN },
            )}
            />
          </div>
        </DataButton>
      )}
    </div>
  );
};

export default ProfileView;
