import { FC } from 'react';
import cn from 'classnames';

import { Divider } from 'src/shared/ui/ContentDivider';

import styles from './styles.module.scss';

import { IDivider } from '../config/declarations';

const PsychicDivider: FC<IDivider> = ({ divider }) => {
  if (!divider) {
    return null;
  }

  return (
    <Divider
      divider={divider}
      className={cn(styles.divider, styles.wrapperWidthSmall)}
      lineClassName={cn(styles.line)}
    />
  );
};

export default PsychicDivider;
