import hash from 'object-hash';

import { CallbackPair } from 'types/objectTypes';

export interface IntersectionObserverInit {
  root?: Element | Document | null;
  rootMargin?: string;
  threshold?: number | number[];
}

interface IntersectionObserverCallback {
  (entries: IntersectionObserverEntry[], observer: IntersectionObserver): void;
}

const deleteUsedCallback = (
  object: any,
  firstDataSrc: string | null,
  secondDataSrc: string | null,
) => {
  if (firstDataSrc || secondDataSrc) {
    const src = firstDataSrc || secondDataSrc!;
    const customCallback = object.callbackMap.get(src);

    if (customCallback) {
      customCallback();
      object.callbackMap.delete(src);
    }
  }
};

export class IntersectionObserverWrapper {
  static instanceMap: Map<string, IntersectionObserver> = new Map();

  static callbackMap: Map<string, Function> = new Map();

  static foreignCBInstancesCount: number = 0;

  static defaultCallback: IntersectionObserverCallback = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver,
  ) => {
    entries.forEach(async (entry) => {
      if (entry.isIntersecting) {
        const entryTarget = entry.target as HTMLElement;
        const dataSrc = entryTarget.getAttribute('data-src');
        const dataBg = entryTarget.getAttribute('data-bg');

        if (dataSrc) {
          entryTarget.setAttribute('src', dataSrc);
        }

        if (dataBg) {
          entryTarget.style.backgroundImage = `url(${dataBg})`;
        }

        if (!dataSrc && !dataBg) {
          const children = entryTarget.childNodes;
          children.forEach((element) => {
            const imageOrSource = element as HTMLImageElement| HTMLSourceElement;
            const dataSrc = imageOrSource.getAttribute('data-src');
            const dataSrcSet = imageOrSource.getAttribute('data-srcset');

            if (dataSrc) {
              imageOrSource.setAttribute('src', dataSrc);
            }

            if (dataSrcSet) {
              imageOrSource.setAttribute('srcset', dataSrcSet);
            }

            deleteUsedCallback(IntersectionObserverWrapper, dataSrc, dataSrcSet);

            return imageOrSource;
          });
        }

        deleteUsedCallback(IntersectionObserverWrapper, dataSrc, dataBg);

        observer.unobserve(entryTarget);
      }
    });
  }

  static defaultOptions: IntersectionObserverInit = {
    rootMargin: '40px',
    threshold: 0,
  }

  static getInstance(
    callback?: (
      entries: Array<IntersectionObserverEntry>,
      observer: IntersectionObserver,
    ) => void,
    options?: IntersectionObserverInit,
    customCallback?: CallbackPair,
  ) {
    const finalOptions = (options)
      ? { ...this.defaultOptions, ...options }
      : this.defaultOptions;

    if (callback) {
      const instanceHash = hash.MD5(++this.foreignCBInstancesCount);
      const observer = new IntersectionObserver(callback, finalOptions);
      this.instanceMap.set(instanceHash, observer);

      return observer;
    }

    const instanceHash = hash.MD5(finalOptions);

    if (customCallback?.key && customCallback.callback) {
      this.callbackMap.set(customCallback.key, customCallback.callback);
    }

    if (this.instanceMap.has(instanceHash)) {
      return this.instanceMap.get(instanceHash);
    }

    const observer = new IntersectionObserver(this.defaultCallback, finalOptions);
    this.instanceMap.set(instanceHash, observer);

    return observer;
  }
}
