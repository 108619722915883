import {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cookie, { CookieAttributes } from 'js-cookie';

import type { Alert } from 'src/__generated__/graphqlTypes';
import { useCustomRouter } from 'src/shared/lib/history/hooks';
import { setLoadingState } from 'app-redux/actions/appActions';
import type { Store } from 'app-redux/types/storeTypes';
import { AlertTypes } from 'constants/enums';
import { DEFAULT_SRC, SESSION_COOKIE_NAME } from 'constants/constants';
import { LoginForm } from 'components/Header/MiddleBar/Interaction/LoginForm';
import {
  LoginFormContainerInterface,
  LoginFormInputs,
  LoginFormSubmit,
  SIGN_IN_ATTEMPTS_COOKIE_NAME,
  SignInAttemptsChecker,
} from 'components/Header/MiddleBar/Interaction/LoginForm/declarations';
import { useLoginFormLazyLoad } from 'components/Header/MiddleBar/Interaction/LoginForm/hooks';
import { signIn } from 'lib/auth.service';
import { Logger } from 'lib/logger';
import { isValidEmail } from 'lib/text.service';
import { sendRecaptchaResult, signOut } from 'src/api/authApi';

const initFormInputs: LoginFormInputs = {
  email: '',
  password: '',
};

const attemptsCookieOptions: CookieAttributes = { secure: true };
const LoginFormContainer: FC<LoginFormContainerInterface> = ({
  isShown,
  loginForm,
  setShownFormState,
}) => {
  const oaPlatform = useSelector((store: Store) => store.server.app.oaPlatform);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const user = useSelector((store: Store) => store.server.auth.user);
  const [formInputs, setFormInputs] = useState<LoginFormInputs>(initFormInputs);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [signInAttemptEmail, setSignInAttemptEmail] = useState<string>('');
  const [isAlertShown, toggleAlert] = useState<boolean>(false);
  const [alert, setAlert] = useState<Alert | null>(null);
  const [isRecaptchaVisible, setRecaptchaVisibilityState] = useState<boolean>(false);
  const [isRecaptchaAfterLoad, setRecaptchaAfterLoadState] = useState<boolean>(false);
  const dispatch = useDispatch();

  const router = useCustomRouter();

  const onRecaptchaChange = (key: string | null) => {
    setRecaptchaVisibilityState(false);
    sendRecaptchaResult(signInAttemptEmail, key).then();

    if (passwordRef.current?.value) {
      passwordRef.current.value = '';
    }
  };

  const showAlertIfExists = (type: AlertTypes, message?: string | null, slug?: string) => {
    const alert = loginForm.alertsCollection?.items
      .find((item) => {
        const isAlertTypeAndSlugMatch = (item?.type === type)
          && (!slug || item.slug === slug);

        return isAlertTypeAndSlugMatch;
      });

    if (!alert) {
      return;
    }

    alert.description = message || alert.description;
    setAlert(alert);
    toggleAlert(true);
  };

  const setExternalSignInAlert = (alertType: AlertTypes, slug?: string) => {
    showAlertIfExists(alertType, null, slug);
  };

  const getAlertType = (type: number) => {
    if (type >= 500) {
      return AlertTypes.SERVER_ERROR;
    }

    switch (type) {
      case 404: {
        return AlertTypes.UNKNOWN_USERNAME;
      }
      case 400:
      case 401:
      case 403: {
        return AlertTypes.BAD_CREDENTIALS;
      }
      case 0: {
        return AlertTypes.SERVER_ERROR;
      }
      default: {
        return AlertTypes.SERVER_ERROR;
      }
    }
  };

  const handleSubmit = async (e: LoginFormSubmit) => {
    e.preventDefault();
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;

    if (!email || !password || !isValidEmail(email)) {
      return showAlertIfExists(AlertTypes.BAD_CREDENTIALS);
    }

    const attemptsChecker = new SignInAttemptsChecker();

    try {
      setSignInAttemptEmail(email);
      dispatch(setLoadingState(true));

      if (user?.authToken) {
        await signOut(user.authToken);
      }

      const { signInAttempt } = await attemptsChecker.checkAttempts(email);
      cookie.set(
        SIGN_IN_ATTEMPTS_COOKIE_NAME,
        (signInAttempt + 1).toString(),
        attemptsCookieOptions,
      );

      if (attemptsChecker.isCaptchaRequired()) {
        setRecaptchaVisibilityState(true);
        dispatch(setLoadingState(false));

        return showAlertIfExists(AlertTypes.BAD_CREDENTIALS);
      }

      if (attemptsChecker.isAccountLocked()) {
        dispatch(setLoadingState(false));

        return showAlertIfExists(AlertTypes.BAD_CREDENTIALS, null, 'account-locked');
      }

      await signIn(
        { email, password },
        { oaPlatform, device: viewerDevice, path: router.asPath },
      );

      if (isAlertShown) {
        toggleAlert(false);
      }

      const { src } = loginForm.signIn || {};

      if (src) {
        const url = src === DEFAULT_SRC ? router.asPath : src;
        router.replace(`${url}?t=${new Date().getTime()}`, url, { keepQuery: true, withReload: true });
        setShownFormState(false);
        cookie.remove(SIGN_IN_ATTEMPTS_COOKIE_NAME, attemptsCookieOptions);
      } else {
        cookie.set(SESSION_COOKIE_NAME, '', { expires: new Date().getDate(), path: '/' });
        dispatch(setLoadingState(false));
      }
    } catch (e: any) {
      const { message } = e.data || {};
      const alertType = getAlertType(e.status);
      showAlertIfExists(alertType, message);
      dispatch(setLoadingState(false));

      const isCaptchaShouldBeShown = (attemptsChecker.isCaptchaRequired())
          && (e.status === 401 || e.status === 403);

      if (isCaptchaShouldBeShown) {
        setRecaptchaVisibilityState(true);

        showAlertIfExists(AlertTypes.BAD_CREDENTIALS);
      }

      if (alertType === AlertTypes.SERVER_ERROR) {
        Logger.error(e.data);
      }
    }
  };

  useEffect(() => {
    const signInAttempts = cookie.get(SIGN_IN_ATTEMPTS_COOKIE_NAME);

    if (Number(signInAttempts) === 3) {
      setRecaptchaAfterLoadState(true);
      setRecaptchaVisibilityState(true);
    }
  }, []);

  const isLoginFormCanBeShown = useLoginFormLazyLoad(isShown);

  if (!isShown && !isLoginFormCanBeShown) {
    return null;
  }

  return (
    <LoginForm
      isShown={isShown}
      alert={alert}
      isRecaptchaAfterLoad={isRecaptchaAfterLoad}
      isRecaptchaVisible={isRecaptchaVisible}
      loginForm={loginForm}
      formInputs={formInputs}
      setExternalSignInAlert={setExternalSignInAlert}
      setFormInputs={setFormInputs}
      emailRef={emailRef}
      passwordRef={passwordRef}
      isAlertShown={isAlertShown}
      handleSubmit={handleSubmit}
      onRecaptchaChange={onRecaptchaChange}
    />
  );
};

export default LoginFormContainer;
