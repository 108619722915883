import { FC, useReducer } from 'react';
import cn from 'classnames';

import { ContentfulStyles } from 'entities/ContentfulStyles';
import classes from 'src/styles/_commonClasses.module.scss';
import { capitalizeFirstLetter } from 'lib/text.service';

import styles from './styles.module.scss';
import PsychicDivider from './PsychicDivider';

import {
  ErrorHandler,
  ErrorHandlerDispatch,
  IdProvider,
  errorHandlerContext,
} from '../service/context';
import { constructId, getGridAreasTemplate } from '../service';
import type { ISectionLayout } from '../config/declarations';
import { errorHandlerReducer } from '../service/reducers';

const SectionLayout: FC<ISectionLayout> = ({
  block,
  children,
  sectionRef,
  extraStyles,
  topDivider,
  bottomDivider,
  bgColor,
  secondaryButton,
  verticalPadding,
}) => {
  const [store, dispatch] = useReducer(
    errorHandlerReducer,
    errorHandlerContext,
  );

  const areaTemplateName = getGridAreasTemplate(
    [block?.link, secondaryButton],
    block?.content,
  );
  const sectionClasses = cn(
    styles.wrapper,
    styles.wrapperWidthSmall,
    styles[areaTemplateName],
    classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`],
  );

  if (store.shouldHideSection) {
    return null;
  }

  return (
    <ErrorHandler.Provider value={store}>
      <ErrorHandlerDispatch.Provider value={dispatch}>
        <IdProvider.Provider value={block?.blockId}>
          <ContentfulStyles styles={extraStyles} />
          <PsychicDivider divider={topDivider} />
          <section
            id={constructId(block?.blockId)}
            ref={sectionRef}
            className={sectionClasses}
            style={{ background: bgColor }}
          >
            {children}
          </section>
          <PsychicDivider divider={bottomDivider} />
        </IdProvider.Provider>
      </ErrorHandlerDispatch.Provider>
    </ErrorHandler.Provider>
  );
};

export default SectionLayout;
