import { FC } from 'react';

import { SectionComponentInterface } from 'types/componentTypes';
import { CircleImageDescriptionButtonsClone } from 'components/Sections/CircleImageDescriptionButtonClone';
import { ContentfulSlugsNoArrows } from 'types/objectTypes';
import { Block } from 'src/__generated__/graphqlTypes';
import { useCommonContentfulBlocks } from 'lib/shared.hook';

const CircleImageDescriptionButtonContainer: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const {
    content,
    topDivider,
    bottomDivider,
    commonPageMaxWidth,
  } = useCommonContentfulBlocks<ContentfulSlugsNoArrows, Block>(blocks);

  return (
    <CircleImageDescriptionButtonsClone
      bgColor={bgColor}
      content={content}
      extraData={extraData}
      topDivider={topDivider}
      bottomDivider={bottomDivider}
      commonPageMaxWidth={commonPageMaxWidth}
    />
  );
};

export default CircleImageDescriptionButtonContainer;
