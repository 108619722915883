/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { FC } from 'react';
import cn from 'classnames';

import styles from 'components/Sections/PsychicsTiersHardcoded/PsychicsTiersHardcoded.module.scss';
import { Block } from 'src/__generated__/graphqlTypes';
import { RichTextParsersConfig } from 'types/objectTypes';
import classes from 'src/styles/_commonClasses.module.scss';
import {
  CommonRichText as Content,
  DataLink,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { CommonSize, ElementAlign } from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';

import { PsychicHardcodedCardInterface, PsychicHardcodedTiersInterface } from './declarations';

const getParsersConfig = (titleAlign?: ElementAlign): RichTextParsersConfig => {
  const getHeadingClasses = (headingClass: string) => cn(
    styles.tiersTitle,
    classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`],
    headingClass,
  );

  return ({
    [BLOCKS.HEADING_1]: {
      classNames: getHeadingClasses(classes.titleMainH1),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHeadingClasses(classes.titleMainH2),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getHeadingClasses(classes.titleMainH3),
    },
    [BLOCKS.PARAGRAPH]: {
      classNames: styles.linkParagraph,
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};

/* Nested Component */
const PsychicCard: FC<PsychicHardcodedCardInterface> = ({ block, psychics }) => {
  const { image, link, entryName } = block;

  if (!image) {
    return null;
  }

  const [firstPsychic, secondPsychic] = psychics || [];

  return (
    <div className={styles.tiersCardsCard}>
      <WebpImage
        image={image}
        className={styles.tiersCardsCardImage}
      />
      {link && (
        <DataLink
          link={link}
          key={entryName}
          className={styles.tiersCardsCardButton}
        >
          {link.title}
        </DataLink>
      )}
      {firstPsychic && (
        <a
          className={cn(styles.tiersCardsCardLink, styles.tiersCardsCardLinkFirst)}
          href={firstPsychic.link}
        />
      )}
      {secondPsychic && (
        <a
          className={cn(styles.tiersCardsCardLink, styles.tiersCardsCardLinkSecond)}
          href={secondPsychic.link}
        />
      )}
    </div>
  );
};

/* Nested component */
const Title = Content;

/* Main component */
const PsychicsTiersHardcoded: FC<PsychicHardcodedTiersInterface> = ({
  bgColor,
  extraData,
  block,
  hardcodedPsychics,
}) => {
  if (!block) {
    return null;
  }

  const {
    titleAlign = ElementAlign.CENTER,
    verticalPadding = CommonSize.MEDIUM,
  } = extraData || { };
  const {
    contentTypesCollection,
    content,
    richTitle,
    link,
  } = block;
  const circleImages = contentTypesCollection?.items as Array<Block> || [];
  const config = getParsersConfig(titleAlign);

  return (
    <section
      className={cn(
        styles.wrapper,
        classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`],
      )}
      style={{ background: bgColor }}
    >
      <div className={styles.tiers}>
        <Title content={richTitle} parsersConfig={config} />
        <Content content={content} parsersConfig={config} />
        <div className={styles.tiersCards}>
          {circleImages
            .map((block) => (
              <PsychicCard
                key={block.entryName}
                block={block}
                psychics={hardcodedPsychics[block.slug!]}
              />
            ))}
        </div>
        {link && (
          <DataLink link={link} className={styles.tiersButton}>
            {link.title}
          </DataLink>
        )}
      </div>
    </section>
  );
};

export default PsychicsTiersHardcoded;
