import { FC } from 'react';

import styles from 'components/Sections/ExponeaPsychicProfile/ExponeaPsychicProfile.module.scss';

import type { IEstimationComponent } from './config/declarations';

const EstimatedTime: FC<IEstimationComponent> = ({
  texts,
  timeUnit,
  isShortText = false,
  estimatedTime,
}) => {
  const textsArray = texts?.split('-') || [];
  const text = (isShortText ? textsArray[0] : textsArray[1]) || '';

  return (
    <div className={styles.estimation}>
      <strong className={styles.estimationTitle}>{text}</strong>
      <span className={styles.estimationValue}>
        {estimatedTime}
        {' '}
        {timeUnit}
      </span>
    </div>
  );
};

export default EstimatedTime;
