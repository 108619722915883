import parse, { HTMLReactParserOptions } from 'html-react-parser';

import { ImageWrapper, Text } from 'src/__generated__/graphqlTypes';

import { JsxElement, TrustpilotElements } from '../config/declarations';

export const getElements = (
  blocks: Array<Text | ImageWrapper>,
): TrustpilotElements => blocks.reduce((store, block) => {
  if ((block.slug === 'code' || block.__typename === 'Text') && (block as Text).fullText) {
    return { ...store, code: block as Text };
  }

  if ((block.slug === 'image' || block.__typename === 'ImageWrapper') && (block as ImageWrapper).image) {
    return { ...store, image: block as ImageWrapper };
  }

  return store;
}, {} as any);

export const getElement = (replace: HTMLReactParserOptions['replace'], text?: string) => {
  const element = parse(text!, { replace }) as string | JsxElement;

  if (Array.isArray(element)) {
    return element.find((item) => typeof item !== 'string');
  }

  return element;
};
