import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { FC, Fragment } from 'react';
import cn from 'classnames';

import { Block } from 'src/__generated__/graphqlTypes';
import { CircleImageDescriptionButtonsInterface } from 'types/componentTypes';
import { RichTextParsersConfig } from 'types/objectTypes';
import styles from 'components/Sections/CircleImageDescriptionButtonClone/CircleImageDescriptionButtonsClone.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { CircleImageDescriptionButton } from 'components/Sections/CircleImageDescriptionButtonClone';
import { CommonRichText } from 'components/Shared/SharedComponents';
import Divider from 'components/Sections/Divider';
import { CommonSize, ElementAlign } from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';

const getParsersConfig = (
  titleAlign: ElementAlign,
): RichTextParsersConfig => {
  const getHeadingClasses = (headingClass: string) => cn(
    styles.cardsTitle,
    classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`],
    headingClass,
  );

  return ({
    [BLOCKS.HEADING_1]: {
      classNames: getHeadingClasses(classes.titleMainH1),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHeadingClasses(classes.titleMainH2),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getHeadingClasses(classes.titleMainH3),
    },
    [BLOCKS.PARAGRAPH]: {
      classNames: styles.cardsParagraph,
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};
const CircleImageDescriptionButtonClone: FC<CircleImageDescriptionButtonsInterface> = ({
  bgColor,
  extraData,
  content: block,
  topDivider,
  bottomDivider,
  commonPageMaxWidth,
}) => {
  if (!block) {
    return null;
  }

  const {
    titleAlign = ElementAlign.RIGHT,
    verticalPadding = CommonSize.MEDIUM,
  } = extraData || { };
  const {
    contentTypesCollection,
    content,
    richTitle,
  } = block;
  const buildComponent = () => {
    const circleImages = contentTypesCollection?.items as Array<Block> || [];

    return (
      <>
        {richTitle && (
          <CommonRichText
            content={richTitle}
            parsersConfig={getParsersConfig(titleAlign)}
          />
        )}
        {content && (
          <CommonRichText
            content={content}
            parsersConfig={getParsersConfig(titleAlign)}
          />
        )}
        {circleImages.map((block) => (
          <CircleImageDescriptionButton
            key={block.entryName}
            block={block}
          />
        ))}
      </>
    );
  };

  return (
    <section
      className={cn(
        styles.wrapper,
        classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`],
      )}
      style={{ background: bgColor }}
    >
      <div className={styles.cards}>
        <Divider
          block={topDivider}
          maxWidth={`${commonPageMaxWidth}px`}
        />
        {buildComponent()}
        <Divider
          block={bottomDivider}
          maxWidth={`${commonPageMaxWidth}px`}
        />
      </div>
    </section>
  );
};

export default CircleImageDescriptionButtonClone;
