import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';

import { Block } from 'src/__generated__/graphqlTypes';
import styles from 'src/components/Sections/AboutNcOfferPackages/AboutNcOfferPackages.module.scss';
import { RichTextParsersConfig } from 'types/objectTypes';
import CardAboutNcOfferPackages from 'src/components/Sections/AboutNcOfferPackages/CardAboutNcOfferPackages';
import {
  CommonRichText,
  DataLink,
  H2,
} from 'components/Shared/SharedComponents';
import Slider from 'components/Sections/AboutNcOfferPackages/AboutNcOfferPackagesSlider';
import { mapBlocksParagraphSchemas } from 'lib/richTextMappers';
import { AboutNcOfferPackagesInterface, ChildsInterface } from 'components/Sections/AboutNcOfferPackages/declarations';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.offerParagraph,
    mapper: mapBlocksParagraphSchemas({
      itemProp: 'description',
    }),
  },
};

const Childs: React.FC<ChildsInterface> = ({
  childBlocks,
  arrowImages,
  isMobileViewWidth,
  onPackageClick,
}) => {
  if (!childBlocks) return null;

  const children = childBlocks.map((childBlock) => (
    <CardAboutNcOfferPackages
      key={childBlock?.entryName}
      block={childBlock}
      onPackageClick={onPackageClick}
    />
  ));

  if (isMobileViewWidth) {
    return (
      <Slider
        blocks={childBlocks}
        images={arrowImages}
        onPackageClick={onPackageClick}
      />
    );
  }

  return (
    <div className={styles.offerContent}>
      {children}
    </div>
  );
};

const AboutNcOfferPackages: React.FC<AboutNcOfferPackagesInterface> = ({
  blocks,
  bgColor,
  images,
  isMobileViewWidth,
  onPackageClick,
}) => {
  const buildComponents = () => blocks
    .map((block: Block) => {
      const {
        content,
        contentTypesCollection,
        title,
        link,
        entryName,
      } = block;
      const children = contentTypesCollection?.items as Array<Block> | undefined;

      if (!content) return null;

      return (
        <React.Fragment key={entryName}>
          <H2
            title={title as string}
            className={styles.offerTitle}
            itemProp="name"
          />
          <Childs
            childBlocks={children}
            arrowImages={images}
            isMobileViewWidth={isMobileViewWidth}
            onPackageClick={onPackageClick}
          />
          <CommonRichText content={content} parsersConfig={parsersConfig} />
          {link && (
            <DataLink
              link={link}
              href={link.src!}
              className={styles.offerButton}
              itemProp="url"
            >
              {link.title}
            </DataLink>
          )}
        </React.Fragment>
      );
    });

  return (
    <section
      className={styles.offer}
      itemScope
      itemType="https://schema.org/OfferCatalog"
      style={{ background: bgColor }}
    >
      {buildComponents()}
    </section>
  );
};

export default AboutNcOfferPackages;
