/* eslint-disable no-param-reassign */
import type { Link } from 'src/__generated__/graphqlTypes';

import type { Components, ServerPsychicsBlock } from '../config/declarations';

export const extractComponents = (blocks: Array<ServerPsychicsBlock | Link>) => blocks
  .reduce((store, item) => {
    if (item.slug === 'left-arrow') {
      store.leftArrow = item as Link;
    }

    if (item.slug === 'right-arrow') {
      store.rightArrow = item as Link;
    }

    if (item.slug === 'content' || item.__typename === 'Block') {
      store.block = item as ServerPsychicsBlock;
    }

    return store;
  }, {} as Components);
