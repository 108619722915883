import React from 'react';
import cn from 'classnames';

import styles from 'components/Sections/HoroscopesSignsAllAboutSigns/HoroscopesSignsAllAboutSigns.module.scss';
import { SignFeatureHeadingInterface } from 'components/Sections/HoroscopesSignsAllAboutSigns/Shared/declarations';

const SignFeatureHeading: React.FC<SignFeatureHeadingInterface> = ({
  title,
  titleClassName,
  underlineClassName,
}) => (
  <div className={styles.allAboutContainerHeading}>
    <span className={cn(
      styles.allAboutContainerHeadingTitle,
      titleClassName,
    )}
    >
      {title}
    </span>
    <hr className={cn(
      styles.allAboutContainerHeadingUnderline,
      underlineClassName,
    )}
    />
  </div>
);

export default SignFeatureHeading;
