/* eslint-disable no-param-reassign */
import {
  FC,
  useRef,
  useState,
} from 'react';
import { Element, HTMLReactParserOptions } from 'html-react-parser';
import cn from 'classnames';
import { useRouter } from 'next/router';

import { GAReplacementValue } from 'constants/enums';
import classes from 'src/styles/_commonClasses.module.scss';
import { SectionComponentGenericInterface } from 'types/componentTypes';
import { capitalizeFirstLetter, replaceContentfulVariables } from 'lib/text.service';
import { ImageWrapper, Text } from 'src/__generated__/graphqlTypes';
import { WebpImage } from 'components/Shared/SharedComponents';

import styles from './OptionalTrustpilot.module.scss';
import TrustpilotWrapper from './TrustpilotWrapper';
import TrustpilotCode from './TrustpilotCode';

import { getElement, getElements } from '../lib';
import { useTrustScore } from '../lib/hooks';

const tpThreshold = Number(process.env.NEXT_PUBLIC_TRUSTPILOT_THRESHOLD_SCORE) || 4;

const OptionalTrustpilot: FC<SectionComponentGenericInterface<Text | ImageWrapper>> = ({
  blocks,
  extraData,
  bgColor,
}) => {
  const router = useRouter();
  const [trustScore, setTrustScore] = useState<number | null>(null);
  const sectionRef = useRef<HTMLElement>(null);

  const { code, image } = getElements(blocks);
  const replace: HTMLReactParserOptions['replace'] = (domNode) => {
    const elementNodeAttribs = (domNode as Element).attribs;

    if (elementNodeAttribs?.['data-label'] === `{${GAReplacementValue.FULL_URL}}`) {
      (domNode as Element).attribs['data-label'] = replaceContentfulVariables(
        elementNodeAttribs['data-label'],
        { [GAReplacementValue.FULL_URL]: `${process.env.NEXT_PUBLIC_BASE_SERVER_URL}${router.asPath}` },
      );
    }

    if (elementNodeAttribs?.['data-label'] === `{${GAReplacementValue.SHORT_URL}}`) {
      (domNode as Element).attribs['data-label'] = replaceContentfulVariables(
        elementNodeAttribs['data-label'],
        { [GAReplacementValue.SHORT_URL]: router.asPath },
      );
    }

    return domNode;
  };

  const element = getElement(replace, code?.fullText!);
  useTrustScore(element, setTrustScore);

  const { horizontalPadding, verticalPadding } = extraData || {};
  const extraDataClasses = {
    [styles[`paddingHorizontal${capitalizeFirstLetter(horizontalPadding)}`]]: Boolean(horizontalPadding),
    [classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`]]: Boolean(verticalPadding),
  };
  const wrapperStyles = cn(styles.wrapper, extraDataClasses);

  if (code && Number(trustScore) >= tpThreshold) {
    return (
      <TrustpilotWrapper
        sectionRef={sectionRef}
        className={wrapperStyles}
        style={{ background: bgColor }}
      >
        <TrustpilotCode element={element} sectionRef={sectionRef} />
      </TrustpilotWrapper>
    );
  }

  if (image && Number(trustScore) < tpThreshold) {
    const { image: innerImage } = image as ImageWrapper;

    return (
      <TrustpilotWrapper
        sectionRef={sectionRef}
        className={wrapperStyles}
        style={{ background: bgColor }}
      >
        <WebpImage
          key={innerImage?.sys?.id}
          image={innerImage!}
          className={styles.trustpilotImage}
        />
      </TrustpilotWrapper>
    );
  }

  return (
    <TrustpilotWrapper
      sectionRef={sectionRef}
      className={wrapperStyles}
      style={{ background: bgColor }}
    />
  );
};

export default OptionalTrustpilot;
