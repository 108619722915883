import { useEffect, useState } from 'react';

import { getCustomerOffers } from 'src/api/customerApi';
import type { UserType } from 'types/objectTypes';
import type { Block, ImageWrapper } from 'src/__generated__/graphqlTypes';
import { setItemInObjectBySlug } from 'lib/sharedMethods.service';

import {
  CustomConfigInterface,
  ImageObjectInterface,
  ImageSlug as Slug,
} from '../config/declarations';
import { slugs } from '../config/constants';

import { extractOfferImages } from '.';

const defaultBanner = {
  promoCode: 'Nothing',
  isOfferImage: false,
  offerLabel: 'Default Offer',
};

export const useOffersImages = (user: UserType | null, offersConfig: CustomConfigInterface) => {
  const [offersImages, setOffersImages] = useState<Array<ImageObjectInterface> | null>(null);

  useEffect(() => {
    if (!user) {
      return;
    }

    getCustomerOffers({
      custId: user?.custId,
      offerType: 'all',
      promoCode: null,
    }).then((data) => {
      if (!data.totalCount) {
        return setOffersImages([defaultBanner]);
      }

      const images = extractOfferImages(offersConfig, data?.custOffers);

      if (!images.length) {
        return setOffersImages([defaultBanner]);
      }

      setOffersImages(images);
    });
  }, [user]);

  return offersImages;
};

export const useDefaultOfferImage = (
  isMobileViewWidth: boolean,
  block: Block = {} as Block,
) => {
  const reducer = setItemInObjectBySlug(slugs);
  const { imagesCollection } = block;

  const {
    desktop,
    mobile,
  } = (imagesCollection?.items as Array<ImageWrapper>)
    ?.reduce(reducer, {} as Record<Slug, ImageWrapper>) || {};
  const imageWrapper = isMobileViewWidth
    ? mobile
    : desktop;

  return imageWrapper?.image;
};
