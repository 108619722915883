import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import styles from 'components/Sections/CircleImageDescriptionButtonClone/CircleImageDescriptionButtonsClone.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import {
  CommonRichText,
  DataLink,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { CircleImageDescriptionButtonInterface } from 'components/Sections/CircleImageDescriptionButtonClone/declarations';

const getHeadingClasses = (headingClass: string) => cn(
  styles.cardsBlockTitle,
  headingClass,
);
const parsersConfig = {
  [BLOCKS.HEADING_3]: {
    classNames: getHeadingClasses(styles.cardsBlockTitleH3),
  },
  [BLOCKS.HEADING_4]: {
    classNames: getHeadingClasses(styles.cardsBlockTitleH4),
  },
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.cardsBlockParagraph,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
};

const CircleImageDescriptionButton: FC<CircleImageDescriptionButtonInterface> = ({ block }) => {
  const {
    content,
    link,
    image,
  } = block;

  if (!link) {
    return null;
  }

  return (
    <DataLink link={link} className={styles.cardsBlock}>
      {image && (
        <WebpImage
          image={image}
          className={styles.cardsBlockImage}
        />
      )}
      {content && (
        <CommonRichText
          content={content}
          parsersConfig={parsersConfig}
        />
      )}
      <span
        className={styles.cardsBlockButton}
      >
        {link.title}
      </span>
    </DataLink>
  );
};

export default CircleImageDescriptionButton;
