import { FC } from 'react';

import { DataLink } from 'components/Shared/SharedComponents';
import { InboxEnvelope as Envelope } from 'entities/MyInboxEnvelope';

import styles from '../styles.module.scss';
import type { IGreetingChild } from '../../config/declarations';

const Inbox: FC<IGreetingChild> = ({ link }) => {
  if (!link) {
    return null;
  }

  return (
    <DataLink link={link} className={styles.inbox}>
      <span className={styles.textColor}>{link.title}</span>
      <Envelope
        className={styles.envelope}
        indicatorClassName={styles.envelopeIndicator}
        image={link.image}
      />
    </DataLink>
  );
};

export default Inbox;
