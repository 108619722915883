import { FC } from 'react';
import { useSelector } from 'react-redux';

import type { PersonalizeSection } from 'src/__generated__/graphqlTypes';
import Content from 'src/components/Content/Content';
import { Store } from 'app-redux/types/storeTypes';
import { capitalizeFirstLetter } from 'lib/text.service';

const ContentContainer: FC = ({ children }) => {
  const slug = useSelector((store: Store) => store.server.page.slug);
  const page = useSelector((store: Store) => store.server.page.pages[slug]);
  const serverCookies = useSelector((store: Store) => store.server.app.serverCookies);
  const { contentCollection, relativeUrl, backgroundColor } = page;
  const content = contentCollection?.items as Array<PersonalizeSection>;

  if (!content?.length) {
    return null;
  }

  const className = relativeUrl
    ?.split('/')
    .filter((word) => Boolean(word))
    .map((word, i) => {
      const compleatWord = word
        .split('-')
        .map((word, i) => ((i === 0) ? word : capitalizeFirstLetter(word)))
        .join('');

      return ((i === 0) ? compleatWord : capitalizeFirstLetter(compleatWord));
    })
    .join('');

  return (
    <>
      {children}
      <Content
        content={content}
        className={className || slug}
        serverCookies={serverCookies}
        bgColor={backgroundColor}
      />
    </>
  );
};

export default ContentContainer;
