import { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { DataButton, WebpImage } from 'components/Shared/SharedComponents';
import type { Store } from 'app-redux/types/storeTypes';
import { ViewerDevice } from 'constants/enums';
import { getGaAccordion } from 'src/shared/lib/analytics/ga';

import styles from '../styles.module.scss';
import type { ICollapseButton } from '../../config/declarations';

const CollapseButton: FC<ICollapseButton> = ({
  button,
  isCollapsed,
  toggleCollapseButton,
}) => {
  const userDevice = useSelector((store: Store) => store.server.app.viewerDevice);

  if (!button?.image?.url || userDevice !== ViewerDevice.MOBILE) {
    return null;
  }

  const { _meta: meta = {} } = button?.gaProperties || {};
  const { image } = button;

  return (
    <DataButton
      link={button}
      gaData={getGaAccordion(meta, !isCollapsed)}
      onClick={toggleCollapseButton}
      className={cn(
        styles.collapseButton,
        (isCollapsed
          ? styles.collapsed
          : styles.expanded
        ) || '',
      )}
    >
      <WebpImage
        image="external"
        src={image.url!}
        className={styles.image}
      />
    </DataButton>
  );
};

export default CollapseButton;
