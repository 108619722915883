import {
  FC,
  useEffect,
  useRef,
} from 'react';
import cn from 'classnames';

import { HeaderNavInteraction } from 'features/HeaderNavInteraction';
import { DataButton } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';
import CloseHamburger from './CloseHamburger';
import SecondLevelItem from './2LItem';

import { useLocalDispatch } from '../lib/hooks';
import type { ISecondLevelMenu } from '../config/declarations';

/* Nested Component */
const BackButton: FC<any> = ({ button, onClick }) => {
  if (!button) {
    return null;
  }

  return (
    <DataButton
      link={button}
      onClick={onClick}
      className={styles.back}
    >
      <i className={styles.backTick} />
      <span className={styles.backTitle}>
        {button.title}
      </span>
    </DataButton>
  );
};

/* Main Component */
const SecondLevelMenu: FC<ISecondLevelMenu> = ({
  back,
  links,
  title,
  auth,
  rating,
  mobileApp,
  isSubMenuVisible,
}) => {
  const dispatch = useLocalDispatch();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (isSubMenuVisible) {
      const rect = ref.current.getBoundingClientRect();
      dispatch({ type: 'secondLevelMenuHeight', payload: `${rect.height}px` });
    } else {
      dispatch({ type: 'secondLevelMenuHeight', payload: undefined });
    }
  }, [ref, isSubMenuVisible]);

  return (
    <div
      ref={ref}
      className={cn(
        styles.l2nav,
        isSubMenuVisible
          ? styles.l2navVisible
          : styles.l2navHidden,
      )}
    >
      {isSubMenuVisible && <CloseHamburger /> }
      {isSubMenuVisible && (
        <div className={styles.l2navHeading}>
          <strong className={styles.title}>{title}</strong>
          <BackButton
            button={back}
            onClick={() => {
              dispatch({ type: 'isSubMenuVisible', payload: false });
            }}
          />
        </div>
      )}
      <ul className={styles.l2navUl}>
        {links.map((item) => (
          <SecondLevelItem
            key={item.sys.id}
            item={item}
          />
        ))}
      </ul>
      <HeaderNavInteraction
        auth={auth}
        rating={rating}
        mobileApp={mobileApp}
      />
    </div>
  );
};

export default SecondLevelMenu;
