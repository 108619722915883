import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Block, Link } from 'src/__generated__/graphqlTypes';
import { MysteryLinksSet } from 'components/Sections/MysteryLinksSet';
import { SectionComponentInterface } from 'types/componentTypes';
import { Store } from 'app-redux/types/storeTypes';
import { CommonContentfulSlug } from 'constants/enums';
import { useCommonContentfulBlocks } from 'lib/shared.hook';

const MysteryLinksSetContainer: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const {
    content,
    topDivider,
    bottomDivider,
    nextArrow,
    prevArrow,
  } = useCommonContentfulBlocks<CommonContentfulSlug, Block | Link>(blocks);

  const {
    mobileViewMaxWidth,
    commonPageMaxWidth,
    mysteryLinkSideSizeMobile,
  } = useSelector((store: Store) => store.server.app.scssVariables);
  const clientWidth = useSelector((store: Store) => store.client.app.clientWidth);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);

  return (
    <MysteryLinksSet
      mobileViewMaxWidth={mobileViewMaxWidth}
      commonPageMaxWidth={commonPageMaxWidth}
      clientWidth={clientWidth}
      content={content}
      topDivider={topDivider as Block}
      bottomDivider={bottomDivider as Block}
      prevArrow={prevArrow as Link}
      nextArrow={nextArrow as Link}
      bgColor={bgColor}
      extraData={extraData}
      viewerDevice={viewerDevice}
      mysteryLinkSideSizeMobile={mysteryLinkSideSizeMobile}
    />
  );
};

export default MysteryLinksSetContainer;
