import { CommonRulingPlanetAndSignElementInterface, ViewsMaxWidthInterface } from 'types/componentTypes';

export interface RulingPlanetAndSignElementInterface
  extends CommonRulingPlanetAndSignElementInterface,
  ViewsMaxWidthInterface {}

export interface RulingPlanetAndSignElementContainerInterface
  extends CommonRulingPlanetAndSignElementInterface {}

export enum ImageSideSizeEnum {
  DESKTOP = 80,
  TABLET = 63,
  MOBILE = 55,
}
