import React, {
  MouseEvent,
  Reducer,
  useMemo,
  useReducer,
} from 'react';
import Modal from 'react-modal';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';
import { useRouter } from 'next/router';

import styles from 'components/Sections/HoroscopeNewsletters/HoroscopeNewsletters.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { DateOfBirthInput, CommonTextInput } from 'components/Sections/HoroscopeNewsletters/Inputs';
import { CommonRichText, DataButton } from 'components/Shared/SharedComponents';
import { FreeHoroscopeInputsReducerType, RichTextParsersConfig } from 'types/objectTypes';
import { CloseModalMethodInterface } from 'types/componentTypes';
import { Alert, Input } from 'src/__generated__/graphqlTypes';
import { getDefaultDaysInMonthArray, getYears } from 'lib/date.service';
import {
  FreeHoroscopeModalInputs,
  InputPurpose,
  ModalWindowStepNumber,
} from 'constants/enums';
import { FreeHoroscopeModalInterface, FreeHoroscopesModalStepInterface } from 'components/Sections/HoroscopeNewsletters/declarations';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.freeHoroscopeModalTitle,
  },
  [MARKS.BOLD]: {
    classNames: cn(styles.freeHoroscopeModalTitle, styles.freeHoroscopeModalTitleBold),
  },
};

const initialState: FreeHoroscopeInputsReducerType = {
  birthDate: { },
  name: '',
  email: '',
  isEmailValid: true,
  isBirthDateValid: true,
  isNameValid: true,
};

const reducer = (state: FreeHoroscopeInputsReducerType, { type, payload }: any) => {
  switch (type) {
    case FreeHoroscopeModalInputs.MONTH:
    case FreeHoroscopeModalInputs.DAY:
    case FreeHoroscopeModalInputs.YEAR:
      return { ...state, birthDate: { ...state.birthDate, [type]: payload } };
    case FreeHoroscopeModalInputs.NAME:
    case FreeHoroscopeModalInputs.EMAIL:
    case FreeHoroscopeModalInputs.IS_BIRTH_DATE_VALID:
    case FreeHoroscopeModalInputs.IS_EMAIL_VALID:
    case FreeHoroscopeModalInputs.IS_NAME_VALID:
      return { ...state, [type]: payload };
    default: return state;
  }
};

const DEFAULT_YEARS_AMOUNT = 100;
const yearsArray = getYears(DEFAULT_YEARS_AMOUNT);

/* Nested component */
const CloseButton: React.FC<CloseModalMethodInterface> = ({ closeModal }) => (
  <button
    type="button"
    className={styles.freeHoroscopeModalClose}
    onClick={closeModal}
    onKeyPress={closeModal}
  >
    <span className={styles.freeHoroscopeModalCloseIcon} />
  </button>
);

/* Nested component */
const FirstStepModal: React.FC<FreeHoroscopesModalStepInterface> = ({
  step: firstModalStep,
  modalErrors,
  setModalErrors,
  goNextStepOrShowErrors,
  subscribeToNewsletters,
  closeModal,
}) => {
  const { locale } = useRouter();
  const [
    state,
    dispatch,
  ] = useReducer<Reducer<FreeHoroscopeInputsReducerType, FreeHoroscopeInputsReducerType>>(
    reducer,
    initialState,
  );
  const isFormValid = state.isBirthDateValid
    && state.isEmailValid
    && state.isNameValid
    && state.email
    && state.name
    && state.birthDate.day
    && state.birthDate.month
    && state.birthDate.year;
  const defaultDaysArray = useMemo(() => getDefaultDaysInMonthArray(), []);
  const submitForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (isFormValid
        && subscribeToNewsletters
        && goNextStepOrShowErrors
        && setModalErrors) {
      setModalErrors(undefined);
      const errors = await subscribeToNewsletters(state);
      goNextStepOrShowErrors(errors);
    }
  };

  if (!firstModalStep) {
    return null;
  }

  const {
    link,
    content,
    contentTypesCollection,
    textList = [],
  } = firstModalStep;
  const inputs = (contentTypesCollection?.items || []) as Array<Input>;

  return (
    <>
      {content && (
        <CommonRichText
          content={content}
          parsersConfig={parsersConfig}
        />
      )}
      <form
        id={styles.freeHoroscopeModalForm}
        className={styles.freeHoroscopeModalForm}
      >
        {inputs.map((input) => {
          const alerts = (input.alertsCollection?.items as Array<Alert>) || [];

          switch (input.purpose) {
            case InputPurpose.DATE:
              return (
                <DateOfBirthInput
                  key={input.placeholder!}
                  textList={textList as Array<string>}
                  locale={locale || 'en'}
                  modalErrors={modalErrors}
                  defaultDaysArray={defaultDaysArray}
                  yearsAmount={yearsArray}
                  placeholder={input.placeholder!}
                  birthDate={state.birthDate}
                  isValid={state.isBirthDateValid}
                  alerts={alerts}
                  dispatch={dispatch}
                />
              );
            case InputPurpose.NAME:
              return (
                <CommonTextInput
                  key={input.placeholder!}
                  placeholder={input.placeholder!}
                  value={state.name!}
                  isValid={state.isNameValid}
                  inputClassName={styles.freeHoroscopeModalFormInputName}
                  alerts={alerts}
                  dispatch={dispatch}
                />
              );
            case InputPurpose.EMAIL:
              return (
                <CommonTextInput
                  key={input.placeholder!}
                  placeholder={input.placeholder!}
                  isValid={state.isEmailValid}
                  alerts={alerts}
                  modalErrors={modalErrors}
                  inputClassName={styles.freeHoroscopeModalFormInputEmail}
                  inputProps={{ inputMode: 'email' }}
                  value={state.email!}
                  isEmail
                  dispatch={dispatch}
                />
              );
            default: return null;
          }
        })}
      </form>
      {link && (
        <DataButton
          type="submit"
          form={styles.freeHoroscopeModalForm}
          className={cn(
            styles.freeHoroscopeModalButton,
            !isFormValid && styles.freeHoroscopeModalButtonDisabled,
          )}
          onClick={submitForm}
          {...{ disabled: !isFormValid }}
        >
          {link.title}
        </DataButton>
      )}
      <CloseButton closeModal={closeModal} />
    </>
  );
};

/* Nested component */
const SecondStepModal: React.FC<FreeHoroscopesModalStepInterface> = ({
  step: secondModalStep,
  closeModal,
}) => {
  const { link, content } = secondModalStep;

  return (
    <>
      {content && (
        <CommonRichText
          content={content}
          parsersConfig={parsersConfig}
        />
      )}
      {link && (
        <DataButton
          type="button"
          className={cn(
            styles.freeHoroscopeModalButton,
            styles.freeHoroscopeModalStepLastButton,
          )}
          onClick={closeModal}
        >
          {link.title}
        </DataButton>
      )}
      <CloseButton closeModal={closeModal} />
    </>
  );
};

/* Main component */
const HoroscopeNewslettersModal: React.FC<FreeHoroscopeModalInterface> = ({
  isModalVisible,
  firstModalStep,
  lastModalStep,
  modalErrors,
  modalStep,
  setModalErrors,
  goNextStepOrShowErrors,
  subscribeToNewsletters,
  closeModal,
}) => {
  const applyStep = () => {
    switch (modalStep) {
      case ModalWindowStepNumber.FIRST: return (
        <FirstStepModal
          step={firstModalStep}
          modalErrors={modalErrors}
          closeModal={closeModal}
          setModalErrors={setModalErrors}
          goNextStepOrShowErrors={goNextStepOrShowErrors}
          subscribeToNewsletters={subscribeToNewsletters}
        />
      );
      case ModalWindowStepNumber.SECOND: return (
        <SecondStepModal
          step={lastModalStep}
          closeModal={closeModal}
        />
      );
      default: return null;
    }
  };

  return (
    <Modal
      isOpen={isModalVisible}
      className={cn(
        styles.freeHoroscopeModal,
        (modalStep === ModalWindowStepNumber.FIRST) && styles.freeHoroscopeModalStepFirst,
        (modalStep === ModalWindowStepNumber.SECOND) && styles.freeHoroscopeModalStepLast,
      )}
      overlayClassName={styles.freeHoroscopeModalBackground}
      shouldCloseOnOverlayClick
      bodyOpenClassName={classes.overflowHidden}
      onRequestClose={closeModal}
    >
      {applyStep()}
    </Modal>
  );
};

export default HoroscopeNewslettersModal;
