import {
  FC,
  useEffect,
  useState,
} from 'react';
import cookie, { CookieAttributes } from 'js-cookie';

import { getCurrentGeolocation } from 'src/api/customerApi';
import { calculateCookiesDomain } from 'src/shared/lib/cookie';
import { CookiePolicy } from 'components/CookiePolicy';
import { GDPR_COOKIE } from 'constants/constants';
import type { CookiePolicyContainerInterface } from 'components/CookiePolicy/declarations';
import { Logger } from 'lib/logger';

const CookiePolicyContainer: FC<CookiePolicyContainerInterface> = ({
  isStickyOfferExists,
  cookiePolicy,
}) => {
  const [isEuropeanCustomer, setEuropeanCustomerFlagState] = useState<boolean>(false);
  const [isAccepted, setAcceptedState] = useState<boolean>(Boolean(cookie.get(GDPR_COOKIE)));

  const acceptCookies = () => {
    const currentDate = new Date();
    const expirationDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 10));
    const domain = calculateCookiesDomain(
      window.location.origin,
      process.env.NEXT_PUBLIC_CP_DOMAIN,
    );

    const cookieOptions: CookieAttributes = {
      expires: expirationDate,
      domain,
      path: '/',
      secure: process.env.NODE_ENV === 'production',
    };
    cookie.set(GDPR_COOKIE, encodeURIComponent(true), cookieOptions);
    setAcceptedState(true);
  };

  useEffect(() => {
    const setInformationAboutCurrentUserLocation = async () => {
      if (isAccepted) {
        return;
      }

      try {
        const data = await getCurrentGeolocation();
        setEuropeanCustomerFlagState(data.isInEuropeanUnion);
      } catch (e) {
        Logger.error(e);
      }
    };

    if (window.requestIdleCallback) {
      window.requestIdleCallback(setInformationAboutCurrentUserLocation, { timeout: 2000 });
    } else {
      setTimeout(setInformationAboutCurrentUserLocation, 500);
    }
  }, [isAccepted]);

  if (!isEuropeanCustomer || isAccepted) {
    return null;
  }

  return (
    <CookiePolicy
      isStickyOfferExists={isStickyOfferExists}
      cookiePolicy={cookiePolicy}
      acceptCookies={acceptCookies}
    />
  );
};

export default CookiePolicyContainer;
