import {
  FC,
  KeyboardEvent,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { Cross } from 'src/shared/ui/Cross';
import type { Store } from 'app-redux/types/storeTypes';

import styles from './styles.module.scss';
import { PsychicsList } from './PsychicsList';

import {
  ISearchBoxTooltip,
  ISearchBoxTooltipClear,
  ISearchBoxTooltipClose,
} from '../config/declarations';

/* Nested component */
const Close: FC<ISearchBoxTooltipClose> = ({
  button,
  isMobile,
  isTooltip,
  tooltipRef,
  searchValue,
}) => {
  if ((!isMobile && !isTooltip) || (isMobile && !searchValue)) {
    return null;
  }

  return (
    <button
      type="button"
      className={styles.close}
      onClick={() => tooltipRef.current?.close()}
    >
      {button?.title || 'X'}
    </button>
  );
};

/* Nested component */
const Clear: FC<ISearchBoxTooltipClear> = ({
  searchValue,
  clear,
}) => {
  if (!searchValue) {
    return null;
  }

  return (
    <button
      className={styles.clear}
      type="button"
      onClick={clear}
    >
      <Cross size={14} />
    </button>
  );
};

const SearchBarInput: FC<ISearchBoxTooltip> = ({
  tooltipRef,
  searchRef,
  searchValue,
  searchBox,
  psychicsList,
  isTooltip = false,
  search,
  clear,
  changeHandler,
}) => {
  const isMobile = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const [isPsychicListVisible, setPsychicListVisibilityState] = useState<boolean>(false);

  const onPressSend = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      search?.();
    }
  };

  const placeholderImage = isMobile
    ? { background: `url(${searchBox.placeholderImage?.url}) 10px center / 20px no-repeat, rgb(255, 255, 255)` }
    : {};

  return (
    <>
      <input
        style={placeholderImage}
        ref={searchRef}
        value={searchValue}
        onKeyUp={onPressSend}
        onChange={changeHandler}
        type="text"
        className={styles.input}
        onFocus={setPsychicListVisibilityState.bind(this, true)}
        data-type="search"
        placeholder={searchBox.placeholder || ''}
      />
      <Clear
        searchValue={searchValue}
        clear={clear}
      />
      <Close
        isTooltip={isTooltip}
        isMobile={isMobile}
        searchValue={searchValue}
        button={searchBox.cancel}
        tooltipRef={tooltipRef}
      />
      <PsychicsList
        baseLink={searchBox.baseLink!}
        psychicsList={psychicsList}
        isPsychicListVisible={isPsychicListVisible}
      />
    </>
  );
};

export default SearchBarInput;
