import { CSSProperties } from 'react';

import { ItemsLayout, PsychicCardAppearance } from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';
import { RightPsychic } from 'types/objectTypes';

export const getPsychicsLayoutStyle = (
  itemsLayout: ItemsLayout,
  psychicCardAppearance: PsychicCardAppearance,
  array: Array<RightPsychic>,
): CSSProperties | undefined => {
  if (itemsLayout !== ItemsLayout.GRID || psychicCardAppearance === PsychicCardAppearance.LIST) {
    return;
  }

  if ((psychicCardAppearance === PsychicCardAppearance.SIMPLE && array.length < 5)
    || (psychicCardAppearance === PsychicCardAppearance.TILE && array.length < 3)) {
    return { gridTemplateColumns: new Array(array.length).fill('1fr').join(' ') };
  }
};

export const getItemsLayoutClass = (
  psychicCardAppearance: PsychicCardAppearance,
  itemsLayout: ItemsLayout,
) => {
  if (psychicCardAppearance === PsychicCardAppearance.LIST) {
    return capitalizeFirstLetter(ItemsLayout.COLUMN);
  }

  return capitalizeFirstLetter(itemsLayout);
};

export const getPsychicCardAppearanceName = (psychicCardAppearance: PsychicCardAppearance) => {
  if (psychicCardAppearance === PsychicCardAppearance.SIMPLE
      || psychicCardAppearance === PsychicCardAppearance.SIMPLE_ADDITIONAL
      || psychicCardAppearance === PsychicCardAppearance.SIMPLE_WITH_DESCRIPTION) {
    return 'Simple';
  }

  if (psychicCardAppearance === PsychicCardAppearance.SIMPLE_VERTICAL
      || psychicCardAppearance === PsychicCardAppearance.SIMPLE_HORIZONTAL) {
    return 'SimpleExtended';
  }

  if (psychicCardAppearance === PsychicCardAppearance.TILE) {
    return 'Tile';
  }
};
