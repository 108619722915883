import {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';

import { capitalizeFirstLetter, replaceContentfulVariables } from 'lib/text.service';
import classes from 'src/styles/_commonClasses.module.scss';
import { Status } from 'constants/enums';

import { TextStatusComponentInterface } from '../config/declarations';

const Text: FC<TextStatusComponentInterface> = ({
  status,
  psychic,
  className,
  offsetClass,
  psychicFrame,
  wrapperClass,
  smallTextClass,
}) => {
  const textStyles = cn(className, classes.statusText);
  const [backInTime, setBackInTime] = useState<number>(psychic?.onBreakMinutes);
  const wasOnBreakRef = useRef<boolean>(false);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    if (status === Status.ON_BREAK) {
      if (!wasOnBreakRef.current) {
        wasOnBreakRef.current = true;

        return setBackInTime(psychic?.onBreakMinutes);
      }

      timeout = setTimeout(() => setBackInTime((prev) => prev - 1), 60 * 1000);

      if (!backInTime) {
        clearTimeout(timeout);
      }

      return;
    }

    if (wasOnBreakRef.current && timeout) {
      wasOnBreakRef.current = false;
      clearTimeout(timeout);
    }
  }, [status, psychic, backInTime, wasOnBreakRef]);

  if (status === Status.ON_BREAK) {
    const backIn = replaceContentfulVariables(
      psychicFrame?.backIn!,
      { min: `${backInTime}` },
    );

    return (
      <div className={wrapperClass}>
        <span className={cn(textStyles, classes.statusTextBusy)}>
          {Status.DISPLAY_ON_BREAK}
        </span>
        {backIn && (
          <span className={smallTextClass || classes.statusTextSmall} style={{ fontSize: '10px', textAlign: 'center' }}>
            {backIn}
          </span>
        )}
      </div>
    );
  }

  const simpleStyles = cn(
    textStyles,
    classes[`statusText${capitalizeFirstLetter(status)}`],
    offsetClass,
  );

  return (
    <span className={simpleStyles}>
      {status}
    </span>
  );
};

export default Text;
