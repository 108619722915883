import { FC } from 'react';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import { capitalizeFirstLetter as capitalize } from 'lib/text.service';

import styles from './OrangeAlertBig.module.scss';
import ExtraComponentProcessor from './ExtraComponentProcessor';

import { constructId } from '../lib';
import { IContentWrapper } from '../config/declarations';
import { BlockPosition } from '../config/constants';

const ContentWrapper: FC<IContentWrapper> = ({
  id,
  children,
  blockAlign,
  colorTheme,
  mobileBlockJustify,
  isMobile,
}) => (
  <div
    id={constructId(id, '-wrapper')}
    className={cn(
      styles.alertWrapper,
      styles[`wrappedItemAlign${capitalize(blockAlign)}`],
    )}
  >
    <ExtraComponentProcessor
      location={BlockPosition.WRAPPER_CHILD_BEFORE}
    />
    <div className={cn(
      styles.alert,
      classes[`themeColor${capitalize(colorTheme)}`],
      styles[`blockAlign${capitalize(blockAlign)}`],
      { [styles[`mobileBlockJustify${capitalize(mobileBlockJustify)}`]]: isMobile && mobileBlockJustify },
    )}
    >
      {children}
    </div>
    <ExtraComponentProcessor
      location={BlockPosition.WRAPPER_CHILD_AFTER}
    />
  </div>
);

export default ContentWrapper;
