import { FC, useRef } from 'react';

import styles from './styles.module.scss';
import ThirdLevelItem from './3LItem';

import type { IThirdLevelMenu } from '../config/declarations';
import { useToggleTransition } from '../lib/hooks';

const ThirdLevelMenu: FC<IThirdLevelMenu> = ({ links, isVisible }) => {
  const ulRef = useRef<HTMLUListElement>(null);
  useToggleTransition(ulRef, isVisible);

  if (!links?.length) {
    return null;
  }

  return (
    <ul
      ref={ulRef}
      className={styles.l3navUl}
    >
      {links.map((link) => (
        <ThirdLevelItem
          item={link}
          key={link._id}
        />
      ))}
    </ul>
  );
};

export default ThirdLevelMenu;
