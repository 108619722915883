import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { FC } from 'react';

import { CommonRichText } from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';

import styles from './AppointmentCalendar.module.scss';

import type { IAppointmentHeader } from '../config/declarations';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.headerText,
  },
  [MARKS.BOLD]: {
    classNames: styles.headerHeading,
  },
};

const AppointmentHeader: FC<IAppointmentHeader> = ({ block, appointmentsAmount }) => {
  if (!block?.richTitle) {
    return null;
  }

  return (
    <div className={styles.header}>
      <CommonRichText
        content={block?.richTitle}
        parsersConfig={parsersConfig}
      />
      {' '}
      <span className={styles.headerText}>{appointmentsAmount || 0}</span>
    </div>

  );
};

export default AppointmentHeader;
