import { ChangeEvent, FC } from 'react';
import cn from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import dynamic from 'next/dist/shared/lib/dynamic';

import classes from 'src/styles/_commonClasses.module.scss';
import type { Link } from 'src/__generated__/graphqlTypes';
import { EMAIL } from 'src/constants/constants';
import styles from 'components/Header/MiddleBar/Interaction/LoginForm/LoginForm.module.scss';
import { LoginFormAlert } from 'components/Header/MiddleBar/Interaction/LoginForm';
import { DataLink } from 'components/Shared/SharedComponents';
import { LoginFormInterface, LoginFormSubmit } from 'components/Header/MiddleBar/Interaction/LoginForm/declarations';
import PasswordInput from 'components/Header/MiddleBar/Interaction/LoginForm/PasswordInput';
import { AuthProviders } from 'constants/enums';
import type { AuthenticationButton as AuthenticationButtonType, SubmitButton as SubmitButtonType } from 'entities/AuthenticationButtons';

const AuthenticationButton = dynamic(import('entities/AuthenticationButtons')
  .then((mod) => mod.AuthenticationButton)) as typeof AuthenticationButtonType;
const SubmitButton = dynamic(import('entities/AuthenticationButtons')
  .then((mod) => mod.SubmitButton)) as typeof SubmitButtonType;

const LoginForm: FC<LoginFormInterface> = ({
  alert,
  isShown,
  loginForm,
  isAlertShown,
  emailRef,
  passwordRef,
  formInputs,
  isRecaptchaVisible,
  isRecaptchaAfterLoad,
  onRecaptchaChange,
  setExternalSignInAlert,
  setFormInputs,
  handleSubmit,
}) => {
  const clickWrapper = (e: LoginFormSubmit, handler: (e: LoginFormSubmit) => void) => {
    handler(e);
    e.currentTarget.blur();
  };
  const hidingClass = { [classes.displayNone]: !isShown };

  const submitForm = (e: LoginFormSubmit) => clickWrapper(e, handleSubmit);
  const {
    link: forgotPasswordLink,
    title,
    separator,
    password,
    email,
    signIn,
    iconsCollection,
  } = loginForm;

  const passwordImage = iconsCollection?.items?.find((item) => item?.slug === 'password');
  const applyButtons = () => {
    const buttons = loginForm?.buttonsCollection?.items as Array<Link>;

    return buttons?.map((button: Link) => (
      <AuthenticationButton
        provider={button.slug as AuthProviders}
        key={button.entryName}
        button={button}
        signInSrc={loginForm.signIn?.src}
        className={styles.loginRestButton}
        setAlert={setExternalSignInAlert}
      />
    ));
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target || {};
    setFormInputs((prev) => ({ ...prev, [name]: value }));
  };
  const isSignInButtonDisabled = !formInputs.email
    || !formInputs.password
    || (isRecaptchaVisible && Boolean(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY));
  const isInputDisables = isRecaptchaVisible && !isRecaptchaAfterLoad;

  return (
    <>
      <div className={cn(styles.loginArrow, hidingClass)} />
      <div className={cn(styles.login, hidingClass)}>
        <span className={styles.loginTitle}>{title}</span>
        <form className={styles.loginForm} onSubmit={submitForm}>
          <input
            ref={emailRef}
            className={styles.loginFormInput}
            autoComplete="email"
            type="email"
            value={formInputs.email}
            name={EMAIL}
            disabled={isInputDisables}
            placeholder={email || ''}
            onChange={onChange}
          />
          <PasswordInput
            passwordRef={passwordRef}
            password={password}
            image={passwordImage?.image}
            value={formInputs.password}
            disabled={isInputDisables}
            onChange={onChange}
          />
          {isAlertShown && alert && <LoginFormAlert alert={alert} />}
          {isRecaptchaVisible && process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY && (
            <ReCAPTCHA
              className={styles.recaptcha}
              size="normal"
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
              onChange={onRecaptchaChange}
            />
          )}
          <SubmitButton
            link={signIn!}
            className={styles.loginFormSubmit}
            disabled={isSignInButtonDisabled}
            onClick={submitForm}
          />
          <input type="submit" hidden />
        </form>
        <div className={styles.loginSeparator}>
          <hr className={styles.loginSeparatorLine} />
          <span className={styles.loginSeparatorText}>{separator}</span>
          <hr className={styles.loginSeparatorLine} />
        </div>
        {applyButtons()}
        {forgotPasswordLink && (
          <DataLink
            link={forgotPasswordLink}
            href={forgotPasswordLink.src || ''}
            className={styles.loginForgot}
          >
            {forgotPasswordLink.title}
          </DataLink>
        )}
      </div>
    </>
  );
};

export default LoginForm;
