import { FC } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

import type { RichTextParsersConfig } from 'types/objectTypes';
import { CommonRichText, DataLink } from 'components/Shared/SharedComponents';
import { mapSmallText } from 'lib/richTextMappers';

import styles from './styles.module.scss';

import type { IECAdditionalInformation } from '../../config/declarations';

const contentConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.text,
    mapper: mapSmallText,
  },
};

const ECAdditionalInformation: FC<IECAdditionalInformation> = ({ block }) => {
  if (!block) {
    return null;
  }

  const { link } = block;

  return (
    <div className={styles.faq}>
      {link && (
        <DataLink link={link} className={styles.link}>
          {link.title}
        </DataLink>
      )}
      <CommonRichText content={block.content} parsersConfig={contentConfig} />
    </div>
  );
};

export default ECAdditionalInformation;
