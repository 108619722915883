import ModalReact from 'react-modal';
import cn from 'classnames';
import {
  BLOCKS,
  INLINES,
  MARKS,
} from '@contentful/rich-text-types';
import { FC } from 'react';

import classes from 'styles/_commonClasses.module.scss';
import { CommonRichText } from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';

import styles from './styles.module.scss';

import { ModalInterface } from '../config/declarations';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.content,
  },
  [INLINES.HYPERLINK]: {
    classNames: styles.contentLink,
  },
  [MARKS.UNDERLINE]: {
    classNames: styles.contentUnderline,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
};

const Modal: FC<ModalInterface> = ({
  content,
  isOpened,
  buttonTitle,
  closeModal,
}) => (
  <ModalReact
    isOpen={isOpened}
    className={cn(styles.modal, classes.modal)}
    overlayClassName={classes.modalBackground}
  >
    <CommonRichText
      content={content}
      parsersConfig={parsersConfig}
    />
    <button
      type="button"
      className={styles.button}
      onClick={closeModal}
    >
      {buttonTitle}
    </button>
  </ModalReact>
);

export default Modal;
