import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serialize } from 'cookie';

import type { Store } from 'app-redux/types/storeTypes';
import { getCustomerDetails } from 'src/api/customerApi';
import { buildPartialAuthObjectFromCustomerDetails } from 'lib/auth.service';
import { setUser } from 'app-redux/actions/authActions';
import { SESSION_COOKIE_NAME } from 'constants/constants';
import { Logger } from 'lib/logger';

import { getAuthProviderSessionCookie } from '.';

export const useUpdateCustomerDetails = () => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const dispatch = useDispatch();
  const isUpdated = useRef<boolean>(false);
  const [isLoading, setLoadingState] = useState<boolean>(false);

  useEffect(() => {
    if (!user || isUpdated.current) {
      return;
    }

    (async () => {
      try {
        setLoadingState(true);
        const customerDetails = await getCustomerDetails(user.custId);
        const shouldUserBeUpdated = customerDetails.dollarBalance !== user.dollarBalance
          || customerDetails.availableDollarBalance !== user.availableBalance
          || customerDetails.karmaPoints !== user.karmaPoints;

        if (!shouldUserBeUpdated) {
          return;
        }

        const mappedDetails = buildPartialAuthObjectFromCustomerDetails(customerDetails);
        const newUser = { ...user, ...mappedDetails };
        dispatch(setUser(newUser));
        document.cookie = serialize(
          SESSION_COOKIE_NAME,
          JSON.stringify(newUser),
          getAuthProviderSessionCookie(),
        );
      } catch (e) {
        Logger.error(e);
      } finally {
        isUpdated.current = true;
        setLoadingState(false);
      }
    })();
  }, [user, isUpdated]);

  return { isLoading };
};
