import React, { useMemo, useState } from 'react';

import { Alert } from 'src/__generated__/graphqlTypes';
import styles from 'components/Sections/HoroscopeNewsletters/HoroscopeNewsletters.module.scss';
import { DEFAULT_SELECT_OPTION } from 'constants/constants';
import { AlertTypes, FreeHoroscopeModalInputs } from 'constants/enums';
import { InputAlertInterface } from 'components/Sections/HoroscopeNewsletters/Inputs/declarations';

export type AlertStoreType = {
  alert: string | null,
  setValidState: () => void,
  setInvalidState: (alertType: AlertTypes | null, messageText?: string | null) => void,
  togleValidityState: (flag: boolean, alertType?: AlertTypes) => void,
}

export const setSelectValueOrDefault = (value?: string) => value || DEFAULT_SELECT_OPTION;

export const useAlertStore = (
  alerts: Array<Alert>,
  type: FreeHoroscopeModalInputs,
  dispatch: React.Dispatch<any>,
): AlertStoreType => {
  const alertStore = useMemo(() => {
    const store: typeof AlertTypes = alerts.reduce((accumilator, alert) => {
      if (alert.type) {
        // eslint-disable-next-line no-param-reassign
        accumilator[alert.type] = alert;
      }

      return accumilator;
    }, {} as any);

    return store;
  }, [alerts]);
  const [alert, setAlert] = useState<string | null>(null);

  const setAlertFromStore = (alertType: AlertTypes) => {
    const alert: Alert | undefined = alertStore[alertType];

    return setAlert(alert?.title || null);
  };

  const setValidState = () => {
    dispatch({ type, payload: true });
  };

  const setInvalidState = (alertType: AlertTypes | null, messageText?: string | null) => {
    dispatch({ type, payload: false });

    if (alertType) {
      setAlertFromStore(alertType);
    } else if (messageText) {
      setAlert(messageText);
    }
  };

  const toggleValidityState = (flag: boolean, alertType?: AlertTypes) => {
    if (flag) {
      setValidState();
    } else if (!flag && alertType) {
      setInvalidState(alertType);
    } else {
      dispatch({ type, payload: false });
    }
  };

  return { alert, setValidState, setInvalidState, togleValidityState: toggleValidityState };
};

export const InputAlert: React.FC<InputAlertInterface> = ({
  isValid,
  alert,
}) => ((!isValid && alert)
  ? (
    <small className={styles.freeHoroscopeModalFormControlError}>
      {alert}
    </small>
  )
  : null);
