import { FC, useRef } from 'react';
import cn from 'classnames';
import { Tooltip } from 'react-tooltip';

import classes from 'src/styles/_commonClasses.module.scss';

import styles from '../styles.module.scss';
import type { IOfferHint } from '../../config/declarations';

const OfferHint: FC<IOfferHint> = ({ id, description }) => {
  const tooltipWrapper = useRef<HTMLDivElement>(null);

  return (
    <>
      <button
        type="button"
        data-tooltip-id={`offer-${id}`}
        className={cn(styles.icon, classes.cursorPointer)}
      >
        i
      </button>
      <div ref={tooltipWrapper}>
        <Tooltip
          openEvents={{ click: true }}
          globalCloseEvents={{ clickOutsideAnchor: true }}
          closeEvents={{ click: true, mouseleave: false }}
          id={`offer-${id}`}
          content={description}
          place="bottom"
          className={styles.hint}
        />
      </div>
    </>
  );
};

export default OfferHint;
