import { FC } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import classes from 'src/styles/_commonClasses.module.scss';
import type { Store } from 'app-redux/types/storeTypes';
import { DataLink, WebpImage } from 'components/Shared/SharedComponents';
import { FullBackgroundImage } from 'src/shared/ui/FullBackgroundImage';
import type { ImageWrapper, Link } from 'src/__generated__/graphqlTypes';

import styles from './styles.module.scss';

import type { IDefaultCard, IDefaultCardLink } from '../config/declarations';

/* Nested component */
const CardLink: FC<IDefaultCardLink> = ({ link, collection }) => {
  const user = useSelector((store: Store) => store.server.auth.user);

  if (!link) {
    return null;
  }

  const isButtonForKarmaMember = link.slug === 'member' && user?.isKarmaMember;
  const isButtonNotRelatedToKarma = !link.slug;

  if (!user || isButtonNotRelatedToKarma || isButtonForKarmaMember) {
    return (
      <DataLink
        link={link}
        className={styles.link}
      >
        {link.title}
      </DataLink>
    );
  }

  const noKarmaLink = collection?.find((item) => item.slug === 'not-member');

  if (!noKarmaLink) {
    return null;
  }

  return (
    <DataLink
      link={noKarmaLink}
      className={styles.link}
    >
      {noKarmaLink.title}
    </DataLink>
  );
};

/* Main component */
const DefaultCard: FC<IDefaultCard> = ({ card }) => {
  if (!card) {
    return null;
  }

  return (
    <li className={cn(
      styles.card,
      styles.default,
      { [styles.defaultSmallGap]: card.image?.url },
    )}
    >
      {card.image?.url && (
        <WebpImage
          src={card.image.url}
          image="external"
          className={styles.image}
        />
      )}
      <p className={cn(
        styles.text,
        classes.textBold,
        { [styles.textSmall]: card.image?.url },
      )}
      >
        {card.title}
      </p>
      <CardLink
        link={card.link}
        collection={card.contentTypesCollection?.items as Array<Link>}
      />
      {card.imagesCollection?.items?.length
      && (
        <FullBackgroundImage
          skipMobile
          skipTablet
          backgrounds={card.imagesCollection?.items as Array<ImageWrapper>}
          className={styles.bg}
        />
      )}
    </li>
  );
};

export default DefaultCard;
