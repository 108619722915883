import { Block } from 'src/__generated__/graphqlTypes';
import { ViewsMaxWidthInterface } from 'types/componentTypes';

export interface AllAboutSignsLuckColorInterface
  extends ViewsMaxWidthInterface {
  luckyColor: Block;
}

export enum LuckyColorImageSizeSlug {
  DESKTOP_HEIGHT = 64,
  DESKTOP_WIDTH = 146,
  MOBILE_HEIGHT = 52,
  MOBILE_WIDTH = 118,
}
