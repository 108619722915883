import { FC, useRef } from 'react';
import cn from 'classnames';

import styles from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/Divider/HoroscopesDivider.module.scss';
import { LazyWebpImage, WebpImage } from 'components/Shared/SharedComponents';
import { capitalizeFirstLetter } from 'lib/text.service';
import { HoroscopeDividerInterface } from 'components/Sections/HoroscopesSignsHoroscopesAndPredictions/Divider/declarations';

const IMAGE_SIDE_SIZE = '37px';

const HoroscopesDivider: FC<HoroscopeDividerInterface> = ({
  block: divider,
  zodiacSign,
  className,
  lazy,
}) => {
  const pictureRef = useRef<HTMLImageElement>(null);

  const { image } = divider;
  const lineClasses = cn(
    styles.dividerLine,
    zodiacSign && styles[`dividerLine${capitalizeFirstLetter(zodiacSign)}`],
  );
  const Component = lazy ? LazyWebpImage : WebpImage;

  return (
    <div className={cn(styles.divider, className)}>
      <span className={lineClasses} />
      {image && (
        <Component
          image={image}
          height={IMAGE_SIDE_SIZE}
          width={IMAGE_SIDE_SIZE}
          ref={pictureRef}
          className={styles.dividerImage}
        />
      )}
      <span className={lineClasses} />
    </div>
  );
};

export default HoroscopesDivider;
