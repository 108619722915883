/* eslint-disable no-underscore-dangle */
import {
  FC,
  Fragment,
  useMemo,
  useState,
} from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import { Block, Link } from 'src/__generated__/graphqlTypes';
import {
  RichTextParsersConfig,
  SectionExtraDataType,
  WebpWidthLimitType,
} from 'types/objectTypes';
import styles from 'components/Sections/TextAndSideAsset/TextAndSideAsset.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { TextAndSideAssetBlock } from 'components/Sections/TextAndSideAsset';
import {
  CommonRichText,
  DataButton,
  DataLink,
  WebpImage,
} from 'components/Shared/SharedComponents';
import Divider from 'components/Sections/Divider';
import {
  TextAndSideAssetButtonInterface as ButtonInterface,
  TextAndSideAssetCustomAssetInterface as CustomAssetInterface,
  TextAndSideAssetInterface,
  TextAndSideAssetRichTextInterface as RichTextInterface,
  TextAndSideAssetTextClassNames as TextClassNames,
} from 'components/Sections/TextAndSideAsset/declarations';
import {
  CommonSize,
  ElementAlign,
  ImageFitMode,
  ViewerDevice,
} from 'constants/enums';
import { getImagesWithLimitedSize } from 'lib/image.service';
import { capitalizeFirstLetter } from 'lib/text.service';
import { BUTTON, YOUTUBE } from 'constants/constants';

const DESKTOP_TABLET_IMAGE_WIDTH = 500;
const MOBILE_IMAGE_WIDTH = 330;

const getParsersConfig = (
  {
    titleAlign,
    sectionSize,
    pAlign,
  }: SectionExtraDataType,
  {
    titleClasses,
    paragraphClasses,
  }: TextClassNames = {},
): RichTextParsersConfig => {
  const pAlignClass = classes[`pAlign${capitalizeFirstLetter(pAlign || titleAlign)}`];
  const getHClasses = (specificClass: string) => cn(
    styles[`${sectionSize}ContentTitle`],
    specificClass,
    classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`],
    titleClasses,
  );

  return ({
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(styles[`${sectionSize}ContentParagraph`], pAlignClass, paragraphClasses),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getHClasses(classes.titleMainH3),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHClasses(classes.titleMainH2),
    },
    [BLOCKS.HEADING_1]: {
      classNames: getHClasses(classes.titleMainH1),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};

/* Nested component */
const CustomAsset: FC<CustomAssetInterface> = ({
  image,
  video,
  assetAlign,
  videoAutoplay,
  isMobileViewWidth,
  mobileViewMaxWidth,
}) => {
  const [isPreviewImageClicked, setPreviewImageClicked] = useState<boolean>(false);

  const onClickPreviewImage = () => {
    setPreviewImageClicked(true);
  };
  const isVideoExists = video && (video.src || video.image?.url);
  const isVideoWithPreview = !isPreviewImageClicked && image && isVideoExists;
  const isVideo = isPreviewImageClicked && isVideoExists;
  const isImage = image && !isVideoExists;
  const mobileHeight = Math.round((image.height! / image.width!) * MOBILE_IMAGE_WIDTH);

  const widthLimit: Array<WebpWidthLimitType> = image
    ? getImagesWithLimitedSize([{
      width: MOBILE_IMAGE_WIDTH,
      height: mobileHeight,
      media: `${mobileViewMaxWidth}px`,
    },
    {
      width: DESKTOP_TABLET_IMAGE_WIDTH,
      isMinWidth: true,
      media: `${mobileViewMaxWidth}px`,
    },
    ], { fit: ImageFitMode.SCALE })
    : [];

  if (isVideoWithPreview) {
    return (
      <DataButton
        link={video}
        className={cn(
          styles[`${assetAlign}Asset`],
          styles[`${assetAlign}AssetButton`],
          classes.cursorPointer,
        )}
        onClick={onClickPreviewImage}
        onKeyPress={onClickPreviewImage}
      >
        <WebpImage
          image={image}
          widthLimit={widthLimit}
          aria-label={image.title as string}
          className={cn(styles[`${assetAlign}Asset`], styles[`${assetAlign}AssetImage`])}
        />
      </DataButton>
    );
  }

  if (isVideo) {
    const { src, title, image } = video;
    const isYoutube = src?.toLowerCase()?.includes(YOUTUBE);
    const width = isMobileViewWidth ? MOBILE_IMAGE_WIDTH : DESKTOP_TABLET_IMAGE_WIDTH;

    if (isYoutube) {
      return (
        <iframe
          className={cn(styles[`${assetAlign}Asset`], styles[`${assetAlign}AssetVideo`])}
          src={src!}
          title={title!}
          height={mobileHeight}
          width={width}
          allow={videoAutoplay ? 'autoplay' : ''}
          frameBorder="0"
          allowFullScreen
        />
      );
    }

    const videoSource = (src === '#' || !src)
      ? image?.url
      : src;

    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video width={width} controls autoPlay={videoAutoplay}>
        <source src={videoSource!} />
      </video>
    );
  }

  if (isImage) {
    return (
      <WebpImage
        image={image}
        widthLimit={widthLimit}
        className={cn(styles[`${assetAlign}Asset`], styles[`${assetAlign}AssetImage`])}
      />
    );
  }

  return null;
};

/* Nested component */
const RichText: FC<RichTextInterface> = ({
  richText,
  richTextExtraData,
  extraClasses,
}) => {
  if (!richText) {
    return null;
  }

  return (
    <CommonRichText
      content={richText}
      parsersConfig={getParsersConfig(richTextExtraData, extraClasses)}
    />
  );
};

/* Nested component */
const Title = RichText;

/* Nested component */
const Button: FC<ButtonInterface> = ({
  link,
  className,
  isVisible,
}) => {
  if (!isVisible || !link) {
    return null;
  }

  return (
    <DataLink
      link={link}
      className={className}
    >
      {link.title}
    </DataLink>
  );
};

/* Main component */
const TextAndSideAsset: FC<TextAndSideAssetInterface> = ({
  content: block,
  topDivider,
  bottomDivider,
  extraData,
  bgColor,
  viewerDevice,
  isMobileViewWidth,
  mobileViewMaxWidth,
  commonTabletMaxWidth,
  commonPageMaxWidth,
}) => {
  const isDesktopWidth = useMemo(() => {
    if (process.browser && commonPageMaxWidth) {
      return +commonPageMaxWidth < window.innerWidth;
    }

    return viewerDevice === ViewerDevice.DESKTOP;
  }, [commonPageMaxWidth, viewerDevice]);

  const {
    assetAlign = ElementAlign.LEFT,
    buttonWithContent = true,
    titleAlign = ElementAlign.RIGHT,
    verticalPadding = CommonSize.MEDIUM,
    sectionSize = CommonSize.MEDIUM,
    videoAutoplay = false,
    pAlign,
  } = extraData || {};
  const richTextExtraData = { titleAlign, sectionSize, pAlign };
  const {
    content,
    richTitle,
    image,
    link,
    entryName,
    video,
    contentTypesCollection,
  } = block;
  const allBlocks = contentTypesCollection?.items as Array<Block | Link>;
  const { blocks, extraButtons } = allBlocks.reduce((store, item) => {
    if (item.__typename === 'Link' && item.slug === BUTTON) {
      store.extraButtons.push(item);
    } else {
      store.blocks.push(item as Block);
    }

    return store;
  }, { blocks: [] as Array<Block>, extraButtons: [] as Array<Link> });

  const buildComponents = () => {
    const contentBlock = (
      <TextAndSideAssetBlock
        assetAlign={assetAlign}
        sectionSize={sectionSize}
        blocks={blocks!}
        mobileViewMaxWidth={mobileViewMaxWidth}
      />
    );
    const isBlockContentVisible = blocks?.length > 0;

    return (
      <Fragment key={entryName}>
        <Title
          richText={richTitle}
          richTextExtraData={richTextExtraData}
          extraClasses={{ titleClasses: styles.wrapperTitle }}
        />
        <div className={styles[`${assetAlign}`]}>
          <CustomAsset
            image={image!}
            video={video!}
            assetAlign={assetAlign}
            videoAutoplay={videoAutoplay}
            isMobileViewWidth={isMobileViewWidth}
            mobileViewMaxWidth={mobileViewMaxWidth}
            commonTabletMaxWidth={commonTabletMaxWidth}
          />
          {(isDesktopWidth || content) && (
            <div className={styles[`${assetAlign}Content`]}>
              <RichText
                richText={content}
                richTextExtraData={richTextExtraData}
                extraClasses={{
                  paragraphClasses: isBlockContentVisible
                    ? styles[`${assetAlign}ContentParagraphMargin`]
                    : '',
                }}
              />
              {isDesktopWidth && contentBlock}
              <div className={styles[`${assetAlign}ContentButtonWrapper`]}>
                <Button
                  link={link}
                  isVisible={buttonWithContent}
                  className={styles[`${assetAlign}ContentButton`]}
                />
                {extraButtons.map((link) => (
                  <Button
                    key={link.entryName}
                    link={link}
                    isVisible={buttonWithContent}
                    className={cn(
                      styles[`${assetAlign}ContentButton`],
                      styles[`${assetAlign}ContentButtonSecondary`],
                    )}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        {!isDesktopWidth && isBlockContentVisible && (
          <div className={styles[`${assetAlign}Content`]}>
            {contentBlock}
          </div>
        )}
        <Button
          link={link}
          isVisible={!buttonWithContent}
          className={styles.wrapperButton}
        />
      </Fragment>
    );
  };

  return (
    <section
      className={cn(styles.wrapper, classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`])}
      style={{ background: bgColor }}
    >
      <Divider
        block={topDivider}
        maxWidth={`${commonPageMaxWidth}px`}
      />
      {buildComponents()}
      <Divider
        block={bottomDivider}
        maxWidth={`${commonPageMaxWidth}px`}
      />
    </section>
  );
};

export default TextAndSideAsset;
