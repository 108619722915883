import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { getRightPsychicsProxy, getTestimonialsByExtIdProxy } from 'src/api/psychicApi';
import { Store } from 'app-redux/types/storeTypes';
import { mapSearchOptions } from 'lib/serverMapper.service';
import { Logger } from 'lib/logger';

import PsychicsWithTestimonials from './PsychicsWithTestimonials';

import { IPsychicsWithTestimonialsContainer } from '../config/declarations';

const getPsychicsWithTestimonials = async (psychics: Array<any>) => {
  const pageSize = 1;
  const psychicPromises = psychics
    ?.map(({ extId }) => getTestimonialsByExtIdProxy({ extId, pageSize }));
  const psychicsWithTestimonials = await Promise.all([...psychicPromises]);

  return psychicsWithTestimonials;
};

const PsychicsWithTestimonialsContainer: FC<IPsychicsWithTestimonialsContainer> = ({
  block,
  slug,
  zodiacSign,
}) => {
  const { blocks, images } = block;
  const [topPsychicsBlock] = blocks;
  const [leftArrow, rightArrow] = images;
  const [psychics, setPsychics] = useState<Array<any>>([]);
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const user = useSelector((store: Store) => store.server.auth.user);
  const {
    loader: loaderImage,
  } = useSelector((store: Store) => store.server.page.pages[slug]) || {};

  useEffect(() => {
    (async () => {
      const requestPsychicParameters = {
        format: 'json',
        custID: user?.custId,
        SearchOptions: mapSearchOptions({ tool: 'Astrology' }),
      };
      try {
        setLoadingState(true);
        const { psychics } = await getRightPsychicsProxy(requestPsychicParameters);

        const psychicsWithTestimonials = await getPsychicsWithTestimonials(psychics);
        setPsychics(psychicsWithTestimonials);
      } catch (e) {
        Logger.error(e);
      } finally {
        setLoadingState(false);
      }
    })();
  }, [user]);

  if (!topPsychicsBlock) {
    return null;
  }

  return (
    <PsychicsWithTestimonials
      topPsychicsBlock={topPsychicsBlock as any}
      psychics={psychics}
      loaderImage={loaderImage!}
      isLoading={isLoading}
      leftArrow={leftArrow}
      rightArrow={rightArrow}
      zodiacSign={zodiacSign}
    />
  );
};

export default PsychicsWithTestimonialsContainer;
