import { FC } from 'react';

import { DataLink } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';

import type { IQuickLink } from '../config/declarations';

const QuickLinks: FC<IQuickLink> = ({ links }) => {
  if (!links?.length) {
    return null;
  }

  return (
    <section className={styles.qLinks}>
      {links.map((link) => (
        <DataLink key={link.entryName} link={link}>
          {link.title}
        </DataLink>
      ))}
    </section>
  );
};

export default QuickLinks;
