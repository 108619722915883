import {
  CONTACT_US_SLUG,
  HOMEPAGE_SLUG,
  EC_HOMEPAGE_SLUG,
  ABOUT_SLUG,
  READING_SLUG,
  BIO_SLUG,
  DODGE_THE_DUBIOUS,
  SLIM_HP_PERCENT,
  PERCENT_HP_SLIM,
} from 'constants/constants';

import { SIDE_SIZE_OF_CHAT_BUBBLE } from './declarations';

const allowedPages = {
  [CONTACT_US_SLUG]: true,
  '/': true,
  [HOMEPAGE_SLUG]: true,
  [EC_HOMEPAGE_SLUG]: true,
  [ABOUT_SLUG]: true,
  [READING_SLUG]: true,
  [BIO_SLUG]: false,
  [DODGE_THE_DUBIOUS]: true,
  [SLIM_HP_PERCENT]: true,
  [PERCENT_HP_SLIM]: true,
};

export const checkShouldPageHaveAgentChat = (slug: string) => allowedPages[slug];

export class AgentChatIframe {
  private static iframe: HTMLIFrameElement | null;

  static setIframe(iframe: HTMLIFrameElement | null) {
    this.iframe = iframe;
  }

  static isOpened() {
    if (!this.isSet()) {
      return false;
    }

    const height = this.iframe!.clientHeight || 0;
    const width = this.iframe!.clientWidth || 0;

    return height > SIDE_SIZE_OF_CHAT_BUBBLE && width > SIDE_SIZE_OF_CHAT_BUBBLE;
  }

  static isSet() {
    return Boolean(this.iframe);
  }
}
