/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FC } from 'react';
import { useSelector } from 'react-redux';

import Icon from 'src/shared/ui/Icon';
import type { Store } from 'app-redux/types/storeTypes';
import { PsychicSimpleViewButtons as Buttons } from 'entities/PsychicSimpleViewButtons';
import { getPsychicCtaButtons } from 'lib/psychic.service';
import { PsychicCardImage } from 'entities/PsychicCardImage';
import { PsychicAvatarWrapperLink } from 'entities/PsychicAvatarWrapperLink';
import { StatusIndicatorWrapper as StatusIndicator } from 'entities/JsxParserComponents';

import styles from './styles.module.scss';

import type { CardInterface } from '../config/declarations';

const Card: FC<CardInterface> = ({
  psychicSkeleton,
  positionInArray,
  psychic,
  bootStatus,
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const { profileButton, avatar } = psychicSkeleton;
  const avatarUrl = avatar?.url || '';
  const psychicImageUrl = psychic.psychicImageUrl || avatarUrl || '';

  const cta = getPsychicCtaButtons(psychic, psychicSkeleton, { hashedId: user?.hashedId });

  return (
    <li className={styles.card}>
      <PsychicAvatarWrapperLink
        className={styles.link}
        positionInArray={positionInArray}
        profileButton={profileButton}
        psychic={psychic}
      >
        <PsychicCardImage
          psychic={psychicSkeleton}
          image="external"
          src={psychicImageUrl}
          alt={psychic.psychicName}
          className={styles.image}
        />
      </PsychicAvatarWrapperLink>
      <div className={styles.interaction}>
        <div>
          <StatusIndicator
            chatStatus={psychic.chatStatus}
            phoneStatus={psychic.phoneStatus}
            className={styles.indicator}
          />
          <span className={styles.name}>{psychic.psychicName}</span>
        </div>
        <div className={styles.iconWrapper}>
          {psychic.overallScore}
          {' '}
          <Icon
            image={psychicSkeleton.starImage}
            className={styles.icon}
          />
        </div>
        <Buttons
          className={styles.buttons}
          bootStatus={bootStatus}
          chatButton={cta.chatButton}
          talkButton={cta.talkButton}
          callbackButton={cta.callbackButton}
          inQueueButton={cta.inQueueButton}
          messageButton={cta.messageButton}
          psychicFrame={psychicSkeleton}
          psychic={psychic}
        />
      </div>
    </li>
  );
};

export default Card;
