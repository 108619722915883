import {
  FC,
  KeyboardEvent,
  MouseEvent,
} from 'react';
import cn from 'classnames';

import { DataButton } from 'components/Shared/SharedComponents';
import classes from 'src/styles/_commonClasses.module.scss';
import { Link, Text } from 'src/__generated__/graphqlTypes';
import Icon from 'src/shared/ui/Icon';

import styles from './styles.module.scss';
import { Hint } from './Hint';

import { HeartImageInterface } from '../config/declarations';
import { getHeartImageProps } from '../lib';
import { useHeartImageData } from '../lib/hooks';

const HeartImage: FC<HeartImageInterface> = ({
  psychic,
  className,
  hintClassName,
  assetClassName,
  assetFavoriteClassName,
  hintArrowClassName,
  contentfulData,
  onClick,
}) => {
  const {
    isFavorite,
    isAddToFavoriteHintActive,
    isAuthenticated,
    onClick: hooksOnClick,
  } = useHeartImageData(psychic);
  const localOnClick = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => {
    onClick?.(e);
    hooksOnClick();
  };
  const favoritePsychicCollection = contentfulData.favoritePsychicCollection
    ?.items as Array<Link | Text>;

  const favoritePsychicStyles = (!isFavorite)
    ? assetClassName || styles.heartImgAsset
    : assetFavoriteClassName || styles.heartImgAssetFavorite;
  const isRemove = isAuthenticated && !isFavorite;
  const { button, hint } = getHeartImageProps(favoritePsychicCollection, isRemove);

  if (!button) {
    return null;
  }

  const { image } = button;
  const buttonClasses = cn(classes.cursorPointer, className);

  return (
    <>
      <DataButton
        link={button}
        className={buttonClasses}
        onClick={localOnClick}
        onKeyPress={localOnClick}
      >
        <Icon
          image={image}
          className={favoritePsychicStyles}
        />
        <Hint
          hint={hint}
          isAddToFavoriteHintActive={isAddToFavoriteHintActive}
          className={hintClassName}
          arrowClassName={hintArrowClassName}
        />
      </DataButton>
    </>
  );
};

export default HeartImage;
