import { FC, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  BLOCKS,
  MARKS,
  INLINES,
} from '@contentful/rich-text-types';
import qs from 'query-string';
import ReactModal from 'react-modal';

import type { Store } from 'app-redux/types/storeTypes';
import { CommonRichText, DataButton } from 'components/Shared/SharedComponents';
import classes from 'styles/_commonClasses.module.scss';
import { setLoadingState } from 'app-redux/actions/appActions';
import { BioOverlay, IFRAME_QUERY_PARAMETER } from 'src/shared/lib/bio-overlay';
import { mapHyperlinkToButtonWithCookie } from 'lib/richTextMappers';
import { RichTextParsersConfig } from 'types/objectTypes';
import { ASK_ACCOUNT_INFO } from 'constants/constants';
import { accountInfoObject } from 'src/shared/lib/cookie/accountInfo';
import { setMultipleCookies } from 'src/entities/PsychicCtaButton/lib/ctaCookie';

import styles from './AppointmentCalendar.module.scss';
import AppointmentHeader from './AppointmentHeader';
import Navigation from './Navigation';
import AppointmentList from './AppointmentList';
import AppointmentModalContent from './Modal/AppointmentModalContent';
import modalStyles from './Modal/CalendarModal.module.scss';
import HotDealWindow from './Modal/HotDealWindow';

import type { IAppointment, ModalClass } from '../config/declarations';
import { useGetAppointmentToolFunction as useGetWayOfResponse, usePrepareAppointment } from '../lib/appointmentHook';
import { getQuery } from '../lib';

const getParsersConfig = (extId: string): RichTextParsersConfig => ({
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.alertText,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
  [INLINES.HYPERLINK]: {
    classNames: cn(styles.alertText, styles.alertLink),
    mapper: mapHyperlinkToButtonWithCookie(extId, [
      { key: 'apptCreateAccount', value: window.location.pathname },
      { key: 'CTAType', value: 'appointment' },
    ]),
  },
});

const ExistingAppointment: FC<IAppointment> = ({
  selected,
  button,
  menu,
  modals,
  isCollapsed,
  dispatch,
}) => {
  const [modalContent, setModalContent] = useState<any | null>(null);
  const [modalClass, setModalClass] = useState<ModalClass>('');
  const user = useSelector((store: Store) => store.server.auth.user);
  const { extId } = useSelector((store: Store) => store.server.page.data);
  const [selectedTime, setSelectedTime] = useState<number | null>(null);
  const globalDispatch = useDispatch();

  const clearModal = () => {
    setModalClass('');
    setModalContent(null);
  };

  const prepareAppointment = usePrepareAppointment(
    (selectedTime !== null) ? selected.forAppointments[selectedTime] : null,
  );

  const getWayOfResponse = useGetWayOfResponse(
    (selectedTime !== null) ? selected.forAppointments[selectedTime] : null,
    selected,
    menu?.link,
  );

  const makeAppointment = async () => {
    try {
      globalDispatch(setLoadingState(true));
      const preparedAppointment = await prepareAppointment();

      if (!preparedAppointment) {
        return;
      }

      if (preparedAppointment.type === 'create-appointment') {
        const appointment = await getWayOfResponse(preparedAppointment.isHotDeal);

        if (!appointment) {
          return;
        }

        if (appointment.type === 'modal') {
          const modal = modals?.find((modal) => modal.slug === 'appointment');

          if (!modal) {
            return;
          }

          setModalClass('appointment');

          return setModalContent(<AppointmentModalContent
            block={modal}
            onClick={clearModal}
          />);
        }

        if (appointment.type === 'redirect') {
          const { query } = appointment!;
          let url = '';

          if (user?.isLeadCustomer) {
            const number = user?.customerPhoneNumber === '9999999999' ? '' : user?.customerPhoneNumber;
            setMultipleCookies({
              [ASK_ACCOUNT_INFO]: {
                ...accountInfoObject,
                extId,
                custId: user?.custId,
                chat: false,
                dob: user?.userDateOfBirth,
                ncflowtype: 2,
                email: user?.userEmail,
                phoneNumber: number,
              },
            });
            url = `ncsignup?extid=${query!.extId}`;
          } else {
            url = `${menu?.link?.src}?${getQuery(query!.startDate, `${query!.extId}`)}`;
          }

          const parsedIframeQuery = qs.parse(window.location.search);
          const isFrame = parsedIframeQuery[IFRAME_QUERY_PARAMETER];

          if (isFrame) {
            return BioOverlay
              .notifyParentOnNavigation(`${process.env.NEXT_PUBLIC_BASE_SERVER_URL}/${url.toString()}`);
          }

          window.location.href = url;

          return;
        }
      }

      if (preparedAppointment.type === 'show-hot-deal') {
        const modal = modals?.find((modal) => modal.slug === 'hot-deal');

        if (!modal) {
          return;
        }

        setModalClass('hotDeal');
        setModalContent(<HotDealWindow
          offer={preparedAppointment.offer}
          extId={preparedAppointment.extId}
          block={modal}
          create={() => getWayOfResponse(false)}
          cancel={clearModal}
        />);
      }
    } finally {
      globalDispatch(setLoadingState(false));
    }
  };

  return (
    <div className={cn(styles.appointments, { [styles.hidden]: isCollapsed })}>
      <ReactModal
        isOpen={Boolean(modalContent)}
        className={cn(modalStyles.modal, modalStyles[modalClass])}
        overlayClassName={modalStyles.overlay}
        shouldCloseOnOverlayClick
        onRequestClose={clearModal}
        preventScroll
      >
        {modalContent}
      </ReactModal>
      <AppointmentHeader block={menu} appointmentsAmount={selected.forAppointments.length} />
      <div className={cn(styles.widget, styles.body)}>
        <Navigation
          dispatch={dispatch}
          date={selected.date}
        />
        <AppointmentList selected={selected} select={setSelectedTime} />
      </div>
      {(selectedTime !== null && !user) && (
        <div className={styles.alert}>
          <span className={styles.alertIcon}>!</span>
          <CommonRichText content={menu?.content} parsersConfig={getParsersConfig(extId)} />
        </div>
      )}
      {(selectedTime !== null && button) && (
        <DataButton
          link={button}
          disabled={!user}
          className={styles.button}
          onClick={makeAppointment}
        >
          {button.title}
        </DataButton>
      )}
    </div>
  );
};

export default ExistingAppointment;
