/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import {
  CommonRichText as Title,
  DataLink,
  WebpImage,
} from 'components/Shared/SharedComponents';
import type { Psychic } from 'src/__generated__/graphqlTypes';
import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';
import { capitalizeFirstLetter } from 'lib/text.service';
import { RichTextParsersConfig, ZodiacSign } from 'types/objectTypes';
import { mapBlocksHeadingWithReplacement, mapMarksBoldWithReplacement } from 'lib/richTextMappers';
import { ElementAlign, Heading } from 'constants/enums';
import { CustomersTestimonialsCarousel } from 'entities/PsychicWithTestimonial';

import styles from './PsychicsWithTestimonials.module.scss';

import { IPsychicsWithTestimonials } from '../config/declarations';

const getParsersConfig = (
  zodiacSign?: ZodiacSign,
  titleAlign?: ElementAlign,
): RichTextParsersConfig => {
  const replacementObject = { sign: capitalizeFirstLetter(zodiacSign) };

  return ({
    [BLOCKS.HEADING_3]: {
      classNames: cn(styles.psychicsTitle, classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`]),
      mapper: mapBlocksHeadingWithReplacement(replacementObject, Heading.THIRD),
    },
    [BLOCKS.HEADING_2]: {
      classNames: cn(styles.psychicsTitle, classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`]),
      mapper: mapBlocksHeadingWithReplacement(replacementObject),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
      mapper: mapMarksBoldWithReplacement(replacementObject),
    },
  });
};

const PsychicsWithTestimonials: FC<IPsychicsWithTestimonials> = ({
  topPsychicsBlock,
  psychics,
  zodiacSign,
  loaderImage,
  isLoading,
  rightArrow,
  leftArrow,
  isSimpleView = false,
  titleAlign = ElementAlign.CENTER,
}) => {
  const {
    richTitle,
    link,
    contentTypesCollection,
  } = topPsychicsBlock;
  const [psychicSkeleton] = (contentTypesCollection?.items || []) as Array<Psychic>;

  const applyCarouselOrLoader = () => {
    if (isLoading) {
      return (
        <div className={styles.psychicsLoader}>
          <WebpImage
            image={EXTERNAL_WEBP_IMAGE}
            alt={loaderImage.title!}
            src={loaderImage.url!}
            className={styles.psychicsLoaderRotate}
          />
        </div>
      );
    }

    return (
      <CustomersTestimonialsCarousel
        psychicFrame={psychicSkeleton}
        psychics={psychics}
        rightArrow={rightArrow}
        leftArrow={leftArrow}
      />
    );
  };

  return (
    <div className={cn(
      styles.psychics,
      isSimpleView ? styles.psychicsSimple : styles.psychicsEmbedded,
      link ? styles.psychicsWithLink : styles.psychicsNoLink,
    )}
    >
      <Title
        content={richTitle}
        parsersConfig={getParsersConfig(zodiacSign, titleAlign)}
      />
      <hr className={styles.psychicsUnderline} />
      {applyCarouselOrLoader()}
      {link && (
        <DataLink
          link={link}
          href={link.src!}
          className={styles.psychicsButton}
        >
          {link.title}
        </DataLink>
      )}
    </div>
  );
};

export default PsychicsWithTestimonials;
