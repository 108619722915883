import { FC, memo } from 'react';

import { ExponeaPsychicsResults } from 'constants/enums';
import { useAvailablePsychics, usePsychicStatuses } from 'src/firebase/firebase.hook';
import { RightPsychic } from 'types/objectTypes';
import {
  ExponeaFirestoreProviderAllInterface as AllPsychicsHandlerInterface,
  ExponeaFirestoreProviderAvailableInterface as AvailablePsychicsHandlerInterface,
  ExponeaFirestoreProviderInterface,
} from 'components/Sections/ExponeaPsychicsList/declarations';

type CallbackType = (psychics: Array<RightPsychic>) => Array<RightPsychic>;

const arePropsEquals = <T extends AllPsychicsHandlerInterface, >(
  oldProps: T,
  newProps: T,
) => {
  if (!newProps.canHookBeInvoked && newProps.psychicData !== oldProps.psychicData) {
    return true;
  }

  const oldPropsEntries = Object.entries(oldProps);
  const newPropsEntries = Object.entries(newProps);

  return oldPropsEntries.length === newPropsEntries.length
    && oldPropsEntries
      .every(([oldPropsKey, oldPropsValue]) => newProps[oldPropsKey] === oldPropsValue);
};

/* Nested component */
const AvailablePsychicsHandler: FC<AvailablePsychicsHandlerInterface> = memo(({
  extraData,
  psychicData,
  loadingData,
  setHookInvocationState,
}) => {
  const [isLoading, setLoadingState] = loadingData;
  const [psychics, setPsychics] = psychicData;
  const { resultPsychics, exponeaOptions } = extraData;
  const setAvailablePsychics = (callback: CallbackType) => {
    if (resultPsychics === ExponeaPsychicsResults.ALL) {
      return;
    }

    if (!isLoading) {
      setLoadingState(true);
    }

    const availablePsychics = callback(psychics || []);

    if (!availablePsychics?.length || !exponeaOptions) {
      return setLoadingState(false);
    }

    setPsychics(availablePsychics.slice(0, +exponeaOptions.size));
    setLoadingState(false);
    setHookInvocationState(false);
  };

  // @ts-ignore
  useAvailablePsychics(setAvailablePsychics);

  return null;
}, arePropsEquals);

/* Nested component */
const AllPsychicsHandler: FC<AllPsychicsHandlerInterface> = memo(({
  psychicData,
  loadingData,
  setHookInvocationState,
}) => {
  const [isLoading, setLoadingState] = loadingData;
  const [psychics, setPsychics] = psychicData;
  const idList = psychics?.map((psychic) => +psychic.extId) || [];

  const setAllPsychics = (callback: CallbackType) => {
    if (!isLoading) {
      setLoadingState(true);
    }

    const allPsychics = callback(psychics || []);

    setPsychics(allPsychics);
    setLoadingState(false);
    setHookInvocationState(false);
  };

  // @ts-ignore
  usePsychicStatuses(idList, setAllPsychics);

  return null;
}, arePropsEquals);

/* Main component */
const ExponeaFirestoreProvider: FC<ExponeaFirestoreProviderInterface> = ({
  extraData,
  psychicData,
  loadingData,
  canHookBeInvoked,
  resultPsychics,
  setHookInvocationState,
}) => {
  const commonProperties = {
    loadingData,
    psychicData,
    canHookBeInvoked,
    setHookInvocationState,
  };

  return (
    <>
      {(resultPsychics === ExponeaPsychicsResults.AVAILABLE)
          && (
            <AvailablePsychicsHandler
              extraData={extraData}
              {...commonProperties}
            />
          )}
      {(resultPsychics === ExponeaPsychicsResults.ALL)
          && <AllPsychicsHandler {...commonProperties} />}
    </>
  );
};

export default ExponeaFirestoreProvider;
