import { forwardRef } from 'react';
import cn from 'classnames';

import styles from 'components/AgentChat/AgentChat.module.scss';
import { AGENT_CHAT_IFRAME_ID } from 'constants/constants';
import { AgentChatInterface } from 'components/AgentChat/declarations';

const AgentChat = forwardRef<HTMLIFrameElement, AgentChatInterface>(({ url }, ref) => (
  <iframe
    ref={ref}
    id={AGENT_CHAT_IFRAME_ID}
    title="agent-chat"
    className={cn(styles.agentChat, 'ctm-call-widget')}
    src={url}
  />
));

export default AgentChat;
