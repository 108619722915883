import { FC } from 'react';

import { SelfCareArticles } from 'components/Sections/HoroscopesSignsAllAboutSigns/SelfCareArticles';
import { ZodiacSignFeatureSingleElementInterface } from 'types/componentTypes';
import { useDeviceHoroscopeInfo } from 'lib/horoscope/horoscope.hook';

const SelfCareArticlesContainer: FC<ZodiacSignFeatureSingleElementInterface> = ({
  element,
}) => {
  const {
    mobileViewMaxWidth,
    horoscopeTabletMaxWidth,
    viewerDevice,
  } = useDeviceHoroscopeInfo();

  return (
    <SelfCareArticles
      selfCare={element}
      viewerDevice={viewerDevice}
      mobileViewMaxWidth={mobileViewMaxWidth}
      horoscopeTabletMaxWidth={horoscopeTabletMaxWidth}
    />
  );
};

export default SelfCareArticlesContainer;
