import { forwardRef } from 'react';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';

import styles from './CircleNotificationIndicator.module.scss';

import type { ICircleNotificationIndicator } from '../config/declarations';

const defaultClasses = cn(styles.indicator, styles.indicatorSize, classes.textBold700);

const CircleNotificationIndicator = forwardRef<HTMLSpanElement, ICircleNotificationIndicator>((
  { className, children, ...rest },
  ref,
) => (
  <span
    ref={ref}
    className={cn(defaultClasses, className)}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    { children }
  </span>
));

export default CircleNotificationIndicator;
