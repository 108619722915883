import { useSelector } from 'react-redux';

import type {
  Block,
  Header,
  HeaderTopBar,
} from 'src/__generated__/graphqlTypes';
import type { Store } from 'app-redux/types/storeTypes';
import { HELP_TELEPHONE_SLUG } from 'constants/constants';
import { ViewerDevice } from 'constants/enums';

import sassVariables from '../ui/styles.module.scss';

export const usePhoneNumbers = (phonesCollection: HeaderTopBar['phonesCollection']) => {
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const user = useSelector((store: Store) => store.server.auth.user);
  const phoneNumbers = phonesCollection?.items as Array<Block> || [];

  if (user && viewerDevice === ViewerDevice.MOBILE) {
    return phoneNumbers.filter(((phNumber) => phNumber.slug === HELP_TELEPHONE_SLUG));
  }

  return phoneNumbers;
};

export const calculateMobileHeaderHeight = (header: Header) => {
  let height = Number(sassVariables.headerHeight);

  if (header.top) {
    height += Number(sassVariables.topBarHeight);
  }

  if (header.quickLinksCollection?.items?.length! > 0) {
    height += Number(sassVariables.quickLinksHeight);
  }

  return height;
};
