import cookie from 'cookie';

import { GAReplacementValue } from 'constants/enums';

export const getGaClientId = (cookies?: Record<string, string> | string): string => {
  const localCookies = cookies || document.cookie;
  const cookieObject = (typeof localCookies === 'string')
    ? cookie.parse(localCookies)
    : localCookies;

  const clientId = cookieObject.OAGAClientId || '';

  if (!clientId) {
    const gaMeta = cookieObject._ga;

    if (!gaMeta) {
      return '';
    }

    const gaMetaArray = gaMeta.split('.');
    const { length } = gaMetaArray;

    if (length > 0) {
      return `${gaMetaArray[length - 2]}.${gaMetaArray[length - 1]}`;
    }
  }

  return clientId;
};

export const getGaAccordion = (
  meta: Record<string, string>,
  isExpanded: boolean,
  defaultText?: string,
) => {
  const text = defaultText || 'Expand or Collapse';

  if (meta) {
    return {
      [GAReplacementValue.ACCORDION]: isExpanded
        ? meta.expand
        : meta.close,
    };
  }

  return { [GAReplacementValue.ACCORDION]: text };
};
