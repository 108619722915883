import { FC } from 'react';
import cn from 'classnames';

import MobileApps from 'components/Footer/Links/Media/MobileApps';
import styles from 'components/Footer/Links/Media/Media.module.scss';
import SocialMedias from 'components/Footer/Links/Media/SocialMedias';
import { FooterLinksMediaInterface } from 'components/Footer/Links/declarations';
import Divider from 'components/Footer/Links/Media/Divider';
import { capitalizeFirstLetter } from 'lib/text.service';

const Media: FC<FooterLinksMediaInterface> = ({
  mobileApps,
  socialMedias,
  footerType,
}) => (
  <div className={cn(styles.media, styles[`media${capitalizeFirstLetter(footerType)}`])}>
    <SocialMedias socialMedias={socialMedias} footerType={footerType} />
    <Divider footerType={footerType} />
    <MobileApps mobileApps={mobileApps} footerType={footerType} />
  </div>
);
export default Media;
