import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import type { Link } from 'src/__generated__/graphqlTypes';
import { CommonRichText, DataLink } from 'components/Shared/SharedComponents';
import { Heading } from 'constants/enums';
import { RichTextParsersConfig } from 'types/objectTypes';
import { mapBlocksHeadingWithReplacement } from 'lib/richTextMappers';
import { replaceContentfulVariables } from 'lib/text.service';

import styles from './styles.module.scss';

import type { IHeading, IHeadingButton } from '../config/declarations';
import { useUrlSlug } from '../lib/hooks';

const getParsersConfig = (total?: number): RichTextParsersConfig => ({
  [BLOCKS.HEADING_2]: {
    classNames: styles.title,
    mapper: mapBlocksHeadingWithReplacement({ amount: total?.toString() }),
  },
  [BLOCKS.HEADING_3]: {
    classNames: styles.headingTitle,
    mapper: mapBlocksHeadingWithReplacement({ amount: total?.toString() }, Heading.SECOND),
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold700,
  },
});

/* Nested component */
const Button: FC<IHeadingButton> = ({ button }) => {
  const slug = useUrlSlug();

  if (!button?.src) {
    return null;
  }

  return (
    <DataLink
      link={button}
      href={replaceContentfulVariables(button.src, { slug })}
      className={styles.button}
    >
      {button.title}
    </DataLink>
  );
};

/* Main component */
const SectionHeading: FC<IHeading> = ({ total, block, isCollapsed }) => {
  if (!block) {
    return null;
  }

  const links = (block.contentTypesCollection?.items || []) as Array<Link>;
  const { search, write } = links.reduce((store, link) => {
    if (link.__typename !== 'Link') {
      return store;
    }

    return { ...store, [link.slug || '']: link };
  }, {} as Record<string, Link | undefined>);
  const shouldShowSearchTestimonials = total >= 3;
  const shouldShowDivider = shouldShowSearchTestimonials && write && search;

  return (
    <div className={cn(
      styles.heading,
      { [styles.collapsed]: isCollapsed },
    )}
    >
      <CommonRichText
        content={block.richTitle}
        parsersConfig={getParsersConfig(total)}
      />
      <div className={cn(
        styles.headingButtons,
        { [styles.hidden]: isCollapsed },
      )}
      >
        {shouldShowSearchTestimonials && <Button button={search} />}
        {shouldShowDivider && <span>|</span>}
        <Button button={write} />
      </div>
    </div>
  );
};

export default SectionHeading;
