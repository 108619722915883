import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { DataLink } from 'components/Shared/SharedComponents';
import type { Store } from 'app-redux/types/storeTypes';
import { GAReplacementValue } from 'constants/enums';

import type { NumberInterface } from '../config/declarations';
import { getNumber } from '../lib/phone';

const Number = forwardRef<HTMLAnchorElement, NumberInterface>(({
  number: defaultNumber,
  ...props
}, ref) => {
  const ctmNumber = useSelector((store: Store) => store.client.app.ctmNumber);
  const number = getNumber(defaultNumber, ctmNumber);

  return (
    <DataLink
      // @ts-ignore
      ref={ref}
      href={`tel:${number}`}
      gaData={{ [GAReplacementValue.TELEPHONE]: number }}
      {...props}
    >
      {number}
    </DataLink>
  );
});

export default Number;
