import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { getTestimonialsByExtIdProxy } from 'src/api/psychicApi';
import { useCustomRouter } from 'src/shared/lib/history/hooks';
import type { Store } from 'app-redux/types/storeTypes';
import { Logger } from 'lib/logger';
import { RightPsychic, TestimonialResponse } from 'types/objectTypes';

const testimonialSelectorFunc = (store: Store) => store.server.page.data?.testimonials;
const psychicSelectorFunc = (store: Store) => store.server.page.data?.psychic;

export const useTestimonials = (amount: number) => {
  const serverTestimonials: TestimonialResponse = useSelector(testimonialSelectorFunc) || {};
  const serverPsychic: RightPsychic = useSelector(psychicSelectorFunc) || {};

  const [testimonials, setTestimonials] = useState<TestimonialResponse>(serverTestimonials);
  const [error, setError] = useState<any>(null);
  const [isLoading, setLoadingState] = useState<boolean>(false);

  const prevParameters = useRef<string>('');

  const refetch = async (parameters: Record<string, any>) => {
    const {
      amount,
      page = 0,
      sortType,
    } = parameters;

    try {
      if (prevParameters.current === JSON.stringify(parameters)) {
        return;
      }

      prevParameters.current = JSON.stringify(parameters);
    } catch (e) {
      console.error(e);
    }

    try {
      setLoadingState(true);
      const { psychic } = await getTestimonialsByExtIdProxy({
        extId: serverPsychic.extId as any,
        pageIndex: page,
        pageSize: amount,
        sortType,
      });

      setTestimonials(psychic);
    } catch (e) {
      Logger.error(e);
      setError(e);
    } finally {
      setLoadingState(false);
    }
  };

  const slicedTestimonials = useMemo(
    () => testimonials.lstTestimonial?.slice(0, amount) || [],
    [testimonials],
  );

  useEffect(() => {
    if (slicedTestimonials.length === amount) {
      return;
    }

    if (slicedTestimonials.length !== 0 || !serverPsychic.extId) {
      return;
    }

    refetch({ amount });
  }, [amount, slicedTestimonials, serverPsychic.extId]);

  return {
    feature: testimonials.featuredTestimonialData,
    testimonials: slicedTestimonials,
    total: testimonials.testimonialTotal,
    error,
    refetch,
    isLoading,
  };
};

export const useUrlSlug = () => {
  const router = useCustomRouter();

  return router.query.slug as string;
};
