import { Dispatch, SetStateAction } from 'react';

import { camalifyKebab } from 'lib/text.service';
import type {
  Block,
  Link,
  Text,
} from 'src/__generated__/graphqlTypes';
import { makeCalendarDateKey } from 'entities/PlainCalendar';

import { ScheduleSlot, TimeslotsResponse } from '../config/declarations';

export const parseBlocks = (blocks: Array<Block>) => blocks.reduce((store, item) => {
  if (item.__typename !== 'Block' || !item.slug) {
    return store;
  }

  return { ...store, [camalifyKebab(item.slug)]: item };
}, {} as Record<string, Block | undefined>);

export const parseTextItems = (collection: Array<Text | Block | Link>) => {
  if (!collection?.length) {
    return {};
  }

  return collection.reduce((store, item) => {
    if (item.__typename !== 'Text' || !item.slug) {
      return store;
    }

    return { ...store, [camalifyKebab(item.slug)]: item };
  }, {} as Record<string, Text | undefined>);
};

export const nth = (day: number) => {
  if (day > 3 && day < 21) { return 'th'; }

  switch (day % 10) {
    case 1: { return 'st'; }
    case 2: { return 'nd'; }
    case 3: { return 'rd'; }
    default: { return 'th'; }
  }
};

export const getCalendarOnChange = (
  setSelected: Dispatch<SetStateAction<ScheduleSlot>>,
  data?: TimeslotsResponse,
) => (item: Date) => {
  const { psychicSchedules } = data || {};

  if (!psychicSchedules?.length) {
    return;
  }

  const slot = psychicSchedules
    .find((slot) => makeCalendarDateKey(item) === makeCalendarDateKey(slot.date));

  if (!slot) {
    return;
  }

  return setSelected(slot);
};

export const getQuery = (startDate: Date, extId: string) => {
  const query = new URLSearchParams();
  query.set('extid', extId);
  query.set('startDate', `${startDate.getTime()}`);

  return query.toString();
};
