import { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import type { ImageWrapper } from 'src/__generated__/graphqlTypes';
import type { Store } from 'app-redux/types/storeTypes';
import { LoadingMode, ViewerDevice } from 'constants/enums';
import { WebpImage } from 'components/Shared/SharedComponents';
import { setItemInObjectBySlug } from 'lib/sharedMethods.service';

import type { IDivider } from './declarations';
import { DividerSlug } from './constants';
import styles from './styles.module.scss';

const slugs = {
  mobile: [DividerSlug.MOBILE],
  desktop: [DividerSlug.DESKTOP],
};

const Divider: FC<IDivider> = ({
  divider,
  className,
  lineHeight,
  lineClassName,
  imageClassName,
  loadingMode = LoadingMode.EAGER,
}) => {
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);

  if (!divider) {
    return null;
  }

  const { imagesCollection } = divider;
  const reducer = setItemInObjectBySlug(slugs);
  const {
    desktop,
    mobile,
  } = (imagesCollection?.items as Array<ImageWrapper>)
    ?.reduce(reducer, {} as Record<DividerSlug, ImageWrapper>) || {};

  const imageWrapper = (viewerDevice !== ViewerDevice.MOBILE)
    ? desktop
    : mobile;
  const { image } = imageWrapper || {};
  const line = (
    <span
      style={{ height: lineHeight }}
      className={cn(styles.line, lineClassName)}
    />
  );

  return (
    <div className={cn(styles.divider, className)}>
      {line}
      {image?.url && (
        <WebpImage
          loading={loadingMode}
          src={image.url}
          image="external"
          className={cn(styles.image, imageClassName)}
        />
      )}
      {line}
    </div>
  );
};

export default Divider;
