import { ImageSlug as Slug } from './declarations';

export const OFFERS_LIST_CHANNEL_ID_TO_SKIP = 12;
export const OFFERS_LIST_DESCRIPTIONS_TO_ADD = 4;
export const OFFERS_LIST_ARROW_MOBILE_SIZE = 30;
export const OFFERS_LIST_ARROW_DESKTOP_SIZE = 60;

export const defaultOffersConfig = {
  bannerDisplayCount: 3,
  desktopBannerHeight: 300,
  desktopBannerWidth: 1920,
  mobileBannerHeight: 400,
  mobileBannerWidth: 740,
  pauseDurationDesktop: 5000,
  pauseDurationMobile: 5000,
};

export const defaultBanner = {
  promoCode: 'Nothing',
  isOfferImage: false,
};

export const slugs = {
  mobile: [Slug.MOBILE],
  desktop: [Slug.DESKTOP],
};
