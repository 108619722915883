import { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { DataButton, DataLink } from 'components/Shared/SharedComponents';
import { Store } from 'app-redux/types/storeTypes';
import { capitalizeFirstLetter } from 'lib/text.service';
import { GAReplacementValue } from 'constants/enums';
import { useExponeaProductSelectedTracking } from 'lib/external/exponeaHooks';
import { setPageUrlCookie } from 'src/shared/lib/cookie';
import { CTA_TYPE, PSYCHIC_TALK_COOKIE_BIO } from 'constants/constants';

import styles from './styles.module.scss';
import { ButtonIcon } from './Icon';

import { ChatButtonInterface } from '../config/declarations';
import { getUrl } from '../lib';
import { setMultipleCookies } from '../lib/ctaCookie';
import { useChatClick } from '../lib/chatHooks';

export const ChatButton: FC<ChatButtonInterface> = ({
  psychic,
  href,
  shape,
  button,
  className,
  buttonName,
  bootStatus,
  ...rest
}) => {
  const isAuthenticated = useSelector((store: Store) => store.server.auth.isAuthenticated);
  const trackExponea = useExponeaProductSelectedTracking(psychic);
  const slug = useSelector((store: Store) => store.server.page.slug);

  const { extId, psychicName } = psychic;
  const url = getUrl(href, button?.src!, { extid: extId.toString(), ischat: '1' });
  const pageUrl = getUrl(href, button?.src!, { extid: extId.toString() });

  const onChatButtonClick = useChatClick(bootStatus, psychic, pageUrl);

  if (!button) {
    return null;
  }

  const localOnChatButtonClick = async () => {
    trackExponea(buttonName);

    if (slug === 'psychic-bio') {
      setPageUrlCookie(pageUrl);
    }

    setMultipleCookies(
      {
        [CTA_TYPE]: buttonName.toLowerCase(),
        [PSYCHIC_TALK_COOKIE_BIO]: { ExtId: extId, Name: psychicName },
      },
    );
  };

  const gaData = {
    [GAReplacementValue.PSYCHIC_NAME]: psychicName,
    [GAReplacementValue.EXT_ID]: extId.toString(),
  };
  const classes = cn(
    styles.button,
    styles[`shape${capitalizeFirstLetter(shape)}`],
    styles[`button${buttonName}`],
    className,
  );
  const chatButtonContent = (
    <>
      <ButtonIcon
        isSpriteImage
        image={button.image}
        className={styles.buttonAsset}
        buttonName={buttonName}
      />
      <span className={styles.buttonTitle}>
        {button.title}
      </span>
    </>
  );
  const commonButtonsProps = {
    link: button,
    className: classes,
    gaData,
    ...rest,
  };

  if (isAuthenticated) {
    return (
      <DataButton
        onClick={onChatButtonClick}
        {...commonButtonsProps}
      >
        {chatButtonContent}
      </DataButton>
    );
  }

  return (
    <DataLink
      href={url}
      onClick={localOnChatButtonClick}
      {...commonButtonsProps}
    >
      {chatButtonContent}
    </DataLink>
  );
};

export default ChatButton;
