import { FC } from 'react';
import cn from 'classnames';

import type { Block, ImageWrapper } from 'src/__generated__/graphqlTypes';
import { WebpImage } from 'components/Shared/SharedComponents';
import { camalifyKebab } from 'lib/text.service';

import styles from './styles.module.scss';
import Stars from './Stars';

import type { ITestimonial } from '../config/declarations';

const getReadingSource = (provider: 1 | 2, list: Block['textList']) => {
  if (!list?.length) {
    return null;
  }

  const index = provider - 1;

  return list[index] || null;
};

const TestimonialBlock: FC<ITestimonial> = ({
  block,
  testimonial,
  className,
  isFeatured = false,
}) => {
  if (!block || !testimonial) {
    return null;
  }

  const { score } = testimonial;

  const {
    filledStar,
    outlinedStar,
    conversation,
    halfStar,
    vip,
  } = (block.imagesCollection?.items || [])
    .reduce((store, image) => ({
      ...store,
      [camalifyKebab(image?.slug!)]: image!,
    }), {} as Record<string, ImageWrapper>);

  const readingSource = getReadingSource(testimonial.provider, block.textList);

  return (
    <div className={cn(
      className,
      styles.testimonial,
      isFeatured ? styles.testimonialFeatured : styles.testimonialCommon,
    )}
    >
      <div className={styles.header}>
        <div className={styles.images}>
          {conversation?.image?.url && (
            <WebpImage
              className={styles.imagesIcon}
              src={conversation.image.url}
              image="external"
              loading="lazy"
            />
          )}
          <Stars
            score={score}
            fallback={block.title}
            filledStar={filledStar}
            halfStar={halfStar}
            outlinedStar={outlinedStar}
          />
        </div>
        <div className={styles.authorInformation}>
          <strong className={styles.authorName}>
            {testimonial.author}
          </strong>
          { testimonial.customerTierId !== 0
            ? (
              <WebpImage
                src={vip.image?.url!}
                image="external"
                loading="lazy"
              />
            )
            : null }
          <span className={styles.headerMeta}>
            {testimonial.displayDate}
            ,
            {' '}
            {readingSource}
          </span>
        </div>
      </div>
      <p className={styles.description}>
        {testimonial.testimonialContent}
      </p>
    </div>
  );
};

export default TestimonialBlock;
