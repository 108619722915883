import { FC } from 'react';
import Modal from 'react-modal';

import { PsychicCtaButtons } from 'entities/PsychicCtaButton';
import styles from 'components/Sections/ExponeaPsychicProfile/ExponeaPsychicProfile.module.scss';

import Queue from './Queue';
import EstimatedTime from './EstimatedTime';
import type { IStickyInteraction } from './config/declarations';

const StickyInteraction: FC<IStickyInteraction> = ({
  isMobile,
  bootStatus,
  cta,
  psychic,
  psychicFrame,
}) => {
  if (!isMobile) {
    return (
      <PsychicCtaButtons
        bootStatus={bootStatus}
        chatButton={cta.chatButton}
        talkButton={cta.talkButton}
        callbackButton={cta.callbackButton}
        messageButton={cta.messageButton}
        inQueueButton={cta.inQueueButton}
        psychic={psychic}
        shape="Oval"
        psychicFrame={psychicFrame}
        className={styles.interaction}
      />
    );
  }

  return (
    <Modal
      isOpen
      className={styles.sticky}
      overlayClassName={styles.stickyOverlay}
    >
      {psychic.peopleInQueue > 0 && (
        <div className={styles.info}>
          <Queue
            queue={psychicFrame.queue}
            peopleInQueue={psychic.peopleInQueue}
            isModal
          />
          <div className={styles.divider} />
          <EstimatedTime
            isShortText
            texts={psychicFrame.estimatedWaiting}
            timeUnit={psychicFrame.ratePerMinute}
            estimatedTime={psychic.estimatedWaitTime}
          />
        </div>
      )}
      <PsychicCtaButtons
        bootStatus={bootStatus}
        chatButton={cta.chatButton}
        talkButton={cta.talkButton}
        callbackButton={cta.callbackButton}
        messageButton={cta.messageButton}
        inQueueButton={cta.inQueueButton}
        psychic={psychic}
        shape="Oval"
        psychicFrame={psychicFrame}
        className={styles.interaction}
      />
    </Modal>
  );
};

export default StickyInteraction;
