import { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import { SectionComponentInterface } from 'types/componentTypes';
import type { Store } from 'app-redux/types/storeTypes';
import { CommonSize } from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';

import styles from './styles.module.scss';
import Offers from './Offers/Offers';
import Greeting from './Greeting/Greeting';

import { getBlocks } from '../lib';

const Component: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const { verticalPadding = CommonSize.X_SMALL } = extraData || {};
  const user = useSelector((store: Store) => store.server.auth.user);

  if (!user) {
    return user;
  }

  const { greeting, offers } = getBlocks(blocks);

  return (
    <section
      className={cn(
        styles.wrapper,
        { [classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`]]: Boolean(verticalPadding) },
      )}
      style={{ background: bgColor }}
    >
      <Greeting greeting={greeting} />
      <Offers offers={offers} />
    </section>
  );
};

export default Component;
