/* eslint-disable no-param-reassign */
import {
  CSSProperties,
  FC,
  memo,
} from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import type {
  Block,
  Link,
  Psychic,
} from 'src/__generated__/graphqlTypes';
import classes from 'src/styles/_commonClasses.module.scss';
import type { Store } from 'app-redux/types/storeTypes';
import type {
  RichTextParsersConfig,
  RightPsychic,
  SectionExtraDataType,
} from 'types/objectTypes';
import styles from 'components/Sections/PsychicsSetMediumSizeImagesClone/PsychicsSetMediumSizeImages.module.scss';
import {
  CommonRichText,
  DataButton,
  DataLink,
} from 'components/Shared/SharedComponents';
import {
  CommonSize,
  ElementAlign,
  ItemsLayout,
  PaginationType,
  PsychicCardAppearance,
} from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';
import { getItemPositionForGa } from 'lib/sharedMethods.service';
import Divider from 'components/Sections/Divider';
import {
  PsychicsSetMediumSizeImagesCloneInterface,
  PsychicsSetMediumSizeImagesClonePsychicsInterface as PsychicsInterface,
  PsychicsSetMediumSizeImagesCloneButtons as ButtonsInterface,
} from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';
import { PsychicCardSimple } from 'features/sections/PsychicCardSimple';
import { PsychicCardTile } from 'features/sections/PsychicCardTile';
import { PsychicCardList } from 'features/sections/PsychicCardList';
import LocalLoader from 'src/shared/ui/LocalLoader/LocalLoader';

import { reducer } from './lib';
import PaginationControl from './PaginationControl';
import BannerWrapper from './BannerWrapper';

const getParsersConfig = ({ titleAlign, pAlign }: SectionExtraDataType): RichTextParsersConfig => {
  const titleAlignClass = classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`];
  const pAlignClass = classes[`pAlign${capitalizeFirstLetter(pAlign || titleAlign)}`];
  const getHeadingClasses = (headingClass: string) => cn(
    styles.psychicTitle,
    headingClass,
    titleAlignClass,
  );

  return ({
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(styles.psychicParagraph, pAlignClass),
    },
    [BLOCKS.HEADING_1]: {
      classNames: getHeadingClasses(classes.titleMainH1),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHeadingClasses(classes.titleMainH2),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getHeadingClasses(classes.titleMainH3),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};

/* Nested component */
const Buttons: FC<ButtonsInterface> = ({ primary, secondary }) => {
  if (!primary && !secondary) {
    return null;
  }

  if (primary && secondary) {
    const commonClasses = cn(styles.psychicButton, styles.psychicButtonMobileSmall);

    return (
      <div className={cn(styles.psychicButtonWrapper, styles.psychicButtonMargin)}>
        <DataLink
          link={primary}
          href={primary.src!}
          className={cn(commonClasses, styles.psychicButtonPrimary)}
        >
          {primary.title}
        </DataLink>
        <DataLink
          link={secondary}
          href={secondary.src!}
          className={cn(commonClasses, styles.psychicButtonSecondary)}
        >
          {secondary.title}
        </DataLink>
      </div>
    );
  }

  const link = primary || secondary!;

  return (
    <DataLink
      link={link}
      href={link.src!}
      className={cn(
        styles.psychicButton,
        styles.psychicButtonMargin,
        {
          [styles.psychicButtonPrimary]: Boolean(primary),
          [styles.psychicButtonSecondary]: Boolean(secondary),
        },
      )}
    >
      {link.title}
    </DataLink>
  );
};

/* Nested component */
const Psychics: FC<PsychicsInterface> = ({
  array,
  banner,
  bootStatus,
  itemsLayout,
  psychicFrame,
  psychicCardAppearance,
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);

  if (!array?.length) {
    return (
      <LocalLoader className={styles.loaderWrapper} />
    );
  }

  if (!psychicFrame) {
    return null;
  }

  // eslint-disable-next-line no-undef
  let components: Array<JSX.Element> | null = null;

  if (psychicCardAppearance === PsychicCardAppearance.SIMPLE_WITH_DESCRIPTION
    || psychicCardAppearance === PsychicCardAppearance.SIMPLE_ADDITIONAL
    || psychicCardAppearance === PsychicCardAppearance.SIMPLE) {
    components = array.map((psychic, i) => {
      // eslint-disable-next-line prefer-destructuring, no-param-reassign
      psychic.psychicImageUrl = psychic.images[5];

      return (
        <PsychicCardSimple
          key={psychic.extId}
          user={user}
          view={psychicCardAppearance}
          psychicSkeleton={psychicFrame}
          positionInArray={getItemPositionForGa<RightPsychic>(array, i)}
          psychic={psychic}
        />
      );
    });
  }

  if (psychicCardAppearance === PsychicCardAppearance.TILE) {
    components = array.map((psychic, i) => (
      <BannerWrapper
        key={psychic.extId}
        /* This condition doesn't have any sense but it's the
        easies way how to render banner only once */
        shouldRenderBanner={i === 1}
        banner={banner}
        itemsLayout={itemsLayout}
        psychicCardAppearance={psychicCardAppearance}
      >
        <PsychicCardTile
          bootStatus={bootStatus}
          user={user}
          psychicSkeleton={psychicFrame}
          positionInArray={getItemPositionForGa<RightPsychic>(array, i)}
          psychic={psychic}
        />
      </BannerWrapper>
    ));
  }

  if (psychicCardAppearance === PsychicCardAppearance.LIST) {
    components = array.map((psychic, i) => (
      <BannerWrapper
        key={psychic.extId}
        /* This condition doesn't have any sense but it's the
        easies way how to render banner only once */
        shouldRenderBanner={i === 1}
        banner={banner}
        itemsLayout={itemsLayout}
        psychicCardAppearance={psychicCardAppearance}
      >
        <PsychicCardList
          bootStatus={bootStatus}
          user={user}
          psychicSkeleton={psychicFrame}
          positionInArray={getItemPositionForGa<RightPsychic>(array, i)}
          psychic={psychic}
        />
      </BannerWrapper>
    ));
  }

  const getStyle = (): CSSProperties | undefined => {
    if (itemsLayout !== ItemsLayout.GRID || psychicCardAppearance === PsychicCardAppearance.LIST) {
      return;
    }

    if ((psychicCardAppearance === PsychicCardAppearance.SIMPLE && array.length < 5)
    || (psychicCardAppearance === PsychicCardAppearance.TILE && array.length < 3)) {
      return { gridTemplateColumns: new Array(array.length).fill('1fr').join(' ') };
    }
  };

  const getItemsLayoutClass = () => {
    if (psychicCardAppearance === PsychicCardAppearance.LIST) {
      return capitalizeFirstLetter(ItemsLayout.COLUMN);
    }

    return capitalizeFirstLetter(itemsLayout);
  };
  const itemsLayoutName = getItemsLayoutClass();
  const getPsychicCardAppearanceName = () => {
    if (psychicCardAppearance === PsychicCardAppearance.SIMPLE
      || psychicCardAppearance === PsychicCardAppearance.SIMPLE_ADDITIONAL
      || psychicCardAppearance === PsychicCardAppearance.SIMPLE_WITH_DESCRIPTION) {
      return 'Simple';
    }

    if (psychicCardAppearance === PsychicCardAppearance.TILE) {
      return 'Tile';
    }
  };
  const psychicCardAppearanceName = getPsychicCardAppearanceName();

  return (
    <ul
      style={getStyle()}
      className={cn(
        styles.psychicContent,
        styles[`psychicContent${psychicCardAppearanceName}${itemsLayoutName}`],
      )}
    >
      {components}
    </ul>
  );
};

/* Main component */
const PsychicsSetMediumSizeImages: FC< PsychicsSetMediumSizeImagesCloneInterface> = ({
  banner,
  bgColor,
  content: block,
  psychics,
  extraData,
  sectionRef,
  topDivider,
  bootStatus,
  viewerDevice,
  bottomDivider,
  commonPageMaxWidth,
  paginationInfo,
  observerRef,
  loadingScroll,
  isShowLoadMoreButton,
  zeroResultPanelVisibility = false,
  loadMorePsychicHandler,
  changePage,
}) => {
  const {
    titleAlign = ElementAlign.LEFT,
    pAlign,
    verticalPadding = CommonSize.MEDIUM,
    psychicCardAppearance = PsychicCardAppearance.SIMPLE,
    itemsLayout = ItemsLayout.ROW,
    paginationType,
  } = extraData || {};
  const {
    richTitle,
    content,
    contentTypesCollection,
    link,
    entryName,
  } = block;
  const children = contentTypesCollection?.items as Array<Psychic | Link> | undefined;
  const richTextConfig = { titleAlign, pAlign };

  const { psychics: paginationPsychics } = paginationInfo || {};

  const {
    psychicFrame,
    secondaryButton,
    paginationButton,
    paginationBackButton,
    paginationNextButton,
  } = reducer(children);

  const isPaginationPsychics = paginationPsychics && paginationPsychics.length;
  const psychicsToDisplay = paginationType === PaginationType.SCROLL
    ? psychics : (isPaginationPsychics ? paginationPsychics : psychics) || [];

  if (zeroResultPanelVisibility) {
    return null;
  }

  return (
    <section
      ref={sectionRef}
      className={cn(styles.wrapper, classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`])}
      style={{ background: bgColor }}
    >
      <Divider
        block={topDivider as Block}
        maxWidth={`${commonPageMaxWidth}px`}
      />
      <div className={styles.psychic} key={entryName}>
        <CommonRichText
          content={richTitle}
          parsersConfig={getParsersConfig(richTextConfig)}
        />
        <CommonRichText
          content={content}
          parsersConfig={getParsersConfig(richTextConfig)}
        />
        <Psychics
          banner={banner}
          itemsLayout={itemsLayout}
          bootStatus={bootStatus}
          psychicCardAppearance={psychicCardAppearance}
          psychicFrame={psychicFrame}
          array={psychicsToDisplay}
          viewerDevice={viewerDevice}
        />
        <PaginationControl
          paginationButton={paginationButton}
          paginationBackButton={paginationBackButton}
          paginationNextButton={paginationNextButton}
          paginationType={paginationType}
          paginationInfo={paginationInfo}
          changePage={changePage}
        />
        {paginationType === PaginationType.SCROLL ? (
          <>
            {loadingScroll && psychics?.length > 0 && (
              <LocalLoader />
            )}
            { paginationButton && (
              <DataButton
                className={styles.loadButton}
                onClick={loadMorePsychicHandler}
                style={isShowLoadMoreButton
                  ? {}
                  : { visibility: 'hidden', height: 0 }}
                ref={observerRef}
              >
                {paginationButton.title}
              </DataButton>
            )}
          </>
        ) : (
          <Buttons
            primary={link!}
            secondary={secondaryButton}
          />
        )}
      </div>
      <Divider
        block={bottomDivider as Block}
        maxWidth={`${commonPageMaxWidth}px`}
      />
    </section>
  );
};

export default memo(PsychicsSetMediumSizeImages);
