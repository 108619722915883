import { createContext } from 'react';

import type { IMessageContext, IMessageContextDispatch } from '../config/declarations';

export const messageButtonContext: IMessageContext = {
  isButtonDisabled: false,
  customerDetails: null,
  countryList: null,
  telephones: null,
};

export const MessageContext = createContext<IMessageContext>(messageButtonContext);

export const MessageContextDispatch = createContext<IMessageContextDispatch>(() => {});
