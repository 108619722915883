import {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import { checkTrustpilotScore } from './api';

import { JsxElement } from '../config/declarations';

export const useTrustScore = (
  element: JsxElement | string,
  setTrustScore: Dispatch<SetStateAction<number | null>>,
) => {
  useEffect(() => {
    (async () => {
      const { props } = element as JsxElement;
      const trustpilotResponse = await checkTrustpilotScore(props);
      const { trustScore } = trustpilotResponse.businessUnit;
      setTrustScore(trustScore);
    })();
  }, [element]);
};
