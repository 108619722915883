import { FC, MouseEvent } from 'react';
import cn from 'classnames';

import { DataLink } from 'components/Shared/SharedComponents';
import { capitalizeFirstLetter } from 'lib/text.service';
import { GAReplacementValue } from 'constants/enums';
import { useExponeaProductSelectedTracking } from 'lib/external/exponeaHooks';

import { ButtonIcon } from './Icon';
import styles from './styles.module.scss';

import { ButtonInterface } from '../config/declarations';
import { getUrlForReadingsButton } from '../lib';

const Button: FC<ButtonInterface> = ({
  button: link,
  psychic,
  className,
  buttonName,
  shape = 'Rectangle',
  href = '',
  onClick,
  ...rest
}) => {
  const trackExponea = useExponeaProductSelectedTracking(psychic);

  if (!link) {
    return null;
  }

  const localOnClick = (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    trackExponea(buttonName);
    onClick?.(e);
  };

  const { extId, psychicName } = psychic;
  const url = getUrlForReadingsButton(buttonName, href, link, extId, psychicName);

  return (
    <DataLink
      {...rest}
      link={link}
      href={url}
      className={cn(
        styles.button,
        styles[`shape${capitalizeFirstLetter(shape)}`],
        styles[`button${buttonName}`],
        className,
      )}
      gaData={{
        [GAReplacementValue.PSYCHIC_NAME]: psychicName,
        [GAReplacementValue.EXT_ID]: extId.toString(),
      }}
      onClick={localOnClick}
    >
      <ButtonIcon
        isSpriteImage={false}
        image={link.image}
        className={styles.buttonAsset}
        buttonName={buttonName}
      />
      <span className={styles.buttonTitle}>
        {link.title}
      </span>
    </DataLink>
  );
};

export default Button;
