import { FC, useRef } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { DataButton } from 'components/Shared/SharedComponents';
import {
  CategoryFilterSectionProps,
  FilterListPanelInterface,
  PriceFilterSectionProps,
} from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';
import { CustomerType, GAReplacementValue } from 'constants/enums';
import type { Store } from 'app-redux/types/storeTypes';
import { useClickOutsideMultiple } from 'lib/shared.hook';
import { useLoadFiltersOptions } from 'components/Sections/PsychicsSetMediumSizeImagesClone/lib/hooks';
import LocalLoader from 'src/shared/ui/LocalLoader/LocalLoader';
import { capitalizeFirstLetter } from 'lib/text.service';

import PriceFilterSectionList from './FilterPriceItem';

import styles from '../PsychicFilter.module.scss';

const PriceFilterSection: FC<PriceFilterSectionProps> = ({
  priceTitle,
  priceOptions,
  changeFilter,
  checkIfFilterPresent,
}) => (
  <div className={styles.searchCategorySection}>
    <div className={styles.searchCategorySectionHeader}>Price</div>
    {changeFilter && priceOptions && priceOptions.map((item) => (
      <PriceFilterSectionList
        key={item.rate}
        changeFilter={changeFilter}
        checkIfFilterPresent={checkIfFilterPresent}
        item={item}
        priceTitle={priceTitle}
      />
    ))}
  </div>
);

const CategoryFilterSection: FC<CategoryFilterSectionProps> = ({
  filtersByCategories,
  doneButton,
  clearFilterButton,
  totalResultsLocal,
  viewTitle,
  resultsTitle,
  doneFilter,
  changeFilter,
  removeFilter,
  checkIfFilterPresent,
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);

  if (!filtersByCategories) {
    return null;
  }

  return (
    <>
      {Object.keys(filtersByCategories).map((categoryID, index) => (
        <div
          className={styles.searchCategorySection}
          key={filtersByCategories[categoryID].majorCategoryDescription}
        >
          <div className={styles.searchCategorySectionHeader}>
            {filtersByCategories[categoryID].richText}
          </div>
          {filtersByCategories[categoryID].descriptions.map((categoryDesc) => {
            const modifiedDesc = categoryDesc.includes('/')
              ? categoryDesc
                .split('/')
                .map((str: string) => str
                  .split(' ')
                  .map(capitalizeFirstLetter)
                  .join(' '))
                .join(' & ')
              : categoryDesc
                .split(' ')
                .map(capitalizeFirstLetter)
                .join(' ');
            const filterDetail = {
              type: filtersByCategories[categoryID].majorCategoryDescription,
              value: categoryDesc,
            };
            const isSelected = checkIfFilterPresent?.(filterDetail);
            const majorCategoryGa = filtersByCategories[categoryID].majorCategoryDescription;
            const gaData: Partial<Record<GAReplacementValue, string>> = {
              [GAReplacementValue.MAJOR_CATEGORY]: majorCategoryGa,
              [GAReplacementValue.CATEGORY]: modifiedDesc,
              [GAReplacementValue.USER]: user
                ? CustomerType.EC.toUpperCase()
                : CustomerType.NC.toUpperCase(),
            };
            const sharedButtonProps = {
              key: modifiedDesc,
              link: resultsTitle,
              onClick: () => changeFilter?.(filterDetail),
              gaData,
            };

            if (isSelected) {
              return (
                <DataButton
                  className={styles.searchCategorySectionContent}
                  {...sharedButtonProps}
                >
                  <div className={styles.searchCategorySectionContentRow}>
                    <div className={styles.searchCategorySectionContentRowPannel}>
                      <input type="checkbox" className={styles.searchCategorySectionContentRowPannelInput} defaultChecked readOnly />
                      <span className={styles.searchCategorySectionContentRowPannelLabel}>
                        <span className={styles.searchCategorySectionContentRowPannelLabelText}>
                          {modifiedDesc}
                        </span>
                      </span>
                    </div>
                  </div>
                </DataButton>
              );
            }

            return (
              <DataButton
                className={styles.categoryContentRow}
                {...sharedButtonProps}
              >
                <div className={styles.categoryContentRowInner}>
                  <span className={styles.categoryContentRowLabel}>
                    <span className={styles.categoryContentRowTitle}>{modifiedDesc}</span>
                  </span>
                </div>
              </DataButton>
            );
          })}
          {Object.keys(filtersByCategories).length - 1 === index && (
            <div className={styles.searchCategorySectionButtonWrapper}>
              <div className={styles.searchCategorySectionDoneButton}>
                {doneButton && (
                  <DataButton link={doneButton} onClick={doneFilter}>
                    <span className={styles.searchCategorySectionResult}>
                      {`${viewTitle?.title} ${totalResultsLocal} ${resultsTitle?.title}`}
                    </span>
                  </DataButton>
                )}
              </div>
              <div>
                <DataButton
                  link={clearFilterButton}
                  gaData={{
                    [GAReplacementValue.USER]: user
                      ? CustomerType.EC.toUpperCase()
                      : CustomerType.NC.toUpperCase(),
                  }}
                  onClick={removeFilter}
                >
                  <span className={styles.clearAllText}>
                    {clearFilterButton?.title}
                  </span>
                </DataButton>
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

const FilterListPanel: FC<FilterListPanelInterface> = ({
  totalResultsLocal,
  doneButton,
  clearFilterButton,
  showFilters,
  priceTitle,
  viewTitle,
  resultsTitle,
  filterByTitleRef,
  changeFilter,
  checkIfFilterPresent,
  doneFilter,
  removeFilter,
  closeFilter,
}) => {
  const filterPanelRef = useRef<HTMLDivElement>(null);
  const { options, isLoading } = useLoadFiltersOptions(Boolean(showFilters));
  useClickOutsideMultiple([filterByTitleRef, filterPanelRef], () => closeFilter?.(), false);

  if (!showFilters) {
    return null;
  }

  if (isLoading) {
    return (
      <div
        ref={filterPanelRef}
        className={cn(styles.searchPannelWrapper, styles.searchPannelLoading)}
      >
        <LocalLoader />
      </div>
    );
  }

  return (
    <div ref={filterPanelRef} className={styles.searchPannelWrapper}>
      <div className={styles.searchPannel}>
        <div className={styles.searchPannelInner}>
          <PriceFilterSection
            priceOptions={options.price}
            changeFilter={changeFilter}
            checkIfFilterPresent={checkIfFilterPresent}
            priceTitle={priceTitle}
          />
          {changeFilter && options.category && (
            <CategoryFilterSection
              filtersByCategories={options.category}
              changeFilter={changeFilter}
              checkIfFilterPresent={checkIfFilterPresent}
              doneButton={doneButton}
              clearFilterButton={clearFilterButton}
              doneFilter={doneFilter}
              removeFilter={removeFilter}
              totalResultsLocal={totalResultsLocal}
              viewTitle={viewTitle}
              resultsTitle={resultsTitle}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterListPanel;
