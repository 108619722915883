import type {
  Block,
  Link,
  Psychic,
  Text,
} from 'src/__generated__/graphqlTypes';
import { RightPsychic, SectionExtraDataType } from 'types/objectTypes';
import {
  CommonSize,
  ElementAlign,
  ItemsLayout,
  PsychicCardAppearance,
} from 'constants/enums';

import type { ComponentExtraElements, ParsedBlocks } from '../config/declarations';
import { PsychicApiToUse } from '../config/constants';

export const getExtraElements = (block?: Block) => {
  const additionalEntries = block
    ?.contentTypesCollection
    ?.items as Array<Psychic | Link> | undefined;

  if (!additionalEntries?.length) {
    return {};
  }

  return additionalEntries.reduce((store, item) => {
    if (item.__typename === 'Psychic') {
      // eslint-disable-next-line no-param-reassign
      store.psychicFrame = item;
    }

    if (item.__typename === 'Link' && item.slug === 'secondary-link') {
      // eslint-disable-next-line no-param-reassign
      store.secondaryButton = item;
    }

    return store;
  }, {} as any) as ComponentExtraElements;
};

export const getGridAreasTemplate = (buttons: Array<Block['link']>, content: Block['content']) => {
  const areas = 'areas';

  if (buttons.length > 0 && content) {
    return `${areas}All`;
  }

  if (content) {
    return `${areas}NoB`;
  }

  if (buttons.length > 0) {
    return `${areas}NoD`;
  }

  return `${areas}NoDB`;
};

export const constructId = (id: Block['blockId'], namePart: string = '') => {
  if (!id) {
    return undefined;
  }

  return id.concat(namePart);
};

export const getDefaultExtraData = (extraData: SectionExtraDataType) => ({
  ...extraData,
  titleAlign: extraData.titleAlign || ElementAlign.LEFT,
  apiToUse: extraData.apiToUse || PsychicApiToUse.COMMON,
  itemsLayout: extraData.itemsLayout || ItemsLayout.ROW,
  psychicCardAppearance: extraData.psychicCardAppearance || PsychicCardAppearance.SIMPLE_VERTICAL,
  verticalPadding: extraData.verticalPadding || CommonSize.XX_SMALL,
});

export const getBlocksAndStyles = (blocks: Array<Block | Text>): ParsedBlocks => blocks
  .reduce((store, item) => {
    if (item.__typename === 'Block' && (!item.slug || item.slug === 'block' || item.slug === 'content')) {
      return { ...store, block: item };
    }

    if (item.__typename === 'Text' && item.slug === 'styles') {
      return { ...store, styles: item };
    }

    if (item.__typename === 'Block' && item.slug === 'top-divider') {
      return { ...store, topDivider: item };
    }

    if (item.__typename === 'Block' && item.slug === 'bottom-divider') {
      return { ...store, bottomDivider: item };
    }

    return store;
  }, {});

export const getDistinctPsychics = (array: Array<RightPsychic>, limit: number = 10) => {
  const map = new Map();

  let i = 0;

  while (i < array.length && map.size < limit) {
    const psychic = array[i];
    map.set(psychic.extId, psychic);
    i++;
  }

  return Array.from(map.values());
};

export const getMaxAllowedPsychics = (array: Array<RightPsychic>, limit: number = 10) => {
  const psychics: Array<RightPsychic> = [];

  let i = 0;

  while (i < array.length && psychics.length < limit) {
    const psychic = array[i];
    psychics.push(psychic);
    i++;
  }

  return psychics;
};
