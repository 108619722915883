import { FC } from 'react';

import { StringWithCommaInterface } from '../config/declarations';

const StringWithComma: FC<StringWithCommaInterface> = ({ array }) => {
  const joinedArray = (Array.isArray(array)) ? array?.join(', ') : array;

  return (
    <>
      {joinedArray}
    </>
  );
};

export default StringWithComma;
