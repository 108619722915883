import { FC } from 'react';
import cn from 'classnames';

import { PsychicNotificationIndicator } from 'entities/PsychicNotification';
import { WebpImage } from 'components/Shared/SharedComponents';
// import { styles } from 'components/Sections/HoroscopesSignsAllAboutSigns/BestDayAndLuckyNumbers';

import styles from './styles.module.scss';

import type { IEnvelope } from '../config/declarations';

const Envelope: FC<IEnvelope> = ({
  image,
  className,
  imageClassName,
  indicatorClassName,
}) => {
  if (!image?.url) {
    return (
      <PsychicNotificationIndicator
        className={styles.notifications}
      />
    );
  }

  return (
    <div className={cn(styles.envelope, className)}>
      <WebpImage
        image="external"
        src={image.url!}
        className={cn(styles.image, imageClassName)}
      />
      <PsychicNotificationIndicator
        className={cn(styles.notifications, indicatorClassName)}
      />
    </div>
  );
};

export default Envelope;
