import { FC } from 'react';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';

import type { Store } from 'app-redux/types/storeTypes';
import { Loader } from 'components/Loader';
import { DataLink } from 'components/Shared/SharedComponents';
import { useHtmlParserReplace } from 'lib/tag.service';

import styles from './HoroSignShortInfo.module.scss';

import type { IHoroscopeText } from '../config/declarations';
import { useUserHoroscope } from '../lib/hooks';

const HoroscopeText: FC<IHoroscopeText> = ({ user, link }) => {
  const { slug, pages } = useSelector((store: Store) => store.server.page);
  const { loader } = pages[slug];
  const replace = useHtmlParserReplace();
  const horoscope = useUserHoroscope(user);

  if (horoscope === null) {
    return (
      <div className={styles.textLoader}>
        <Loader
          image={loader}
          isVisible
        />
      </div>
    );
  }

  return (
    <div className={styles.textWrapper}>
      <p className={styles.text}>
        {horoscope && parse(horoscope, { replace }) }
      </p>
      { link && (
        <DataLink className={styles.textLink} link={link}>
          {link.title}
        </DataLink>
      )}
    </div>
  );
};

export default HoroscopeText;
