import { ViewerDevice } from 'constants/enums';
import { Block, ImageWrapper } from 'src/__generated__/graphqlTypes';
import { SectionComponentInterface } from 'types/componentTypes';

export enum CtaFlatBannerSlugs {
  BACKGROUND_MOBILE = 'backgroundMobile',
  BACKGROUND_DESKTOP = 'backgroundDesktop',
}

export interface CtmFlatBannerInterface extends SectionComponentInterface {
  viewerDevice: ViewerDevice;
}

export interface CtaFlatBannerImageInterface {
  mobileImage?: ImageWrapper;
  desktopImage?: ImageWrapper;
  viewerDevice?: ViewerDevice;
  isBackground?: boolean;
  className: string;
  pictureClassName?: string;
}

export interface CtaFlatBannerButtonInterface {
  link?: Block['link'];
}
