/* eslint-disable react/jsx-props-no-spreading */
import { useSelect } from 'downshift';
import { FC } from 'react';
import cn from 'classnames';

import { Tick } from 'src/shared/ui/Tick';

import styles from './DownshiftSelect.module.scss';
import { IDownshiftSelect } from './declarations';

const DownshiftSelect: FC<IDownshiftSelect> = ({
  options = [],
  label,
  isLoading,
  className,
  placeholder,
  ulClassName,
  toggleClassName,
  circleClassName,
  circleSelectedClassName,
  renderLabel,
  onChange,
}) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items: options,
    defaultSelectedItem: (placeholder) ? undefined : options?.[0],
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        onChange(selectedItem);
      }
    },
  });

  if (!options.length) {
    return null;
  }

  return (
    <div className={cn(styles.select, className)}>
      {(renderLabel?.(getLabelProps()) || label) && (
        <span className={styles.label} {...getLabelProps()}>
          {label}
        </span>
      )}
      <div className={styles.selectWrapper}>
        <button
          className={cn(styles.button, toggleClassName)}
          type="button"
          disabled={isLoading}
          {...getToggleButtonProps()}
        >
          <strong>{selectedItem?.text || placeholder}</strong>
          <Tick
            sideSize={8}
            direction="bottom"
            className={styles.buttonTick}
          />
        </button>
        <ul
          className={cn(
            styles.list,
            ulClassName,
            { [styles.hidden]: !isOpen },
          )}
          {...getMenuProps()}
        >
          {options.map((item, index) => {
            const isSelected = item.sys.id === selectedItem?.sys?.id;
            const classNamePart = isSelected ? 'Selected' : 'Common';

            return (
              <li
                key={item.sys.id}
                className={cn(
                  styles.item,
                  styles[`item${classNamePart}`],
                )}
                {...getItemProps({ item, index })}
              >
                <i className={cn(
                  {
                    [styles.circle]: !circleClassName,
                    [styles[`circle${classNamePart}`]]: !circleSelectedClassName,
                  },
                  circleClassName,
                  circleSelectedClassName,
                )}
                />
                <span>{item.text}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default DownshiftSelect;
