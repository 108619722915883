import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';

import styles from 'components/Sections/HoroscopeNewsletters/HoroscopeNewsletters.module.scss';
import {
  InputAlert,
  setSelectValueOrDefault,
  useAlertStore,
} from 'components/Sections/HoroscopeNewsletters/Inputs/InputsSharedComponents';
import {
  calculateAge,
  getDaysAmountInMonth,
  getDefaultDaysInMonthArray,
  getMonthsWithIndexes,
} from 'lib/date.service';
import { useClickOutside } from 'lib/shared.hook';
import { FreeHoroscopeInputsReducerType, Pair } from 'types/objectTypes';
import {
  AlertTypes,
  ErrorCodeSignUpNewsletters,
  FreeHoroscopeModalInputs,
} from 'constants/enums';
import { DEFAULT_SELECT_OPTION, MIN_ALLOWED_AGE } from 'constants/constants';
import { ValidationMark } from 'components/Shared/SharedComponents';
import { FreeHoroscopesDateOfBirthInputInterface, BirthDateSelectInterface } from 'components/Sections/HoroscopeNewsletters/Inputs/declarations';

/* Nested component */
const BirthDateSelect: React.FC<BirthDateSelectInterface> = ({
  selectClassName,
  value,
  defaultOptionTitle,
  array,
  isPair,
  onChange,
  onClick,
}) => {
  const applyOption = (item: Pair<string> | string | number) => {
    if (isPair) {
      const { key, value } = item as Pair<string>;

      return <option key={key} value={key}>{ value }</option>;
    }

    const stringItem = item as string;

    return <option key={stringItem} value={stringItem}>{ stringItem }</option>;
  };

  return (
    <select
      className={selectClassName}
      onChange={onChange}
      onClick={onClick}
      value={value}
      required
    >
      {defaultOptionTitle && (
        <option
          value={DEFAULT_SELECT_OPTION}
          disabled
          hidden
        >
          {defaultOptionTitle}
        </option>
      )}
      {array.map((item) => applyOption(item))}
    </select>
  );
};

/* Main component */
const DateOfBirthInput: React.FC<FreeHoroscopesDateOfBirthInputInterface> = ({
  textList,
  locale,
  defaultDaysArray,
  yearsAmount,
  placeholder,
  birthDate,
  modalErrors,
  isValid,
  alerts,
  dispatch,
}) => {
  const birthDateRef = useRef<HTMLDivElement>(null);
  const {
    alert,
    setValidState,
    setInvalidState,
  } = useAlertStore(alerts, FreeHoroscopeModalInputs.IS_BIRTH_DATE_VALID, dispatch);
  const months = getMonthsWithIndexes('short', locale);
  const [isFirstClickOccures, setFirstClickState] = useState<boolean>(false);
  const [isFirstEntering, setFirstEnteringState] = useState<boolean>(true);
  const isTextListArray = Boolean(textList?.length);

  useEffect(() => {
    if (!modalErrors?.length) {
      return;
    }

    modalErrors.forEach((error) => {
      if (error.code === ErrorCodeSignUpNewsletters.DOB) {
        if (error.message) {
          setInvalidState(null, error.message);
        } else {
          setInvalidState(AlertTypes.BIRTHDAY_REQUIRED);
        }
      }
    });
  }, [modalErrors]);

  const setFirstClick = () => !isFirstClickOccures && setFirstClickState(true);
  const validateAndChangeValidationState = (birthDate: FreeHoroscopeInputsReducerType['birthDate']) => {
    if (!isFirstClickOccures) {
      return;
    }

    const isAllSelectsFilled = !!(birthDate.day && birthDate.month && birthDate.year);

    if (isAllSelectsFilled) {
      const { year, month, day } = birthDate;
      const monthIndex = +month!.key - 1;
      const usersBirthDate = new Date(+year!, monthIndex, +day!);
      const isValidSelects = calculateAge(usersBirthDate) >= MIN_ALLOWED_AGE;

      if (isValidSelects) {
        setValidState();
      } else {
        setInvalidState(AlertTypes.UNDER_AGE);
      }
    } else {
      setInvalidState(AlertTypes.BIRTHDAY_REQUIRED);
    }
  };

  useClickOutside(birthDateRef, () => {
    if (birthDate.day || birthDate.month || birthDate.year) {
      setFirstEnteringState(false);
    }

    validateAndChangeValidationState(birthDate);
  });

  const onSelectYear = (e: ChangeEvent<HTMLSelectElement>) => {
    const year = e.target.value;

    if (!isValid || (birthDate.day && birthDate.month)) {
      validateAndChangeValidationState({ day: birthDate.day, month: birthDate.month, year });
    }

    if (birthDate.month && birthDate.day) {
      const daysAmount = getDaysAmountInMonth(+birthDate.month.key, +year);
      const isInvalidBirthDay = daysAmount < +birthDate.day;

      if (isInvalidBirthDay) {
        dispatch({ type: FreeHoroscopeModalInputs.DAY, payload: undefined });
      } else if (isFirstEntering) {
        setFirstEnteringState(false);
      }
    }

    dispatch({ type: FreeHoroscopeModalInputs.YEAR, payload: year });
  };

  const onSelectMonth = (e: ChangeEvent<HTMLSelectElement>) => {
    const monthIndex = +e.target.value;
    const month = months[monthIndex - 1];

    if (!isValid || (birthDate.day && birthDate.year)) {
      validateAndChangeValidationState({ day: birthDate.day, month, year: birthDate.year });
    }

    if (birthDate.year && birthDate.day) {
      const daysAmount = getDaysAmountInMonth(monthIndex, +birthDate.year);
      const isInvalidBirthDay = daysAmount < +birthDate.day;

      if (isInvalidBirthDay) {
        dispatch({ type: FreeHoroscopeModalInputs.DAY, payload: undefined });
      } else if (isFirstEntering) {
        setFirstEnteringState(false);
      }
    }

    dispatch({
      type: FreeHoroscopeModalInputs.MONTH,
      payload: month,
    });
  };

  const onSelectDay = (e: ChangeEvent<HTMLSelectElement>) => {
    const day = e.target.value;

    if (!isValid) {
      validateAndChangeValidationState({ day, month: birthDate.month, year: birthDate.year });
    }

    if (birthDate.month && birthDate.year) {
      validateAndChangeValidationState({ day, month: birthDate.month, year: birthDate.year });

      if (isFirstEntering) {
        setFirstEnteringState(false);
      }
    }

    dispatch({ type: FreeHoroscopeModalInputs.DAY, payload: day });
  };

  const daysArray = (birthDate.month && birthDate.year)
    ? getDefaultDaysInMonthArray(getDaysAmountInMonth(+birthDate.month.key, +birthDate.year))
    : defaultDaysArray;

  return (
    <div className={cn(
      styles.freeHoroscopeModalFormControl,
      styles.freeHoroscopeModalFormControlBirthDate,
    )}
    >
      <div
        ref={birthDateRef}
        className={cn(
          styles.freeHoroscopeModalFormInput,
          styles.freeHoroscopeModalFormInputBirthDate,
          !isValid && styles.freeHoroscopeModalFormInputBirthDateInvalid,
        )}
      >
        <span className={styles.freeHoroscopeModalFormInputPlaceholder}>
          {placeholder}
        </span>
        <div className={styles.freeHoroscopeModalFormInputBirthDateSelects}>
          <BirthDateSelect
            selectClassName={styles.freeHoroscopeModalFormInputBirthDateSelectsSelect}
            onChange={onSelectMonth}
            onClick={setFirstClick}
            value={setSelectValueOrDefault(birthDate.month?.key)}
            defaultOptionTitle={isTextListArray ? textList[0] : ''}
            array={months}
            isPair
          />
          <span className={styles.freeHoroscopeModalFormInputBirthDateSelectsSeparator}>/</span>
          <BirthDateSelect
            selectClassName={styles.freeHoroscopeModalFormInputBirthDateSelectsSelect}
            onChange={onSelectDay}
            onClick={setFirstClick}
            value={setSelectValueOrDefault(birthDate.day)}
            defaultOptionTitle={isTextListArray ? textList[1] : ''}
            array={daysArray}
          />
          <span className={styles.freeHoroscopeModalFormInputBirthDateSelectsSeparator}>/</span>
          <BirthDateSelect
            selectClassName={styles.freeHoroscopeModalFormInputBirthDateSelectsSelect}
            onChange={onSelectYear}
            onClick={setFirstClick}
            value={setSelectValueOrDefault(birthDate.year)}
            defaultOptionTitle={isTextListArray ? textList[2] : ''}
            array={yearsAmount}
          />
          { !isFirstEntering && (
            <div className={styles.freeHoroscopeModalFormInputMark}>
              <ValidationMark
                isValid={isValid}
              />
            </div>
          )}
        </div>
      </div>
      <InputAlert isValid={isValid} alert={alert} />
    </div>
  );
};

export default DateOfBirthInput;
