import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';
import { useRouter } from 'next/router';

import classes from 'src/styles/_commonClasses.module.scss';
import { CommonRichText } from 'components/Shared/SharedComponents';
import type { RichTextParsersConfig } from 'types/objectTypes';
import { getLocalizedFullMonthDYDate } from 'lib/date.service';

import styles from './HoroSignShortInfo.module.scss';

import type { IContent } from '../config/declarations';

const getParsersConfig = (): RichTextParsersConfig => ({
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.dates,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
});

const Content: FC<IContent> = ({ content, text }) => {
  const router = useRouter();

  return (
    <>
      <CommonRichText content={content} parsersConfig={getParsersConfig()} />
      <strong className={cn(styles.datesCurrent, classes.textBold700)}>
        {text}
        :
        {' '}
        {getLocalizedFullMonthDYDate(router.locale)}
      </strong>
    </>
  );
};

export default Content;
