import { PersonalizedVariant } from '@uniformdev/context';

import { HardcodedPersonalizationData } from 'types/objectTypes';

import { personalizationHandlers } from './store';

export const chooseVariant = (
  variants: Array<any>,
  personalizationData: HardcodedPersonalizationData,
): Array<PersonalizedVariant> => {
  if (variants.length <= 1) {
    return variants;
  }

  const defaultVariant = variants.find((variant) => !variant.pz);
  const handler = personalizationHandlers.get(defaultVariant?.type);

  if (!handler) {
    return [defaultVariant];
  }

  const variant = variants.find((variant) => handler(variant.pz, personalizationData));

  if (variant) {
    delete variant.pz;

    return [variant];
  }

  return [defaultVariant];
};
