import { SectionType } from 'constants/enums';
import type { Block } from 'src/__generated__/graphqlTypes';
import { DailyHoroscope } from 'entities/DailyHoroscope';
import { FlatBanner } from 'entities/FlatBanner';

import styles from '../ui/styles.module.scss';

export const getComponent = (block: Block) => {
  const { slug } = block;

  switch (slug) {
    case SectionType.EC_HORO_SIGH_SHORT_INFO: {
      const blocks = block.contentTypesCollection?.items || [];

      return (
        <DailyHoroscope
          key={block.sys.id}
          date={new Date()}
          blocks={blocks as Array<Block>}
          className={styles.horoscope}
          horoscopeClassName={styles.description}
          showDate
        />
      );
    }
    case SectionType.CTA_FLAT_BANNER: {
      const [contentBlock] = block.contentTypesCollection?.items || [];

      return (
        <FlatBanner
          key={block.sys.id}
          block={contentBlock as Block}
          className={styles.banner}
          contentClassName={styles.content}
          linkClassName={styles.link}
          textClassName={styles.title}
        />
      );
    }
    default: {
      return null;
    }
  }
};
