import { FC } from 'react';

import { LuckyColor } from 'components/Sections/HoroscopesSignsAllAboutSigns/LuckyColor';
import { ZodiacSignFeatureSingleElementInterface } from 'types/componentTypes';
import { useDeviceHoroscopeInfo } from 'lib/horoscope/horoscope.hook';

const LuckyColorContainer: FC<ZodiacSignFeatureSingleElementInterface> = ({
  element,
}) => {
  const {
    mobileViewMaxWidth,
    horoscopeTabletMaxWidth,
    viewerDevice,
  } = useDeviceHoroscopeInfo();

  return (
    <LuckyColor
      luckyColor={element}
      viewerDevice={viewerDevice}
      mobileViewMaxWidth={mobileViewMaxWidth}
      horoscopeTabletMaxWidth={horoscopeTabletMaxWidth}
    />
  );
};

export default LuckyColorContainer;
