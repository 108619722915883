import { FC } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

import {
  CommonRichText,
  DataButton,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';
import { FilterByInterface } from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';

import styles from '../PsychicFilter.module.scss';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.HEADING_3]: {
    classNames: styles.filterByTitle,
  },
};

const FilterBy: FC<FilterByInterface> = ({
  filterImage, filterTitle, toggleFilters, filterByTitleRef,
}) => (
  <div ref={filterByTitleRef} className={styles.filterBy}>
    <DataButton
      className={styles.filterByInner}
      onClick={toggleFilters}
    >
      <WebpImage
        image={filterImage || 'external'}
        pictureClassName={styles.filterByIconWrapper}
        className={styles.filterByIcon}
      />
      <CommonRichText content={filterTitle} parsersConfig={parsersConfig} />
    </DataButton>
  </div>
);

export default FilterBy;
