/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import {
  FC,
  useCallback,
  useState,
} from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import { Block, Link } from 'src/__generated__/graphqlTypes';
import { RichTextParsersConfig, SectionExtraDataType } from 'types/objectTypes';
import styles from 'components/Sections/ExpandableItemsList/ExpandableItemsList.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import Divider from 'components/Sections/Divider';
import { ExpandableItem } from 'components/Sections/ExpandableItemsList';
import { CommonRichText, DataButton } from 'components/Shared/SharedComponents';
import { CommonSize, ElementAlign } from 'constants/enums';
import { ExpandableItemsListInterface } from 'types/componentTypes';
import { capitalizeFirstLetter } from 'lib/text.service';
import { COLLAPSE, EXPAND } from 'constants/constants';
import { ItemsWithButtons } from 'components/Sections/ExpandableItemsList/declarations';

const getParsersConfig = (
  { titleAlign, pAlign }: SectionExtraDataType,
  rootClass: string = 'expandableItem',
): RichTextParsersConfig => {
  const titleAlignClass = classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`];
  const pAlignClass = classes[`pAlign${capitalizeFirstLetter(pAlign || titleAlign)}`];
  const getHClasses = (specificClass: string) => cn(
    styles[`${rootClass}Title`],
    specificClass,
    titleAlignClass,
  );

  return ({
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(styles[`${rootClass}Paragraph`], pAlignClass),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getHClasses(classes.titleMainH3),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHClasses(classes.titleMainH2),
    },
    [BLOCKS.HEADING_1]: {
      classNames: getHClasses(classes.titleMainH1),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};

const ExpandableItemsList: FC<ExpandableItemsListInterface> = ({
  content: block,
  topDivider,
  bottomDivider,
  extraData,
  bgColor,
  commonPageMaxWidth,
}) => {
  const {
    titleAlign = ElementAlign.CENTER,
    verticalPadding = CommonSize.MEDIUM,
    pAlign,
  } = extraData || {};
  const {
    richTitle,
    link,
    entryName,
    content,
    contentTypesCollection,
  } = block;
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({});
  const [expandedCount, setExpandedCount] = useState<number>(0);

  const toggleExpanded = useCallback((id: string) => setExpandedItems((prev) => {
    const isExpanded = !prev[id];

    if (isExpanded) {
      setExpandedCount((prev) => prev + 1);
    } else {
      setExpandedCount((prev) => prev - 1);
    }

    prev[id] = isExpanded;

    return prev;
  }), []);

  const applyExpandableList = (expandableItems?: Array<Block>) => expandableItems && (
    <ul className={styles.expandableItemList}>
      {expandableItems.map((item) => (
        <ExpandableItem
          key={item.entryName}
          id={item.sys.id}
          isExpanded={expandedItems[item.sys.id]}
          title={item.richTitle}
          link={item.link}
          paragraphs={item.content}
          toggleExpanded={toggleExpanded}
        />
      ))}
    </ul>
  );

  const applyToggleButton = (
    expandableItemsLength: number,
    toggleButton?: Block['link'],
  ) => toggleButton && (
    <DataButton
      link={toggleButton}
      className={styles.expandableItemButton}
      onClick={() => {
        const isAllExpanded = expandableItemsLength === expandedCount;
        setExpandedItems((prev) => Object.keys(prev)
          .reduce((store, key) => ({ ...store, [key]: !isAllExpanded }), {}));
        setExpandedCount(isAllExpanded ? 0 : expandableItemsLength);
      }}
    >
      {toggleButton.title}
    </DataButton>
  );

  const applyRichText = (richText: Block['richTitle'] | Block['content']) => richText && (
    <CommonRichText
      content={richText}
      parsersConfig={getParsersConfig({ titleAlign, pAlign })}
    />
  );

  const buildComponent = () => {
    const entries = contentTypesCollection?.items as Array<Block | Link> || [];
    const {
      expandButton,
      collapseButton,
      expandableItems,
    } = entries.reduce((store, item) => {
      if (item.__typename === 'Link') {
        if (item.slug === COLLAPSE || item.slug === EXPAND) {
          store[`${item.slug}Button`] = item;
        }
      } else {
        store.expandableItems.push(item as Block);
      }

      return store;
    }, { expandableItems: [] } as ItemsWithButtons);

    if (!Object.keys(expandedItems).length) {
      setExpandedItems(expandableItems
        .reduce((store, item) => ({ ...store, [item.sys.id]: false }), {}));
    }

    const toggleButton = (expandableItems.length !== expandedCount)
      ? expandButton || link
      : collapseButton || link;

    return (
      <div className={styles.expandableItem} key={entryName}>
        {applyRichText(richTitle)}
        {applyRichText(content)}
        {applyToggleButton(expandableItems.length, toggleButton)}
        {applyExpandableList(expandableItems)}
      </div>
    );
  };

  return (
    <section
      className={cn(styles.wrapper, classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`])}
      style={{ background: bgColor }}
    >
      <Divider
        block={topDivider}
        maxWidth={`${commonPageMaxWidth}px`}
      />
      {buildComponent()}
      <Divider
        block={bottomDivider}
        maxWidth={`${commonPageMaxWidth}px`}
      />
    </section>
  );
};

export default ExpandableItemsList;
