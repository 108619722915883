import React, { useEffect } from 'react';
import cookie from 'cookie';
import { AxiosResponse } from 'axios';

import { StickyOffer } from 'components/StickyOffer';
import { getStickyOfferDataProxy } from 'src/api/customerApi';
import { SESSION_COOKIE_NAME } from 'constants/constants';
import { StickyOfferContainerInterface } from 'components/StickyOffer/declarations';
import { Logger } from 'lib/logger';

const StickyOfferContainer: React.FC<StickyOfferContainerInterface> = ({
  stickyOfferData,
  stickyOffer,
  setStickyOfferData,
}) => {
  useEffect(() => {
    if (!stickyOffer || stickyOfferData) return;

    (async () => {
      try {
        const cookies = cookie.parse(document.cookie);
        const authObject = JSON.parse(cookies[SESSION_COOKIE_NAME]);
        const response: AxiosResponse = await getStickyOfferDataProxy(authObject.customerId);

        if (response.status === 200) {
          setStickyOfferData(response?.data);
        }
      } catch (e) {
        Logger.error(e);
      }
    })();
  }, [stickyOffer, stickyOfferData, setStickyOfferData]);

  if (!stickyOfferData) return null;

  return <StickyOffer data={stickyOfferData} stickyOffer={stickyOffer} />;
};

export default StickyOfferContainer;
