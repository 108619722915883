import { FC } from 'react';

import styles from './styles.module.scss';

import { PsychicWorkDescriptionInterface, PsychicWorkDescriptionLiInterface as LiWithArrayInterface } from '../config/declarations';

/* Nested component */
const LiWithArray: FC<LiWithArrayInterface> = ({ array, topic }) => {
  const joinedArray = (Array.isArray(array)) ? array?.join(', ') : array;

  return (
    <li className={styles.workDescriptionItem}>
      <span className={styles.workDescriptionItemTopic}>{topic}</span>
      {' '}
      <strong
        title={joinedArray}
        className={styles.workDescriptionItemDescription}
      >
        {joinedArray || '-'}
      </strong>
    </li>
  );
};

/* Main component */
const PsychicWorkDescription: FC<PsychicWorkDescriptionInterface> = ({
  topics,
  abilities,
  tools,
  totalReadings,
  startYear,
  contentfulData,
}) => (
  <ul className={styles.workDescription}>
    <li className={styles.workDescriptionItem}>
      <span className={styles.workDescriptionItemTopic}>{contentfulData.totalReadings}</span>
      {' '}
      <strong className={styles.workDescriptionItemDescription}>
        {totalReadings}
        {' '}
        -
        {' '}
        {contentfulData.since}
        {' '}
        {startYear}
      </strong>
    </li>
    <LiWithArray
      topic={contentfulData.abilities}
      array={abilities}
    />
    <LiWithArray
      topic={contentfulData.topics}
      array={topics}
    />
    <LiWithArray
      topic={contentfulData.tools}
      array={tools}
    />
  </ul>
);

export default PsychicWorkDescription;
