import { FC } from 'react';
import cn from 'classnames';

import Icon from 'src/shared/ui/Icon';
import { PsychicRate } from 'components/Shared/SharedComponents';
import { PsychicCardImage } from 'entities/PsychicCardImage';
import { StatusIndicatorWrapper as StatusIndicator } from 'entities/JsxParserComponents';

import styles from './styles.module.scss';

import type { IPsychicPhotoModule } from '../config/declarations';
import { getRatePerMinute } from '../lib';

const priceStyles = {
  wrapper: styles.price,
  common: styles.priceCommon,
  commonWithDiscount: styles.priceCrossed,
  discount: styles.priceDiscount,
};

const PhotoWithRate: FC<IPsychicPhotoModule> = ({
  src,
  psychic,
  imageClassName,
  psychicSkeleton,
}) => {
  const rates = getRatePerMinute(psychic, psychicSkeleton);

  return (
    <div className={styles.photoWithRate}>
      <PsychicCardImage
        psychic={psychicSkeleton}
        image="external"
        src={src}
        alt={psychic.psychicName}
        className={cn(styles.image, imageClassName)}
      />
      <div className={styles.stars}>
        <StatusIndicator
          chatStatus={psychic.chatStatus}
          phoneStatus={psychic.phoneStatus}
        />
        <span
          title={psychic.psychicName}
          className={styles.name}
        >
          {psychic.psychicName}
        </span>
        <div className={styles.iconWrapper}>
          {psychic.overallScore}
          {' '}
          <Icon
            image={psychicSkeleton.starImage}
            className={styles.icon}
          />
        </div>
      </div>
      <PsychicRate
        pricePerMinute={rates.price}
        priceWithDiscountPerMinute={rates.discount}
        classNames={priceStyles}
      />
    </div>
  );
};

export default PhotoWithRate;
