import { FC } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

import styles from 'components/Sections/HoroscopesSignsAllAboutSigns/Gemstones/Gemstones.module.scss';
import { SignFeatureContainer } from 'components/Sections/HoroscopesSignsAllAboutSigns/Shared';
import { CommonRichText } from 'components/Shared/SharedComponents';
import { Block } from 'src/__generated__/graphqlTypes';
import { getImagesWithLimitedSize } from 'lib/image.service';
import { RichTextParsersConfig, WebpWidthLimitType } from 'types/objectTypes';
import { calculateSizeWithRatio } from 'lib/sharedMethods.service';
import { ImageFitMode } from 'constants/enums';
import { getImageBasedDevice } from 'lib/horoscope/horoscope.service';
import { AllAboutSignsGemstonesInterface, GemstonesImageSizeRatioSlug as Slug } from 'components/Sections/HoroscopesSignsAllAboutSigns/Gemstones/declarations';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.gemstonesContentAssetTextDescription,
  },
};

const Gemstones: FC<AllAboutSignsGemstonesInterface> = ({
  gemstones,
  viewerDevice,
  mobileViewMaxWidth,
  horoscopeTabletMaxWidth,
}) => {
  if (!gemstones) {
    return <SignFeatureContainer containerClassName={styles.gemstones} />;
  }

  const { contentTypesCollection, title } = gemstones;
  const gemstonesArray = (contentTypesCollection?.items as Array<Block>) || [];

  if (!gemstonesArray.length) {
    return null;
  }

  const Image = getImageBasedDevice(viewerDevice);

  const applyGemstonesArray = () => gemstonesArray.map((gemstone) => {
    if (!gemstone) {
      return null;
    }

    const {
      entryName,
      title,
      image,
      content,
    } = gemstone;

    if (!image || !title) {
      return null;
    }

    const { height, width } = image;
    let imagesWithLimitedSize: Array<WebpWidthLimitType> | undefined;

    if (height && width) {
      const entryHeight = calculateSizeWithRatio(Slug.ENTRY_IMAGE, height);
      const entryWidth = calculateSizeWithRatio(Slug.ENTRY_IMAGE, width);
      imagesWithLimitedSize = getImagesWithLimitedSize([
        {
          height: calculateSizeWithRatio(Slug.MOBILE, entryHeight),
          width: calculateSizeWithRatio(Slug.MOBILE, entryWidth),
          media: `${mobileViewMaxWidth}px`,
        },
        {
          height: calculateSizeWithRatio(Slug.TABLET, entryHeight),
          width: calculateSizeWithRatio(Slug.TABLET, entryWidth),
          media: `${horoscopeTabletMaxWidth}px`,
        },
        {
          height: entryHeight,
          width: entryWidth,
          isMinWidth: true,
          media: `${horoscopeTabletMaxWidth}px`,
        },
      ],
      { fit: ImageFitMode.SCALE });
    }

    return (
      <div key={entryName || image.url} className={styles.gemstonesContentAsset}>
        <Image
          image={image}
          className={styles.gemstonesContentAssetImage}
          widthLimit={imagesWithLimitedSize}
        />
        <div className={styles.gemstonesContentAssetText}>
          <span className={styles.gemstonesContentAssetTextTitle}>{title}</span>
          {content && (
            <CommonRichText
              content={content}
              parsersConfig={parsersConfig}
            />
          )}
        </div>
      </div>
    );
  });

  return (
    <SignFeatureContainer
      title={title!}
      containerClassName={styles.gemstones}
    >
      <div className={styles.gemstonesContent}>
        {applyGemstonesArray()}
      </div>
    </SignFeatureContainer>
  );
};

export default Gemstones;
