import { FC } from 'react';

import { DataLink } from 'components/Shared/SharedComponents';
import Tick from 'src/shared/ui/Tick/Tick';

import styles from './SimilarPsychics.module.scss';

import type { IActionButton } from '../config/declarations';

const ActionButton: FC<IActionButton> = ({ link }) => {
  if (!link) {
    return null;
  }

  return (
    <DataLink link={link} className={styles.wrapperButton}>
      {link.title}
      <Tick
        direction="right"
        className={styles.tick}
        sideSize={8}
      />
    </DataLink>
  );
};

export default ActionButton;
