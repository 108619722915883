import React from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import styles from 'components/Header/Desktop/DesktopNavBar.module.scss';
import { MobileNavBarClasses } from 'types/classTypes';
import CommonNavBar from 'components/Navigation';
import { Link } from 'src/__generated__/graphqlTypes';
import { NavigationLocation } from 'constants/enums';
import { DesktopNavBarWrapperInterface } from 'components/Header/Desktop/declarations';
import { Store } from 'app-redux/types/storeTypes';

const MiddleBarNavigation: React.FC<DesktopNavBarWrapperInterface> = ({ navBar }) => {
  const user = useSelector((store: Store) => store.server.auth.user);

  const router = useRouter();
  const addHighlightToNavItemDueToCurrentPath = (): string => {
    const { pathname } = router;
    const topLevelNavItems = navBar?.navItemsCollection?.items;

    if (!pathname || !topLevelNavItems) return '';

    const lowerPathname = pathname.toLowerCase();
    const appropriateItem = topLevelNavItems.find((item) => {
      const itemHeadline = item?.headline as Link | undefined;

      if (!itemHeadline?.src || itemHeadline.src === '/') return false;

      return lowerPathname.includes(itemHeadline.src.toLowerCase());
    });

    return appropriateItem?.headline?.entryName || '';
  };

  const classes: MobileNavBarClasses = {
    highOrder: {
      ul: styles.navigationUl,
      li: styles.navigationUlLi,
      aCurrent: styles.navigationUlLiACurrent,
      link: styles.navigationUlLiA,
      linkBold: styles.navigationUlLiABold,
      span: styles.navigationUlLiASpan,
      img: styles.navigationUlLiASpanImage,
      imgCurrent: styles.navigationUlLiASpanImageCurrent,
      innerUl: styles.navigationUlLiDropdownUl,
    },
    middleOrder: {
      li: styles.navigationUlLiDropdownUlLi,
      link: styles.navigationUlLiDropdownUlLiA,
      linkBold: styles.navigationUlLiDropdownUlLiABold,
      span: styles.navigationUlLiDropdownUlLiASpan,
      img: styles.navigationUlLiDropdownUlLiASpanImage,
      innerUl: styles.navigationUlLiDropdownUlLiRightDropdownUl,
    },
    lowOrder: {
      li: styles.navigationUlLiDropdownUlLiRightDropdownUlLi,
      link: styles.navigationUlLiDropdownUlLiRightDropdownUlLiA,
    },
  };

  const itemEqualWithCurrentPathName = addHighlightToNavItemDueToCurrentPath();

  return (
    <nav
      role="navigation"
      aria-label="header"
      className={styles.navigation}
    >
      <CommonNavBar
        navBar={navBar}
        classes={classes}
        isMobile={false}
        navigationLocation={NavigationLocation.HEADER}
        itemEqualWithCurrentPathName={itemEqualWithCurrentPathName}
        user={user}
      />
    </nav>
  );
};

export default MiddleBarNavigation;
