import {
  useRef,
  FC,
  useMemo,
} from 'react';
import cn from 'classnames';

import styles from 'components/Sections/Divider/Divider.module.scss';
import { DividerInterface } from 'types/componentTypes';
import { LazyWebpImage, WebpImage } from 'components/Shared/SharedComponents';
import { capitalizeFirstLetter } from 'lib/text.service';

const Divider: FC<DividerInterface> = ({
  block: divider,
  className,
  maxWidth,
  lazy = false,
  position,
}) => {
  const pictureRef = useRef<HTMLImageElement>(null);
  const calculatedPosition = useMemo(() => {
    const { slug } = divider || {};
    const [slugPosition] = slug?.split('-') || [];

    return position || slugPosition;
  }, [position, divider]);

  if (!divider) {
    return null;
  }

  const { image } = divider;
  const Component = lazy ? LazyWebpImage : WebpImage;

  return (
    <div
      style={{ maxWidth }}
      className={cn(
        styles.divider,
        styles[`divider${capitalizeFirstLetter(calculatedPosition)}`],
        className,
      )}
    >
      <span className={styles.dividerLine} />
      {image && (
        <Component
          image={image}
          ref={pictureRef}
          className={styles.dividerImage}
        />
      )}
      <span className={styles.dividerLine} />
    </div>
  );
};

export default Divider;
