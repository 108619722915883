import React, { FC } from 'react';

import { DataLink } from 'components/Shared/SharedComponents';
import { GAReplacementValue } from 'constants/enums';

import type { IAvatarWrapperLink } from '../config/declarations';

const AvatarWrapperLink: FC<IAvatarWrapperLink> = ({
  children,
  psychic,
  profileButton,
  positionInArray,
  className,
}) => {
  const { _meta: meta = {} } = profileButton?.gaProperties || {};
  const finalProfileLink = (profileButton?.src)
    ? profileButton.src + psychic.psychicBioURL
    : `/psychics${psychic.psychicBioURL}`;

  return (
    <DataLink
      href={finalProfileLink}
      link={profileButton!}
      className={className}
      gaData={{
        [GAReplacementValue.PSYCHIC_NAME]: psychic.psychicName,
        [GAReplacementValue.POSITION]: meta[positionInArray],
      }}
    >
      {children}
    </DataLink>
  );
};

export default AvatarWrapperLink;
