import { useSelector } from 'react-redux';

import type { Store } from 'app-redux/types/storeTypes';
import { callReserve } from 'src/api/customerApi';

export const useCallbackReserveFunction = () => {
  const user = useSelector((store: Store) => store.server.auth.user);

  const talkReserve = async (
    custId: string,
    extId: Number,
  ) => {
    const res = await callReserve(
      {
        callbackInfo: {
          CustID: custId,
          ExtId: extId,
          statusOnly: true,
          SaleLocation: 'Mobile',
        },
      },
      user!,
    );

    return res;
  };

  return { talkReserve };
};
