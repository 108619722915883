import { FC } from 'react';

import styles from './PlainCalendar.module.scss';

import { useWeekdays } from '../lib/hooks';
import type { ITHead } from '../config/declarations';

const THead: FC<ITHead> = ({ weekdays }) => {
  const backupWeekdays = useWeekdays();
  const displayedWeekdays = (weekdays?.length === 7)
    ? weekdays
    : backupWeekdays;

  return (
    <thead className={styles.head}>
      <tr>
        {displayedWeekdays.map((day) => (
          <th key={day} className={styles.th}>
            {day}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
