import { FC, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import LocalLoader from 'src/shared/ui/LocalLoader/LocalLoader';
import type { Store } from 'app-redux/types/storeTypes';
import type { SectionComponentInterface } from 'types/componentTypes';
import { PlainCalendar } from 'entities/PlainCalendar';
import type { Block } from 'src/__generated__/graphqlTypes';

import styles from './AppointmentCalendar.module.scss';
import CalendarHeader from './CalendarHeader';
import ColorInfo from './ColorInfo';
import Navigation from './Navigation';
import Appointment from './Appointment';
import NoSidebarContent from './NoSidebarContent';

import { getCalendarOnChange, parseBlocks } from '../lib';
import {
  useDatesHandlers,
  useHours,
  useNestedDispatch,
  useSelected,
  useTimeSlots,
} from '../lib/hooks';

const AppointmentCalendar: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const isSidebar = useSelector((store: Store) => store.client.app.isSidebarActive);
  const [isCollapsed, setCollapsedState] = useState<boolean>(true);
  const { calendar, menu, modals } = parseBlocks(blocks);
  const { data, isLoading } = useTimeSlots();
  const [selected, setSelected] = useSelected(data?.psychicSchedules);
  const hours = useHours(data);
  const { dispatch, setDispatch } = useNestedDispatch();
  const handlers = useDatesHandlers(hours);

  return (
    <section
      style={{ background: bgColor }}
      className={
        isSidebar
          ? styles.wrapperSide
          : styles.wrapper
      }
    >
      <NoSidebarContent
        title={calendar?.title}
        isSidebar={isSidebar}
        isCollapsed={isCollapsed}
        toggle={() => setCollapsedState((prev) => !prev)}
      />
      <div className={cn(
        styles.calendar,
        styles.widget,
        { [styles.hidden]: isCollapsed },
      )}
      >
        { isLoading && (
          <div className={styles.loader}>
            <LocalLoader isVisible={isLoading} />
          </div>
        )}
        <CalendarHeader
          block={calendar}
          hours={hours}
          selected={selected}
        />
        <Navigation
          dispatch={dispatch}
          date={selected.date}
          dateFormat="short"
        />
        <PlainCalendar
          weekdays={menu?.textList}
          selected={selected.date}
          onChange={getCalendarOnChange(setSelected, data)}
          getState={({ dispatch }) => setDispatch(dispatch)}
          datesHandlers={handlers}
        />
        <ColorInfo list={calendar?.textList} />
      </div>
      <Appointment
        isCollapsed={isCollapsed}
        selected={selected}
        modals={modals?.contentTypesCollection?.items as Array<Block>}
        menu={menu}
        button={calendar?.link}
        dispatch={dispatch}
      />
    </section>
  );
};

export default AppointmentCalendar;
