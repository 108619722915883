import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import { useCustomRouter } from 'src/shared/lib/history/hooks';
import { CommonRichText, ValidationMark } from 'components/Shared/SharedComponents';
import type { RichTextParsersConfig } from 'types/objectTypes';
import { mapMarksBoldWithReplacement } from 'lib/richTextMappers';
import { PsychicCardImage } from 'entities/PsychicCardImage';

import styles from './styles.module.scss';

import type { IECResponseError, IECResponseOk } from '../../config/declarations';

const getParsersConfig = (replacementValues: Record<string, string>): RichTextParsersConfig => ({
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.text,
  },
  [MARKS.BOLD]: {
    classNames: styles.bold,
    mapper: mapMarksBoldWithReplacement(replacementValues),
  },
});

const ECResponseOk: FC<IECResponseOk> = ({
  number,
  psychic,
  psychicFrame,
  text,
  redirectUrl,
  close,
}) => {
  const router = useCustomRouter();
  const replacementValues = { phone: number, name: psychic.lineName };

  return (
    <div className={styles.ok}>
      <div className={styles.description}>
        <PsychicCardImage
          psychic={psychicFrame!}
          className={styles.image}
          src={psychic.images[5]}
          image="external"
        />
        <CommonRichText
          content={text}
          parsersConfig={getParsersConfig(replacementValues)}
        />
      </div>
      <button
        type="button"
        className={styles.button}
        onClick={() => {
          close();

          if (redirectUrl && !window.location.pathname.includes(redirectUrl)) {
            if (redirectUrl.match(/^\/{0,1}psychics\/[a-zA-Z]+-\d{4}$/g)) {
              return;
            }

            router.push(redirectUrl);
          }
        }}
      >
        OK
      </button>
    </div>
  );
};

const ECResponseError: FC<IECResponseError> = ({
  message,
  redirectUrl,
  shouldRedirect,
  close,
}) => {
  const router = useCustomRouter();

  return (
    <div className={styles.error}>
      <ValidationMark isValid={false} />
      <p className={styles.text}>
        {message}
      </p>
      <button
        type="button"
        className={styles.button}
        onClick={() => {
          close();

          if (shouldRedirect && redirectUrl) {
            router.push(redirectUrl);
          }
        }}
      >
        OK
      </button>
    </div>
  );
};

export { ECResponseOk, ECResponseError };
