import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import styles from 'components/Sections/TextAndSideAsset/TextAndSideAsset.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import {
  RichTextParsersConfig,
  SectionExtraDataType,
  WebpWidthLimitType,
} from 'types/objectTypes';
import { CommonRichText, WebpImage } from 'components/Shared/SharedComponents';
import { ImageFitMode } from 'constants/enums';
import { TextAndSideAssetContentInterface, CustomAssetInterfaceBlock } from 'components/Sections/TextAndSideAsset/declarations';

const getParsersConfig = ({ sectionSize }: SectionExtraDataType): RichTextParsersConfig => {
  const parentClassName = `${sectionSize}BlockText`;

  return ({
    [BLOCKS.PARAGRAPH]: {
      classNames: styles[`${parentClassName}Paragraph`],
    },
    [BLOCKS.HEADING_3]: {
      classNames: cn(styles[`${parentClassName}Title`], styles[`${parentClassName}TitleH3`]),
    },
    [BLOCKS.HEADING_4]: {
      classNames: cn(styles[`${parentClassName}Title`], styles[`${parentClassName}TitleH4`]),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};

const MOBILE_SIZE_MULTIPLIER = 0.8;
const multiplySize = (size: number) => Math.floor(size * MOBILE_SIZE_MULTIPLIER);

/* Nested component */
const CustomAsset: FC<CustomAssetInterfaceBlock> = ({ image, parentClassName, mobileMedia }) => {
  if (!image?.width) {
    return null;
  }

  const widthLimit: Array<WebpWidthLimitType> = [{
    width: multiplySize(image.width!),
    height: multiplySize(image.height!),
    media: `${mobileMedia}px`,
    fit: ImageFitMode.SCALE,
  }];

  return (
    <WebpImage
      image={image}
      widthLimit={widthLimit}
      className={styles[`${parentClassName}BlockIcon`]}
    />
  );
};

/* Main component */
const TextAndSideAssetBlock: FC<TextAndSideAssetContentInterface> = ({
  assetAlign,
  sectionSize,
  mobileViewMaxWidth,
  blocks,
}) => {
  if (!blocks?.length) {
    return null;
  }

  const parentClassName = `${assetAlign}ContentWrapper`;

  return (
    <div className={styles[parentClassName]}>
      {blocks.map((block) => {
        if (!block) {
          return null;
        }

        const { image, content, entryName, richTitle } = block;

        return (
          <div key={entryName} className={styles[`${parentClassName}Block`]}>
            <CustomAsset
              image={image!}
              parentClassName={parentClassName}
              mobileMedia={mobileViewMaxWidth}
            />
            {(content || richTitle) && (
              <div className={styles[`${parentClassName}BlockText`]}>
                {richTitle && (
                  <CommonRichText
                    content={richTitle}
                    parsersConfig={getParsersConfig({ sectionSize })}
                  />
                )}
                {content && (
                  <CommonRichText
                    content={content}
                    parsersConfig={getParsersConfig({ sectionSize })}
                  />
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TextAndSideAssetBlock;
