import type { ReducerAction } from 'app-redux/types/storeTypes';

import { initialContext } from './context';

import type { IContext } from '../config/declarations';

export const contextReducer = (
  store: IContext = initialContext,
  action: ReducerAction<keyof IContext>,
) => {
  switch (action.type) {
    case 'isSubMenuVisible':
    case 'secondLevelMenuHeight': {
      return { ...store, [action.type]: action.payload };
    }
    default: return store;
  }
};
