import { FC, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import type { Link } from 'src/__generated__/graphqlTypes';

import type { ArrowRendererProps, IOffersListCarouselWrapper } from '../config/declarations';
import { OFFERS_LIST_ARROW_DESKTOP_SIZE, OFFERS_LIST_ARROW_MOBILE_SIZE } from '../config/constants';
import {
  getLeftArrowRenderer,
  getRightArrowRenderer,
  renderIndicator,
} from '../lib/renderers';

const OffersListCarouselWrapper: FC<IOffersListCarouselWrapper> = ({
  children,
  isMobileViewWidth,
  pauseDurationMobile,
  pauseDurationDesktop,
  blocks,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const {
    leftArrow,
    rightArrow,
    leftArrowHover,
    rightArrowHover,
  } = blocks;

  const getArrowRendererParameters = (
    commonArrow?: Link,
    hoveredArrow?: Link,
  ): ArrowRendererProps => ({
    commonArrow,
    hoveredArrow,
    sideSize: isMobileViewWidth ? OFFERS_LIST_ARROW_MOBILE_SIZE : OFFERS_LIST_ARROW_DESKTOP_SIZE,
    isCarouselHovered: isHovered,
  });

  return (
    <section
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Carousel
        showStatus={false}
        showThumbs={false}
        infiniteLoop
        useKeyboardArrows
        autoPlay
        stopOnHover
        interval={isMobileViewWidth ? pauseDurationMobile : pauseDurationDesktop}
        renderArrowNext={getRightArrowRenderer(
          getArrowRendererParameters(rightArrow, rightArrowHover),
        )}
        renderArrowPrev={getLeftArrowRenderer(
          getArrowRendererParameters(leftArrow, leftArrowHover),
        )}
        renderIndicator={renderIndicator}
      >
        {children}
      </Carousel>
    </section>

  );
};

export default OffersListCarouselWrapper;
