import { FC } from 'react';

import DesktopNavBarWrapper from 'components/Header/Desktop/DesktopNavBarWrapper';
import MainLogo from 'components/Header/MiddleBar/MainLogo';
import { SearchBar } from 'entities/SearchBar';

import styles from './styles.module.scss';

import type { INavigationContainerDesktop } from '../config/declarations';

const NavigationContainerDesktop: FC<INavigationContainerDesktop> = ({ nav, logo, search }) => {
  if (!nav && !logo) {
    return null;
  }

  return (
    <section className={styles.wrapper}>
      <div className={styles.navContainer}>
        <MainLogo mainLogo={logo!} isMobileView={false} />
        <SearchBar search={search} />
        <DesktopNavBarWrapper navBar={nav} />
      </div>
    </section>
  );
};

export default NavigationContainerDesktop;
