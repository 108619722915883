import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';
import 'react-multi-carousel/lib/styles.css';

import { Block, Link } from 'src/__generated__/graphqlTypes';
import { RichTextParsersConfig, SectionExtraDataType } from 'types/objectTypes';
import {
  CommonSize,
  ElementAlign,
  MobileItemsLayout,
} from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';
import Divider from 'components/Sections/Divider';
import styles from 'components/Sections/MysteryLinksSet/MysteryLinksSet.module.scss';
import { MysteryLinks } from 'components/Sections/MysteryLinksSet';
import classes from 'src/styles/_commonClasses.module.scss';
import { CommonRichText } from 'components/Shared/SharedComponents';
import { MysteryLinksSetInterface, MysteryLinksRichText as RichTextInterface } from 'components/Sections/MysteryLinksSet/declarations';

const getParsersConfig = ({ titleAlign, pAlign }: SectionExtraDataType): RichTextParsersConfig => {
  const titleAlignClass = classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`];
  const pAlignClass = classes[`pAlign${capitalizeFirstLetter(pAlign || titleAlign)}`];
  const getHeadingClasses = (headingClass: string) => cn(
    styles.mysteryLinksSetTitle,
    titleAlignClass,
    headingClass,
  );

  return ({
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(styles.psychicParagraph, pAlignClass),
    },
    [BLOCKS.HEADING_1]: {
      classNames: getHeadingClasses(classes.titleMainH1),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHeadingClasses(classes.titleMainH2),
    },
    [BLOCKS.HEADING_3]: {
      classNames: getHeadingClasses(classes.titleMainH3),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};

/* Nested component */
const Content: FC<RichTextInterface> = ({ richText, config }) => {
  if (!richText) {
    return null;
  }

  return (
    <CommonRichText
      content={richText}
      parsersConfig={config}
    />
  );
};

/* Nested component */
const Title: FC<RichTextInterface> = Content;

/* Main component */
const MysteryLinksSet: FC<MysteryLinksSetInterface> = ({
  content: block,
  mobileViewMaxWidth,
  commonPageMaxWidth,
  topDivider,
  bottomDivider,
  nextArrow,
  prevArrow,
  bgColor,
  extraData,
  viewerDevice,
  clientWidth,
  mysteryLinkSideSizeMobile,
}) => {
  const {
    titleAlign = ElementAlign.LEFT,
    verticalPadding = CommonSize.MEDIUM,
    mobileItemsLayout,
    pAlign,
  } = extraData || {};
  const { contentTypesCollection, content, richTitle } = (block || {}) as Block;
  const mysteryLinks = contentTypesCollection?.items as Array<Block>;
  const verticalPaddingClass = classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`];
  const richTextConfig = getParsersConfig({ titleAlign, pAlign });
  const isGridMobileItemLayout = mobileItemsLayout === MobileItemsLayout.GRID_2
    || mobileItemsLayout === MobileItemsLayout.GRID_3;

  return (
    <section
      className={cn(styles.wrapper, verticalPaddingClass)}
      style={{ background: bgColor }}
    >
      <Divider
        block={topDivider as Block}
        maxWidth={`${commonPageMaxWidth}px`}
      />
      <div className={cn({
        [styles.mysteryLinksSet]: true,
        [styles[`${mobileItemsLayout}Wrapper`]]: isGridMobileItemLayout,
      })}
      >
        <Title
          richText={richTitle}
          config={richTextConfig}
        />
        <Content
          richText={content}
          config={richTextConfig}
        />
        <MysteryLinks
          mobileItemsLayout={mobileItemsLayout}
          mobileViewMaxWidth={mobileViewMaxWidth}
          mysteryLinks={mysteryLinks}
          nextArrow={nextArrow as Link}
          prevArrow={prevArrow as Link}
          commonPageMaxWidth={commonPageMaxWidth}
          viewerDevice={viewerDevice}
          clientWidth={clientWidth}
          mysteryLinkSideSizeMobile={mysteryLinkSideSizeMobile}
        />
      </div>
      <Divider
        block={bottomDivider as Block}
        maxWidth={`${commonPageMaxWidth}px`}
      />
    </section>
  );
};

export default MysteryLinksSet;
