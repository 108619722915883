import {
  SET_API_ERROR,
  SET_API_RESPONSE,
  SET_API_STATUS,
} from 'app-redux/types/actionTypes';
import type { APIStatus } from 'app-redux/types/storeTypes';
import type { Pair } from 'types/objectTypes';

export const setApiStatus = (status: Pair<APIStatus>) => ({
  type: SET_API_STATUS,
  payload: status,
});

export const setApiResponse = <T>(response: Pair<T>) => ({
  type: SET_API_RESPONSE,
  payload: response,
});

export const setApiError = <T>(error: Pair<T>) => ({
  type: SET_API_ERROR,
  payload: error,
});
