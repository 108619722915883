import { FC } from 'react';

import type { LoginForm } from 'src/__generated__/graphqlTypes';
import LoginFormContainer from 'components/Header/MiddleBar/Interaction/LoginForm/LoginFormContainer';
import { DataButton, DataLink } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';

import type { IAuthInteractiveComponent, ISignInButton } from '../config/declarations';

/* Nested Component */
const SignInButton: FC<ISignInButton> = ({ signIn, setShownFormState, isShownForm }) => {
  if (!signIn) {
    return null;
  }

  if (process.env.NEXT_PUBLIC_KEEP_SIGN_IN_FLYOUT === 'true') {
    return (
      <DataButton
        aria-label={signIn.title || ''}
        className={styles.authSignInButton}
        data-ajax="false"
        onClick={() => setShownFormState(!isShownForm)}
        onKeyPress={() => setShownFormState(!isShownForm)}
      >
        {signIn.title}
      </DataButton>
    );
  }

  return (
    <DataLink
      link={signIn}
      className={styles.authSignInButton}
    >
      {signIn.title}
    </DataLink>
  );
};

/* Main Component */
const NCView: FC<IAuthInteractiveComponent> = ({
  signInRef,
  auth,
  isShownForm,
  setShownFormState,
}) => {
  if (!auth) {
    return null;
  }

  const { signUp, collapsed, signIn } = auth;

  return (
    <>
      {signUp && (
        <>
          <DataLink
            href={signUp.src || ''}
            link={signUp}
            className={styles.authSignUp}
          >
            {signUp.title}
          </DataLink>
          <div className={styles.authDivider} />
        </>
      )}
      <div ref={signInRef} className={styles.authSignIn}>
        <SignInButton
          isShownForm={isShownForm}
          setShownFormState={setShownFormState}
          signIn={signIn}
        />
        <LoginFormContainer
          isShown={isShownForm}
          loginForm={collapsed as LoginForm}
          setShownFormState={setShownFormState}
        />
      </div>
    </>
  );
};

export default NCView;
