/* eslint-disable no-param-reassign */
import cn from 'classnames';
import {
  FC,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Status } from 'constants/enums';
import { PsychicStatusIndicator } from 'entities/PsychicStatus';
import classes from 'src/styles/_commonClasses.module.scss';
import { StatusIndicatorsInterface, StatusObject } from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';
import { usePsychicStatusesWithUnsubscribe } from 'src/firebase/firebase.hook';

import styles from '../PsychicFilter.module.scss';

const StatusIndicators: FC<StatusIndicatorsInterface> = ({
  extIds = [],
  busyTitle,
  availableTitle,
  totalBusyPsychics,
  totalAvailablePsychics,
}) => {
  const [
    stabPsychics,
    setStabPsychics,
  ] = useState<Array<Record<string, any>>>(extIds.map((id) => ({ extId: id })) || []);
  const [{ available, busy }, setStatusesCount] = useState<StatusObject>({ available: 0, busy: 0 });

  useEffect(() => {
    setStabPsychics(extIds.map((id) => ({ extId: id })));
  }, [extIds]);

  usePsychicStatusesWithUnsubscribe(extIds, setStabPsychics as any);

  const firebaseStatuses = useMemo(() => stabPsychics.reduce((store, item) => {
    if (item.lineStatus === Status.AVAILABLE) {
      store.available += 1;
    }

    if (item.lineStatus === Status.BUSY) {
      store.busy += 1;
    }

    return store;
  }, { available: 0, busy: 0 } as StatusObject) as StatusObject, [stabPsychics]);

  useEffect(() => {
    setStatusesCount({ available: totalAvailablePsychics || 0, busy: totalBusyPsychics || 0 });
  }, [totalBusyPsychics, totalAvailablePsychics]);

  useEffect(() => {
    const { available, busy } = firebaseStatuses;

    if (available > 0 || busy > 0) {
      setStatusesCount({ available, busy });
    }
  }, [firebaseStatuses]);

  return (
    <div className={styles.statusBar}>
      <div className={styles.statusDiv}>
        <div className={styles.psychicStatusWrapper}>
          <PsychicStatusIndicator status={Status.AVAILABLE} className={styles.statusIndicator} />
          <span className={cn(styles.statusText, classes.statusTextAvailable)}>
            {available}
          </span>
          <span className={cn(styles.statusText, classes.statusTextAvailable)}>
            {availableTitle?.title}
          </span>
        </div>
      </div>
      <div className={styles.statusDiv}>
        <div className={styles.psychicStatusWrapper}>
          <PsychicStatusIndicator status={Status.BUSY} className={styles.statusIndicator} />
          <span className={cn(styles.statusText, classes.statusTextBusy)}>{busy}</span>
          <span className={cn(styles.statusText, classes.statusTextBusy)}>{busyTitle?.title}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(StatusIndicators);
