import {
  FC,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { Psychic } from 'src/__generated__/graphqlTypes';
import type { Store } from 'app-redux/types/storeTypes';
import { SectionComponentInterface } from 'types/componentTypes';
import { ExponeaPsychicProfile } from 'components/Sections/ExponeaPsychicProfile';
import { useBootChatSolution } from 'entities/PsychicCtaButton';
import type { RightPsychic } from 'types/objectTypes';
import { usePsychicStatuses } from 'src/firebase/firebase.hook';
import { setFirebaseRequiredState } from 'app-redux/actions/appActions';

const ExponeaPsychicProfileContainer: FC<SectionComponentInterface> = ({
  blocks,
  extraData,
  bgColor,
}) => {
  const psychic: RightPsychic = useSelector((store: Store) => store.server.page.data.psychic);
  const [currentPsychic, setCurrentPsychic] = useState<Array<RightPsychic>>([psychic]);
  const [block] = blocks;
  const psychicFrame = (block.contentTypesCollection?.items[0] || {}) as Psychic;
  const bootStatus = useBootChatSolution();
  const dispatch = useDispatch();

  useEffect(() => {
    if (psychic) {
      dispatch(setFirebaseRequiredState(true));
    }
  }, [psychic]);

  usePsychicStatuses(currentPsychic.map(({ extId }) => extId), setCurrentPsychic);

  if (!psychic) {
    return null;
  }

  return (
    <ExponeaPsychicProfile
      psychic={currentPsychic[0]}
      bootStatus={bootStatus}
      psychicFrame={psychicFrame}
      extraData={extraData}
      bgColor={bgColor}
    />
  );
};

export default ExponeaPsychicProfileContainer;
