import React from 'react';
import { useSelector } from 'react-redux';

import { MysteryDirections } from 'components/Sections/HoroscopesSignsMysteryDirections';
import { SectionComponentInterface } from 'types/componentTypes';
import { Store } from 'app-redux/types/storeTypes';

const MysteryDirectionsContainer: React.FC<SectionComponentInterface> = ({
  blocks,
}) => {
  const {
    mobileViewMaxWidth,
    horoscopeTabletMaxWidth,
  } = useSelector((store: Store) => store.server.app.scssVariables);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);

  return (
    <MysteryDirections
      blocks={blocks}
      viewerDevice={viewerDevice}
      mobileViewMaxWidth={mobileViewMaxWidth}
      horoscopeTabletMaxWidth={horoscopeTabletMaxWidth}
    />
  );
};

export default MysteryDirectionsContainer;
