/* eslint-disable no-continue */
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPsychicsForEc, getUpcominngReadings } from 'src/api/customerApi';
import { getRightPsychicsProxy } from 'src/api/psychicApi';
import { GET_EC_PSYCHIC_CATEGORIES, GET_READINGS } from 'src/shared/api/apiNames';
import { getVisitedPsychicsIds } from 'src/shared/lib/customer/cookie';
import { setFirebaseRequiredState } from 'app-redux/actions/appActions';
import type { Store } from 'app-redux/types/storeTypes';
import { PsychicCardAppearance } from 'constants/enums';
import {
  PsychicReadingKeys,
  RightPsychic,
  SectionExtraDataType,
} from 'types/objectTypes';
import { mapPsychicFromRemoteServer } from 'lib/serverMapper.service';

import { useContextDispatch } from './hooks';

import {
  ErrorMessage,
  PsychicApiToUse,
  apiNames,
} from '../config/constants';
import type { ApiParameters } from '../config/declarations';
import {
  getPsychicsReadingExtIdWise,
  getPsychicsReadingKeys,
  getSortedDisplayReading,
} from '../lib';

import { getDistinctPsychics, getMaxAllowedPsychics } from '.';

export const useGetApiCaller = (extraData: SectionExtraDataType) => {
  const isFirebaseRequired = useSelector((store: Store) => store.client.app.isFirebaseRequired);
  const user = useSelector((store: Store) => store.server.auth.user);
  const [apiParameters, setApiParameters] = useState<ApiParameters>(null);
  const dispatch = useDispatch();
  const localDispatch = useContextDispatch();

  const setUpFirebaseIfRequired = () => {
    const { psychicCardAppearance } = extraData;
    const shouldUseFirebase = psychicCardAppearance !== PsychicCardAppearance.SIMPLE
        && psychicCardAppearance !== PsychicCardAppearance.SIMPLE_ADDITIONAL;

    if (!isFirebaseRequired && shouldUseFirebase) {
      dispatch(setFirebaseRequiredState(true));
    }
  };

  const getResponseMapperFunction = (key: PsychicApiToUse) => async () => {
    const psychicsIds = getVisitedPsychicsIds();
    const mappedPsychicsIds = psychicsIds.reverse().join(',');

    if (!user) {
      throw Error(ErrorMessage.NO_USER);
    }

    const apiResponse = await getPsychicsForEc(user, mappedPsychicsIds);
    const fieldName = apiNames[key];
    const psychicsAmount = apiResponse[fieldName]?.length;

    if (!psychicsAmount && key === PsychicApiToUse.NEW_HIGH_PRICE) {
      return localDispatch({
        payload: { apiToRefetch: PsychicApiToUse.COMMON, shouldRefetchPsychics: true },
        type: 'apiToRefetch',
      });
    }

    setUpFirebaseIfRequired();

    return apiResponse;
  };

  useEffect(() => {
    const { itemsAmount, apiToUse } = extraData;
    const { custId = '' } = user || {};
    const requestBody: any = {
      AppId: 1002,
      CustFavorite: false,
      ResultType: 1,
      SearchOptions: null,
    };

    if (itemsAmount) {
      requestBody.PageSize = itemsAmount;
    }

    const getAndMapCommonPsychics = async () => {
      const { psychics } = await getRightPsychicsProxy(requestBody);

      if (!psychics.length) {
        throw new Error(ErrorMessage.NO_PSYCHICS);
      }

      setUpFirebaseIfRequired();

      return psychics;
    };

    const getUpcomingReadingsPsychics = async () => {
      const results = (await getUpcominngReadings(custId)).results as Array<PsychicReadingKeys>;

      if (!results?.length) {
        throw new Error(ErrorMessage.NO_PSYCHICS);
      }

      setUpFirebaseIfRequired();
      const psychics = results.map((psychic) => getPsychicsReadingKeys(psychic));

      /**
       *  This value should be an object to go to switch in {@link useMappedPsychics}
       *  */
      return { psychics };
    };

    switch (apiToUse) {
      case PsychicApiToUse.FAVORITE_AND_CIRCLE:
      case PsychicApiToUse.NEW_HIGH_PRICE:
      case PsychicApiToUse.VISITED:
      case PsychicApiToUse.READ_BEFORE: {
        return setApiParameters({
          key: extraData.newKey || GET_EC_PSYCHIC_CATEGORIES,
          call: getResponseMapperFunction(apiToUse),
        });
      }
      case PsychicApiToUse.READINGS: {
        return setApiParameters({
          key: extraData.newKey || GET_READINGS,
          call: getUpcomingReadingsPsychics,
        });
      }
      case PsychicApiToUse.COMMON:
      default: {
        return setApiParameters({
          key: extraData.newKey || 'psychics/right',
          call: getAndMapCommonPsychics,
        });
      }
    }
  }, [extraData, user]);

  return apiParameters;
};
const API_LIMIT = 10;
export const useMappedPsychics = (
  data: Record<string, any> | Array<RightPsychic>,
  extraData: SectionExtraDataType,
  setPsychics: Dispatch<SetStateAction<RightPsychic[]>>,
) => {
  const dispatch = useContextDispatch();

  useEffect(() => {
    if (!data) {
      return;
    }

    const fakeSession: any = { loggedIn: true };
    const { apiToUse, itemsAmount = 10 } = extraData;

    if (Array.isArray(data)) {
      if (data.length) {
        const distinctPsychics = getDistinctPsychics(data);

        return setPsychics(distinctPsychics);
      }

      return dispatch({ payload: true, type: 'shouldHideSection' });
    }

    const mapPsychics = (namedResp: Array<any>) => {
      if (!namedResp) {
        return dispatch({ payload: true, type: 'shouldHideSection' });
      }

      const distinctPsychics = getDistinctPsychics(namedResp, API_LIMIT);
      const psychics = distinctPsychics.map((data) => {
        const { psychic } = data;

        return mapPsychicFromRemoteServer(psychic, fakeSession);
      });

      if (!psychics?.length) {
        return dispatch({ payload: true, type: 'shouldHideSection' });
      }

      return setPsychics(psychics);
    };

    switch (apiToUse) {
      case PsychicApiToUse.FAVORITE_AND_CIRCLE: {
        const array = data?.favoriteAndCircle;

        if (!array) {
          return dispatch({ payload: true, type: 'shouldHideSection' });
        }

        let showfavoriteButton = false;
        let showPsychicsCircleButton = false;
        let i = 0;
        const map = new Map();

        while (map.size < API_LIMIT && i < array.length) {
          const { psychic, source } = array[i] || {};
          const storedPsychic = map.get(psychic.extId);
          i++;

          const newPsychic = mapPsychicFromRemoteServer({ ...psychic }, fakeSession);

          if (source.toLowerCase() === 'circle') {
            showPsychicsCircleButton = true;

            map.set(newPsychic.extId, {
              ...newPsychic,
              isInCircle: true,
              isFavorite: storedPsychic?.isFavorite ?? false,
            });
          } else if (source.toLowerCase() === 'favorite') {
            showfavoriteButton = true;
            map.set(newPsychic.extId, {
              ...newPsychic,
              isFavorite: true,
              isInCircle: storedPsychic?.isInCircle ?? false,
            });
          } else {
            map.set(newPsychic.extId, newPsychic);
          }
        }

        const psychics = Array.from(map.values());

        if (showfavoriteButton !== showPsychicsCircleButton) {
          dispatch({
            payload: showfavoriteButton ? 'secondary' : 'primary',
            type: 'buttonToHide',
          });
        }

        if (!psychics.length) {
          return dispatch({ payload: true, type: 'shouldHideSection' });
        }

        return setPsychics(psychics);
      }
      case PsychicApiToUse.NEW_HIGH_PRICE: {
        if (!data.psychics?.length) {
          return dispatch({
            payload: { apiToRefetch: PsychicApiToUse.COMMON, shouldRefetchPsychics: true },
            type: 'apiToRefetch',
          });
        }

        return mapPsychics(data.psychics);
      }
      case PsychicApiToUse.READ_BEFORE: {
        return mapPsychics(data.customerReading);
      }
      case PsychicApiToUse.READINGS: {
        const { psychics } = data;
        const readingsByExtId = getPsychicsReadingExtIdWise(psychics);
        const readings = getSortedDisplayReading(readingsByExtId);
        const psychicsToDisplay = getMaxAllowedPsychics(readings, itemsAmount);
        const mappedPsychics = psychicsToDisplay
          .map((psychic) => mapPsychicFromRemoteServer(psychic, fakeSession));

        return setPsychics(mappedPsychics);
      }
      case PsychicApiToUse.VISITED: {
        return mapPsychics(data.visitPsychicBio);
      }
      case PsychicApiToUse.COMMON: {
        if (Array.isArray(data) && data.length) {
          const distinctPsychics = getDistinctPsychics(data);

          return setPsychics(distinctPsychics);
        }

        /** It has different handling from default because of the case with refetching.
        {@link extraData} is updated first, but not {@link data}. It is updated only after
        request finishing */
        if (Array.isArray(data) && !data.length) {
          return dispatch({ payload: true, type: 'shouldHideSection' });
        }

        return setPsychics([]);
      }
      default: {
        if (Array.isArray(data) && data.length) {
          const distinctPsychics = getDistinctPsychics(data);

          return setPsychics(distinctPsychics);
        }

        return dispatch({ payload: true, type: 'shouldHideSection' });
      }
    }
  }, [data, extraData]);
};
