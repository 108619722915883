import cn from 'classnames';
import {
  FC,
  useRef,
  useState,
} from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

import { CommonRichText, DataButton } from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';
import {
  FilterListPanelMobileInterface,
  FilterActionsInterface,
  FilterOptionsInterface,
  FilterTitleInterface,
  FilterTabsInterface,
} from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';
import { useClickOutsideMultiple } from 'lib/shared.hook';
import { useLoadFiltersOptions } from 'components/Sections/PsychicsSetMediumSizeImagesClone/lib/hooks';
import LocalLoader from 'src/shared/ui/LocalLoader/LocalLoader';
import { capitalizeFirstLetter } from 'lib/text.service';

import PriceFilterSectionList from './FilterPriceItem';

import styles from '../PsychicFilter.module.scss';
import mobileStyles from '../PsychicFilterMobile.module.scss';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.HEADING_3]: {
    classNames: mobileStyles.heading,
  },
};

const FilterTitle: FC<FilterTitleInterface> = ({ filterTitle }) => (
  <CommonRichText content={filterTitle} parsersConfig={parsersConfig} />
);

const FilterTabs: FC<FilterTabsInterface> = ({
  currentVisibleTabInMobile,
  setCurrentVisibleTabInMobile,
  filtersByCategories,
  priceTitle,
}) => (
  <ul className={mobileStyles.listOne}>
    <li
      className={cn({ [mobileStyles.listSelect]: currentVisibleTabInMobile === 'Price' })}
      onClick={() => setCurrentVisibleTabInMobile?.('Price')}
      onKeyPress={() => setCurrentVisibleTabInMobile?.('Price')}
      role="presentation"
    >
      {priceTitle?.title}
    </li>
    {filtersByCategories && Object.keys(filtersByCategories).map((category) => (
      <li
        key={category}
        className={cn({
          [mobileStyles.listSelect]:
            currentVisibleTabInMobile === filtersByCategories[category].majorCategoryDescription,
        })}
        onClick={() => setCurrentVisibleTabInMobile?.(
          filtersByCategories[category].majorCategoryDescription,
        )}
        onKeyPress={() => setCurrentVisibleTabInMobile?.('Price')}
        role="presentation"
      >
        {filtersByCategories[category].richText}
      </li>
    ))}
  </ul>
);

const FilterOptions: FC<FilterOptionsInterface> = ({
  currentVisibleTabInMobile,
  priceOptions,
  filtersByCategories,
  checkIfFilterPresent,
  changeFilter,
  priceTitle,
  popularTitle,
}) => (
  <ul className={mobileStyles.listTwo}>
    {currentVisibleTabInMobile === 'Price'
      && priceOptions
      && priceOptions.map((item) => (
        <PriceFilterSectionList
          key={item.rate}
          changeFilter={changeFilter}
          checkIfFilterPresent={checkIfFilterPresent}
          item={item}
          priceTitle={priceTitle}
        />
      ))}
    {currentVisibleTabInMobile !== 'Price'
      && filtersByCategories
      && Object.keys(filtersByCategories)
        .filter((
          category,
        ) => filtersByCategories[category].majorCategoryDescription === currentVisibleTabInMobile)
        .map((category) => filtersByCategories[category].descriptions.map((categoryDesc) => {
          const isPopularPsyhicFilter = currentVisibleTabInMobile === popularTitle?.title;
          let modifiedDesc = categoryDesc;

          if (!isPopularPsyhicFilter) {
            if (categoryDesc.includes('/')) {
              modifiedDesc = categoryDesc
                .split('/')
                .map((str) => str
                  .split(' ')
                  .map(capitalizeFirstLetter)
                  .join(' '))
                .join(' & ');
            } else {
              modifiedDesc = categoryDesc
                .split(' ')
                .map(capitalizeFirstLetter)
                .join(' ');
            }
          }

          const filterDetail = {
            type: filtersByCategories?.[category]?.majorCategoryDescription,
            value: isPopularPsyhicFilter ? categoryDesc.val : categoryDesc,
          };
          const isSelected = checkIfFilterPresent?.(filterDetail);
          const displayVal = isPopularPsyhicFilter ? modifiedDesc.displayVal : modifiedDesc;

          if (isSelected) {
            return (
              <DataButton
                key={displayVal}
                className={styles.searchCategorySectionContent}
                onClick={() => changeFilter?.(filterDetail)}
              >
                <div className={mobileStyles.searchCategorySectionContentRow}>
                  <div className={mobileStyles.searchCategorySectionContentRowPannel}>
                    <input
                      type="checkbox"
                      className={styles.searchCategorySectionContentRowPannelInput}
                      defaultChecked
                      readOnly
                    />
                    <span className={styles.searchCategorySectionContentRowPannelLabel}>
                      <span className={mobileStyles.searchCategorySectionContentRowPannelLabelText}>
                        {displayVal}
                      </span>
                    </span>
                  </div>
                </div>
              </DataButton>
            );
          }

          return (
            <li
              key={displayVal}
              onClick={() => changeFilter?.(filterDetail)}
              onKeyPress={() => changeFilter?.(filterDetail)}
              role="presentation"
            >
              {displayVal}
            </li>
          );
        }))}
  </ul>
);

const FilterActions: FC<FilterActionsInterface> = ({
  doneFilter,
  totalResultsLocal,
  removeFilter,
  clearFilterButton,
  viewTitle,
  resultsTitle,
}) => (
  <div className={mobileStyles.filterButtons}>
    <DataButton className={mobileStyles.viewResults} onClick={doneFilter}>
      {`${viewTitle?.title} ${totalResultsLocal} ${resultsTitle?.title}`}
    </DataButton>
    <DataButton className={mobileStyles.clear} onClick={removeFilter}>
      {clearFilterButton?.title}
    </DataButton>
  </div>
);

const FilterListPanelMobile: FC<FilterListPanelMobileInterface> = ({
  filterTitle,
  showFilters,
  checkIfFilterPresent,
  changeFilter,
  doneFilter,
  totalResultsLocal,
  removeFilter,
  clearFilterButton,
  priceTitle,
  viewTitle,
  resultsTitle,
  closeFilter,
  filterByTitleRef,
  popularFilters,
  popularTitle,
}) => {
  const [currentVisibleTabInMobile, setCurrentVisibleTabInMobile] = useState<string>('Price');
  const filterPanelRef = useRef<HTMLDivElement>(null);
  const { options, isLoading } = useLoadFiltersOptions(Boolean(showFilters), popularFilters);
  useClickOutsideMultiple([filterByTitleRef, filterPanelRef], () => closeFilter?.(), false);

  if (!showFilters) {
    return null;
  }

  if (isLoading) {
    return (
      <div
        ref={filterPanelRef}
        className={styles.panelLoading}
      >
        <LocalLoader />
      </div>
    );
  }

  return (
    <div ref={filterPanelRef}>
      <FilterTitle filterTitle={filterTitle} />
      <div className={mobileStyles.listContainer}>
        <FilterTabs
          currentVisibleTabInMobile={currentVisibleTabInMobile}
          setCurrentVisibleTabInMobile={setCurrentVisibleTabInMobile}
          filtersByCategories={options.category}
          priceTitle={priceTitle}
        />
        <FilterOptions
          currentVisibleTabInMobile={currentVisibleTabInMobile}
          priceOptions={options.price}
          filtersByCategories={options.category}
          checkIfFilterPresent={checkIfFilterPresent}
          changeFilter={changeFilter}
          priceTitle={priceTitle}
          popularTitle={popularTitle}
        />
      </div>
      <FilterActions
        doneFilter={doneFilter}
        totalResultsLocal={totalResultsLocal}
        removeFilter={removeFilter}
        clearFilterButton={clearFilterButton}
        viewTitle={viewTitle}
        resultsTitle={resultsTitle}
      />
    </div>
  );
};

export default FilterListPanelMobile;
