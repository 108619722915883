import React from 'react';
import { useSelector } from 'react-redux';

import { Store } from 'app-redux/types/storeTypes';
import { BestDayAndLuckyNumbers } from 'components/Sections/HoroscopesSignsAllAboutSigns/BestDayAndLuckyNumbers';
import { BestDayAndLuckyNumbersContainerInterface } from 'components/Sections/HoroscopesSignsAllAboutSigns/BestDayAndLuckyNumbers/declarations';

const BestDayAndLuckyNumbersContainer: React.FC<BestDayAndLuckyNumbersContainerInterface> = ({
  bestDay,
  luckyNumbers,
}) => {
  const slug = useSelector((store: Store) => store.server.page.slug);

  return (
    <BestDayAndLuckyNumbers
      bestDay={bestDay}
      luckyNumbers={luckyNumbers}
      slug={slug}
    />
  );
};

export default BestDayAndLuckyNumbersContainer;
