import { Link } from 'src/__generated__/graphqlTypes';

export const getUrl = (
  href?: string,
  buttonSrc?: string,
  query?: Record<string, string | undefined>,
) => {
  if (href) {
    return href;
  }

  if (buttonSrc) {
    const url: URL = new URL(buttonSrc, process.env.NEXT_PUBLIC_BASE_SERVER_URL);

    if (!query) {
      return url.toString();
    }

    Object.entries(query).forEach(([key, value]) => value && url.searchParams.append(key, value));

    return url.toString();
  }

  return '';
};

export const getUrlForReadingsButton = (
  buttonName: string,
  href: string,
  link: Link,
  extId: number,
  psychicName: string,
) => {
  switch (buttonName) {
    case 'Details': {
      return getUrl(href, link?.src!);
    }
    case 'ReschedulePurchase': {
      return getUrl(href, link?.src!, { extid: extId.toString() });
    }
    case 'Reschedule': {
      return getUrl(href, `${link?.src!}/${psychicName}-${extId}`);
    }
    default: {
      return '';
    }
  }
};
