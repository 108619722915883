import { FC } from 'react';

import { WebpImage } from 'components/Shared/SharedComponents';
import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';

import { IconInterface } from '../config/declarations';

export const ButtonIcon: FC<IconInterface> = ({
  isSpriteImage,
  image,
  buttonName,
  className,
}) => {
  if (!image?.url) {
    return null;
  }

  if (!isSpriteImage) {
    return (
      <WebpImage
        image={EXTERNAL_WEBP_IMAGE}
        src={image.url}
        className={className}
        data-button-icon={buttonName}
      />
    );
  }

  return (
    <span
      data-button-icon={buttonName}
      className={className}
      style={{ backgroundImage: `url(${image.url})` }}
    />
  );
};
