import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Loader } from 'components/Loader';
import type { Store } from 'app-redux/types/storeTypes';
import { ILocalLoader } from 'types/componentTypes';

const LocalLoader: FC<ILocalLoader> = ({
  className,
  isVisible = true,
  withBackground = false,
}) => {
  const { slug, pages } = useSelector((store: Store) => store.server.page);
  const { loader } = pages[slug];

  return (
    <div className={className}>
      <Loader
        image={loader}
        isVisible={isVisible}
        withBackground={withBackground}
      />
    </div>
  );
};

export default LocalLoader;
