import type { Psychic, Text } from 'src/__generated__/graphqlTypes';
import { makePayloadToGetPsychic, updateWithDefaultSearchOptions } from 'lib/psychic.service';
import { getRightPsychicsProxy } from 'src/api/psychicApi';

import type { TemplateHtmlPsychicBlocks } from '../config/declarations';

export const divideBlocks = (blocks: Array<Psychic | Text>) => blocks
  .reduce((result, block) => {
    if (block.__typename === 'Psychic') {
      return { ...result, psychicFrame: block };
    }

    if (block.__typename === 'Text') {
      result.texts.push(block);

      return result;
    }

    return result;
  }, { texts: [], psychicFrame: {} as Psychic } as TemplateHtmlPsychicBlocks);

export const getSetFunction = ({
  extraData,
  requestFirebase,
  setLoadingState,
  setPsychics,
}) => async (apiData?: any) => {
  const { itemsAmount, psychicCategory } = extraData;
  let requestBody: any = {
    AppId: 1002,
    CustFavorite: false,
    ResultType: 1,
    SearchOptions: null,
  };

  if (itemsAmount) {
    requestBody.PageSize = itemsAmount;
  }

  if (psychicCategory) {
    requestBody = { ...requestBody, ...makePayloadToGetPsychic(psychicCategory) };
  }

  if (apiData) {
    requestBody = { ...updateWithDefaultSearchOptions(apiData), isFullData: true };
  }

  try {
    setLoadingState(true);
    const { psychics } = await getRightPsychicsProxy(requestBody);

    setPsychics(psychics);
    requestFirebase();
  } finally {
    setLoadingState(false);
  }
};
