import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import cn from 'classnames';

import type { Psychic } from 'src/__generated__/graphqlTypes';
import type { Store } from 'app-redux/types/storeTypes';
import type { SectionComponentInterface } from 'types/componentTypes';
import type { RightPsychic } from 'types/objectTypes';
import { CollapseIcon } from 'src/shared/ui/CollapseIcon';

import Properties from './Properties';
import styles from './PsychicBioInformation.module.scss';

const psychicSelector = (store: Store) => store.server.page.data?.psychic;

const PsychicBioInformation: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const psychic: RightPsychic | undefined = useSelector(psychicSelector);
  const [isCollapsed, setCollapsedState] = useState<boolean>(false);
  const block = blocks[0];

  if (!psychic || !block) {
    return null;
  }

  const psychicFrame = block.contentTypesCollection?.items?.[0] as Psychic | undefined;

  return (
    <section
      style={{ background: bgColor }}
      className={cn(
        styles.bio,
        { [styles.collapsed]: isCollapsed },
      )}
    >
      <button
        type="button"
        className={styles.collapseButton}
        onClick={() => setCollapsedState((prev) => !prev)}
      >
        <CollapseIcon width={25} isExpanded={!isCollapsed} />
      </button>
      <h2 className={styles.title}>
        {block.title}
        {' '}
        {psychic.lineName}
      </h2>
      <Properties
        frame={psychicFrame}
        psychic={psychic}
        isCollapsed={isCollapsed}
      />
      {psychic.bio && (
        <p className={cn(
          styles.description,
          { [styles.hidden]: isCollapsed },
        )}
        >
          {psychic.lineName}
          {' '}
          {parse(psychic.bio)}
        </p>
      )}
    </section>
  );
};

export default PsychicBioInformation;
