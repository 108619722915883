import { FC } from 'react';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import { CommonSize } from 'constants/enums';
import type { SectionComponentInterface } from 'types/componentTypes';
import { capitalizeFirstLetter } from 'lib/text.service';

import styles from './styles.module.scss';

import { getComponent } from '../lib/parser';

const SectionsCombiner: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
  extraData,
}) => {
  const { verticalPadding = CommonSize.SMALL } = extraData || {};
  const components = blocks.map(getComponent);

  return (
    <section
      style={{ background: bgColor }}
      className={cn(
        styles.wrapper,
        styles.widthSmall,
        classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`],
      )}
    >
      {components}
    </section>
  );
};

export default SectionsCombiner;
