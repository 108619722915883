/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FC } from 'react';
import cn from 'classnames';

import { PsychicCardImage } from 'entities/PsychicCardImage';
import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';
import { RatePerMinuetType } from 'types/objectTypes';
import { PsychicRate } from 'components/Shared/SharedComponents';
import { PsychicCardAppearance } from 'constants/enums';
import { PsychicAvatarWrapperLink } from 'entities/PsychicAvatarWrapperLink';

import styles from './styles.module.scss';
import AdditionalInformation from './AdditionalInformation';

import { CardInterface } from '../config/declarations';

const Card: FC<CardInterface> = ({
  psychicSkeleton,
  positionInArray,
  psychic,
  view,
}) => {
  const { profileButton, avatar, starImage } = psychicSkeleton;
  const avatarUrl = avatar?.url || '';

  const ratePerMinute: RatePerMinuetType = {
    discount: psychic.customerPrice,
    price: psychic.basePrice,
  };

  return (
    <li className={styles.wrapper}>
      <div className={styles.card}>
        <PsychicAvatarWrapperLink
          positionInArray={positionInArray}
          profileButton={profileButton}
          psychic={psychic}
        >
          <PsychicCardImage
            psychic={psychicSkeleton}
            image={EXTERNAL_WEBP_IMAGE}
            src={psychic.psychicImageUrl || avatarUrl || ''}
            alt={psychic.psychicName}
            className={styles.cardImage}
          />
          <div className={styles.cardButton}>
            <span className={styles.cardButtonTitle}>{profileButton?.title}</span>
          </div>
        </PsychicAvatarWrapperLink>
        <div className={styles.cardInfoTitle}>
          <span className={styles.cardInfoTitleName} title={psychic.psychicName}>
            {psychic.psychicName}
          </span>
          <div className={styles.cardInfoTitleRating}>
            {psychic.overallScore}
            <span
              style={{ backgroundImage: `url(${starImage?.url!})` }}
              className={styles.cardInfoTitleRatingImage}
            />
            {`(${psychic.responses})`}
          </div>
        </div>
        <PsychicRate
          pricePerMinute={ratePerMinute.price}
          priceWithDiscountPerMinute={ratePerMinute.discount}
          classNames={{
            wrapper: styles.cardInfoPrice,
            common: cn(styles.cardInfoPriceText, styles.cardInfoPriceCommon),
            commonWithDiscount: cn(styles.cardInfoPriceText, styles.cardInfoPriceCrossed),
            discount: cn(styles.cardInfoPriceText, styles.cardInfoPriceDiscount),
          }}
        />

        {(view === PsychicCardAppearance.SIMPLE_WITH_DESCRIPTION)
        && (
          <div className={styles.cardInfoUsp}>
            {psychic.usp}
          </div>
        )}

        {(view === PsychicCardAppearance.SIMPLE_ADDITIONAL)
        && <AdditionalInformation psychic={psychic} />}
      </div>
    </li>
  );
};

export default Card;
