import React from 'react';
import cn from 'classnames';

import styles from 'components/Sections/HoroscopesSignsAllAboutSigns/HoroscopesSignsAllAboutSigns.module.scss';
import { SignFeatureHeading } from 'components/Sections/HoroscopesSignsAllAboutSigns/Shared';
import { SignFeatureContainerInterface } from 'components/Sections/HoroscopesSignsAllAboutSigns/Shared/declarations';

const SignFeatureContainer: React.FC<SignFeatureContainerInterface> = ({
  containerClassName,
  titleClassName,
  underlineClassName,
  title,
  children,
}) => {
  const applyContent = () => {
    if (title) {
      return (
        <>
          <SignFeatureHeading
            title={title}
            titleClassName={titleClassName}
            underlineClassName={underlineClassName}
          />
          {children}
        </>
      );
    }

    return children || null;
  };

  return (
    <div className={cn(
      styles.allAboutContainer,
      containerClassName || styles.allAboutContainerSize,
    )}
    >
      {applyContent()}
    </div>
  );
};

export default SignFeatureContainer;
