/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import {
  FC,
  FocusEvent,
  MouseEvent,
  useState,
} from 'react';
import cn from 'classnames';
import { UseSelectGetToggleButtonPropsOptions, useSelect } from 'downshift';

import classes from 'src/styles/_commonClasses.module.scss';
import { getSelectItemId } from 'lib/sharedMethods.service';

import styles from './Form.module.scss';
import ButtonSelect from './Components/ButtonSelect';
import ErrorMessage from './Components/ErrorMessage';

import type { IFeedbackFormSelect } from '../config/declarations';

const FeedbackFormSelect: FC<IFeedbackFormSelect> = ({
  title,
  selectOptions,
  className,
  inputData,
  onBlur,
  setInputValue,
  onClick,
}) => {
  const {
    selectedItem,
    isOpen,
    getMenuProps,
    getToggleButtonProps,
    getItemProps,
  } = useSelect({ items: selectOptions });
  const [isFocused, setFocusedState] = useState<boolean>(false);
  const { isValid, error, value, sysId } = inputData;
  const isInvalid = isValid === false;
  const buttonStyles = cn(
    styles.formFieldsetDivInput,
    styles.formDropdownButton,
    {
      [styles.formFieldsetInputFocused]: isFocused,
      [styles.formFieldsetDivInputSuccess]: isValid,
      [styles.formFieldsetDivInputError]: isInvalid,
      [styles.formDropdownButtonActive]: isOpen && selectOptions.length,
    },
  );

  const toggleButtonProps = getToggleButtonProps(
    getSelectItemId(sysId, 'button') as UseSelectGetToggleButtonPropsOptions,
  );
  const menuProps = getMenuProps(getSelectItemId(sysId, 'ul'));
  const handleClickButton = (e: MouseEvent<HTMLButtonElement>) => {
    setFocusedState(true);
    toggleButtonProps.onClick?.(e);

    if (selectOptions.length === 0 && onClick) {
      onClick(e);
    }
  };

  const onUlBlur = (e: FocusEvent<HTMLUListElement>) => {
    onBlur(e);
    menuProps?.onBlur(e);
  };

  const isValuePresented = selectedItem?.text
        && ((typeof value === 'string' && value)
          || ((value as any).id && (value as any).name));

  return (
    <div className={cn(styles.formDropdown, className)}>
      <ButtonSelect
        isValuePresented={isValuePresented}
        props={toggleButtonProps}
        onClick={handleClickButton}
        className={buttonStyles}
        title={title}
        selectedItem={selectedItem}
      />
      <ErrorMessage error={error} isInvalid={isInvalid} />
      <ul
        {...menuProps}
        onBlur={onUlBlur}
        className={cn(
          styles.formDropdownMenu,
          (!isOpen || !selectOptions.length) && cn(classes.visibilityHidden, classes.zeroHeight),
        )}
      >
        {selectOptions.map((item, index) => {
          const { entryName, text } = item;
          const itemProps = getItemProps({ item, index, ...getSelectItemId(sysId, 'li') });

          return (
            <li
              key={entryName!}
              value={text!}
              {...itemProps}
              onClick={(e) => {
                itemProps.onClick(e);
                setFocusedState(false);
                setInputValue(item);
              }}
              className={styles.formDropdownMenuOption}
            >
              <span title={text || ''} className={styles.formDropdownMenuOptionTitle}>
                {text}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FeedbackFormSelect;
