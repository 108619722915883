import React from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import styles from 'components/Sections/HoroscopesSignsMysteryDirections/MysteryDirections.module.scss';
import { MysteryDirectionsPicture } from 'components/Sections/HoroscopesSignsMysteryDirections';
import { CommonRichText } from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';
import { Link } from 'src/__generated__/graphqlTypes';
import { HoroscopesSignsMysteryDirectionsInterface } from 'components/Sections/HoroscopesSignsMysteryDirections/declarations';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.mysteryDirectionsTitle,
  },
  [MARKS.BOLD]: {
    classNames: cn(styles.mysteryDirectionsTitle, styles.mysteryDirectionsTitleBold),
  },
};
const MysteryDirections: React.FC<HoroscopesSignsMysteryDirectionsInterface> = ({
  blocks,
  viewerDevice,
  mobileViewMaxWidth,
  horoscopeTabletMaxWidth,
}) => {
  const applyMysteryDirectionsBlocks = () => blocks.map((block, i) => {
    if (!block) {
      return null;
    }

    const { content, entryName, contentTypesCollection } = block;
    const mysteryDirections = contentTypesCollection?.items as Array<Link> || [];

    return (
      <React.Fragment key={entryName || i.toString()}>
        {content && (
          <CommonRichText
            content={content}
            parsersConfig={parsersConfig}
          />
        )}
        {mysteryDirections.length && (
          <div className={styles.mysteryDirectionsContent}>
            {mysteryDirections.map((direction) => {
              if (!direction) {
                return null;
              }

              return (
                <MysteryDirectionsPicture
                  key={direction.entryName}
                  link={direction}
                  viewerDevice={viewerDevice}
                  mobileViewMaxWidth={mobileViewMaxWidth}
                  horoscopeTabletMaxWidth={horoscopeTabletMaxWidth}
                />
              );
            })}
          </div>
        )}
      </React.Fragment>

    );
  });

  return (
    <section className={styles.mysteryDirections}>
      {applyMysteryDirectionsBlocks()}
    </section>
  );
};

export default MysteryDirections;
