import { FC } from 'react';

import type { Block } from 'src/__generated__/graphqlTypes';

import Title from './Title';
import Image from './Image';
import Links from './Links';
import LinkComponent from './Link';

import type { IFlexElement } from '../config/declarations';

const FlexElement: FC<IFlexElement> = ({ element, ...rest }) => {
  if (!element) {
    return null;
  }

  const inlineStyles = { ...element.properties?.style || {}, ...rest.style || {} };
  const children = (element.contentTypesCollection?.items || []) as Array<Block>;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...rest} style={inlineStyles}>
      {children.map((child) => {
        if (child.slug === 'image' && child.image) {
          return <Image key={child.entryName} element={child} />;
        }

        if (child.slug === 'link' && child.link) {
          return <LinkComponent key={child.entryName} element={child} />;
        }

        if (child.slug === 'title' && (child.content || child.richTitle)) {
          return <Title key={child.entryName} element={child} />;
        }

        if (child.slug === 'links' && child.contentTypesCollection?.items.length) {
          return <Links key={child.entryName} element={child} />;
        }
      })}
    </div>
  );
};

export default FlexElement;
