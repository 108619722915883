import { FC } from 'react';
import cn from 'classnames';

import styles from './AppointmentCalendar.module.scss';
import ExistingAppointment from './ExistingAppointment';

import type { IAppointment } from '../config/declarations';

const Appointment: FC<IAppointment> = ({
  selected,
  menu,
  button,
  modals,
  isCollapsed,
  dispatch,
}) => {
  if (selected.forAppointments.length) {
    return (
      <ExistingAppointment
        isCollapsed={isCollapsed}
        selected={selected}
        menu={menu}
        modals={modals}
        button={button}
        dispatch={dispatch}
      />
    );
  }

  if (!menu?.title) {
    return null;
  }

  return (
    <div className={cn(styles.appointments, { [styles.hidden]: isCollapsed })}>
      <strong className={styles.headerHeading}>{menu.title}</strong>
    </div>
  );
};

export default Appointment;
