import { FC } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import type { Psychic } from 'src/__generated__/graphqlTypes';
import type { Store } from 'app-redux/types/storeTypes';
import type { SectionComponentInterface } from 'types/componentTypes';
import { PsychicCardSimpleSquare } from 'features/sections/PsychicCardSimpleSquare';
import { Status } from 'constants/enums';
import { getItemPositionForGa } from 'lib/sharedMethods.service';
import type { RightPsychic } from 'types/objectTypes';

import styles from './SimilarPsychics.module.scss';
import Heading from './Heading';
import ActionButton from './ActionButton';

import { usePsychic, useSimilarPsychics } from '../lib/hooks';

const SimilarPsychics: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const psychic = usePsychic();
  const block = blocks[0];

  const user = useSelector((store: Store) => store.server.auth.user);
  const isSidebarActive = useSelector((store: Store) => store.client.app.isSidebarActive);
  const bootStatus = useSelector((store: Store) => store.client.app.chatBootStatus);
  const isPsychicNotOffline = !psychic
    || (psychic.phoneStatus?.toLowerCase() !== Status.OFFLINE
      || psychic.chatStatus?.toLowerCase() !== Status.OFFLINE);
  const { psychics, isLoading } = useSimilarPsychics(isPsychicNotOffline, psychic);
  const inNoSimilar = !psychics.length && !isLoading;

  if (!block || isPsychicNotOffline || inNoSimilar) {
    return null;
  }

  const psychicFrame = block.contentTypesCollection?.items
    ?.find((item) => item?.__typename === 'Psychic') as Psychic | undefined;

  const { link, richTitle } = block;

  return (
    <section
      style={{ background: bgColor }}
      className={cn(
        styles.wrapper,
        {
          [styles.noSidebar]: !isSidebarActive,
        },
      )}
    >
      <Heading title={richTitle} />
      <ul className={styles.wrapperPsychics}>
        {psychics.map((psychic, i) => (
          <PsychicCardSimpleSquare
            key={psychic.extId}
            user={user}
            psychic={psychic}
            psychicFrame={psychicFrame}
            positionInArray={getItemPositionForGa<RightPsychic>(psychics, i)}
            bootStatus={bootStatus}
          />
        ))}
      </ul>
      <ActionButton link={link} />
    </section>
  );
};

export default SimilarPsychics;
