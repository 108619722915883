import React from 'react';

import { FooterMobileNavBarItemInterface } from 'types/componentTypes';
import { Text } from 'src/__generated__/graphqlTypes';

const DesktopNavBarTextItem: React.FC<FooterMobileNavBarItemInterface> = ({
  navItem,
  children,
  classes,
}) => {
  const navText = navItem as Text;

  return (
    <li key={navText.entryName} className={classes?.li}>
      <h3 className={classes?.span}>
        {navText.text}
      </h3>
      <ul className={classes?.innerUl}>
        {children}
      </ul>
    </li>
  );
};
export default DesktopNavBarTextItem;
