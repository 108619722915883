import { FC } from 'react';
import { useSelector } from 'react-redux';

import { SectionComponentInterface } from 'types/componentTypes';
import { Store } from 'app-redux/types/storeTypes';
import { ViewerDevice } from 'constants/enums';
import { Love1 } from 'components/Sections/GridHeroBanner';

const GridHeroBannerContainer: FC<SectionComponentInterface> = ({
  blocks,
  extraData,
  bgColor,
}) => {
  const { mobileViewMaxWidth } = useSelector((store: Store) => store.server.app.scssVariables);
  const clientWidth = useSelector((store: Store) => store.client.app.clientWidth);
  const viewerDevice = useSelector<Store, ViewerDevice>((store) => store.server.app.viewerDevice);
  const [block] = blocks;
  const isMobileWidth = clientWidth < mobileViewMaxWidth;

  return (
    <Love1
      block={block}
      bgColor={bgColor}
      extraData={extraData}
      viewerDevice={viewerDevice}
      isMobileWidth={isMobileWidth}
      mobileViewMaxWidth={mobileViewMaxWidth}
    />
  );
};

export default GridHeroBannerContainer;
