import { FC } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface ICollapseIcon {
  isExpanded?: boolean;
  width?: number;
  height?: number;
  className?: string;
}

const root = 'icon';
const CollapseIcon: FC<ICollapseIcon> = ({
  isExpanded = false,
  width = 15,
  height = 2,
  className,
}) => (
  <>
    <span
      style={{ height, width }}
      className={cn(
        styles[root],
        className,
        styles[`${root}Dash`],
      )}
    />
    <span
      style={{
        height,
        width,
        ...isExpanded ? { } : { right: width / 2 },
      }}
      className={cn(
        styles[`${root}`],
        className,
        styles[`${root}${isExpanded ? 'Dash' : 'Line'}`],
      )}
    />
  </>
);

export default CollapseIcon;
