import { FC } from 'react';

import { WebpImage } from 'components/Shared/SharedComponents';

import styles from './HoroSignShortInfo.module.scss';

import type { IImage } from '../config/declarations';

const Image: FC<IImage> = ({ image }) => {
  if (!image?.url) {
    return null;
  }

  return (
    <WebpImage
      image="external"
      height={200}
      width={200}
      className={styles.image}
      src={image.url}
    />
  );
};

export default Image;
