import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import type { RichTextParsersConfig } from 'types/objectTypes';
import { capitalizeFirstLetter as capitalize } from 'lib/text.service';
import {
  mapBlocksEmbeddedEntryLink,
  mapBlocksHeadingWithId,
  mapBlocksParagraphWithExtraData,
} from 'lib/richTextMappers';
import { CommonRichText } from 'components/Shared/SharedComponents';
import { Heading } from 'constants/enums';

import styles from './OrangeAlertBig.module.scss';

import type { IRichText, RichTextParam } from '../config/declarations';
import { constructId } from '../lib';

const getParsersConfig = ({
  titleAlign,
  isOffset,
  pAlign,
  considerOnlyPresented,
  isContentAbove,
  isContentBelow,
  id,
}: RichTextParam): RichTextParsersConfig => {
  const titleAlignClass = classes[`titleAlign${capitalize(titleAlign)}`];
  const getHClasses = (specificClass?: string) => cn(
    styles.alertTitle,
    specificClass,
    titleAlignClass,
    { [styles.alertTitleOffset]: isOffset },
  );

  return ({
    [BLOCKS.EMBEDDED_ENTRY]: {
      classNames: cn(styles.alertButton, styles.alertButtonRichText),
      mapper: mapBlocksEmbeddedEntryLink(constructId(id, '-embedded-link')),
    },
    [BLOCKS.HEADING_1]: {
      classNames: getHClasses(),
      mapper: mapBlocksHeadingWithId(constructId(id, '-heading'), Heading.FIRST),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHClasses(classes.alertTitleH2),
      mapper: mapBlocksHeadingWithId(constructId(id, '-heading')),
    },
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(
        styles.alertParagraph,
        classes[`pAlign${capitalize(pAlign)}`],
        {
          [styles.alertParagraphMT]: isContentAbove && !considerOnlyPresented,
          [styles.alertParagraphMB]: isContentBelow && !considerOnlyPresented,
        },
      ),
      mapper: mapBlocksParagraphWithExtraData({ id: constructId(id, '-paragraph'), title: undefined }),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
    },
  });
};

const Title: FC<IRichText<RichTextParam>> = ({
  richText,
  config,
  id,
}) => {
  if (!richText) {
    return null;
  }

  const localConfig = { ...config, id };

  return (
    <CommonRichText
      content={richText}
      parsersConfig={getParsersConfig(localConfig)}
    />
  );
};

const Content = Title;

export { Content, Title };
