import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Loader } from 'components/Loader';
import { Store } from 'app-redux/types/storeTypes';
import { LoaderContainerInterface } from 'components/Loader/declarations';

const LoaderContainer: React.FC<LoaderContainerInterface> = ({
  image: outsideImage,
  withBackground = false,
  isLoadingLocal = null,
}) => {
  const pages = useSelector((store: Store) => store.server.page.pages);
  const slug = useSelector((store: Store) => store.server.page.slug);
  const currentPage = pages[slug];
  const image = outsideImage || currentPage.loader;
  const isLoading = useSelector((store: Store) => store.client.app.isLoading);
  const [isVisible, setIsVisible] = useState<boolean>((isLoadingLocal !== null)
    ? isLoadingLocal
    : isLoading);

  useEffect(() => {
    setIsVisible(isLoading);
  }, [isLoading]);

  return (
    <Loader
      image={image}
      isVisible={isVisible}
      withBackground={withBackground}
      isLocal={isLoadingLocal !== null}
    />
  );
};

export default LoaderContainer;
