import { CSSProperties, FC } from 'react';

import styles from 'components/Sections/HoroscopesSignsAllAboutSigns/Flowers/Flowers.module.scss';
import { WebpWidthLimitType } from 'types/objectTypes';
import { calculateSizeWithRatio, isAppropriatViewportWidth } from 'lib/sharedMethods.service';
import { getImagesWithLimitedSize } from 'lib/image.service';
import { ImageFitMode, RelationalOperator } from 'constants/enums';
import { getImageBasedDevice } from 'lib/horoscope/horoscope.service';
import { AllAboutSignsFlowersPictureInterface, FlowersPictureImageSizeRatioSlug as Slug } from 'components/Sections/HoroscopesSignsAllAboutSigns/Flowers/declarations';

const FlowersPicture: FC<AllAboutSignsFlowersPictureInterface> = ({
  maxWidth,
  maxHeight,
  entryName,
  image,
  title,
  viewerDevice,
  mobileViewMaxWidth,
  horoscopeTabletMaxWidth,
  setMaxWidth,
  setMaxHeight,
}) => {
  if (!image || !title) {
    return null;
  }

  const setMaxWidthIfNewOneGraterThanCurrent = (newWidth?: number) => {
    if (newWidth && (newWidth > maxWidth)) {
      setMaxWidth(newWidth);
    }
  };

  const setMaxHeightIfNewOneGraterThanCurrent = (newHeight?: number) => {
    if (newHeight && (newHeight > maxHeight)) {
      setMaxHeight(newHeight);
    }
  };

  const Image = getImageBasedDevice(viewerDevice);
  const { height, width } = image;
  let imagesWithLimitedSize: Array<WebpWidthLimitType> | undefined;
  let style: CSSProperties | undefined;

  if (height && width) {
    const entryHeight = calculateSizeWithRatio(Slug.ENTRY_IMAGE, height);
    const entryWidth = calculateSizeWithRatio(Slug.ENTRY_IMAGE, width);
    const tabletHeight = calculateSizeWithRatio(Slug.TABLET, entryHeight);
    const tabletWidth = calculateSizeWithRatio(Slug.TABLET, entryWidth);
    const mobileHeight = calculateSizeWithRatio(Slug.MOBILE, entryHeight);
    const mobileWidth = calculateSizeWithRatio(Slug.MOBILE, entryWidth);

    imagesWithLimitedSize = getImagesWithLimitedSize([
      {
        height: mobileHeight,
        width: mobileWidth,
        media: `${mobileViewMaxWidth}px`,
      },
      {
        height: tabletHeight,
        width: tabletWidth,
        media: `${horoscopeTabletMaxWidth}px`,
      },
      {
        height: entryHeight,
        width: entryWidth,
        isMinWidth: true,
        media: `${horoscopeTabletMaxWidth}px`,
      },
    ],
    { fit: ImageFitMode.SCALE });

    let newHeight: number | undefined;
    let newWidth: number | undefined;

    if (isAppropriatViewportWidth(horoscopeTabletMaxWidth, RelationalOperator.GREATER_THEN)) {
      newHeight = entryHeight;
      newWidth = entryWidth;
    } else if (isAppropriatViewportWidth(mobileViewMaxWidth, RelationalOperator.LESS_OR_EQUAL)) {
      newHeight = mobileHeight;
      newWidth = mobileWidth;
    } else if (process.browser) {
      newHeight = tabletHeight;
      newWidth = tabletWidth;
    }

    setMaxHeightIfNewOneGraterThanCurrent(newHeight);
    setMaxWidthIfNewOneGraterThanCurrent(newWidth);
    style = { ...style, maxWidth, gridTemplateRows: `${maxHeight}px auto` };
  }

  return (
    <div
      key={entryName || image.url}
      className={styles.flowersContentAsset}
      style={style}
    >
      <Image
        image={image}
        className={styles.flowersContentAssetImage}
        widthLimit={imagesWithLimitedSize}
      />
      <span className={styles.flowersContentAssetTitle}>{title}</span>
    </div>
  );
};

export default FlowersPicture;
