import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { setMobileHeaderNavActivityState } from 'app-redux/actions/appActions';
import { Cross } from 'src/shared/ui/Cross';

import styles from './styles.module.scss';

import { useLocalDispatch } from '../lib/hooks';

const CloseHamburger: FC = () => {
  const dispatch = useDispatch();
  const lDispatch = useLocalDispatch();
  const close = () => {
    lDispatch({ type: 'isSubMenuVisible', payload: false });
    dispatch(setMobileHeaderNavActivityState(false));
  };

  return (
    <button
      type="button"
      onClick={close}
      className={styles.close}
    >
      <Cross />
    </button>
  );
};

export default CloseHamburger;
