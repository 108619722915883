import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

import { HoroscopeType } from 'constants/enums';
import { getHoroscopeProxy } from 'src/api/horoscopeApi';
import { useHtmlParserReplace } from 'lib/tag.service';
import type { Store } from 'app-redux/types/storeTypes';
import { calculateZodiacSign } from 'src/shared/lib/horoscope';
import type { ZodiacSign } from 'types/objectTypes';

import type { HoroscopeRequestProps } from '../config/declarations';

export const useHoroscope = (
  horoscopeProps: HoroscopeRequestProps,
  existingHoroscope?: string,
) => {
  // eslint-disable-next-line no-undef
  const [horoscope, setHoroscope] = useState<JSX.Element | string | null>(null);
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const replace = useHtmlParserReplace();

  useEffect(() => {
    if (!horoscopeProps.sign) {
      return setHoroscope('-');
    }

    if (existingHoroscope) {
      // @ts-ignore
      return setHoroscope(parse(existingHoroscope, { replace }));
    }

    setLoadingState(true);
    getHoroscopeProxy({
      sign: horoscopeProps.sign,
      date: horoscopeProps.date || new Date().toDateString(),
      type: HoroscopeType.DAILY_TODAY,
      customerId: horoscopeProps.customerId,
    })
    // @ts-ignore
      .then((data) => setHoroscope(parse(data, { replace })))
      .catch(() => setHoroscope('-'))
      .finally(() => setLoadingState(false));
  }, [horoscopeProps, existingHoroscope]);

  return { horoscope, isLoading };
};

export const useHoroscopeRequestProps = (date: Date, sign: HoroscopeRequestProps['sign']) => {
  const user = useSelector((store: Store) => store.server.auth.user);

  // eslint-disable-next-line no-undef
  const horoscopeProps: HoroscopeRequestProps = useMemo(() => {
    const zodiacSign = user?.userSign
    || sign
    || calculateZodiacSign(new Date(user?.userDateOfBirth || '')).toUpperCase() as Uppercase<ZodiacSign>;

    return ({
      sign: zodiacSign,
      date: date.toDateString(),
      customerId: user?.customerId,
    });
  }, [user, sign, date]);

  return horoscopeProps;
};
