import { Asset, Block } from 'src/__generated__/graphqlTypes';
import { ViewsMaxWidthInterface } from 'types/componentTypes';

export interface AllAboutSignsSelfCareArticlesInterface
  extends ViewsMaxWidthInterface {
  selfCare: Block;
}

export interface AllAboutSignsSelfCareArticlePictureInterface
  extends ViewsMaxWidthInterface {
  maxWidth: number;
  maxHeight: number;
  entryName?: string;
  title?: string;
  image?: Asset;
  setMaxWidth: (value: number) => void;
  setMaxHeight: (value: number) => void;
}

export enum SelfCareArticlePictureImageSizeRatioSlug {
  TABLET = 0.93,
  DESKTOP = 0.85,
  ENTRY_IMAGE = 0.5,
}
