import { FC } from 'react';
import cn from 'classnames';
import { ResponsiveType } from 'react-multi-carousel/lib/types';
import Carousel from 'react-multi-carousel/lib/Carousel';
import 'react-multi-carousel/lib/styles.css';

import { Block } from 'src/__generated__/graphqlTypes';
import { MobileItemsLayout, ViewerDevice } from 'constants/enums';
import styles from 'components/Sections/MysteryLinksSet/MysteryLinksSet.module.scss';
import { MultiSliderArrow } from 'components/Shared/SharedComponents';
import { MysteryLink } from 'components/Sections/MysteryLinksSet';
import { MysteryLinksInterface } from 'components/Sections/MysteryLinksSet/declarations';
import { IS_CLIENT_SIDE } from 'constants/constants';
import { getItemPositionForGa } from 'lib/sharedMethods.service';

const MOBILE_BALANCE_VALUE = 25;
const createResponsiveMobilesFields = (
  max: number,
  mysteryLinkSideSizeMobile: number,
  index: number | undefined = undefined,
  itemsAmount: number = 4,
  responsivePropertiesStore: ResponsiveType = {},
): ResponsiveType => {
  const min = max - mysteryLinkSideSizeMobile - MOBILE_BALANCE_VALUE;
  const newIndex = index ? index + 1 : 1;
  const newItemsAmount = itemsAmount - 1;
  const breakpoint = { max, min: (itemsAmount === 1) ? 0 : min };

  // eslint-disable-next-line no-param-reassign
  responsivePropertiesStore[`${ViewerDevice.MOBILE}${index || ''}`] = {
    breakpoint,
    items: itemsAmount,
    slidesToSlide: itemsAmount > 3 ? 2 : 1,
  };

  if (itemsAmount === 1) {
    return responsivePropertiesStore;
  }

  return createResponsiveMobilesFields(
    min,
    mysteryLinkSideSizeMobile,
    newIndex,
    newItemsAmount,
    responsivePropertiesStore,
  );
};

/* Main component */
const MysteryLinks: FC<MysteryLinksInterface> = ({
  mobileItemsLayout,
  mysteryLinks,
  mobileViewMaxWidth,
  commonPageMaxWidth,
  mysteryLinkSideSizeMobile,
  clientWidth,
  nextArrow,
  prevArrow,
  viewerDevice,
}) => {
  if (!mysteryLinks?.length) {
    return null;
  }

  const links = mysteryLinks.map(({ link, entryName, content }, i) => (
    <MysteryLink
      key={entryName}
      positionInArray={getItemPositionForGa<Block>(mysteryLinks, i)}
      link={link}
      entryName={entryName}
      content={content}
      mobileViewMaxWidth={mobileViewMaxWidth}
    />
  ));

  if (mobileItemsLayout === MobileItemsLayout.GRID_2
    || mobileItemsLayout === MobileItemsLayout.GRID_3) {
    return (
      <div className={styles[mobileItemsLayout]}>
        {links}
      </div>
    );
  }

  const tileMobileSize = +mysteryLinkSideSizeMobile;
  const minForTablet = (tileMobileSize + MOBILE_BALANCE_VALUE) * 4 + tileMobileSize;
  const responsive: ResponsiveType = {
    [ViewerDevice.DESKTOP]: {
      breakpoint: { max: 4000, min: +commonPageMaxWidth! },
      items: 6,
      slidesToSlide: 2,
    },
    [ViewerDevice.TABLET]: {
      breakpoint: { max: +commonPageMaxWidth!, min: minForTablet },
      items: 4,
      slidesToSlide: 2,
    },
    ...createResponsiveMobilesFields(minForTablet, tileMobileSize),
  };

  const findCurrentDevice = () => {
    if (!IS_CLIENT_SIDE) {
      return viewerDevice;
    }

    const responsiveObject = Object
      .entries<typeof responsive[keyof typeof responsive]>(responsive)
      .find((entry) => {
        const { min, max } = entry[1].breakpoint;

        if (min <= clientWidth && max > clientWidth) {
          return true;
        }

        return false;
      });

    const [currentViewerDevice] = responsiveObject || [];

    return currentViewerDevice || viewerDevice;
  };

  const currentDevice = findCurrentDevice();
  const isMoreItemsThenDefault = responsive[currentDevice]?.items < mysteryLinks.length;

  return (
    <Carousel
      swipeable
      showDots={false}
      responsive={responsive}
      ssr
      infinite={isMoreItemsThenDefault}
      deviceType={currentDevice}
      customRightArrow={(isMoreItemsThenDefault)
        ? (
          <MultiSliderArrow
            arrow={nextArrow}
            altImage=">"
            className={cn(
              styles.mysteryLinksSetGeneralInfoArrow,
              styles.mysteryLinksSetGeneralInfoArrowRight,
            )}
          />
        )
        : <></>}
      customLeftArrow={(
        <MultiSliderArrow
          arrow={prevArrow}
          altImage="<"
          className={cn(
            styles.mysteryLinksSetGeneralInfoArrow,
            styles.mysteryLinksSetGeneralInfoArrowLeft,
          )}
        />
      )}
      centerMode={false}
      className={styles.mysteryLinksSetGeneralInfo}
      shouldResetAutoplay={false}
      autoPlay={false}
      keyBoardControl
      customTransition="transform 200ms ease-in-out"
      itemClass={styles.mysteryLinksSetGeneralInfoCardWrapper}
    >
      {links}
    </Carousel>
  );
};

export default MysteryLinks;
