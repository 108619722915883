import { FC, useEffect } from 'react';

import { useCustomRouter } from 'src/shared/lib/history/hooks';

import styles from './AppointmentCalendar.module.scss';

import { IAppointmentList } from '../config/declarations';

const options: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  hour12: true,
  minute: 'numeric',
};

const AppointmentList: FC<IAppointmentList> = ({ selected, select }) => {
  const router = useCustomRouter();
  const formatDate = (date: Date) => date
    .toLocaleString(router.locale, options)
    .replace(/\s/g, '')
    .toLowerCase();

  useEffect(() => {
    select(null);
  }, [selected]);

  return (
    <ul
      role="radiogroup"
      aria-labelledby="radiogroup"
      className={styles.content}
    >
      {selected.forAppointments.map((appointment, i) => (
        <li key={appointment.start.getTime()} className={styles.li}>
          <input
            id={`${appointment.start.getTime()}`}
            className={styles.radio}
            onChange={() => select(i)}
            name="radiogroup"
            type="radio"
          />
          <label className={styles.label} htmlFor={`${appointment.start.getTime()}`}>
            {formatDate(appointment.start)}
          </label>
        </li>
      ))}
    </ul>
  );
};

export default AppointmentList;
