/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import cn from 'classnames';
import type { KeyboardEvent, MouseEvent } from 'react';

import { SliderArrow } from 'components/Shared/SharedComponents';
import type { Link } from 'src/__generated__/graphqlTypes';

import styles from '../ui/PsychicWithTestimonial.module.scss';

export const getRightArrowRenderer = (arrow: Link) => (
  clickHandler: () => void,
  hasNext: boolean,
  label: string,
) => (
  <SliderArrow
    arrow={arrow}
    altImage=">"
    hasNextElement={hasNext}
    label={label}
    defaultControlClass="control-next"
    className={cn(styles.carouselArrow, styles.carouselArrowRight)}
    clickHandler={clickHandler}
  />
);

export const getLeftArrowRenderer = (arrow: Link) => (
  clickHandler: () => void,
  hasPrev: boolean,
  label: string,
) => (
  <SliderArrow
    arrow={arrow}
    altImage="<"
    hasNextElement={hasPrev}
    label={label}
    defaultControlClass="control-prev"
    className={cn(styles.carouselArrow, styles.carouselArrowLeft)}
    clickHandler={clickHandler}
  />
);

export const renderIndicator = (
  clickHandler: (e: MouseEvent<HTMLLIElement> | KeyboardEvent<HTMLLIElement>) => void,
  isSelected: boolean,
  index: number,
  label: string,
) => (
  <li
    key={index}
    onClick={clickHandler}
    onKeyPress={clickHandler}
    title={label}
    aria-label={label}
    className={cn(
      isSelected && styles.carouselIndicatorSelected,
      styles.carouselIndicator,
    )}
  />
);
