class ContentfulPages {
  pages = new Set<string>();

  initiate(pages: Array<string> = []) {
    pages.forEach((page) => this.pages.add(page));
    this.add('/');
  }

  constructor(pages: Array<string> = []) {
    if (!pages) {
      return;
    }

    this.initiate(pages);
  }

  add(pageName: string) {
    this.pages.add(pageName);
  }

  has(pageName: string) {
    return this.pages.has(pageName);
  }
}

const contentfulPages = new ContentfulPages();

export default contentfulPages;
