import { FC } from 'react';

import styles from './styles.module.scss';

import { FloatInfoInterface } from '../config/declarations';

const FloatInfo: FC<FloatInfoInterface> = ({
  starImage,
  extension,
  tagLine,
  responses,
  overallScore,
  extId,
}) => (
  <div className={styles.cardFloatInfo}>
    <span className={styles.cardFloatInfoExt}>
      {extension}
      <strong className={styles.cardFloatInfoExtBold}>{extId}</strong>
    </span>
    <div className={styles.cardFloatInfoRate}>
      {overallScore}
      <span
        style={{ backgroundImage: `url(${starImage?.url!})` }}
        className={styles.cardFloatInfoRateStar}
      />
      (
      {responses}
      )
    </div>
    <div className={styles.cardFloatInfoDomain}>
      {tagLine}
    </div>
  </div>
);

export default FloatInfo;
