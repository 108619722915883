import { useSelector } from 'react-redux';
import cookie from 'js-cookie';

import type { Block } from 'src/__generated__/graphqlTypes';
import { checkPaymentAutoReload } from 'src/api/customerApi';
import { getCustomerOffers } from 'src/api/offersApi';
import type { Store } from 'app-redux/types/storeTypes';
import { RightPsychic } from 'types/objectTypes';
import { parseDotNetDate } from 'lib/date.service';

import { useTimeSlots } from './hooks';

import { ScheduleSlot, StartEnd } from '../config/declarations';

const DEFAULT_APPOINTMENT_DURATION = 20;

const getOffers = async (custId: string, extId: number) => {
  try {
    const offers = await getCustomerOffers(custId, extId, DEFAULT_APPOINTMENT_DURATION);

    return offers;
  } catch (e) {
    return null;
  }
};

const psychicSelectorFunc = (store: Store) => store.server.page.data?.psychic;

export const useGetAppointmentToolFunction = (
  appointment?: StartEnd<Date> | null,
  selected?: ScheduleSlot<Date>,
  link?: Block['link'],
) => {
  const psychic: RightPsychic | undefined = useSelector(psychicSelectorFunc);
  const user = useSelector((store: Store) => store.server.auth.user)!;
  const { mutate, refetch } = useTimeSlots();

  const createAppointment = async (isHotDeal: boolean) => {
    let isHotDealLocal = isHotDeal;
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);

    if (user?.custGroup === 33) {
      isHotDealLocal = true;
    }

    cookie.set(
      'hotDealAppt',
      isHotDealLocal.toString(),
      { expires: expirationDate, domain: process.env.NEXT_PUBLIC_CP_DOMAIN },
    );

    const timeSlotsResponse = await refetch();
    mutate(timeSlotsResponse);

    const timeSlots = timeSlotsResponse.psychicSchedules;
    const isSelectedDateStillAvailable = timeSlots
      .find((slot: Record<string, any>) => {
        const slotDate = parseDotNetDate(slot.date) as Date;

        return slotDate.getTime && slotDate.getTime() === selected?.date.getTime();
      });

    if (!isSelectedDateStillAvailable) {
      return;
    }

    const isSelectedTimeStillAvailable = selected?.forAppointments
      .find((timeSlot) => timeSlot.start.getTime() === appointment?.start.getTime()
        && timeSlot.end.getTime() === appointment.end.getTime());

    if (!isSelectedTimeStillAvailable) {
      return;
    }

    const availableMinutes = user.availableBalance / Number(psychic?.customerPrice.replace('$', ''));

    if (availableMinutes >= DEFAULT_APPOINTMENT_DURATION && link) {
      return {
        type: 'redirect',
        query: {
          extId: psychic?.extId,
          startDate: appointment!.start,
        },
      };
    }

    try {
      const resp = await checkPaymentAutoReload(
        user!,
        psychic?.extId!,
        DEFAULT_APPOINTMENT_DURATION,
      );

      if ((resp.success || resp.errorCode === 102) && link?.src) {
        return {
          type: 'redirect',
          query: {
            extId: psychic?.extId,
            startDate: appointment!.start,
          },
        };
      }

      return { type: 'modal' };
    } catch (e) {
      return {
        type: 'redirect',
        query: {
          extId: psychic?.extId,
          startDate: appointment!.start,
        },
      };
    }
  };

  return createAppointment;
};

export const usePrepareAppointment = (appointment?: StartEnd<Date> | null) => {
  const psychic: RightPsychic | undefined = useSelector(psychicSelectorFunc);
  const user = useSelector((store: Store) => store.server.auth.user);

  return async () => {
    if (!user?.loggedIn) {
      cookie.set('CTAType', 'appointment');

      return null;
    }

    cookie.set('urlPage', window.location.href);

    if (!psychic?.extId || !appointment) {
      return null;
    }

    let isHotDeal: boolean = false;
    const hotDealCookie = cookie.get('hotDealAppt');
    const offer = await getOffers(user.custId, psychic.extId);

    if (hotDealCookie && offer?.offerType === 'Discount') {
      // eslint-disable-next-line eqeqeq
      isHotDeal = hotDealCookie == 'true';
    } else if (offer?.offerType !== 'Discount') {
      isHotDeal = false;
    }

    if (offer?.offerType === 'Discount' && offer.psychicRate < offer.baseRate) {
      const startTime = new Date(appointment.start);
      const appointmentDate = new Date(startTime);
      appointmentDate.setHours(0);
      const offerStartDate = parseDotNetDate(offer.startDate);
      const offerEndDate = parseDotNetDate(offer.endDate);

      if (offerStartDate <= startTime && startTime <= offerEndDate) {
        return {
          type: 'create-appointment',
          isHotDeal: true,
        };
      }

      const isCurrentTimeNotInOfferDateRange = offerStartDate > startTime
        || startTime > offerEndDate;

      if (isCurrentTimeNotInOfferDateRange) {
        return {
          type: 'show-hot-deal',
          isHotDeal,
          offer,
          extId: psychic.extId,
        };
      }

      isHotDeal = true;
    }

    return {
      type: 'create-appointment',
      isHotDeal,
    };
  };
};
