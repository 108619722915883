import React from 'react';

import styles from 'components/StickyOffer/StickyOffer.module.scss';
import { DataLink } from 'components/Shared/SharedComponents';
import { StickyOfferInterface } from 'components/StickyOffer/declarations';

const StickyOffer: React.FC<StickyOfferInterface> = ({ stickyOffer, data }) => (
  <div className={styles.offer}>
    <DataLink
      link={stickyOffer}
      href={data?.stickyBannerURL || stickyOffer.src}
      className={styles.offerLink}
    >
      {data.stickyBannerTitle}
    </DataLink>
  </div>
);

export default StickyOffer;
