import {
  Dispatch,
  createContext,
  useContext,
  useReducer,
} from 'react';

import {
  PsychicTemplateState,
  TemplateAction,
  TemplateStateType,
} from '../config/declarations';

const initialState: PsychicTemplateState = {
  currentPage: 0,
};

export const TemplateContext = createContext(initialState);

export const useTemplateContext = () => {
  const context = useContext(TemplateContext);

  return context;
};

const templateStateReducer = (
  state: PsychicTemplateState = initialState,
  action: TemplateAction,
) => {
  switch (action.type) {
    case TemplateStateType.SET_CURRENT_PAGE: {
      return { ...state, currentPage: action.payload };
    }
    default: {
      return state;
    }
  }
};

export const useTemplateReducer = (): [PsychicTemplateState, Dispatch<TemplateAction>] => {
  const [templateState, dispatch] = useReducer(
    templateStateReducer,
    initialState,
  );

  return [templateState, dispatch];
};
