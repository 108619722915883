import { useSelector } from 'react-redux';
import {
  Dispatch,
  SetStateAction,
  useState,
} from 'react';

import { updatePhoneNumber, verifyUSPhoneNumber } from 'src/api/customerApi';
import type { Store } from 'app-redux/types/storeTypes';
import { capitalizeFirstLetter } from 'lib/text.service';
import type { CommonCountryItem } from 'types/objectTypes';

import { useMessageButtonContext, useMessageDispatch } from './message';

const getPhoneType = (isPhoneVerified: boolean) => (isPhoneVerified ? 1 : 2);

export const useAddNumberFunction = () => {
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const user = useSelector((store: Store) => store.server.auth.user);
  const dispatch = useMessageDispatch();
  const store = useMessageButtonContext();

  const addNewNumber = async (
    selectedCountry: CommonCountryItem,
    newNumber: string,
    isPhoneVerified: boolean,
  ) => {
    const res = await updatePhoneNumber(
      {
        PhoneCountry: selectedCountry.countryCode,
        PhoneNumber: newNumber,
        PhoneType: getPhoneType(isPhoneVerified).toString(),
        SaleLocation: capitalizeFirstLetter(viewerDevice),
        SetPrimaryNumber: 0,
        isVerified: true,
      },
      user!,
    );

    if (res !== true) {
      return;
    }

    const telephoneObject = {
      countryCallingCode: selectedCountry.countryCallingCode,
      countryCode: selectedCountry.countryCode,
      isVerified: true,
      phoneNumber: newNumber,
      phoneType: getPhoneType(isPhoneVerified),
    };
    dispatch({
      type: 'telephones',
      payload: [telephoneObject, ...(store.telephones || [])],
    });
  };

  return addNewNumber;
};

export const useNumberCheckerFunction = (
  telephone: string,
  setPhoneVerificationState: Dispatch<SetStateAction<boolean>>,
) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const [isPhoneValidatingNow, setPhoneValidatingStatus] = useState<boolean>(false);

  const isNumberValid = async (
    selectedCountry: CommonCountryItem,
    newNumber: string,
  ): Promise<boolean | never> => {
    if (!newNumber.length || !newNumber.match(/[0-9]+/)) {
      return false;
    }

    if ((!selectedCountry || selectedCountry.countryCode !== 'USA') && telephone.length < 20) {
      return true;
    }

    try {
      setPhoneValidatingStatus(true);
      const resp = await verifyUSPhoneNumber(telephone, user?.authToken!);

      if (!resp.isSuccess) {
        throw new Error('open-modal');
      }

      if (resp.response?.carrier?.type === 'mobile') {
        setPhoneVerificationState(true);

        return true;
      }

      return true;
    } catch (e) {
      throw new Error('open-modal');
    } finally {
      setPhoneValidatingStatus(false);
    }
  };

  return { isNumberValid, isPhoneValidatingNow };
};
