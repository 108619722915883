import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import type { Link } from 'src/__generated__/graphqlTypes';
import { useCustomRouter } from 'src/shared/lib/history/hooks';
import classes from 'styles/_commonClasses.module.scss';
import {
  CommonRichText,
  DataButton,
  DataLink,
} from 'components/Shared/SharedComponents';
import { mapMarksBoldWithReplacement } from 'lib/richTextMappers';
import { parseDotNetDate } from 'lib/date.service';
import type { RichTextParsersConfig } from 'types/objectTypes';

import styles from './CalendarModal.module.scss';

import type { IHotDealWindow } from '../../config/declarations';

const getRichTextConfig = (offer: Record<string, any>, locale: string = 'en'): RichTextParsersConfig => {
  const startDate = (parseDotNetDate(offer.startDate) as Date)
    .toLocaleString(locale, { month: 'long', day: 'numeric' });
  const endDate = (parseDotNetDate(offer.endDate) as Date)
    .toLocaleString(locale, { month: 'long', day: 'numeric' });
  const { startTime } = offer;
  const { endTime } = offer;

  return ({
    [BLOCKS.PARAGRAPH]: {
      classNames: styles.paragraph,
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold,
      mapper: mapMarksBoldWithReplacement({
        startTime,
        startDate,
        endTime,
        endDate,
      }),
    },
  });
};

const HotDealWindow: FC<IHotDealWindow> = ({
  offer,
  block,
  extId,
  create,
  cancel,
}) => {
  const router = useCustomRouter();

  if (!block || !extId) {
    return null;
  }

  const callback = block.link;
  const callbackHref = callback?.src + new URLSearchParams([['extid', extId.toString()]]).toString();
  const { schedule, cancel: cancelButton }: Record<string, Link> = block
    .contentTypesCollection
    ?.items
    ?.reduce((store, item) => {
      if (item?.__typename !== 'Link' || !item.slug) {
        return store;
      }

      return { ...store, [item.slug]: item };
    }, {} as any);

  return (
    <>
      <div>
        <CommonRichText
          content={block.content}
          parsersConfig={getRichTextConfig(offer, router.locale)}
        />
        {callback && (
          <DataLink
            link={callback}
            href={callbackHref}
            className={styles.link}
          >
            {callback.title}
          </DataLink>
        )}
      </div>
      <div className={styles.buttons}>
        {schedule && (
          <DataButton
            link={schedule}
            onClick={create}
            className={styles.primary}
          >
            {schedule.title}
          </DataButton>
        )}
        {cancelButton && (
          <DataButton
            link={cancelButton}
            onClick={cancel}
            className={styles.secondary}
          >
            {cancelButton.title}
          </DataButton>
        )}
      </div>
    </>
  );
};

export default HotDealWindow;
