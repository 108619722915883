/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import {
  BLOCKS,
  MARKS,
  INLINES,
  Block,
  Inline,
} from '@contentful/rich-text-types';
import { Asset, Entry } from 'contentful';
import { Options } from '@contentful/rich-text-react-renderer';
import React, { ReactNode, CSSProperties } from 'react';

import {
  RichTextMaps,
  RichTextParserConfig,
  RichTextParsersConfig,
} from 'types/objectTypes';
import {
  mapBlocksEmbeddedAsset,
  mapBlocksEmbeddedEntry,
  mapBlocksParagraph,
  mapBlocksUlList,
  mapMarksBold,
  mapHyperlink,
  mapMarksItalic,
  mapMarksUnderline,
  mapBlocksMainHeading,
  mapBlocksSecondHeading,
  mapBlocksThirdHeading,
  mapBlocksFourthHeading,
  mapBlocksFifthHeading,
  mapBlocksSixthHeading,
} from 'lib/richTextMappers';
import { UlListClasses } from 'types/classTypes';
import { NEW_LINE_SIGN } from 'constants/constants';

const getMarksDefaultCallback = (
  specificMapper: Function,
  config?: RichTextParserConfig,
) => (text: ReactNode): ReactNode => {
  if (!config) {
    return specificMapper(text);
  }

  const mapper = config.mapper || specificMapper;
  const { classNames } = config;

  return mapper(text, classNames);
};

const getBlocksDefaultCallback = <T extends (...args: Array<any>) => ReactNode>(
  specificMapper: T,
  config?: RichTextParserConfig,
) => (_: Block | Inline, children: ReactNode) => {
  const parsedChildren = (children as Array<any>)
  .reduce((children, child) => {
    if (Array.isArray(child)) {
      children.push(...child.filter(Boolean));
    } else if (child) {
      children.push(child);
    }

    return children;
  }, [] as any);

  if (!parsedChildren.length) {
    return null;
  }

  if (!config) {
    return specificMapper(parsedChildren);
  }

  const mapper = config.mapper || specificMapper;
  const { classNames } = config;

  return mapper(parsedChildren, classNames);
};

const richTextOptionsHandler = (
  parsersConfig: RichTextParsersConfig,
  maps?: RichTextMaps,
): Options => ({
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const parserConfig = parsersConfig[BLOCKS.EMBEDDED_ASSET];

      if (!maps) {
        return null;
      }

      if (!parserConfig) {
        return mapBlocksEmbeddedAsset(node, maps.assetMap);
      }

      const mapper = parserConfig.mapper || mapBlocksEmbeddedAsset;
      const { classNames } = parserConfig;

      return mapper(
        node,
        maps.assetMap,
        classNames as string,
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const parserConfig = parsersConfig[BLOCKS.EMBEDDED_ENTRY];

      if (!maps) {
        return null;
      }

      if (!parserConfig) {
        return mapBlocksEmbeddedEntry(node, maps.entryMap);
      }

      const mapper = parserConfig.mapper || mapBlocksEmbeddedEntry;
      const { classNames } = parserConfig;

      return mapper(
        node,
        maps.entryMap,
        classNames as string,
      );
    },

    [BLOCKS.PARAGRAPH]: getBlocksDefaultCallback(
      mapBlocksParagraph,
      parsersConfig[BLOCKS.PARAGRAPH],
    ),
    [BLOCKS.HEADING_1]: getBlocksDefaultCallback(
      mapBlocksMainHeading,
      parsersConfig[BLOCKS.HEADING_1],
    ),
    [BLOCKS.HEADING_2]: getBlocksDefaultCallback(
      mapBlocksSecondHeading,
      parsersConfig[BLOCKS.HEADING_2],
    ),
    [BLOCKS.HEADING_3]: getBlocksDefaultCallback(
      mapBlocksThirdHeading,
      parsersConfig[BLOCKS.HEADING_3],
    ),
    [BLOCKS.HEADING_4]: getBlocksDefaultCallback(
      mapBlocksFourthHeading,
      parsersConfig[BLOCKS.HEADING_4],
    ),
    [BLOCKS.HEADING_5]: getBlocksDefaultCallback(
      mapBlocksFifthHeading,
      parsersConfig[BLOCKS.HEADING_5],
    ),
    [BLOCKS.HEADING_6]: getBlocksDefaultCallback(
      mapBlocksSixthHeading,
      parsersConfig[BLOCKS.HEADING_6],
    ),

    [BLOCKS.UL_LIST]: (node) => {
      const parserConfig = parsersConfig[BLOCKS.UL_LIST];

      if (!parserConfig) {
        return mapBlocksUlList(node);
      }

      const mapper = parserConfig.mapper || mapBlocksUlList;
      const { classNames } = parserConfig;

      return mapper(node, classNames as UlListClasses);
    },
    [INLINES.HYPERLINK]: ({ data }, children: ReactNode) => {
      const typedChildren = children as Array<string>;
      const parserConfig = parsersConfig[INLINES.HYPERLINK];

      if (!parserConfig) {
        return mapHyperlink(data, typedChildren);
      }

      const mapper = parserConfig.mapper || mapHyperlink;

      const { classNames } = parserConfig;

      return mapper(data, typedChildren, classNames as string);
    },
  },
  renderMark: {
    [MARKS.BOLD]: getMarksDefaultCallback(
      mapMarksBold,
      parsersConfig[MARKS.BOLD],
    ),
    [MARKS.ITALIC]: getMarksDefaultCallback(
      mapMarksItalic,
      parsersConfig[MARKS.ITALIC],
    ),
    [MARKS.UNDERLINE]: getMarksDefaultCallback(
      mapMarksUnderline,
      parsersConfig[MARKS.UNDERLINE],
    ),
  },

  renderText: (text) => {
    const style: CSSProperties = { content: ' ' };

    if (text === NEW_LINE_SIGN) {
      return <br style={style} className={parsersConfig.brClassName} />;
    }

    const splittedText = text.split('\n');
    const array = splittedText
      .reduce<Array<ReactNode>>((children, currentText, index) => [
        ...children,
        index > 0 && (
        <br
          style={style}
          className={parsersConfig.brClassName}
          key={index.toString()}
        />
        ),
        currentText,
      ], []);

    return array;
  },
});

export const renderOptions = (
  links: any,
  parsersConfig: RichTextParsersConfig,
): any => {
  if (!links) return richTextOptionsHandler(parsersConfig);

  const { assets, entries } = links;
  const assetMap = new Map<string, Asset>();
  assets?.block?.forEach((asset: Asset) => assetMap.set(asset.sys.id, asset));

  const entryMap = new Map<string, Entry<any>>();
  entries?.block?.forEach((entry: Entry<any>) => entryMap.set(entry.sys.id, entry));
  entries?.inline?.forEach((entry: Entry<any>) => entryMap.set(entry.sys.id, entry));
  const maps: RichTextMaps = { assetMap, entryMap };
  const renderNode = richTextOptionsHandler(parsersConfig, maps);

  return renderNode;
};
