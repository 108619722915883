import { CSSProperties, FC } from 'react';

import type { ICross } from './declarations';

const Cross: FC<ICross> = ({
  color = '#000',
  lineWidth = 2,
  size = 20,
}) => {
  const commonProps: CSSProperties = {
    position: 'relative',
    backgroundColor: color,
    left: `${size / 2}px`,
    width: `${lineWidth}px`,
    height: `${size}px`,
  };

  return (
    <div style={{
      height: `${size}px`,
      width: `${size}px`,
      cursor: 'pointer',
      position: 'relative',
    }}
    >
      <div style={{
        ...commonProps,
        transform: 'rotate(135deg)',
      }}
      />
      <div style={{
        ...commonProps,
        marginTop: `-${size}px`,
        transform: 'rotate(45deg)',
      }}
      />
    </div>

  );
};

export default Cross;
