import { FC } from 'react';
import cn from 'classnames';

import mobileNavStyles from 'components/Footer/Links/Navigation/MobileNavBar.module.scss';
import { MobileNavBarClasses } from 'types/classTypes';
import CommonNavBar from 'components/Navigation';
import { NavigationLocation } from 'constants/enums';
import { DesktopNavBarWrapperInterface } from 'components/Footer/Links/Navigation/declarations';
import { capitalizeFirstLetter } from 'lib/text.service';

const MobileNavBarWrapper: FC<DesktopNavBarWrapperInterface> = ({ navBar, footerType }) => {
  const classes: MobileNavBarClasses = {
    highOrder: {
      ul: cn(mobileNavStyles.navMobile, mobileNavStyles[`navMobile${capitalizeFirstLetter(footerType)}`]),
      li: mobileNavStyles.navMobileCollapse,
      img: mobileNavStyles.navMobileCollapseImage,
      imgReveal: mobileNavStyles.navMobileCollapseImageRevealed,
      imgCollapse: mobileNavStyles.navMobileCollapseImageCollapsed,
      span: mobileNavStyles.navMobileCollapseButton,
      innerUl: mobileNavStyles.navMobileContent,
    },
    middleOrder: {
      ul: mobileNavStyles.navMobileContent,
      li: mobileNavStyles.navMobileContentLi,
      span: mobileNavStyles.navMobileContentLiSpan,
      img: mobileNavStyles.navMobileContentLiImage,
      link: mobileNavStyles.navMobileContentLiA,
    },
  };

  return (
    <CommonNavBar
      navBar={navBar}
      classes={classes}
      isMobile
      navigationLocation={NavigationLocation.FOOTER}
    />
  );
};

export default MobileNavBarWrapper;
