import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import qs from 'query-string';

import { DataButton, WebpImage } from 'components/Shared/SharedComponents';
import type { Store } from 'app-redux/types/storeTypes';
import { setMobileHeaderNavActivityState } from 'app-redux/actions/appActions';
import { BackButtonFromIframe } from 'src/shared/lib/bio-overlay/components';
import { IS_CLIENT_SIDE } from 'constants/constants';
import { IFRAME_QUERY_PARAMETER } from 'src/shared/lib/bio-overlay';

import styles from './styles.module.scss';

import type { IMenuButton } from '../config/declarations';

export const MenuButton: FC<IMenuButton> = ({ button, className }) => {
  const isMobileHeaderNavActive = useSelector((store: Store) => store
    .server.app.isMobileHeaderNavActive);
  const dispatch = useDispatch();

  if (!button?.image?.url) {
    return null;
  }

  if (IS_CLIENT_SIDE && qs.parse(window.location.search)?.[IFRAME_QUERY_PARAMETER]) {
    return <BackButtonFromIframe />;
  }

  const { image } = button;
  const toggleNav = () => dispatch(setMobileHeaderNavActivityState(!isMobileHeaderNavActive));

  return (
    <DataButton
      link={button}
      onClick={toggleNav}
      onKeyPress={toggleNav}
      aria-expanded={isMobileHeaderNavActive}
      aria-controls="nav"
      aria-label="Toggle Header Navigation"
      className={cn(styles.button, className)}
    >
      <WebpImage
        src={image.url!}
        image="external"
        className={styles.icon}
      />
    </DataButton>
  );
};

export default MenuButton;
