import {
  FC,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import type { Link } from 'src/__generated__/graphqlTypes';
import type { Store } from 'app-redux/types/storeTypes';
import { ZodiacSignFeatureSingleElementInterface } from 'types/componentTypes';
import { OtherSignsList } from 'components/Sections/HoroscopesSignsAllAboutSigns/OtherSignsList';

const OtherSignsListContainer: FC<ZodiacSignFeatureSingleElementInterface> = ({
  element,
}) => {
  const {
    horoscopeCompatibilitySelectImageSize,
  } = useSelector<Store, any>((store) => store.server.app.scssVariables);
  const [selectedItem, setSelectedItem] = useState<Link | null>(null);
  const router = useRouter();

  useEffect(() => {
    if (!element || !element.contentTypesCollection) {
      return;
    }

    const zodiacSigns = element.contentTypesCollection.items as Array<Link>;

    if (zodiacSigns?.length) {
      const firstElement = zodiacSigns[0];
      setSelectedItem(firstElement);
    }
  }, [element]);

  if (!element) {
    return null;
  }

  const redirectToSignPage = () => {
    if (selectedItem?.src) {
      router.push(selectedItem.src);
    }
  };

  const {
    title,
    link,
    contentTypesCollection,
    image,
  } = element;
  const zodiacSigns = (contentTypesCollection?.items as Array<Link>) || [];

  return (
    <OtherSignsList
      title={title!}
      zodiacSigns={zodiacSigns}
      button={link!}
      chevron={image!}
      selectedItem={selectedItem}
      signImageSize={+horoscopeCompatibilitySelectImageSize}
      redirectToSignPage={redirectToSignPage}
      setSelectedItem={setSelectedItem}
    />
  );
};

export default OtherSignsListContainer;
