import useSWR from 'swr';
import { useSelector } from 'react-redux';
import jstz from 'jstz';
import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';

import { getPsychicTimeSlots } from 'src/api/psychicApi';
import { useCustomRouter } from 'src/shared/lib/history/hooks';
import type { Store } from 'app-redux/types/storeTypes';
import type { RightPsychic } from 'types/objectTypes';
import { useStableDateGetter } from 'src/shared/lib/date/hooks';
import { USER_TIME_ZONE } from 'constants/constants';
import { parseDotNetDate } from 'lib/date.service';
import {
  CalendarDateHandler,
  DispatchCalendarState,
  makeCalendarDateKey,
  compareDates,
} from 'entities/PlainCalendar';

import styles from '../ui/AppointmentCalendar.module.scss';
import {
  IDates,
  IHours,
  INavigation,
  ScheduleSlot,
  StartEnd,
  TimeslotsResponse,
} from '../config/declarations';
import { MAX_DAYS_FOR_APPOINTMENTS } from '../config/constants';

export const useNavigationDate = (date: Date, dateFormat: INavigation['dateFormat']) => {
  const router = useCustomRouter();
  const month = date.toLocaleString(router.locale, { month: 'long' });
  const year = date.toLocaleString(router.locale, { year: 'numeric' });

  if (dateFormat === 'short') {
    return `${month} ${year}`;
  }

  const day = date.toLocaleString(router.locale, { weekday: 'short' });
  const dayDate = date.toLocaleString(router.locale, { day: 'numeric' });

  return `${day}, ${month} ${dayDate}, ${year}`;
};

export const useDateRange = (): IDates => {
  const getStableDate = useStableDateGetter();
  const now = getStableDate();
  const serverCookies = useSelector((store: Store) => store.server.app.serverCookies);
  const userTimeZone = serverCookies[USER_TIME_ZONE]?.timezone || jstz.determine().name();
  const weekAhead = new Date(now.valueOf());
  weekAhead.setDate(now.getDate() + 7);

  return {
    start: now.toLocaleDateString('en-CA'),
    end: weekAhead.toLocaleDateString('en-CA'),
    timezone: userTimeZone,
  };
};

const psychicSelectorFunc = (store: Store) => store.server.page.data?.psychic;
const transformData = (data: TimeslotsResponse<string>): TimeslotsResponse => {
  const arrayHandler = ({ start, end }: StartEnd<string>) => ({
    start: parseDotNetDate(start) as Date,
    end: parseDotNetDate(end) as Date,
  });

  const today = new Date();

  if (data.psychicSchedules.length < MAX_DAYS_FOR_APPOINTMENTS) {
    const arrayWithDates = new Array<Date>(MAX_DAYS_FOR_APPOINTMENTS)
      .fill(new Date())
      .reduce((store) => {
        if (!store.length) {
          const utcDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

          return [utcDate];
        }

        const last = store[store.length - 1];
        const newDate = new Date(last.valueOf());
        newDate.setDate(newDate.getDate() + 1);

        return [...store, newDate];
      }, [] as Array<Date>);

    const psychicSchedules: Array<ScheduleSlot<Date>> = arrayWithDates.map((date) => {
      const slot = data.psychicSchedules
        .find((item) => compareDates(parseDotNetDate(item.date) as Date, date));

      if (slot) {
        return {
          ...slot,
          date: parseDotNetDate(slot.date) as Date,
          forCalls: slot.forCalls.map(arrayHandler),
          forAppointments: slot.forAppointments.map(arrayHandler),
          forBookedAppointments: slot.forBookedAppointments.map(arrayHandler),
        };
      }

      return {
        date,
        appointmentCount: 0,
        bookedAppointmentCount: 0,
        forCalls: [],
        isUnavailable: true,
        forAppointments: [],
        forBookedAppointments: [],
      } as ScheduleSlot<Date>;
    });

    return {
      ...data,
      psychicSchedules,
    };
  }

  return {
    ...data,
    psychicSchedules: data.psychicSchedules
      .map((slot) => ({
        ...slot,
        date: parseDotNetDate(slot.date) as Date,
        forCalls: slot.forCalls.map(arrayHandler),
        forAppointments: slot.forAppointments.map(arrayHandler),
        forBookedAppointments: slot.forBookedAppointments.map(arrayHandler),
      })),
  };
};

export const useTimeSlots = () => {
  const dates = useDateRange();
  const user = useSelector((store: Store) => store.server.auth.user);
  const serverPsychic: RightPsychic = useSelector(psychicSelectorFunc) || {};
  const key = useMemo(() => `timeslots-${dates.start}-${dates.end}`, [dates.start, dates.end]);

  const swr = useSWR<TimeslotsResponse<string>>(
    key,
    () => getPsychicTimeSlots(dates, serverPsychic.extId, user?.isVIPCustomer || false),
    { refreshInterval: 12 * 60 * 60 * 1000 },
  );

  const refetch = async () => {
    const data = await getPsychicTimeSlots(
      dates,
      serverPsychic.extId,
      user?.isVIPCustomer || false,
    );

    return data;
  };

  if (!swr.data) {
    /**
     * This done just to fit types
    */
    return { ...swr, data: undefined, refetch };
  }

  return { ...swr, data: transformData(swr.data), refetch };
};

export const useHours = (data?: TimeslotsResponse) => {
  const getStableDate = useStableDateGetter();
  const schedules = data?.psychicSchedules || [];

  if (!schedules.length) {
    return [];
  }

  const getMaxDate = (first: Date, second: Date) => {
    if (first.getTime() > second.getTime()) {
      return first;
    }

    return second;
  };

  const getMinDate = (first: Date, second: Date) => {
    if (first.getTime() < second.getTime()) {
      return first;
    }

    return second;
  };

  const response = schedules.reduce((store, day) => {
    const { date, forAppointments, forCalls } = day;
    const normalizedDate = getStableDate(date);

    if (forAppointments.length) {
      const firstAppointment = forAppointments[0].start;
      const firstCall = forCalls?.[0]?.start || firstAppointment;
      const lastAppointment = forAppointments[forAppointments.length - 1].end;
      const lastCall = forCalls?.[forCalls?.length - 1]?.end || lastAppointment;

      const record: IHours = {
        date: normalizedDate,
        type: 'call-appointment',
        firstHour: getMinDate(firstCall as Date, firstAppointment as Date),
        lastHour: getMaxDate(lastCall as Date, lastAppointment as Date),
      };

      return [...store, record];
    }

    if (forCalls.length) {
      const firstHour = forCalls[0].start;
      const lastHour = forCalls[forCalls.length - 1].end;
      const record: IHours = {
        date: normalizedDate,
        type: 'call',
        firstHour,
        lastHour,
      };

      return [...store, record];
    }

    return [
      ...store,
      { date: normalizedDate, type: 'none' } as IHours];
  }, [] as Array<IHours>);

  return response;
};

export const useSelected = (slots?: Array<ScheduleSlot>) => {
  const getStableDate = useStableDateGetter();
  const now = getStableDate(new Date());
  const defaultSlot = {
    date: now,
    forAppointments: [],
    forCalls: [],
    forBookedAppointments: [],
    isDefault: true,
  };
  const initiallySelected = (slots?.length)
    ? slots.find((slot) => makeCalendarDateKey(now) === makeCalendarDateKey(slot.date))
      || defaultSlot
    : defaultSlot;
  const cartage = useState<ScheduleSlot>(initiallySelected);

  useEffect(() => {
    // @ts-ignore this field is not a part of object type but we need it for this check
    if (!cartage[0].isDefault || !slots?.length) {
      return;
    }

    const slot = slots.find((slot) => makeCalendarDateKey(now) === makeCalendarDateKey(slot.date));

    if (!slot) {
      return;
    }

    cartage[1](slot);
  }, [slots, cartage[0]]);

  return cartage;
};

export const useNestedDispatch = <T extends DispatchCalendarState = DispatchCalendarState>() => {
  const dispatchRef = useRef<((value: T) => void) | null>();
  const dispatch = (value: T) => {
    if (!dispatchRef.current) {
      return;
    }

    dispatchRef.current(value);
  };

  const setDispatch = (dispatch: (value: T) => void) => {
    dispatchRef.current = dispatch;
  };

  return {
    dispatch,
    setDispatch,
  };
};

export const useDatesHandlers = (hours: Array<IHours>) => hours.reduce((store, item) => {
  const { date, type } = item;
  const key = makeCalendarDateKey(date);
  let className = cn(styles.cellBg, styles.cellFont);

  if (type === 'call') {
    className = cn(styles.cellBgAvCallbacks, styles.cellFontAvCallbacks);
  } else if (type === 'call-appointment') {
    className = cn(styles.cellBgAv, styles.cellFontAv);
  } else {
    className = cn(styles.cellBgNotAv, styles.cellFontNotAv);
  }

  return { ...store, [key]: { type, className } };
}, {} as Record<string, CalendarDateHandler>);
