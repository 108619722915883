import {
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';

import { NC_OFFER_SLIDER_INTERVAL_DELAY, SVG_TYPE } from 'constants/constants';
import styles from 'src/components/Sections/AboutNcOfferPackages/AboutNcOfferPackages.module.scss';
import CardAboutNcOfferPackages from 'src/components/Sections/AboutNcOfferPackages/CardAboutNcOfferPackages';
import { DataButton, WebpImage } from 'components/Shared/SharedComponents';
import {
  AboutNcOfferPackagesSliderInterface,
  DefaultArrowSpanInterface,
  SliderArrowInterface,
  ArrowsType,
  SliderAnimateType,
} from 'components/Sections/AboutNcOfferPackages/declarations';

/* Nested component */
const DefaultArrowSpan: FC<DefaultArrowSpanInterface> = ({ alt }) => (
  <span className={styles.sliderArrowImage}>
    {alt}
  </span>
);

/* Nested component */
const SliderArrow: FC<SliderArrowInterface> = ({
  arrow,
  className,
  alt,
  onClick,
}) => {
  if (!arrow) {
    return <DefaultArrowSpan alt={alt} />;
  }

  const { image } = arrow;
  const isSvg = image?.contentType === SVG_TYPE;
  const applyArrow = () => (image?.url
    ? (
      <WebpImage
        image={isSvg ? 'external' : image}
        src={isSvg ? image.url! : undefined}
        className={styles.sliderArrowImage}
      />
    )
    : <DefaultArrowSpan alt={alt} />
  );

  return (
    <DataButton
      link={arrow}
      className={className}
      onClick={onClick}
      onKeyPress={onClick}
    >
      {applyArrow()}
    </DataButton>
  );
};

/* Main component */
const AboutNcOfferPackagesSlider: FC<AboutNcOfferPackagesSliderInterface> = ({
  currentItemIndex,
  blocks,
  images,
  onPackageClick,
}) => {
  const { length } = blocks;
  const contentRef = useRef<HTMLDivElement>(null);
  const startingIndex = currentItemIndex ?? Math.floor(length / 2);
  const sliderIntervalRef = useRef<any>();

  const countOffsetSize = () => {
    const ref = contentRef.current;
    const defaultValue = 600;

    if (!ref) return defaultValue;

    const child = ref.firstChild as HTMLDivElement;

    return child.clientWidth;
  };

  const [translateArray, setTranslateArray] = useState<Array<SliderAnimateType>>(
    Array(length).fill({ transform: 0, opacity: 1 }),
  );

  const currentIndexRef = useRef<number>(startingIndex);

  const [isTransitionComplete, setTransitionCompleteFlag] = useState(true);

  const arrows = images?.reduce((accumulator, item): ArrowsType => {
    if (item.src?.includes('prev')) {
      accumulator.prevArrow = item;
    }

    if (item.src?.includes('next')) {
      accumulator.nextArrow = item;
    }

    return accumulator;
  }, {} as ArrowsType);

  const cloneArray = (
    prevArray: Array<SliderAnimateType>,
    outerIndex: number,
    isNext: boolean,
  ) => {
    const offsetSize = countOffsetSize();

    return [...prevArray.map((item, i) => {
      const prevTransform = item.transform;
      const offset = (isNext)
        ? prevTransform - offsetSize
        : prevTransform + offsetSize;

      if (i === outerIndex) {
        const outerOffset = (isNext)
          ? offset + (offsetSize * length)
          : offset - (offsetSize * length);

        return {
          transform: outerOffset,
          opacity: 0,
        };
      }

      return {
        transform: offset,
        opacity: 1,
      };
    })];
  };

  const intervalRestart = () => {
    clearTimeout(sliderIntervalRef.current);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    sliderIntervalRef.current = setTimeout(nextSlide, NC_OFFER_SLIDER_INTERVAL_DELAY);
  };

  const nextSlide = () => {
    if (!isTransitionComplete) return;

    setTransitionCompleteFlag(false);
    const prevIndex = currentIndexRef?.current;
    const nextIndex = (prevIndex === length - 1) ? 0 : (prevIndex + 1);

    const outerIndex = (nextIndex + 1) % length;

    setTranslateArray((prevArray) => cloneArray(prevArray, outerIndex, true));

    currentIndexRef.current = nextIndex;
    intervalRestart();
  };

  useEffect(() => {
    sliderIntervalRef.current = setTimeout(nextSlide, NC_OFFER_SLIDER_INTERVAL_DELAY);

    return () => clearTimeout(sliderIntervalRef.current);
  }, []);

  const prevSlide = () => {
    if (!isTransitionComplete) return;

    setTransitionCompleteFlag(false);
    const prevIndex = currentIndexRef?.current;

    const nextIndex = (prevIndex === 0) ? length - 1 : prevIndex - 1;

    const outerIndex = (length - 1 + nextIndex) % length;

    setTranslateArray((prevArray) => cloneArray(prevArray, outerIndex, false));
    currentIndexRef.current = nextIndex;
    intervalRestart();
  };

  if (!Array.isArray(blocks) || blocks.length === 0) {
    return null;
  }

  return (
    <section className={styles.slider}>
      <SliderArrow
        arrow={arrows.prevArrow}
        onClick={prevSlide}
        className={cn(styles.sliderArrow, styles.sliderArrowPrev)}
        alt="<"
      />
      <div
        ref={contentRef}
        className={styles.sliderContent}
      >
        {blocks.map((childBlock, i) => {
          const transtateItem = translateArray[i];

          if (!transtateItem) return null;

          const { transform, opacity } = transtateItem;

          return (
            <div
              className={styles.sliderContentSlide}
              key={childBlock.entryName}
              style={{ transform: `translateX(${transform}px)`, opacity }}
              onTransitionEnd={() => setTransitionCompleteFlag(true)}
            >
              <CardAboutNcOfferPackages block={childBlock} onPackageClick={onPackageClick} />
            </div>
          );
        })}
      </div>
      <SliderArrow
        arrow={arrows.nextArrow}
        onClick={nextSlide}
        className={cn(styles.sliderArrow, styles.sliderArrowNext)}
        alt=">"
      />
    </section>
  );
};

export default AboutNcOfferPackagesSlider;
