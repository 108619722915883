import { FC } from 'react';
import cn from 'classnames';

import { DataButton, WebpImage } from 'components/Shared/SharedComponents';
import classes from 'src/styles/_commonClasses.module.scss';
import { GAReplacementValue } from 'constants/enums';

import styles from './OffersListCarousel.module.scss';

import type { CarouselImageInterface } from '../config/declarations';
import { defaultBanner } from '../config/constants';

const CarouselImage: FC<CarouselImageInterface> = ({
  imageObject,
  image,
  isMobileViewWidth,
  link,
  redirectToUrl,
}) => {
  const src = isMobileViewWidth ? imageObject.mobileUrl : imageObject.desktopUrl;
  const imageInfo = src
    ? {
      src,
      promoCode: imageObject.promoCode || 'Nothing',
      bannerUrl: imageObject.isOfferImage ? src : 'Nothing',
      offerId: imageObject.offerId,
      linkRedirectUrl: imageObject.linkRedirectUrl,
    }
    : {
      promoCode: defaultBanner.promoCode,
      bannerUrl: 'Nothing',
      offerId: 'Nothing',
      linkRedirectUrl: link?.src,
      image,
    };

  if (!imageInfo) {
    return null;
  }

  const onClick = () => redirectToUrl(imageInfo.linkRedirectUrl);

  return (
    <DataButton
      link={link!}
      className={cn(classes.cursorPointer, styles.carouselImageWrapper)}
      onClick={onClick}
      gaData={{
        [GAReplacementValue.NAME]: imageObject.offerLabel,
        [GAReplacementValue.OFFER_ID]: `${imageObject.offerId}`,
      }}
      onKeyPress={onClick}
      data-from="offers"
      data-offerID={imageInfo.offerId}
      data-promoCode={imageInfo.promoCode}
      data-bannerURL={imageInfo.bannerUrl}
    >
      <WebpImage
        image={imageInfo.image || 'external'}
        src={imageInfo.src || ''}
        className={styles.carouselImage}
        alt="Slide"
      />
    </DataButton>
  );
};

export default CarouselImage;
