import { FC } from 'react';
import cn from 'classnames';

import { DataLink } from 'components/Shared/SharedComponents';
import { replaceContentfulVariables } from 'lib/text.service';

import styles from './styles.module.scss';

import { useUrlSlug } from '../lib/hooks';
import type { ILoadMore } from '../config/declarations';

const LoadMore: FC<ILoadMore> = ({ link, isCollapsed }) => {
  const slug = useUrlSlug();

  if (!link?.src) {
    return null;
  }

  return (
    <DataLink
      link={link!}
      href={replaceContentfulVariables(link.src, { slug })}
      className={cn(
        styles.load,
        { [styles.hidden]: isCollapsed },
      )}
    >
      <u>{link.title}</u>
    </DataLink>
  );
};

export default LoadMore;
