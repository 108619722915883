/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import cn from 'classnames';
import { KeyboardEvent, MouseEvent } from 'react';

import classes from 'src/styles/_commonClasses.module.scss';

import styles from '../ui/OffersListCarousel.module.scss';
import HoveredSliderArrow from '../ui/HoveredSliderArrow';
import type { ArrowRendererProps } from '../config/declarations';

export const getRightArrowRenderer = (
  {
    isCarouselHovered,
    commonArrow,
    hoveredArrow,
    sideSize,
  }: ArrowRendererProps,
) => (
  clickHandler: () => void,
  hasNext: boolean,
  label: string,
) => (
  <HoveredSliderArrow
    hasNext={hasNext}
    className={styles.carouselArrowRight}
    label={label}
    commonArrow={commonArrow}
    hoveredArrow={hoveredArrow}
    onClick={clickHandler}
    isCarouselHovered={isCarouselHovered}
    defaultControlClass="control-next"
    altImage=">"
    height={sideSize}
    width={sideSize}
  />
);

export const getLeftArrowRenderer = (
  {
    isCarouselHovered,
    commonArrow,
    hoveredArrow,
    sideSize,
  }: ArrowRendererProps,
) => (
  clickHandler: () => void,
  hasPrev: boolean,
  label: string,
) => (
  <HoveredSliderArrow
    hasNext={hasPrev}
    className={styles.carouselArrowLeft}
    label={label}
    commonArrow={commonArrow}
    hoveredArrow={hoveredArrow}
    onClick={clickHandler}
    isCarouselHovered={isCarouselHovered}
    defaultControlClass="control-prev"
    altImage="<"
    height={sideSize}
    width={sideSize}
  />
);

export const renderIndicator = (
  clickHandler: (e: MouseEvent<HTMLLIElement> | KeyboardEvent<HTMLLIElement>) => void,
  isSelected: boolean,
  index: number,
  label: string,
) => (
  <li
    key={index}
    onClick={clickHandler}
    onKeyPress={clickHandler}
    title={label}
    aria-label={label}
    className={cn(
      isSelected && styles.carouselIndicatorSelected,
      styles.carouselIndicator,
      classes.cursorPointer,
    )}
  />
);
