import { FC } from 'react';
import cookieParser from 'cookie';

import type { Block, Text } from 'src/__generated__/graphqlTypes';
import LocalLoader from 'src/shared/ui/LocalLoader/LocalLoader';
import { OfferPackage } from 'types/objectTypes';
import {
  AccountInfoCookie,
  accountInfoTemplate,
  getAccountPackages,
} from 'src/shared/lib/cookie/accountInfo';
import { parseEncodedJson } from 'lib/json.service';
import { ASK_ACCOUNT_INFO } from 'constants/constants';
import { useCustomRouter } from 'src/shared/lib/history/hooks';

import styles from './PriceCardsWithKarma.module.scss';
import Card from './Card';
import StubCard from './StubCard';

import type {
  CardParts,
  ICards,
  StubPackage,
} from '../config/declarations';

const Cards: FC<ICards> = ({
  packages,
  offerId,
  block,
  user,
  isReserveContent,
}) => {
  const router = useCustomRouter();

  if (!packages) {
    return <LocalLoader className={styles.loader} />;
  }

  if (isReserveContent) {
    const [helperBlock] = (block?.contentTypesCollection?.items || []) as Array<Block>;
    const redirectAndSetCookie = () => {
      const redirectUrl = block.link?.src || '/ncsignup';

      if (!user) {
        return router.push(redirectUrl);
      }

      const oldAccountInfoString = cookieParser.parse(document.cookie)[ASK_ACCOUNT_INFO];
      const oldAccountInfo = oldAccountInfoString ? parseEncodedJson(oldAccountInfoString) : {};
      const flowType = oldAccountInfo?.ncflowtype || 2;
      const accountInfo: Partial<AccountInfoCookie> = {
        ...accountInfoTemplate,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        dob: user.userDateOfBirth,
        customerId: user.customerId,
        package: getAccountPackages('20')[1],
        ncflowtype: flowType,
        phoneCountry: user.countryCallingCode?.toString(),
        phoneNumber: user.customerPhoneNumber,
        custId: user.custId,
      };

      document.cookie = cookieParser.serialize(
        ASK_ACCOUNT_INFO,
        JSON.stringify(accountInfo),
        { path: '/', secure: true },
      );

      return router.push(redirectUrl);
    };

    return (
      <div className={styles.cards}>
        {(packages as Array<StubPackage>).map((offerPackage, i) => (
          <StubCard
            key={offerPackage.priceId}
            index={i}
            helperBlock={helperBlock}
            currency={block.title!}
            link={block?.link}
            redirectAndSetCookie={redirectAndSetCookie}
            offerPackage={offerPackage}
          />
        ))}
      </div>
    );
  }

  const texts = (block.contentTypesCollection?.items || []) as Array<Text>;
  const cardParts = texts
    .reduce((store, text) => ({ ...store, [text.slug || '']: text }), {} as CardParts);

  return (
    <div className={styles.cards}>
      {(packages as Array<OfferPackage>).map((offerPackage) => (
        <Card
          key={offerPackage.priceId}
          currency={block.title!}
          link={block?.link}
          offerId={offerId}
          router={router}
          cardParts={cardParts}
          offerPackage={offerPackage}
        />
      ))}
    </div>
  );
};

export default Cards;
