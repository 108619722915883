import { FC, useState } from 'react';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import { SliderArrow } from 'components/Shared/SharedComponents';
import type { Link } from 'src/__generated__/graphqlTypes';

import styles from './OffersListCarousel.module.scss';

import type { IHoveredSliderArrow } from '../config/declarations';

const getRightArrowOrExisting = (
  isHovered: boolean,
  commonArrow?: Link,
  hoveredArrow?: Link,
) => {
  if (isHovered && hoveredArrow) {
    return hoveredArrow;
  }

  if (isHovered && !hoveredArrow && commonArrow) {
    return commonArrow;
  }

  if (!isHovered && commonArrow) {
    return commonArrow;
  }

  if (!isHovered && !commonArrow && hoveredArrow) {
    return hoveredArrow;
  }
};

const HoveredSliderArrow: FC<IHoveredSliderArrow> = ({
  hasNext,
  className,
  label,
  commonArrow,
  hoveredArrow,
  onClick,
  isCarouselHovered = false,
  defaultControlClass,
  altImage,
  height,
  width,
}) => {
  const [isArrowHovered, setArrowHoveredState] = useState<boolean>(false);

  if (!commonArrow && !hoveredArrow) {
    return null;
  }

  const arrow = getRightArrowOrExisting(isArrowHovered, commonArrow, hoveredArrow)!;

  return (
    <SliderArrow
      arrow={arrow}
      altImage={altImage}
      hasNextElement={hasNext}
      label={label}
      defaultControlClass={defaultControlClass}
      className={cn(
        styles.carouselArrow,
        className,
        { [classes.displayNone]: !isCarouselHovered },
      )}
      clickHandler={onClick}
      onMouseEnter={() => setArrowHoveredState(true)}
      onMouseLeave={() => setArrowHoveredState(false)}
      height={height}
      width={width}
    />
  );
};

export default HoveredSliderArrow;
