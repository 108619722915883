import { FC } from 'react';
import { useSelector } from 'react-redux';

import { DataLink } from 'components/Shared/SharedComponents';
import type { Store } from 'app-redux/types/storeTypes';

import styles from '../styles.module.scss';
import type { IGreetingChild } from '../../config/declarations';

const Balance: FC<IGreetingChild> = ({ link }) => {
  const user = useSelector((store: Store) => store.server.auth.user);

  if (!link || !user) {
    return null;
  }

  const currency = link.alt || '$';

  return (
    <DataLink link={link} className={styles.balance}>
      <p className={styles.textColor}>{link.title}</p>
      <strong>
        {`${currency}${(user.dollarBalance || 0)?.toFixed(2)}`}
      </strong>
    </DataLink>
  );
};

export default Balance;
