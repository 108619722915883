import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import cn from 'classnames';

import type { Block, Link } from 'src/__generated__/graphqlTypes';
import { extractComponents } from 'src/widgets/OffersListCarousel/lib';
import type { Store } from 'app-redux/types/storeTypes';
import LocalLoader from 'src/shared/ui/LocalLoader/LocalLoader';
import { SectionComponentGenericInterface } from 'types/componentTypes';

import styles from './OffersListCarousel.module.scss';
import OffersListCarouselWrapper from './OffersListCarouselWrapper';
import CarouselImage from './CarouselImage';

import { useDefaultOfferImage, useOffersImages } from '../lib/hooks';
import { defaultOffersConfig } from '../config/constants';

const OffersList: FC<SectionComponentGenericInterface<Block | Link>> = ({
  blocks,
  extraData,
}) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const router = useRouter();
  const {
    pauseDurationDesktop,
    pauseDurationMobile,
    ...offersConfig
  } = { ...defaultOffersConfig, ...extraData };
  const elements = extractComponents(blocks as Array<Link>);
  const offersImages = useOffersImages(user, offersConfig);

  const redirectToUrl = (src: Link['src']) => {
    if (src) {
      router.push(src);
    }
  };

  const image = useDefaultOfferImage(isMobileViewWidth, elements.block);

  if (!user || !elements.block) {
    return null;
  }

  const { link } = elements.block || {};

  if (offersImages === null) {
    const height = isMobileViewWidth
      ? offersConfig.mobileBannerHeight
      : offersConfig.desktopBannerHeight;

    return (
      <section
        style={{ height }}
        className={cn(styles.wrapper, styles.wrapperLoader)}
      >
        <LocalLoader className={styles.loader} />
      </section>
    );
  }

  const imagesComponents = offersImages
    .map((imageObj) => (
      <CarouselImage
        key={imageObj.offerId}
        image={image}
        imageObject={imageObj}
        isMobileViewWidth={isMobileViewWidth}
        link={link}
        redirectToUrl={redirectToUrl}
      />
    ));

  if (offersImages.length === 1) {
    return (
      <section className={styles.wrapper}>
        {imagesComponents}
      </section>
    );
  }

  return (
    <OffersListCarouselWrapper
      isMobileViewWidth={isMobileViewWidth}
      pauseDurationDesktop={pauseDurationDesktop}
      pauseDurationMobile={pauseDurationMobile}
      blocks={elements}
    >
      {imagesComponents}
    </OffersListCarouselWrapper>
  );
};

export default OffersList;
