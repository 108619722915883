import { useRef, FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Store } from 'app-redux/types/storeTypes';
import styles from 'components/Sections/ContentDivider/Divider.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { SectionComponentInterface } from 'types/componentTypes';
import { LazyWebpImage, WebpImage } from 'components/Shared/SharedComponents';
import { capitalizeFirstLetter } from 'lib/text.service';
import { setItemInObjectBySlug } from 'lib/sharedMethods.service';
import {
  CommonSize,
  LoadingMode,
  ViewerDevice,
} from 'constants/enums';
import { DividerSlug as Slug } from 'components/Sections/ContentDivider/declarations';
import { ImageWrapper } from 'src/__generated__/graphqlTypes';

const slugs = {
  mobile: [Slug.MOBILE],
  desktop: [Slug.DESKTOP],
};

const Divider: FC<SectionComponentInterface> = ({
  blocks,
  extraData,
}) => {
  const isMobileView = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const viewerDevice = useSelector<Store, ViewerDevice>((store) => store.server.app.viewerDevice);

  const [divider] = blocks;
  const {
    loadingMode = LoadingMode.EAGER,
    verticalPadding = CommonSize.XX_SMALL,
    dividerWidth,
    isMobileVisible = true,
    dividerHeight,
  } = extraData || {};
  const pictureRef = useRef<HTMLImageElement>(null);

  if (!divider) {
    return null;
  }

  const line = <span style={{ height: dividerHeight }} className={styles.dividerLine} />;
  const { imagesCollection } = divider;
  const reducer = setItemInObjectBySlug(slugs);
  const {
    desktop,
    mobile,
  } = (imagesCollection?.items as Array<ImageWrapper>)
    ?.reduce(reducer, {} as Record<Slug, ImageWrapper>) || {};

  const imageWrapper = (viewerDevice !== ViewerDevice.MOBILE)
    ? desktop
    : mobile;
  const { image } = imageWrapper || {};

  const Component = (loadingMode === LoadingMode.LAZY) ? LazyWebpImage : WebpImage;

  if (isMobileView && !isMobileVisible) {
    return null;
  }

  return (
    <section className={cn(
      styles.divider,
      styles[`width${capitalizeFirstLetter(dividerWidth)}`],
      classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`],
    )}
    >
      {line}
      {image && (
        <Component
          image={image}
          ref={pictureRef}
          className={styles.dividerImage}
        />
      )}
      {line}
    </section>
  );
};

export default Divider;
