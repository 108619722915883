import {
  FC,
  LiHTMLAttributes,
  useState,
} from 'react';
import cn from 'classnames';

import type { Link, NavItem } from 'src/__generated__/graphqlTypes';
import { DataButton, DataLink } from 'components/Shared/SharedComponents';

import styles from './styles.module.scss';
import ThirdLevelMenu from './3LMenu';

import type { ISecondLevelItem } from '../config/declarations';

/* Nested Component */
const Li: FC<LiHTMLAttributes<HTMLLIElement>> = ({ children }) => (
  <li className={cn(styles.li, styles.liBorder)}>
    {children}
  </li>
);

/* Main Component */
const SecondLevelItem: FC<ISecondLevelItem> = ({ item }) => {
  const [isChildrenVisible, setChildrenVisibilityState] = useState<boolean>(false);

  if (item.__typename === 'Link') {
    return (
      <Li>
        <DataLink className={styles.liButton} link={item}>
          {item.title}
        </DataLink>
      </Li>
    );
  }

  const navItem = item as NavItem;

  if (!navItem?.headline || navItem.headline.__typename !== 'Link') {
    return null;
  }

  const { headline, linksCollection } = navItem;
  const links = linksCollection?.items || [];

  if (links.length) {
    return (
      <Li>
        <DataButton
          className={styles.liButton}
          link={headline}
          onClick={() => setChildrenVisibilityState((prev) => !prev)}
        >
          {headline.title}
          <i className={cn(
            styles.revealSub,
            { [styles.revealSubClosed]: isChildrenVisible },
          )}
          />
        </DataButton>
        <ThirdLevelMenu
          links={links as Array<Link>}
          isVisible={isChildrenVisible}
        />
      </Li>
    );
  }

  return (
    <Li>
      <DataLink
        className={styles.liButton}
        link={headline}
      >
        {headline.title}
      </DataLink>
    </Li>
  );
};

export default SecondLevelItem;
