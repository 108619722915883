import { useState, FC } from 'react';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import styles from 'components/Header/MiddleBar/Interaction/LoginForm/LoginForm.module.scss';
import { PASSWORD } from 'constants/constants';
import { WebpImage } from 'components/Shared/SharedComponents';
import { PasswordInputInterface } from 'components/Header/MiddleBar/Interaction/LoginForm/declarations';

const PasswordInput: FC<PasswordInputInterface> = ({ passwordRef, password, image, ...rest }) => {
  const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false);
  const inputType = isPasswordVisible ? 'text' : 'password';

  return (
    <div className={styles.loginFormInputPassword}>
      <input
        ref={passwordRef}
        className={styles.loginFormInput}
        type={inputType}
        name={PASSWORD}
        placeholder={password || ''}
        autoComplete="current-password"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
      {image?.url && (
        <>
          <span className={cn(styles.line, { [classes.displayNone]: isPasswordVisible })} />
          <WebpImage
            image="external"
            src={image.url}
            className={styles.icon}
            onClick={() => setPasswordVisibility((prev) => !prev)}
          />
        </>
      )}
    </div>
  );
};

export default PasswordInput;
