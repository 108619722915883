import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import classes from 'src/styles/_commonClasses.module.scss';
import type { RichTextParsersConfig } from 'types/objectTypes';
import { CommonRichText } from 'components/Shared/SharedComponents';

import styles from './SimilarPsychics.module.scss';

import type { IHeading } from '../config/declarations';

const getParsersConfig = (): RichTextParsersConfig => ({
  [BLOCKS.HEADING_2]: {
    classNames: styles.wrapperTitle,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold700,
  },
});

const Heading: FC<IHeading> = ({ title }) => (
  <CommonRichText
    content={title}
    parsersConfig={getParsersConfig()}
  />
);

export default Heading;
