import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import { SectionExtraDataType } from 'types/objectTypes';

import { ErrorHandler, ErrorHandlerDispatch } from './context';

export const useErrorHandler = () => {
  const store = useContext(ErrorHandler);

  return store;
};

export const useContextDispatch = () => {
  const dispatch = useContext(ErrorHandlerDispatch);
  const savedDispatch = useCallback(dispatch, []);

  return savedDispatch;
};

/** This hook triggers change of extraData, and changes 'key' for SWR.
 * It not directly fetch psychics, just trigger rerender that is lead to changing
 * 'key' in SWR and fetch new psychics
 */
export const useHandlePsychicsRefetch = (
  setMutableData: Dispatch<SetStateAction<SectionExtraDataType>>,
) => {
  const store = useErrorHandler();

  useEffect(() => {
    if (!store.shouldRefetchPsychics || !store.apiToRefetch) {
      return;
    }

    setMutableData((prev) => ({
      ...prev,
      apiToUse: store.apiToRefetch,
      newKey: store.apiToRefetch,
    }));
  }, [store]);
};
