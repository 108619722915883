import React, {
  useState,
  useEffect,
  FC,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';

import { Store } from 'app-redux/types/storeTypes';
import { getRightPsychicsProxy } from 'src/api/psychicApi';
import {
  Block,
  BlockContentTypesItem,
  ImageWrapper,
  Link,
  Text,
} from 'src/__generated__/graphqlTypes';
import { Logger } from 'lib/logger';
import { DataButton } from 'components/Shared/SharedComponents';
import { updatePsychicListOptionCookie, updatePsychicListOptionCookieMultiple } from 'src/shared/lib/cookie';
import { useCustomRouter } from 'src/shared/lib/history/hooks';

import FilterListPanelMobile from './FilterListPanel/FilterListPanelMobile';
import FilterListPanel from './FilterListPanel/FilterListPanel';
import styles from './PsychicFilter.module.scss';
import StatusIndicators from './HeaderToolbar/StatusIndicators';
import SortBy from './HeaderToolbar/SortBy';
import FilterBy from './HeaderToolbar/FilterBy';
import ProfileView from './HeaderToolbar/ProfileView';

import {
  PsychicFilterInterface,
  SelectedSort,
  SelectedValueChange,
  PopularFilters,
} from '../../declarations';
import {
  psychicFilterReducer,
  psychicFilterWrapperReducer,
  sortImageReducer,
  filterImageReducer,
  gridViewReducer,
  listViewReducer,
} from '../../lib';
import { requestBody } from '../../lib/constants';

const PsychicFilter: FC<PsychicFilterInterface> = ({
  content,
  filteredExtIds,
  apiData,
  sortid,
  filterToolbarSelections,
  zeroResultPanelVisibility = true,
  clearFilter,
  displayGridView,
  displayListView,
  updateFilter,
}) => {
  const {
    selectedLayout,
    selectedFilters,
    selectedSortItem,
    totalAvailablePsychics,
    totalBusyPsychics,
    totalResults,
  } = filterToolbarSelections || {};
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const [selectedSortItemLocal, setSelectedSortItem] = useState<SelectedSort>({ SortBy: '', SortByText: '' });
  const [selectedFiltersLocal, setSelectedFilters] = useState<Record<string, any>>(
    selectedFilters || {},
  );

  const [totalResultsLocal, setTotalResults] = useState<number | undefined>(totalResults);
  const [zeroResultPanelVisibilityLocal, setZeroResultPanelVisibility] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const filterByTitleRef = useRef<HTMLDivElement>(null);
  const router = useCustomRouter();

  useEffect(() => {
    setTotalResults(totalResults);
  }, [totalResults]);

  useEffect(() => {
    if (!selectedSortItem) {
      return;
    }

    setSelectedSortItem(selectedSortItem);
  }, [selectedSortItem]);

  useEffect(() => {
    setZeroResultPanelVisibility(zeroResultPanelVisibility);
  }, [zeroResultPanelVisibility]);

  const toggleFilters = () => {
    if (showFilters && !totalResults) {
      setZeroResultPanelVisibility(true);
    }

    updatePsychicListOptionCookie('ResultType', 2);
    setShowFilters(!showFilters);
  };

  const {
    contentTypesCollection,
  } = content || {};

  const children = contentTypesCollection?.items as Array<Block>;

  const {
    sort,
    filter,
    gridViewBlock,
    listViewBlock,
    availableTitle,
    busyTitle,
    backToFiltersTitle,
    noMatchTitle,
  } = psychicFilterWrapperReducer(children) || {};

  const {
    contentTypesCollection: sortTypesCollection,
    imagesCollection: sortImageColection,
    richTitle: sortTitle,
  } = sort || {};
  const sortList = sortTypesCollection?.items as Array<Text> | undefined;
  const {
    sortImage,
  } = sortImageReducer(sortImageColection?.items as Array<ImageWrapper> || []);

  const {
    contentTypesCollection: gridContentTypesCollection,
  } = gridViewBlock || {};
  const {
    gridViewLink,
  } = gridViewReducer(gridContentTypesCollection?.items as Array<BlockContentTypesItem> || []);

  const {
    contentTypesCollection: listContentTypesCollection,
  } = listViewBlock || {};
  const {
    listViewLink,
  } = listViewReducer(listContentTypesCollection?.items as Array<BlockContentTypesItem>) || [];

  const {
    contentTypesCollection: filterTypesCollection,
    imagesCollection: filterImageColection,
    richTitle: filterTitle,
  } = filter || {};
  const {
    filterImage,
  } = filterImageReducer(filterImageColection?.items as Array<ImageWrapper> || []);
  const filterButtonAndLinkList = filterTypesCollection?.items as Array<Block | Link> | undefined;

  const changeFilter = async (selectedValue: SelectedValueChange) => {
    if (!selectedValue) {
      return;
    }

    try {
      if (selectedFiltersLocal && Object.keys(selectedFiltersLocal).length) {
        const filterType = selectedValue.type;
        const currrentSearchOptions = selectedFiltersLocal.SearchOptions || {};
        const existingFilterValue = currrentSearchOptions[filterType];
        let updatedFilterValue = '';

        if (existingFilterValue) {
          const existingFilterValueArr = existingFilterValue.split(',');
          const isFilterAlreadyExist = existingFilterValueArr
            .find((ele: string) => ele === selectedValue.value);

          if (isFilterAlreadyExist) {
            const filtervaluesAfterRemoving = existingFilterValueArr
              .filter((ele: string) => ele !== selectedValue.value);
            updatedFilterValue = filtervaluesAfterRemoving.join(',');
          } else {
            existingFilterValueArr.push(selectedValue.value);
            updatedFilterValue = existingFilterValueArr.join(',');
          }
        } else {
          updatedFilterValue = selectedValue.value;
        }

        const valuesToSet = { [filterType]: updatedFilterValue };
        setSelectedFilters({
          SearchOptions: { ...currrentSearchOptions, ...valuesToSet },
        });
      } else {
        const searchOptionTemplate = { Subject: '', Ability: '', Tool: '', Style: '', Price: '', Popular: '', isNewPsychics: '' };
        setSelectedFilters({
          SearchOptions: { ...searchOptionTemplate, [selectedValue.type]: selectedValue.value },
        });
      }
    } catch (e) {
      Logger.error(e);
    }
  };

  const checkIfFilterPresent = (selectedValue: SelectedValueChange) => {
    if (selectedFiltersLocal && Object.keys(selectedFiltersLocal).length) {
      const filterType = selectedValue.type;
      const currrentSearchOptions = selectedFiltersLocal.SearchOptions;
      const existingFilterValue = currrentSearchOptions[filterType];

      if (existingFilterValue) {
        const existingFilterValueArr = existingFilterValue.split(',');

        return existingFilterValueArr.find((ele) => ele === selectedValue.value) !== undefined;
      }
    }

    return false;
  };

  const changeSort = (value: Text) => {
    if (!value) {
      return;
    }

    try {
      const selectedValue: SelectedSort = {
        SortBy: value.fullText,
        SortByText: value.text,
      };
      updateFilter?.(selectedValue, selectedFiltersLocal);
      updatePsychicListOptionCookieMultiple({
        SortBy: selectedValue.SortBy,
        SortByText: selectedValue.SortByText,
      });
      setShowFilters(false);
    } catch (e) {
      Logger.error(e);
    }
  };

  const closeFilter = () => {
    setShowFilters(false);
  };

  const doneFilter = () => {
    updateFilter?.(selectedSortItemLocal, selectedFiltersLocal);
    updatePsychicListOptionCookie('ResultType', 1);
    setShowFilters(false);
  };

  const removeFilter = () => {
    updatePsychicListOptionCookie('SearchOptions', null);
    clearFilter?.();
    setSelectedFilters({});
    setTotalResults(totalResults);
  };

  useEffect(() => {
    if (!Object.keys(selectedFiltersLocal).length) {
      return;
    }

    (async () => {
      let currentRequestBody = { ...requestBody, PageSize: 1 };

      if (apiData) {
        const filteredApiData = Object.fromEntries(
          Object.entries(apiData).filter(([, value]) => value !== null),
        );
        currentRequestBody = { ...currentRequestBody, ...filteredApiData };
      }

      if (sortid) {
        currentRequestBody.SortId = sortid;
      }

      if (router.query.search) {
        currentRequestBody.SearchText = router.query.search;
      }

      if (Object.keys(selectedFiltersLocal.SearchOptions).length) {
        const anyFilterEnabled = Object
          .values(selectedFiltersLocal.SearchOptions)
          .filter((val) => Boolean(val));

        if (anyFilterEnabled.length) {
          updatePsychicListOptionCookie('SearchOptions', selectedFiltersLocal?.SearchOptions);
        } else {
          updatePsychicListOptionCookie('SearchOptions', null);
        }
      } else {
        updatePsychicListOptionCookie('SearchOptions', null);
      }

      currentRequestBody = {
        ...currentRequestBody,
        ...selectedSortItemLocal,
        ...selectedFiltersLocal,
      };

      const { totalCount } = await getRightPsychicsProxy(currentRequestBody);
      setTotalResults(totalCount);
    })();
  }, [selectedFiltersLocal, apiData, sortid, filter]);

  const {
    clearFilterButton,
    doneButton,
    priceTitle,
    viewTitle,
    resultsTitle,
    popularTitle,
    popularPremiumFilter,
    newPsychicsFilter,
    customerFavoritesFilter,
    staffPicksFilter,
    risingStarsFilter,
  } = psychicFilterReducer(filterButtonAndLinkList) || {};

  const popularFiltersArray = [
    popularPremiumFilter,
    newPsychicsFilter,
    customerFavoritesFilter,
    staffPicksFilter,
    risingStarsFilter,
  ];

  const popularFiltersOptions = popularFiltersArray.map((option) => ({ displayVal: option?.text || '', val: option?.fullText || '' }));
  const popularFilters: PopularFilters = {
    majorCategoryDescription: popularTitle?.title || '',
    richText: popularTitle?.title || '',
    descriptions: popularFiltersOptions,
  };

  if (zeroResultPanelVisibilityLocal) {
    return (
      <div className={styles.noPsychicPanel}>
        <div className={styles.noPsychicMatchTitle}>
          <span>{noMatchTitle?.title}</span>
        </div>
        <div className={styles.noPsychicBackContainer}>
          <DataButton
            onClick={() => {
              setShowFilters(true);
              setZeroResultPanelVisibility(false);
            }}
            className={styles.noPsychicBackfilter}
          >
            {backToFiltersTitle?.title}
          </DataButton>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.headerToolbar}>
        <div className={styles.headerSetmargin}>
          <StatusIndicators
            extIds={filteredExtIds}
            totalAvailablePsychics={totalAvailablePsychics}
            totalBusyPsychics={totalBusyPsychics}
            availableTitle={availableTitle}
            busyTitle={busyTitle}
          />
          <div className={styles.filterWrapper}>
            <SortBy
              sortImage={sortImage}
              sortTitle={sortTitle}
              sortList={sortList}
              changeSort={changeSort!}
            />
            <FilterBy
              filterImage={filterImage}
              filterTitle={filterTitle}
              toggleFilters={toggleFilters}
              filterByTitleRef={filterByTitleRef}
            />
          </div>
          <ProfileView
            gridViewLink={gridViewLink}
            listViewLink={listViewLink}
            selectedLayout={selectedLayout}
            displayGridView={displayGridView}
            displayListView={displayListView}
          />
        </div>
      </div>
      {isMobileViewWidth
        ? (
          <FilterListPanelMobile
            filterTitle={filterTitle}
            showFilters={showFilters}
            checkIfFilterPresent={checkIfFilterPresent}
            changeFilter={changeFilter}
            doneFilter={doneFilter}
            totalResultsLocal={totalResultsLocal}
            removeFilter={removeFilter}
            clearFilterButton={clearFilterButton}
            priceTitle={priceTitle}
            viewTitle={viewTitle}
            resultsTitle={resultsTitle}
            closeFilter={closeFilter}
            filterByTitleRef={filterByTitleRef}
            popularFilters={popularFilters}
            popularTitle={popularTitle}
          />
        )
        : (
          <FilterListPanel
            changeFilter={changeFilter}
            checkIfFilterPresent={checkIfFilterPresent}
            doneFilter={doneFilter}
            removeFilter={removeFilter}
            totalResultsLocal={totalResultsLocal}
            clearFilterButton={clearFilterButton}
            doneButton={doneButton}
            showFilters={showFilters}
            priceTitle={priceTitle}
            viewTitle={viewTitle}
            resultsTitle={resultsTitle}
            closeFilter={closeFilter}
            filterByTitleRef={filterByTitleRef}
          />
        )}
    </>
  );
};

export default PsychicFilter;
