import { FC } from 'react';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import { DataButton, WebpImage } from 'components/Shared/SharedComponents';

import styles from './PriceCardsWithKarma.module.scss';
import GoLink from './GoLink';

import {
  ILeftColumn,
  IRightColumn,
  IStubCard,
} from '../config/declarations';

/* Nested component */
const LeftColumn: FC<ILeftColumn> = ({
  currency,
  offerPackage,
  helperBlock,
}) => {
  const offeredPrice = `${currency}${offerPackage.amountToCharge}`;

  return (
    <div className={styles.left}>
      <p className={cn(styles.basePrice, classes.textBold700)}>
        {offeredPrice}
      </p>
      <p className={styles.perMinutes}>
        {helperBlock?.title}
      </p>
    </div>
  );
};

/* Nested component */
const RightColumn: FC<IRightColumn> = ({
  link,
  index,
  currency = '$',
  helperBlock,
  offerPackage,
}) => {
  const url = helperBlock?.image?.url;
  const packageName = helperBlock?.textList?.[index];
  const basePriceToShow = offerPackage?.amountToCredit - offerPackage?.amountToCharge;
  const basePrice = `${currency}${basePriceToShow?.toFixed(2)}`;

  return (
    <div className={styles.right}>
      <div className={styles.rightPrice}>
        <s className={styles.offerPrice}>
          {
            offerPackage?.amountToCredit !== offerPackage?.amountToCharge
              ? basePrice
              : null
          }
        </s>
        {url && (
          <WebpImage
            image="external"
            src={url}
            className={styles.infoImage}
          />
        )}
      </div>
      <p className={cn(styles.packageName, classes.textBold700)}>
        {packageName}
      </p>
      <GoLink title={link?.title} />
    </div>
  );
};

/* Main component */
const StubCard: FC<IStubCard> = ({
  link,
  index,
  currency,
  helperBlock,
  offerPackage,
  redirectAndSetCookie,
}) => (
  <DataButton
    link={link!}
    className={styles.card}
    onClick={redirectAndSetCookie}
  >
    <LeftColumn
      currency={currency}
      offerPackage={offerPackage}
      helperBlock={helperBlock}
    />
    <RightColumn
      link={link}
      index={index}
      currency={currency}
      offerPackage={offerPackage}
      helperBlock={helperBlock}
    />
  </DataButton>
);

export default StubCard;
