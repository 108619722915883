import type { Block } from 'src/__generated__/graphqlTypes';

import type { SortedBlocks } from '../config/declarations';

export const parseBlocks = (blocks: Array<Block>): SortedBlocks => {
  const sorted = blocks.reduce((store, block) => {
    const isNoRightSlug = block.slug !== 'left'
      && block.slug !== 'right'
      && block.slug !== 'center'
      && block.slug !== 'bg';

    if (isNoRightSlug) {
      if (!store.center) {
        return { ...store, center: block };
      }

      return store;
    }

    return { ...store, [block.slug || '']: block };
  }, {} as SortedBlocks);

  return sorted;
};
