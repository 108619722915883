import { useEffect, useState } from 'react';

import { getCustomerOffers } from 'src/api/customerApi';
import { Logger } from 'lib/logger';
import type { OfferPackage, UserType } from 'types/objectTypes';

import { StubPackage } from '../config/declarations';

export const useOffer = (user: UserType | null) => {
  const [packages, setPackages] = useState<Array<OfferPackage | StubPackage> | null>(null);
  const [offerId, setOfferId] = useState<number | null>(null);
  const isReserveContent = user !== null ? user.isLeadCustomer : false;

  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      try {
        const resp = await getCustomerOffers({ custId: user.custId });
        const offer = resp?.custOffers?.[0];

        if (!offer || !Array.isArray(offer?.packages)) {
          return setPackages(null);
        }

        const packages = offer.packages.slice(0, 3);

        setOfferId(offer.offerId);
        setPackages(packages);
      } catch (e) {
        Logger.error(e);
      }
    })();
  }, [user]);

  return { packages, offerId, isReserveContent };
};
