import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  Dispatch,
} from 'react';

import type { TextStatusComponentInterface } from 'entities/PsychicStatus';
import { Status } from 'constants/enums';
import { RightPsychic } from 'types/objectTypes';
import { DataLinkInterface } from 'components/Shared/declarations';
import { Psychic } from 'src/__generated__/graphqlTypes';
import { ButtonShape as Shape } from 'entities/PsychicCtaButton';

export const enum TemplateStateType {
  SET_CURRENT_PAGE = 'set-current-page',
}

export type TemplateAction = {
  type: TemplateStateType;
  payload: number;
}

export type PsychicTemplateState = {
  currentPage: number;
}

interface HrefsInterface {
  hrefs: Array<string | undefined>;
}

export interface PaginationButtonInterface
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>{
  dispatch: Dispatch<TemplateAction>;
}

export interface StringWithCommaInterface {
  array: Array<string> | string;
}

export interface PsychicRateInterface {
  wrapperStyle: string;
  commonPriceStyle: string;
  discountStyle: string;
  commonWithDiscountStyle: string;
  price: string;
  discount?: string;
}

export interface StatusTextInterface extends Omit<TextStatusComponentInterface, 'status'> {
}

export interface StatusIndicatorInterface {
  chatStatus: Status;
  phoneStatus: Status;
  className?: string;
}

export interface DataLinkWrapperInterface
  extends Omit<DataLinkInterface, 'gaData'> {
  complexHref?: Array<string>;
  psychic?: RightPsychic;
}

export interface PsychicCardImageWrapperInterface extends HrefsInterface {
  psychic: RightPsychic;
  className: string;
  psychicFrame: Psychic;
  isLazy?: boolean;
}

export interface CtaButtonsWrapperInterface {
  psychicFrame: Psychic;
  psychic: RightPsychic;
  className: string;
  shape?: Shape;
}
