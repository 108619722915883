import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import styles from 'components/Sections/HoroscopesSignsSignIntro/SignIntro.module.scss';
import { CommonRichText, WebpImage } from 'components/Shared/SharedComponents';
import { RichTextParsersConfig, WebpWidthLimitType } from 'types/objectTypes';
import { capitalizeFirstLetter } from 'lib/text.service';
import { ImageFitMode } from 'constants/enums';
import { SectionWithSlugInterface } from 'components/Sections/HoroscopesSignsSignIntro/declarations';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.signIntroTextParagraph,
  },
};
const RATIO = 0.6;
const SignIntro: React.FC<SectionWithSlugInterface> = ({
  blocks,
  bgColor,
  slug,
}) => {
  const buildComponents = () => blocks.map((block) => {
    if (!block) return null;

    const { image, content, title } = block;
    const width = Math.floor(image?.width! * RATIO);
    const height = Math.floor(image?.height! * RATIO);

    const imageWidthLimit: Array<WebpWidthLimitType> = [{
      width,
      height,
      media: '360px',
      fit: ImageFitMode.SCALE,
    }];

    return (
      <div className={styles.signIntro} key={title}>
        <div className={styles.signIntroText}>
          <h1 className={styles.signIntroTextTitle}>{title}</h1>
          {content && (
            <CommonRichText
              content={content}
              parsersConfig={parsersConfig}
            />
          )}
        </div>
        {image && (
          <WebpImage
            image={image}
            className={styles.signIntroImage}
            widthLimit={imageWidthLimit}
          />
        )}
      </div>
    );
  });

  return (
    <section
      style={{ background: bgColor }}
      className={cn(
        styles.wrapper,
        slug && styles[`wrapper${capitalizeFirstLetter(slug)}`],
      )}
    >
      {buildComponents()}
    </section>
  );
};

export default SignIntro;
