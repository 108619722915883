import { useSelector } from 'react-redux';

import { ButtonTypes, ReadingsButtonTypes } from 'entities/PsychicCtaButton';
import { RightPsychic, UserType } from 'types/objectTypes';
import { Store } from 'app-redux/types/storeTypes';
import { ViewerDevice } from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';

import { Exponea } from './exponea';

const isPsychicInCircle = (psychic: RightPsychic, user: UserType | null) => {
  if (!user) {
    return false;
  }

  if (psychic.isInCircle) {
    return true;
  }

  const psychicsInCircle = (user.psychicInCircle || '').split(',');

  if (!psychicsInCircle) {
    return false;
  }

  const isCurrentPsychicInCircle = psychicsInCircle
    .find((id) => psychic.extId?.toString() === id);

  return Boolean(isCurrentPsychicInCircle);
};

export const useExponeaProductSelectedTracking = (psychic: RightPsychic) => {
  const user = useSelector((store: Store) => store.server.auth.user);
  const viewerDevice = useSelector((store: Store) => store.server.app.viewerDevice);
  const referrer = useSelector((store: Store) => store.server.page.referrer);

  const track = async (buttonName: ButtonTypes | ReadingsButtonTypes) => Exponea.track('product_selected', {
    CustomerID: user?.customerId || '',
    Favorite: psychic.isFavorite ? 'Yes' : '',
    FullRate: psychic.basePrice.replace('$', ''),
    Platform: capitalizeFirstLetter((viewerDevice === ViewerDevice.DESKTOP)
      ? ViewerDevice.DESKTOP
      : ViewerDevice.MOBILE),
    PsychicCircle: isPsychicInCircle(psychic, user) ? 'Yes' : '',
    PsychicID: psychic.extId,
    PsychicName: psychic.lineName,
    path: window.location.pathname,
    cta_content: buttonName.toLowerCase(),
    referrer,
  });

  return track;
};
