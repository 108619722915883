import { FC } from 'react';

import styles from './Components.module.scss';

import type { IErrorMessage } from '../../config/declarations';

const ErrorMessage: FC<IErrorMessage> = ({ isInvalid, error }) => {
  if (!isInvalid || !error?.title) {
    return null;
  }

  return (
    <small className={styles.errorMessage}>
      {error.title}
    </small>
  );
};

export default ErrorMessage;
