import { FC } from 'react';
import cn from 'classnames';

import { DataButton } from 'components/Shared/SharedComponents';
import styles from 'components/Sections/PsychicsSetMediumSizeImagesClone/PsychicsSetMediumSizeImages.module.scss';

import type { PsychicsSetMediumSizeImagesPaginationButton as PaginationButtonInterface } from './declarations';

const PaginationButton: FC<PaginationButtonInterface> = ({ paginationButton, ...rest }) => {
  if (!paginationButton) {
    return null;
  }

  return (
    <DataButton
      {...rest}
      className={cn(
        styles.psychicButtonSecondary,
        rest.className,
      )}
      link={paginationButton}
    >
      {paginationButton.title}
    </DataButton>
  );
};

export default PaginationButton;
