/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
import {
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import hash from 'object-hash';

import type firebase from 'firebase';
import { setFirebaseLoadedState } from 'app-redux/actions/appActions';
import { Store } from 'app-redux/types/storeTypes';
import { FirebaseDocId, RightPsychic } from 'types/objectTypes';
import { FirestoreChangeType, Status } from 'src/constants/enums';
import { Logger } from 'lib/logger';
import { getStatus } from 'lib/psychic.service';

const START_OF_NUMERIC_ID_PART = 3;

const getElementId = (
  doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
) => {
  const docId = doc.id as FirebaseDocId;

  return +docId.substring(START_OF_NUMERIC_ID_PART);
};

const unsubscribePreviousButchEvents = (unsubscribeFunctions: Array<Function>) => {
  if (unsubscribeFunctions.length) {
    unsubscribeFunctions.forEach((func) => func());
    unsubscribeFunctions.splice(0, unsubscribeFunctions.length - 1);
  }
};

const useUnsubscribePreviousEvents = (unsubscribeFunctions: Array<Function>) => {
  useEffect(
    () => () => unsubscribePreviousButchEvents(unsubscribeFunctions),
    [unsubscribeFunctions],
  );
};

const applyNewDataIfNeeded = (
  condition: boolean,
  psychic: RightPsychic,
  data: any,
) => {
  if (condition) {
    const newPsychic = psychic;
    newPsychic.phoneStatus = data.phoneStatus;
    newPsychic.chatStatus = data.chatStatus;
    newPsychic.lineStatus = getStatus({
      chatStatus: data.chatStatus,
      phoneStatus: data.phoneStatus,
    });
    newPsychic.messageStatus = data.messageStatus === 'enabled'
      ? Status.AVAILABLE
      : Status.OFFLINE;
    newPsychic.isDirectMessageEnabled ??= data.isMessageEnabled;
    newPsychic.isChatEnabled ??= data.isChatEnabled ?? data.chatStatus === Status.AVAILABLE;
    newPsychic.isPhoneEnabled ??= data.isPhoneEnabled ?? data.phoneStatus === Status.AVAILABLE;
    newPsychic.phoneQueue = data.PhoneQueueOrder;

    if (data.estimatedWaitTime) {
      newPsychic.estimatedWaitTime = data.estimatedWaitTime;
    }

    if (data.onBreakMinutes) {
      newPsychic.onBreakMinutes = data.onBreakMinutes;
    }

    return newPsychic;
  }

  return psychic;
};

let firestoreInstance: firebase.firestore.Firestore;
let documentId: () => any = () => { console.log('issue with importing documentId'); };

const setUpFirebaseIfRequired = async () => {
  if (!firestoreInstance) {
    const { default: getFirestoreInstance, documentId: importedDocumentId } = await import('src/firebase/firestore');
    documentId = importedDocumentId;
    firestoreInstance = getFirestoreInstance();
  }
};

const updateModifiedPsychics = (
  setPsychics: Dispatch<SetStateAction<RightPsychic[]>>,
  doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
) => setPsychics((prevPsychics) => {
  const newPsychics = [...prevPsychics];
  const newPsychicData = doc.data();
  const resourceId = getElementId(doc);

  const array = newPsychics.map((psychic: RightPsychic) => {
    const isIdsEqualsIgnoreType = psychic.extId == resourceId;

    return applyNewDataIfNeeded(isIdsEqualsIgnoreType, psychic, newPsychicData);
  });

  return array;
});

const getPsychicDataFromDocs = (
  docs: Array<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>>,
  extraHandler?: (item: firebase.firestore.DocumentData) => boolean,
) => docs.reduce((store, item) => {
  if (item.exists) {
    const data = item.data();
    const resourceId = getElementId(item);

    if (!extraHandler) {
      // eslint-disable-next-line no-param-reassign
      store[resourceId] = data;

      return store;
    }

    const shouldBeUpdated = extraHandler!(data);

    if (shouldBeUpdated) {
      // eslint-disable-next-line no-param-reassign
      store[resourceId] = data;
    }
  }

  return store;
}, {} as Record<string, any>);

const getPsychicsMapper = (psychicsData: Record<string, any>) => (
  psychics: Array<RightPsychic>,
  psychic: RightPsychic,
) => {
  const appropriateElement = psychicsData[psychic.extId];

  if (appropriateElement) {
    psychics.push(applyNewDataIfNeeded(true, psychic, appropriateElement));
  }

  return psychics;
};

const setUpFirestoreListener = (
  psychicCollections: Array<firebase.firestore.Query<firebase.firestore.DocumentData>>,
  unsubscribeFunctions: Array<Function>,
  setPsychics: Dispatch<SetStateAction<RightPsychic[]>>,
): void => {
  psychicCollections.forEach((collection) => {
    const unsubscribe = collection.onSnapshot((snapshot) => {
      const changes = snapshot.docChanges();
      changes.forEach((change) => {
        const { doc, type } = change;

        if (type === FirestoreChangeType.MODIFIED) {
          updateModifiedPsychics(setPsychics, doc);
        }
      });
    }, (error) => {
      Logger.error(error);
    });
    unsubscribeFunctions.push(unsubscribe);
  });
};

export const useAvailablePsychics = (
  setPsychics: Dispatch<SetStateAction<RightPsychic[]>>,
) => {
  const isFirebaseLoaded = useSelector((store: Store) => store.client.app.isFirebaseLoaded);
  const isFirebaseRequired = useSelector((store: Store) => store.client.app.isFirebaseRequired);
  const wasPsychicsLoadedRef = useRef<boolean>(false);
  const { current: unsubscribeFunctions } = useRef<Array<Function>>([]);

  const loadInitialData = async (
    psychicCollection: Array<firebase.firestore.Query<firebase.firestore.DocumentData>>,
  ) => {
    try {
      const psychicsData = (await Promise
        .all(psychicCollection.map((collection) => collection.get())))
        .reduce((store, { docs }) => {
          const extraHandler = (item: firebase.firestore.DocumentData) => {
            const { phoneStatus, chatStatus } = item;

            const isBusyOrPending = phoneStatus === Status.BUSY || phoneStatus === Status.PENDING
              || chatStatus === Status.BUSY || chatStatus === Status.PENDING;

            if (isBusyOrPending) {
              return false;
            }

            return true;
          };
          const items = getPsychicDataFromDocs(docs, extraHandler);

          return { ...store, ...items };
        }, {} as Record<string, any>);

      setPsychics((prevPsychics) => prevPsychics
        ?.reduce(getPsychicsMapper(psychicsData!), [] as Array<RightPsychic>));
      wasPsychicsLoadedRef.current = true;
    } catch (e) {
      Logger.error(e);
    }
  };

  useEffect(() => {
    if (!isFirebaseRequired || !isFirebaseLoaded) {
      return;
    }

    (async () => {
      if (!firestoreInstance) {
        const { default: getFirestoreInstance, documentId: importedDocumentId } = await import('src/firebase/firestore');
        documentId = importedDocumentId;
        firestoreInstance = getFirestoreInstance();
      }

      const advisorCollection = firestoreInstance.collection('advisors');
      const availablePhoneCollection = advisorCollection.where('phoneStatus', '==', Status.AVAILABLE);
      const availableChatCollection = advisorCollection.where('chatStatus', '==', Status.AVAILABLE);
      const psychicCollection = (await Promise
        .all([availablePhoneCollection, availableChatCollection])).flat();
      const wasPsychicsLoaded = wasPsychicsLoadedRef.current;

      if (!wasPsychicsLoaded) {
        await loadInitialData(psychicCollection);
        setUpFirestoreListener(
          psychicCollection,
          unsubscribeFunctions,
          setPsychics,
        );
        wasPsychicsLoadedRef.current = true;
      }
    })();
  },
  [
    isFirebaseLoaded,
    isFirebaseRequired,
    wasPsychicsLoadedRef.current,
  ]);

  useUnsubscribePreviousEvents(unsubscribeFunctions);
};

export const usePsychicStatuses = (
  idList: Array<number>,
  setPsychics: Dispatch<SetStateAction<RightPsychic[]>>,
  shouldBeUsed: boolean = true,
) => {
  const isFirebaseLoaded = useSelector((store: Store) => store.client.app.isFirebaseLoaded);
  const isFirebaseRequired = useSelector((store: Store) => store.client.app.isFirebaseRequired);
  const wasPsychicsLoadedRef = useRef<boolean>(false);
  const { current: unsubscribeFunctions } = useRef<Array<Function>>([]);

  const loadInitialData = async (
    psychicCollection: Array<firebase.firestore.Query<firebase.firestore.DocumentData>>,
  ) => {
    try {
      const psychicsData = (await Promise
        .all(psychicCollection.map((collection) => collection.get())))
        .reduce((store, { docs }) => {
          const items = getPsychicDataFromDocs(docs);

          return { ...store, ...items };
        }, {});

      setPsychics((prevPsychics) => prevPsychics.map((psychic) => {
        const appropriateElement = psychicsData[psychic.extId];

        return applyNewDataIfNeeded(!!appropriateElement, psychic, appropriateElement);
      }));
      wasPsychicsLoadedRef.current = true;
    } catch (e) {
      Logger.error(e);
    }
  };

  useEffect(() => {
    const isFirebaseNotReady = !isFirebaseRequired || !isFirebaseLoaded;
    const isListEmpty = !idList || idList.length === 0;

    if (isListEmpty || isFirebaseNotReady || !shouldBeUsed) {
      return;
    }

    (async () => {
      await setUpFirebaseIfRequired();
      const batches: Array<firebase.firestore.Query<firebase.firestore.DocumentData>> = [];
      const advisorCollection = firestoreInstance.collection('advisors');

      while (idList.length) {
        const batch = idList.splice(0, 10).map((id) => {
          if (id.toString().startsWith('cp')) {
            return id;
          }

          return `cp-${id}`;
        });
        batches.push(advisorCollection
          .where(documentId(), 'in', batch));
      }

      const psychicCollection = (await Promise.all(batches)).flat();
      const wasPsychicsLoaded = wasPsychicsLoadedRef.current;

      if (!wasPsychicsLoaded) {
        await loadInitialData(psychicCollection);
        setUpFirestoreListener(
          psychicCollection,
          unsubscribeFunctions,
          setPsychics,
        );
      }
    })();
  },
  [
    idList,
    isFirebaseLoaded,
    isFirebaseRequired,
    wasPsychicsLoadedRef.current,
    shouldBeUsed,
  ]);

  useUnsubscribePreviousEvents(unsubscribeFunctions);
};

export const usePsychicStatusesWithUnsubscribe = (
  idList: Array<number>,
  setPsychics: Dispatch<SetStateAction<RightPsychic[]>>,
  shouldBeUsed: boolean = true,
) => {
  const isFirebaseLoaded = useSelector((store: Store) => store.client.app.isFirebaseLoaded);
  const isFirebaseRequired = useSelector((store: Store) => store.client.app.isFirebaseRequired);
  const wasPsychicsLoadedRef = useRef<boolean>(false);
  const { current: unsubscribeFunctions } = useRef<Array<Function>>([]);
  const prevHashRef = useRef<string>('');

  const loadInitialData = async (
    psychicCollection: Array<firebase.firestore.Query<firebase.firestore.DocumentData>>,
  ) => {
    try {
      const psychicsData = (await Promise
        .all(psychicCollection.map((collection) => collection.get())))
        .reduce((store, { docs }) => {
          const items = getPsychicDataFromDocs(docs);

          return { ...store, ...items };
        }, {});

      setPsychics((prevPsychics) => prevPsychics.map((psychic) => {
        const appropriateElement = psychicsData[psychic.extId];

        return applyNewDataIfNeeded(!!appropriateElement, psychic, appropriateElement);
      }));
      wasPsychicsLoadedRef.current = true;
    } catch (e) {
      Logger.error(e);
    }
  };

  useEffect(() => {
    const isFirebaseNotReady = !isFirebaseRequired || !isFirebaseLoaded;
    const isListEmpty = !idList || idList.length === 0;

    if (isListEmpty || isFirebaseNotReady || !shouldBeUsed) {
      return;
    }

    const currentHash = hash.MD5(idList);

    if (currentHash === prevHashRef.current) {
      return;
    }

    (async () => {
      await setUpFirebaseIfRequired();
      const batches: Array<firebase.firestore.Query<firebase.firestore.DocumentData>> = [];
      const advisorCollection = firestoreInstance.collection('advisors');
      const idListClone = [...idList];

      while (idListClone.length) {
        const batch = idListClone.splice(0, 10).map((id) => {
          if (id.toString().startsWith('cp')) {
            return id;
          }

          return `cp-${id}`;
        });
        batches.push(advisorCollection.where(documentId(), 'in', batch));
      }

      const psychicCollection = (await Promise.all(batches)).flat();
      unsubscribePreviousButchEvents(unsubscribeFunctions);
      prevHashRef.current = currentHash;
      await loadInitialData(psychicCollection);
      setUpFirestoreListener(psychicCollection, unsubscribeFunctions, setPsychics);
    })();
  },
  [
    idList,
    isFirebaseLoaded,
    isFirebaseRequired,
    wasPsychicsLoadedRef.current,
    shouldBeUsed,
  ]);

  useUnsubscribePreviousEvents(unsubscribeFunctions);
};

export const useFirebaseLazyLoading = () => {
  const isFirebaseLoaded = useSelector((store: Store) => store.client.app.isFirebaseLoaded);
  const isFirebaseRequired = useSelector((store: Store) => store.client.app.isFirebaseRequired);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isFirebaseRequired && !isFirebaseLoaded) {
      import('firebase').then(() => dispatch(setFirebaseLoadedState(true)));
    }
  }, [isFirebaseRequired, isFirebaseLoaded, dispatch]);
};
