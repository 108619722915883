import { FC } from 'react';
import cn from 'classnames';

import styles from './Tick.module.scss';
import { ITick } from './declaration';

const Tick: FC<ITick> = ({
  className,
  direction = 'left',
  color = '#000',
  sideSize = 10,
  borderWidth = 2,
}) => {
  const hypotenuseHalf = Math.round(Math.sqrt((sideSize ** 2) * 2) / 2);
  const borderWidthString = `${borderWidth}px`;

  const getRotation = () => {
    switch (direction) {
      case 'bottom': {
        return '45deg';
      }
      case 'top': {
        return '225deg';
      }
      case 'right': {
        return '315deg';
      }
      default: {
        return '135deg';
      }
    }
  };

  return (
    <i
      style={{
        borderWidth: borderWidthString,
        top: `calc(50% + ${borderWidthString} - ${hypotenuseHalf}px)`,
        height: sideSize,
        width: sideSize,
        borderBottom: `${borderWidthString} solid ${color}`,
        borderRight: `${borderWidthString} solid ${color}`,
        transform: `rotate(${getRotation()})`,
      }}
      className={cn(styles.tick, className)}
    />
  );
};

export default Tick;
