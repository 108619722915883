import { FC } from 'react';

import { Block } from 'src/__generated__/graphqlTypes';
import { SectionComponentInterface } from 'types/componentTypes';
import { ExpandableItemsList } from 'components/Sections/ExpandableItemsList';
import { useCommonContentfulBlocks } from 'lib/shared.hook';
import { CommonContentfulSlug } from 'constants/enums';

const ExpandableItemsListContainer: FC<SectionComponentInterface> = ({
  blocks,
  extraData,
  bgColor,
}) => {
  const {
    content,
    topDivider,
    bottomDivider,
    commonPageMaxWidth,
  } = useCommonContentfulBlocks<CommonContentfulSlug, Block>(blocks);

  return (
    <ExpandableItemsList
      content={content}
      topDivider={topDivider}
      bottomDivider={bottomDivider}
      extraData={extraData}
      bgColor={bgColor}
      commonPageMaxWidth={commonPageMaxWidth}
    />
  );
};

export default ExpandableItemsListContainer;
