import React from 'react';

import styles from 'components/Sections/HoroscopesSignsAllAboutSigns/Traits/Traits.module.scss';
import { SignFeatureContainer, SignFeatureHeading } from 'components/Sections/HoroscopesSignsAllAboutSigns/Shared';
import { WebpImage } from 'components/Shared/SharedComponents';
import { ImageFitMode } from 'constants/enums';
import { AllAboutSignsTraitsInterface } from 'components/Sections/HoroscopesSignsAllAboutSigns/Traits/declarations';

const Traits: React.FC<AllAboutSignsTraitsInterface> = ({
  traits,
  mobileViewMaxWidth,
}) => {
  if (!traits?.length) {
    return <SignFeatureContainer containerClassName={styles.gemstones} />;
  }

  const imageWidthLimit = [{
    width: 50,
    isMinWidth: true,
    media: `${mobileViewMaxWidth}px`,
  },
  {
    width: 40,
    media: `${mobileViewMaxWidth}px`,
  },
  ];

  const applyTraits = () => traits.map(({
    entryName,
    image,
    title,
    textList,
  }) => (
    <div key={entryName} className={styles.traitsContainer}>
      {image && (
        <WebpImage
          image={image}
          additionalProps={{ h: 50, w: 50, fit: ImageFitMode.SCALE }}
          widthLimit={imageWidthLimit}
          className={styles.traitsContainerImage}
        />
      )}
      <SignFeatureHeading
        title={title!}
        titleClassName={styles.traitsContainerHeading}
        underlineClassName={styles.traitsContainerHeadingUnderline}
      />
      {textList && (
        <div className={styles.traitsContainerContent}>
          {textList.map((someTrait) => (
            <span
              key={someTrait}
              className={styles.traitsContainerContentRecord}
            >
              {someTrait}
            </span>
          ))}
        </div>
      )}
    </div>
  ));

  return (
    <SignFeatureContainer containerClassName={styles.traits}>
      {applyTraits()}
    </SignFeatureContainer>
  );
};

export default Traits;
