import { FC } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

import { CommonRichText, WebpImage } from 'components/Shared/SharedComponents';
import { SortByInterface } from 'components/Sections/PsychicsSetMediumSizeImagesClone/declarations';
import { DownshiftSelect } from 'src/shared/ui/DownshiftSelect';
import { RichTextParsersConfig } from 'types/objectTypes';

import styles from '../PsychicFilter.module.scss';

const parsersConfig: RichTextParsersConfig = {
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.sortTitle,
  },
};

const SortBy: FC<SortByInterface> = ({ sortImage, sortTitle, sortList, changeSort }) => (
  <div className={styles.sortBy}>
    <div className={styles.leftSection}>
      <WebpImage
        image={sortImage || 'external'}
        pictureClassName={styles.iconWrapper}
        className={styles.icon}
      />
    </div>
    <div className={styles.rightSection}>
      <DownshiftSelect
        placeholder={<CommonRichText content={sortTitle} parsersConfig={parsersConfig} />}
        toggleClassName={styles.button}
        ulClassName={styles.dropdownList}
        options={sortList}
        onChange={changeSort}
      />
    </div>
  </div>
);

export default SortBy;
