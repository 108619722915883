import { FC } from 'react';

import { Flowers } from 'components/Sections/HoroscopesSignsAllAboutSigns/Flowers';
import { ZodiacSignFeatureSingleElementInterface } from 'types/componentTypes';
import { useDeviceHoroscopeInfo } from 'lib/horoscope/horoscope.hook';

const FlowersContainer: FC<ZodiacSignFeatureSingleElementInterface> = ({
  element,
}) => {
  const {
    mobileViewMaxWidth,
    horoscopeTabletMaxWidth,
    viewerDevice,
  } = useDeviceHoroscopeInfo();

  return (
    <Flowers
      viewerDevice={viewerDevice}
      flowers={element}
      mobileViewMaxWidth={mobileViewMaxWidth}
      horoscopeTabletMaxWidth={horoscopeTabletMaxWidth}
    />
  );
};

export default FlowersContainer;
