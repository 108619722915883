import {
  FC,
  useState,
  MouseEvent,
} from 'react';
import cn from 'classnames';

import { PsychicCardAppearance } from 'constants/enums';

import VideoModal from './VideoModal';
import styles from './styles.module.scss';

import { VideoInterface } from '../config/declarations';

const Video: FC<VideoInterface> = ({
  view,
  psychic,
  className,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const getFormattedVideoUrl = (url?: string): string | undefined => (url ? url.replace('.m3u8', '.mp4').replace('videobios/', '') : undefined);
  const videoUrl = getFormattedVideoUrl(psychic?.psychicVideoURL);

  const openModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  if (!videoUrl) {
    return null;
  }

  return (
    <>
      <button
        type="button"
        data-action="Play video icon"
        data-label={view === PsychicCardAppearance.LIST ? 'List' : 'Gallery'}
        data-category="Psychic Tile"
        className={cn(
          styles.playButton,
          className,
          {
            [styles.list]: view === PsychicCardAppearance.LIST,
            [styles.tile]: view === PsychicCardAppearance.TILE,
          },
        )}
        onClick={openModal}
      >
        <i className={styles.triangle} />
      </button>

      {isModalOpen && (
        <VideoModal
          isOpen={isModalOpen}
          onClose={closeModal}
          videoUrl={videoUrl}
        />
      )}
    </>
  );
};

export default Video;
