/* eslint-disable no-param-reassign */
import { FC } from 'react';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import cn from 'classnames';

import classes from 'src/styles/_commonClasses.module.scss';
import {
  Block,
  ImageWrapper,
  Link,
} from 'src/__generated__/graphqlTypes';
import {
  HeroBannerRichText as RichTextInterface,
  GridHeroBannerComponent,
  HeroBannerRichTextParam,
  Love1LogoInterface as LogoInterface,
} from 'components/Sections/GridHeroBanner/declarations';
import styles from 'components/Sections/GridHeroBanner/Love1/Love1.module.scss';
import { Love1HeroBannerBackground } from 'components/Sections/GridHeroBanner';
import {
  CommonRichText,
  DataLink,
  WebpImage,
} from 'components/Shared/SharedComponents';
import { RichTextParsersConfig } from 'types/objectTypes';
import {
  ColorTheme,
  CommonSize,
  ElementAlign,
  ViewerDevice,
} from 'constants/enums';
import { capitalizeFirstLetter } from 'lib/text.service';
import { IS_CLIENT_SIDE } from 'constants/constants';

const getParsersConfig = ({
  titleAlign,
  pAlign,
}: HeroBannerRichTextParam): RichTextParsersConfig => {
  const getHClasses = (specificClass: string) => cn(
    styles.loveTitle,
    specificClass,
    classes[`titleAlign${capitalizeFirstLetter(titleAlign)}`],
  );
  const paragraphAlign = classes[`pAlign${capitalizeFirstLetter(pAlign)}`];

  return ({
    [BLOCKS.HEADING_1]: {
      classNames: getHClasses(classes.titleMainH1),
    },
    [BLOCKS.HEADING_2]: {
      classNames: getHClasses(classes.titleMainH2),
    },
    [BLOCKS.PARAGRAPH]: {
      classNames: cn(styles.loveParagraph, paragraphAlign),
    },
    [MARKS.BOLD]: {
      classNames: classes.textBold700,
    },
  });
};

/* Nested component */
const Content: FC<RichTextInterface> = ({ richText, config }) => {
  if (!richText) {
    return null;
  }

  return (
    <CommonRichText
      content={richText}
      parsersConfig={getParsersConfig(config)}
    />
  );
};

/* Nested component */
const Title: FC<RichTextInterface> = Content;

/* Nested component */
const Logo: FC<LogoInterface> = ({
  block,
  viewerDevice,
  isMobileWidth,
}) => {
  const { contentTypesCollection } = block;
  const buttons = contentTypesCollection?.items as Array<Link> | undefined;

  if (!buttons?.length) {
    return null;
  }

  const { mobile, desktop } = buttons.reduce(
    (accumulator, item) => ({ ...accumulator, [item.slug || '']: item }),
    {} as Record<string, Link>,
  );

  if ((viewerDevice === ViewerDevice.MOBILE || (IS_CLIENT_SIDE && isMobileWidth)) && mobile.image) {
    return (
      <DataLink link={mobile} className={styles.loveLogo}>
        <WebpImage className={styles.loveLogoImage} image={mobile.image} />
      </DataLink>
    );
  }

  if (desktop.image) {
    return (
      <DataLink link={desktop} className={styles.loveLogo}>
        <WebpImage className={styles.loveLogoImage} image={desktop.image} />
      </DataLink>
    );
  }

  return null;
};

const TitleBlock: FC<{block: Block; config: any}> = ({ block, config }) => {
  if (!block) {
    return null;
  }

  const { content, richTitle, link } = block;

  return (
    <>
      <Title richText={richTitle} config={config} />
      <Content richText={content} config={config} />
      {link && (
        <DataLink className={styles.loveButton} link={link}>
          {link.title}
        </DataLink>
      )}
    </>
  );
};

/* Main Component */
const Love1HeroBanner: FC<GridHeroBannerComponent> = ({
  block,
  bgColor,
  extraData,
  viewerDevice,
  isMobileWidth,
  mobileViewMaxWidth,
}) => {
  const {
    titleAlign = ElementAlign.LEFT_STRICT,
    pAlign = ElementAlign.LEFT_STRICT,
    verticalPadding = CommonSize.MEDIUM,
    colorTheme = ColorTheme.WHITE,
  } = extraData || {};
  const richTextConfig = { titleAlign, pAlign };
  const { imagesCollection, contentTypesCollection } = block;
  const blocks = contentTypesCollection?.items as Array<Block>;
  const images = imagesCollection?.items as Array<ImageWrapper>;
  const verticalPaddingClass = classes[`sharedWrapperPaddingVertical${capitalizeFirstLetter(verticalPadding)}`];
  const { logo, title: titleBlock } = blocks.reduce(
    (accumulator, item) => ({ ...accumulator, [item.slug || '']: item }),
    {} as Record<string, Block>,
  );

  return (
    <section
      className={cn(styles.wrapper, verticalPaddingClass)}
      style={{ background: bgColor }}
    >
      <div className={cn(styles.love, classes[`themeColor${capitalizeFirstLetter(colorTheme)}`])}>
        <Logo
          block={logo}
          viewerDevice={viewerDevice}
          isMobileWidth={isMobileWidth}
        />
        <TitleBlock block={titleBlock} config={richTextConfig} />
      </div>
      <Love1HeroBannerBackground
        backgrounds={images}
        viewerDevice={viewerDevice}
        isMobileWidth={isMobileWidth}
        mobileViewMaxWidth={mobileViewMaxWidth}
      />
    </section>
  );
};

export default Love1HeroBanner;
