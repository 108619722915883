import Head from 'next/head';
import { FC } from 'react';
import parse from 'html-react-parser';

import { IStyles } from '../config/declarations';

const ContentfulStyles: FC<IStyles> = ({ styles, isLocal = false }) => {
  if (!styles?.fullText) {
    return null;
  }

  if (isLocal) {
    return (
      <>
        {parse(styles.fullText)}
      </>
    );
  }

  return (
    <Head>
      {parse(styles.fullText)}
    </Head>
  );
};

export default ContentfulStyles;
