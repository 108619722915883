/* eslint-disable react/jsx-props-no-spreading */
import Script from 'next/script';
import parse, {
  DOMNode,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';
import hash from 'object-hash';
import React, { Fragment, ReactNode } from 'react';
import { useRouter } from 'next/router';

import type { Text } from 'src/__generated__/graphqlTypes';
import {
  AppropriateCmsPathType,
  MappedGoogleServices,
  PageScriptsType,
} from 'types/objectTypes';
import {
  CLOSING_SCRIPTS,
  HEAD_SCRIPTS,
  IS_CLIENT_SIDE,
  PSYCHICS_READINGS_NEW_SLUG,
} from 'constants/constants';
import { Logger } from 'lib/logger';
import contentfulPages from 'lib/contentful/pages';
import { GAReplacementValue } from 'constants/enums';

import { replaceContentfulVariables } from './text.service';

type ScriptProperties = {
  isAfter: boolean,
  __html: string | null,
  props?: any
}

const inlineScripts = new Map();
const SHOULD_BE_CLOSING = 'after';
const initialScriptMappingObject: PageScriptsType = { [HEAD_SCRIPTS]: [], [CLOSING_SCRIPTS]: [] };

const applyNextScript = (__html?: string | null, data?: any) => {
  const key = __html || data.src;

  return (
    <Script
      key={key}
      strategy="afterInteractive"
      {...data}
      {...{ dangerouslySetInnerHTML: __html ? { __html } : undefined }}
    />
  );
};

const applyHtmlScript = (__html: string | null, data?: any) => {
  const key = __html || data.src;

  if (IS_CLIENT_SIDE) {
    return (
      <script
        key={key}
        {...data}
      >
        {/* eslint-disable-next-line no-eval */}
        {__html && eval(__html)}
      </script>
    );
  }

  return (
    <script
      key={key}
      {...data}
      {...{ dangerouslySetInnerHTML: __html ? { __html } : undefined }}
    />
  );
};

const getScriptTag = (isAfter: boolean, data: {__html: string | null, props?: string}) => (isAfter
  ? applyNextScript(data.__html, data.props)
  : applyHtmlScript(data.__html, data.props));

const getScriptOrderAndValue = (
  script?: Text,
): { scriptValue: string, scriptOrder: string } | null => {
  if (!script) {
    return null;
  }

  const scriptValue = script.fullText!;
  const scriptOrder = script.text!;

  if (!scriptValue) {
    return null;
  }

  return { scriptValue, scriptOrder };
};

const isInlineScriptNotUnique = (scriptElementHash: string) => {
  const isElementAlreadyExists = inlineScripts.get(scriptElementHash);

  return isElementAlreadyExists;
};

const getStorageWithUnpickScript = (
  scriptContent: string,
  storage: PageScriptsType,
  { isAfter, props, __html }: ScriptProperties,
) => {
  const storageProperty = isAfter
    ? CLOSING_SCRIPTS
    : HEAD_SCRIPTS;
  const scriptElementHash = hash.MD5(scriptContent);

  if (isInlineScriptNotUnique(scriptElementHash)) {
    return storage;
  }

  inlineScripts.set(scriptElementHash, scriptContent);

  const scriptTag = getScriptTag(isAfter, { __html, props });
  storage[storageProperty].push(scriptTag);

  return storage;
};

const getModifiedStorageIfScriptIsString = (
  scriptElement: string | ReactNode | Array<ReactNode>,
  isAfter: boolean,
  storage: PageScriptsType,
) => {
  if (typeof scriptElement !== 'string') {
    return null;
  }

  const scriptProperties: ScriptProperties = { isAfter, __html: scriptElement };

  return getStorageWithUnpickScript(scriptElement, storage, scriptProperties);
};

const getModifiedStorageIfInlineScript = (
  props: any,
  isAfter: boolean,
  storage: PageScriptsType,
) => {
  if (!props.dangerouslySetInnerHTML) {
    return null;
  }

  const { __html: scriptBody } = props.dangerouslySetInnerHTML;

  if (!scriptBody) {
    return storage;
  }

  const scriptProperties: ScriptProperties = { isAfter, props, __html: scriptBody };

  return getStorageWithUnpickScript(scriptBody, storage, scriptProperties);
};

const getModifiedStorageWithDefaultScript = (
  props: any,
  isAfter: boolean,
  storage: PageScriptsType,
) => {
  const { src } = props;

  if (!src) {
    return storage;
  }

  const scriptProperties: ScriptProperties = { isAfter, props, __html: null };

  return getStorageWithUnpickScript(src, storage, scriptProperties);
};

// eslint-disable-next-line no-undef
const mapScriptFromArray = (script: string | JSX.Element | JSX.Element[]) => {
  if (Array.isArray(script)) {
    const scripts = script.filter((script) => typeof script !== 'string');

    if (scripts.length !== 1) {
      return {};
    }

    return scripts[0];
  }

  return script;
};

export const mapPageScripts = (pageScripts: Array<Text>): PageScriptsType => pageScripts
  ?.reduce((storage, script) => {
    const scriptOrderAndValue = getScriptOrderAndValue(script);

    if (!scriptOrderAndValue) {
      return storage;
    }

    const { scriptValue, scriptOrder } = scriptOrderAndValue;

    try {
      const isAfter = scriptOrder === SHOULD_BE_CLOSING;
      const scriptElement = mapScriptFromArray(parse(scriptValue));

      const storageWithStringScriptOrNull = getModifiedStorageIfScriptIsString(
        scriptElement,
        isAfter,
        storage,
      );
      let isStorageModified = Boolean(storageWithStringScriptOrNull);

      if (isStorageModified) {
        return storageWithStringScriptOrNull!;
      }

      const { props } = scriptElement as any;

      if (!props) {
        return storage;
      }

      const storageWithInlineScriptOrNull = getModifiedStorageIfInlineScript(
        props,
        isAfter,
        storage,
      );
      isStorageModified = Boolean(storageWithInlineScriptOrNull);

      if (isStorageModified) {
        return storageWithInlineScriptOrNull!;
      }

      const storageWithDefaultScript = getModifiedStorageWithDefaultScript(
        props,
        isAfter,
        storage,
      );

      return storageWithDefaultScript;
    } catch (e) {
      Logger.error(e);

      return storage;
    }
  },
  initialScriptMappingObject);

export const mapPageStyles = (pageStyles?: Array<Text>): Array<ReactNode | null> => {
  if (!pageStyles) {
    return [];
  }

  return pageStyles.map((style) => {
    const { fullText: stringStyle, entryName } = style;

    if (!stringStyle) {
      return null;
    }

    try {
      const styleElement = parse(stringStyle);

      return (
        <React.Fragment key={entryName}>
          {styleElement}
        </React.Fragment>
      );
    } catch (e) {
      Logger.error(e);

      return null;
    }
  });
};

export const mapGoogleServices = (tags: Array<Text>): MappedGoogleServices => {
  const defaultOject = {
    scriptArray: [],
    styleArray: [],
    noscriptArray: [],
  };

  if (!tags?.length) {
    return defaultOject;
  }

  const mappedGoogleServices = tags.reduce((storage, tag) => {
    const { fullText: stringTag } = tag;

    if (!stringTag) {
      return storage;
    }

    const possibleTagsArray = ['script', 'noscript', 'style'];
    possibleTagsArray.forEach((tagName) => {
      const tagMatchingPattern = new RegExp(`<${tagName}\\b[^>]*>([\\s\\S]*?)</${tagName}>`, 'gm');
      const isMatchTagFromArray = stringTag.match(tagMatchingPattern);

      if (isMatchTagFromArray) {
        storage[`${tagName}Array`].push(tag);
      }
    });

    return storage;
  }, defaultOject as MappedGoogleServices);

  return mappedGoogleServices;
};

export const mapNoScriptTag = (noscript: Text) => {
  const { fullText, entryName } = noscript;
  try {
    const noscriptTag = parse(fullText!);

    return (
      <React.Fragment key={entryName}>
        {noscriptTag}
      </React.Fragment>
    );
  } catch (e) {
    Logger.error(e);

    return null;
  }
};

export const getAppropriatePathForResource = (path?: string): AppropriateCmsPathType => {
  if (!path) {
    return { isMatched: false, path: path || '' };
  }

  if (path.match(/^\/{0,1}psychics\/[a-zA-Z]+-\d{4}$/g) && IS_CLIENT_SIDE && window.location.pathname === `/${PSYCHICS_READINGS_NEW_SLUG}`) {
    return { isMatched: false, path: path.replace('psychics', 'psychics-new') };
  }

  const isCmsPage = contentfulPages.has(path);
  const isNotCommonLink = path.startsWith('http')
    || !(path[0] === '/' && path.length > 1)
    || path.startsWith('#');
  const hasDomain = path.startsWith(process.env.NEXT_PUBLIC_BASE_SERVER_URL!);

  if (isNotCommonLink || isCmsPage || hasDomain) {
    return { isMatched: false, path };
  }

  return { isMatched: true, path: `${process.env.NEXT_PUBLIC_BASE_SERVER_URL}${path}` };
};

export const escapeOutput = (toOutput: string = '') => toOutput
  .replace(/&/g, '&amp;')
  .replace(/</g, '&lt;')
  .replace(/>/g, '&gt;')
  .replace(/"/g, '&quot;')
  .replace(/'/g, '&#x27;')
  .replace(/\//g, '&#x2F;');

export const useHtmlParserReplace = () => {
  const router = useRouter();

  return (domNode: DOMNode): HTMLReactParserOptions['replace'] => {
    const element = domNode as Element;
    const elementNodeAttribs = element.attribs;

    if (elementNodeAttribs?.['data-label'] === `{${GAReplacementValue.FULL_URL}}`) {
      element.attribs['data-label'] = replaceContentfulVariables(
        elementNodeAttribs['data-label'],
        { [GAReplacementValue.FULL_URL]: `${process.env.NEXT_PUBLIC_BASE_SERVER_URL}${router.asPath}` },
      );
    }

    if (elementNodeAttribs?.['data-label'] === `{${GAReplacementValue.SHORT_URL}}`) {
      element.attribs['data-label'] = replaceContentfulVariables(
        elementNodeAttribs['data-label'],
        { [GAReplacementValue.SHORT_URL]: router.asPath },
      );
    }

    // @ts-ignore
    return domNode;
  };
};

export const renderInlineScripts = (script: ReactNode) => {
  // @ts-ignore
  if (script.props.src) {
    return null;
  }

  return (
  // @ts-ignore
    <Fragment key={script.key}>
      {script}
    </Fragment>
  );
};

export const renderExternalScripts = (script: ReactNode) => {
  // @ts-ignore
  const scriptProps = script.props as Record<string, any>;

  if (scriptProps?.src) {
    // @ts-ignore
    return <Script key={script.key} strategy="beforeInteractive" {...scriptProps} />;
  }

  return null;
};
