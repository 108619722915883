import { ItemsLayout, PsychicCardAppearance } from 'constants/enums';

import type { FilterToolbarSelectionsProps } from '../declarations';

export const requestBody: any = {
  AppId: 1002,
  CustFavorite: false,
  ResultType: 1,
  SearchOptions: null,
  PageSize: 3,
};

export const defaultFilterState: FilterToolbarSelectionsProps = {
  selectedFilters: {},
  selectedSortItem: { SortBy: '', SortByText: '' },
  totalResults: 0,
  totalAvailablePsychics: 0,
  filterPsychicsAvailable: 0,
  filterPsychicsOnCall: 0,
  totalBusyPsychics: 0,
  selectedLayout: ItemsLayout.ROW,
  psychicCardAppearance: PsychicCardAppearance.SIMPLE,
};
